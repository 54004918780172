import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { openCounselingChannelModal } from '../../../helpers/h-react-modals';
import { RootState } from '../../../redux/store';
import CounselingSectionHeader from './CounselingSectionHeader';
import { toast } from 'react-toastify';
import CounselingChannels from './CounselingChannels';
import { t } from 'i18next';

const CounselingMainRight = () => {
  const selectedCategory = useSelector((state: RootState) => state.counselingManagement.selectedCategoryId);

  const handleAddCategory = () => {
    if (!selectedCategory) {
      toast.error(t('CounselingManagement.toastSelectCategory') as string);
      return;
    }
    console.log(selectedCategory);
    openCounselingChannelModal();
  };

  return (
    <div className="flex-[3] mr-2">
      <div className="w-full h-full border border-solid border-[#dfe2e8] bg-white">
        <CounselingSectionHeader
          title={t('CounselingManagement.channelTitle') as string}
          onButtonClick={handleAddCategory}
        />
        <CounselingChannels />
      </div>
    </div>
  );
};

export default CounselingMainRight;
