import React from 'react';
import { useAppSelector } from '../../redux/hooks';

interface Props {
  Comp: () => JSX.Element;
}
const Auth = (Comp: () => JSX.Element) => {
  const { user } = useAppSelector((state) => state.auth);
  return user ? <Comp /> : <div></div>;
};

export default Auth;
