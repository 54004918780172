import { useTranslation } from 'react-i18next';
import {
  ColDef,
  GridApi,
  GridReadyEvent,
  RowDoubleClickedEvent,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useEffect, useMemo, useState } from 'react';
import { adminUserListApiForSp } from '../../../../api/admin/api-admin-user';
import { IspTenantListReturnValue_desc_T } from '../../../../interfaces/api/service-provider/I-api-admin-serviceProvider-fed';
import { IworkspaceRowData_col } from '../../../../interfaces/I-ag-column';
import { utcDateStringToLocaleDateString } from '../../../../utils/dateTimeHandler';
import InformaionModal from '../../../modals/tenantMng/InformaionModal';
import { TenantManagement_workSpaceAdminInfo_column } from './columns';
import CustomTooltip from './customTooltip';
import { AgCellTextCenter } from '../../../common/AgCustomCell';

interface Props {
  selectedTenant: IspTenantListReturnValue_desc_T | null;
  fromPortal: boolean;
}
const TenantManagement_workSpaceAdminInfo_Table = ({
  selectedTenant,
}: Props) => {
  const [show, setShow] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<
    [string, string][] | null
  >(null);
  const { t } = useTranslation();
  // AG_GRID
  const [gridApi, setGridApi] = useState<GridApi<IworkspaceRowData_col[]>>();
  const [rowData, setRowData] = useState<IworkspaceRowData_col[]>([]);
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
  console.log(selectedTenant);
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      resizable: true,
      minWidth: 100,
      tooltipComponent: 'customTooltip',
    };
  }, []);

  const onGridReady = (params: GridReadyEvent<IworkspaceRowData_col[]>) => {
    setGridApi(params.api);
  };

  useEffect(() => {
    setColumnDefs(TenantManagement_workSpaceAdminInfo_column(t));
  }, [t]);

  useEffect(() => {
    if (!gridApi || !selectedTenant) return;
  }, [selectedTenant, gridApi]);

  useEffect(() => {
    if (!selectedTenant) return;

    const fetchData = async () => {
      try {
        const adminUserRes = await adminUserListApiForSp({
          page: 1,
          tenantid: selectedTenant.tenantid,
        });
        const lastIndexOfPayments =
          selectedTenant.tenantdesc.payments.length - 1;
        const currentUsers = adminUserRes.data.data.totcount;
        const totalPrice = selectedTenant.tenantdesc.payments.reduce(
          (curr, acc) => {
            return curr + Number(acc.price);
          },
          0,
        );
        const licenseCount = selectedTenant.tenantdesc.personnellimit;
        const DATA = {
          tenantid: selectedTenant.tenantid,
          workspaceRestcode: selectedTenant.restcode,
          useFederation: selectedTenant.usefederation,
          updateTime: utcDateStringToLocaleDateString(
            selectedTenant.updatetime,
          ),
          planName: selectedTenant.tenantdesc.licesename
            ? selectedTenant.tenantdesc.licesename
            : 'Free',
          licenseCount: `${currentUsers}/${licenseCount}`,
          priceCycle:
            selectedTenant.tenantdesc.payments[0].paymentcycle === 'Y'
              ? 'Year'
              : 'Month',
          totalPrice: selectedTenant.tenantdesc.payments[0].licenseprice,
          storage: selectedTenant.tenantdesc.storage,
          workspaceName: selectedTenant.name,
          workspaceIdx: selectedTenant.tenantdesc.worksapceidx,
          tenantDesc: selectedTenant.tenantdesc.tenantdesc,
          portalUserName: selectedTenant.tenantdesc.portalusername,
          portalUserEmail: selectedTenant.tenantdesc.portaluseremail,
          portalUserPhone: selectedTenant.tenantdesc.portaluserphone,
        };
        setRowData([DATA]);
      } catch (err) {
        //console.log(err);
        setRowData([]);
      }
    };
    fetchData();
  }, [selectedTenant]);

  const togleModal = () => {
    setShow((state) => !state);
  };

  const onRowDoubleClicked = (
    event: RowDoubleClickedEvent<IworkspaceRowData_col>,
  ) => {
    if (event.data) {
      // setSelectedRowData(Object.entries(event.data));
      const { data } = event;
      const DATA: [string, string][] = [
        [t('ServiceMornitoring.tenantId'), data.tenantid],
        [
          t('ServiceMornitoring.useFederation'),
          data.useFederation
            ? t('ServiceMornitoring.use')
            : t('ServiceMornitoring.unUse'),
        ],
        [t('ServiceMornitoring.updateTime'), data.updateTime],
        [t('ServiceMornitoring.planType'), data.planName],
        [t('ServiceMornitoring.licenseCount'), data.licenseCount],
        [t('ServiceMornitoring.priceCycle'), data.priceCycle],
        [t('ServiceMornitoring.totalPrice'), data.totalPrice],
        [t('ServiceMornitoring.workspaceName'), data.workspaceName],
        [t('ServiceMornitoring.workspaceIdx'), data.workspaceIdx],
        [t('ServiceMornitoring.tenantDesc'), data.tenantDesc],
        [t('ServiceMornitoring.portalUserName'), data.portalUserName],
        [t('ServiceMornitoring.portalUserEmail'), data.portalUserEmail],
        [t('ServiceMornitoring.portalUserPhone'), data.portalUserPhone],
        [t('ServiceMornitoring.workspaceRestcode'), data.workspaceRestcode],
      ];
      setSelectedRowData(DATA);
      togleModal();
    }
  };

  return (
    <div className="w-full grow flex flex-col pb-defaultY overflow-hidden">
      <div className="ag-theme-alpine ag-theme-mycustomtheme grow w-full">
        <AgGridReact
          columnDefs={columnDefs}
          rowData={rowData}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          rowSelection={'single'}
          components={{ customTooltip: CustomTooltip }}
          tooltipShowDelay={0}
          onRowDoubleClicked={onRowDoubleClicked}
          overlayNoRowsTemplate={'\xa0'}
        ></AgGridReact>
      </div>
      {selectedRowData && (
        <InformaionModal
          show={show}
          setShow={togleModal}
          data={selectedRowData}
          title={t('ServiceMornitoring.productDetailInfo')}
        />
      )}
    </div>
  );
};

export default TenantManagement_workSpaceAdminInfo_Table;
