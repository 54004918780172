// style
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../../../styles/ag-grid.css';

import { CellClickedEvent, ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RULE_OPTION_LIST_TABLE_COLUMN } from './columns';

interface IrowData {
  key: string;
  value: string;
}

const RuleFuncMngOption_Table = () => {
  const dispatch = useAppDispatch();
  const { selectedRuleFuncHubOption, ruleFuncHubStatus } = useAppSelector(
    (state) => state.ruleFunctionManagement,
  );

  const [columnDefs, setColumnDefs] = useState<ColDef[]>(
    RULE_OPTION_LIST_TABLE_COLUMN,
  );
  const [rowData, setRowData] = useState<IrowData[]>([]);
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      resizable: true,
      minWidth: 100,
      editable: true,
    };
  }, []);

  const cellClickedListener = useCallback((event: CellClickedEvent<any>) => {
    // console.log('cellClicked', event);
    const rule = event.data;
  }, []);

  useEffect(() => {
    if (selectedRuleFuncHubOption.rulefunckey?.length < 1) {
      // console.log('위에 조건 진입');
      setRowData([]);
      return;
    }

    // console.log('위에 통과');
    setRowData([
      {
        key: '기능명',
        value: selectedRuleFuncHubOption.funcname,
      },
      {
        key: '기능 구분',
        value: selectedRuleFuncHubOption.funckind,
      },
      {
        key: selectedRuleFuncHubOption.funcfield1
          ? selectedRuleFuncHubOption.funcfield1
          : '옵션1',
        value: selectedRuleFuncHubOption.funcvalue1,
      },
      {
        key: selectedRuleFuncHubOption.funcfield2
          ? selectedRuleFuncHubOption.funcfield2
          : '옵션2',
        value: selectedRuleFuncHubOption.funcvalue2,
      },
      {
        key: '기본값',
        value: selectedRuleFuncHubOption.defaultvalue,
      },
    ]);
  }, [selectedRuleFuncHubOption]);

  // 옵저버
  useEffect(() => {
    if (ruleFuncHubStatus !== 'success') return;
    if (selectedRuleFuncHubOption.rulefunckey?.length < 1) {
      // console.log('아래 조건 진입');
      setRowData([]);
      return;
    }

    // console.log('옵저버 통과');

    setRowData([
      {
        key: '기능명',
        value: selectedRuleFuncHubOption.funcname,
      },
      {
        key: '기능 구분',
        value: selectedRuleFuncHubOption.funckind,
      },
      {
        key: selectedRuleFuncHubOption.funcfield1
          ? selectedRuleFuncHubOption.funcfield1
          : '옵션1',
        value: selectedRuleFuncHubOption.funcvalue1,
      },
      {
        key: selectedRuleFuncHubOption.funcfield2
          ? selectedRuleFuncHubOption.funcfield2
          : '옵션2',
        value: selectedRuleFuncHubOption.funcvalue2,
      },
      {
        key: '기본값',
        value: selectedRuleFuncHubOption.defaultvalue,
      },
    ]);
  }, [ruleFuncHubStatus]);

  return (
    <div className="w-full grow flex flex-col overflow-hidden">
      <div className="ag-theme-alpine ag-theme-mycustomtheme w-full grow">
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onCellClicked={cellClickedListener}
          rowSelection={'single'}
          overlayNoRowsTemplate={'\xa0'}
        ></AgGridReact>
      </div>
    </div>
  );
};

export default RuleFuncMngOption_Table;
