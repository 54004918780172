import '../../../styles/react-resizable.css';
import React, { ChangeEvent, useEffect, useState } from 'react';

import * as yup from 'yup';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

// 컴포넌트
import Button from '../../common/buttons/Button';
// 라이브러리
import Draggable from 'react-draggable';
import { Resizable } from 'react-resizable';
// API
// 리덕스
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { initUserAddStatus, setTempDeptList } from '../../../redux/slice/organizationUserManagementSlice';
// 인터페이스
import { ResizeCallbackData } from '../../../interfaces/I-modals';
import { SelectListValue } from '../../../interfaces/TypeSelectListbox';
//formik
import { Formik, Form, Field, FormikProps, useFormikContext } from 'formik';
import InputTextFormik from '../../common/inputs/InputTextFormik';
import { IUserFormData } from '../../../interfaces/api/I-api-admin-users';
import { addUserHandler } from '../../../helpers/h-orgUserMng';
import { userFormDataInitial } from '../../../helpers/h-formInitial';
import InputRadioCheckbox from '../../common/inputs/InputRadioCheckbox';
import { toast } from 'react-toastify';
import { getChildUserCountByDeptCode } from '../../../helpers/h-treeView';
import { CustomCell } from '../../../helpers/h-formik';
import { addFormSchema } from '../../../helpers/h-formSchema';
import NoImageUserAvatar from '../../../assets/image/profile_photo.png';
import { openAddDepartmentModal } from '../../../helpers/h-react-modals';
import { getMyRootDeptCode, getMyRootDeptName } from '../../../helpers/h-userInfo';

const userpwdhashtypes = [
  { id: 'SHA256', name: 'SHA256' },
  { id: 'BCRYPT', name: 'BCRYPT' },
];

interface Props {
  onClickClose: () => void;
}
const UserOrgAddUserModal: React.FC<Props> = ({ onClickClose }) => {
  const { t, ready } = useTranslation();
  const [formSchema, setFormSchema] = useState<yup.ObjectSchema<any> | null>(null);
  const dispatch = useAppDispatch();
  const { deptNameAndCode } = useAppSelector((state) => state.organizationUserManagement.orgInfo);
  const userType = useAppSelector((state) => state.user.type);
  let isFirst = true;
  const workspaceName = sessionStorage.getItem('workspacename') || ' ';
  const [deptListOptions, setDeptListOptions] = useState<SelectListValue[]>([]);
  const [selectedDept, setSelectedDept] = useState<SelectListValue>({
    id: '',
    name: '',
  });
  const [size, setSize] = useState({
    width: 400,
    height: 600,
  });
  const [profilePicUrl, setProfilePicUrl] = useState(NoImageUserAvatar);
  const selectedDepartments = useAppSelector((state) => state.organizationUserManagement.orgInfo.deptlist);

  const onResize = (e: React.SyntheticEvent<Element, Event>, data: ResizeCallbackData) => {
    setSize({
      width: data.size.width,
      height: data.size.height,
    });
  };

  const rootDeptCode = getMyRootDeptCode();
  const rootDeptName = getMyRootDeptName();

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePicUrl(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  // 화면 처음 렌더링시.. 셀렉트 리스트의 선택 value 설정
  useEffect(() => {
    if (deptListOptions.length < 1) return;

    if (isFirst) {
      setSelectedDept(deptListOptions[0]);
      isFirst = false;
    }
  }, [deptListOptions]);

  // 셀렉트 리스트 형식 맞추기
  useEffect(() => {
    if (!deptNameAndCode) return;

    setDeptListOptions(
      deptNameAndCode.map((v: { deptcode: string; deptname: string }) => ({
        id: v.deptcode,
        name: v.deptname,
      })),
    );
  }, [deptNameAndCode]);

  // 초기화 함수
  useEffect(() => {
    return () => {
      dispatch(initUserAddStatus());
    };
  }, []);

  //다국어 처리
  useEffect(() => {
    if (ready) {
      setFormSchema(addFormSchema(t));
    }
  }, [t, ready]);

  return (
    <Draggable handle="#handle">
      <Resizable
        handle={
          <div
            className="resizable-custom-handler absolute"
            style={{
              left: size.width - 19,
              top: size.height - 19,
            }}
          ></div>
        }
        height={size.height}
        width={size.width}
        onResize={onResize}
      >
        <div className="modal-wrapper">
          {/* WRAPPER */}
          <div
            className="box drag-box  flex flex-col px-[1.25rem]
            relative border border-modal-subTitle bg-white z-50 shadow-listbox-options
            rounded
          "
            style={{ width: `${size.width}px`, height: `${size.height}px` }}
          >
            {/* HEADER */}
            <div id="handle" className="min-h-[3.5rem] cursor-move w-full flex flex-col relative">
              <div className="grow font-bold w-full flex items-center truncate">
                {t('OrganizationUserManagement.userInfo')}
              </div>
            </div>
            {/* BODY */}
            {selectedDept.id.length > 0 ? (
              <Formik
                initialValues={{
                  ...userFormDataInitial(),
                  deptlist:
                    selectedDepartments.length > 0
                      ? selectedDepartments.map((dept) => ({
                          deptname: dept.deptname || '',
                          deptcode: dept.deptcode || '',
                        }))
                      : [{ deptname: rootDeptName || '', deptcode: rootDeptCode || '' }],
                  // deptlist:
                  //   selectedDepartments.length > 0
                  //     ? selectedDepartments.map((dept) => ({
                  //         deptname: dept.deptname || '',
                  //         deptcode: dept.deptcode || '',
                  //       }))
                  //     : [],
                  deptcode: selectedDept.id,
                  deptname: selectedDept.name,
                  compname: workspaceName,
                  isprojectadmin: 0,
                  isreservationoperator: 0,
                  userfield5: 's',
                }}
                validationSchema={formSchema}
                onSubmit={async (values) => {
                  console.log(values);
                  const userorder = await getChildUserCountByDeptCode(values.deptcode);

                  if (typeof userorder !== 'number') return;

                  const user = {
                    ...values,
                    userorder,
                    deptcode: '',
                    deptname: '',
                  };

                  addUserHandler(user);
                }}
              >
                {(formik: FormikProps<IUserFormData>) => {
                  useEffect(() => {
                    if (selectedDepartments.length > 0) {
                      formik.setFieldValue('deptlist', selectedDepartments);
                    }
                  }, [selectedDepartments]);

                  useEffect(() => {
                    dispatch(setTempDeptList(formik.values.deptlist));
                  }, [formik.values.deptlist]);

                  return (
                    <Form className="grow w-full flex flex-col justify-between overflow-y-auto">
                      {/* 텍스트 */}
                      <div className="grow w-full flex flex-col gap-3 overflow-y-auto">
                        {/* 프로필 사진 */}
                        <div className="relative m-auto w-36 h-36">
                          <img src={profilePicUrl} alt="Profile" className="w-full h-full rounded-full object-cover" />
                        </div>
                        <CustomCell
                          title={t('OrganizationUserManagement.userId')}
                          required
                          content={
                            <Field
                              component={InputTextFormik}
                              name={`userid`}
                              value={`userid`}
                              handleClear={() => {
                                formik.setFieldValue(`userid`, '');
                              }}
                            />
                          }
                        />
                        <CustomCell
                          title={t('OrganizationUserManagement.password')}
                          required
                          content={
                            <Field
                              component={InputTextFormik}
                              name={`userpwd`}
                              value={`userpwd`}
                              handleClear={() => {
                                formik.setFieldValue(`userpwd`, '');
                              }}
                            />
                          }
                        />
                        <CustomCell
                          title={t('OrganizationUserManagement.name')}
                          required
                          content={
                            <Field
                              component={InputTextFormik}
                              name={`usernameKo`}
                              value={`usernameKo`}
                              handleClear={() => {
                                formik.setFieldValue(`usernameKo`, '');
                              }}
                            />
                          }
                        />
                        <CustomCell
                          toolTipId="usernameEn-tooltip"
                          isToolTip={true}
                          toolTipContent={
                            <>
                              {t('OrganizationUserManagement.usernameEnTooltip') as string}
                              <br />
                              {t('OrganizationUserManagement.usernameEnTooltip2') as string}
                            </>
                          }
                          title={t('OrganizationUserManagement.nameEn')}
                          content={
                            <Field
                              component={InputTextFormik}
                              placeholder={t('OrganizationUserManagement.h-placeholder')}
                              name={`usernameEn`}
                              value={`usernameEn`}
                              handleClear={() => {
                                formik.setFieldValue(`usernameEn`, '');
                              }}
                            />
                          }
                        />

                        <CustomCell
                          toolTipId="department-tooltip"
                          isToolTip={true}
                          toolTipContent={
                            <>
                              {t('OrganizationUserManagement.departmentTooltip') as string}
                              <br />
                              {t('OrganizationUserManagement.departmentTooltip2') as string}
                            </>
                          }
                          title={t('OrganizationUserManagement.department')}
                          content={
                            <div className="flex flex-col gap-2 w-full">
                              {(formik.values.deptlist.length > 0
                                ? formik.values.deptlist
                                : [{ deptname: '', deptcode: '' }]
                              ).map((dept, index) => (
                                <div key={dept.deptcode || index} className="flex items-center gap-2">
                                  <Field
                                    component={InputTextFormik}
                                    isReadOnly={true}
                                    grow={true}
                                    placeholder={t('OrganizationUserManagement.h-placeholder2')}
                                    name={`deptlist[${index}].deptname`}
                                    value={formik.values.deptlist[index]?.deptname || ''}
                                    className="flex-grow w-full"
                                    handleClear={() => {
                                      const updatedDeptlist = [...formik.values.deptlist];

                                      if (index === 0 && updatedDeptlist.length === 1) {
                                        console.log('dd1');
                                        formik.setFieldValue('deptlist', []);
                                      } else {
                                        updatedDeptlist.splice(index, 1);
                                        console.log('dd2');
                                        formik.setFieldValue('deptlist', updatedDeptlist);
                                      }
                                    }}
                                  />
                                  {index === 0 && (
                                    <Button
                                      kind="standard"
                                      label={t('OrganizationUserManagement.departmentDesignation')}
                                      onClick={openAddDepartmentModal}
                                      className="w-24"
                                    />
                                  )}
                                </div>
                              ))}
                            </div>
                          }
                        />
                        <CustomCell
                          title={t('OrganizationUserManagement.position')}
                          content={
                            <Field
                              component={InputTextFormik}
                              name={`position`}
                              value={`position`}
                              handleClear={() => {
                                formik.setFieldValue(`position`, '');
                              }}
                            />
                          }
                        />
                        <CustomCell
                          title={t('OrganizationUserManagement.duty')}
                          content={
                            <Field
                              component={InputTextFormik}
                              name={`duty`}
                              value={`duty`}
                              handleClear={() => {
                                formik.setFieldValue(`duty`, '');
                              }}
                            />
                          }
                        />
                        <CustomCell
                          title={t('OrganizationUserManagement.grade')}
                          content={
                            <Field
                              component={InputTextFormik}
                              name={`grade`}
                              value={`grade`}
                              handleClear={() => {
                                formik.setFieldValue(`grade`, '');
                              }}
                            />
                          }
                        />
                        <CustomCell
                          title={t('OrganizationUserManagement.mobilePhone')}
                          content={
                            <Field
                              component={InputTextFormik}
                              name={`telmobile`}
                              value={`telmobile`}
                              handleClear={() => {
                                formik.setFieldValue(`telmobile`, '');
                              }}
                            />
                          }
                        />
                        <CustomCell
                          title={t('OrganizationUserManagement.telOffice')}
                          content={
                            <Field
                              component={InputTextFormik}
                              name={`teloffice`}
                              value={`teloffice`}
                              handleClear={() => {
                                formik.setFieldValue(`teloffice`, '');
                              }}
                            />
                          }
                        />
                        <CustomCell
                          title={t('OrganizationUserManagement.extensionNo')}
                          content={
                            <Field
                              component={InputTextFormik}
                              name={`telextension`}
                              value={`telextension`}
                              handleClear={() => {
                                formik.setFieldValue(`telextension`, '');
                              }}
                            />
                          }
                        />
                        <CustomCell
                          title={t('OrganizationUserManagement.email')}
                          content={
                            <Field
                              component={InputTextFormik}
                              name={`email`}
                              value={`email`}
                              handleClear={() => {
                                formik.setFieldValue(`email`, '');
                              }}
                            />
                          }
                        />

                        {/* 신분(학생/학부모/선생) */}
                        {userType === 'edu' && (
                          <CustomCell
                            title={t('OrganizationUserManagement.userField5')}
                            content={
                              <div className="w-full h-9 flex gap-6 mb-1">
                                <div className="gap-3 jcac">
                                  <Field
                                    component={InputRadioCheckbox}
                                    kind="radio"
                                    name="userField5"
                                    id="userFieldTeacher"
                                    value={`t`}
                                    checked={formik.values.userfield5 === 't'}
                                    onChange={() => {
                                      formik.setFieldValue('userfield5', 't');
                                    }}
                                  />
                                  <label htmlFor="userFieldTeacher" className="truncate">
                                    {t('OrganizationUserManagement.teacher')}
                                  </label>
                                </div>
                                <div className="gap-3 jcac">
                                  <Field
                                    component={InputRadioCheckbox}
                                    kind="radio"
                                    name="userField5"
                                    id="userFieldStudent"
                                    value={`s`}
                                    checked={formik.values.userfield5 === 's'}
                                    onChange={() => {
                                      formik.setFieldValue('userfield5', 's');
                                    }}
                                  />
                                  <label htmlFor="userFieldStudent" className="truncate">
                                    {t('OrganizationUserManagement.student')}
                                  </label>
                                </div>
                                <div className="gap-3 jcac">
                                  <Field
                                    component={InputRadioCheckbox}
                                    kind="radio"
                                    name="userField5"
                                    id="userFieldParent"
                                    value={`p`}
                                    checked={formik.values.userfield5 === 'p'}
                                    onChange={() => {
                                      formik.setFieldValue('userfield5', 'p');
                                    }}
                                  />
                                  <label htmlFor="userFieldParent" className="truncate">
                                    {t('OrganizationUserManagement.parent')}
                                  </label>
                                </div>
                              </div>
                            }
                          />
                        )}

                        {/* 자원 관리자 지정 */}
                        <CustomCell
                          title={t('OrganizationUserManagement.isreservationoperator')}
                          content={
                            <div className="w-full h-9 flex gap-6">
                              <div className="gap-3 jcac">
                                <Field
                                  component={InputRadioCheckbox}
                                  kind="radio"
                                  name="isreservationoperator"
                                  id="isreservationoperatorTrue"
                                  value={1}
                                  checked={formik.values.isreservationoperator === 1}
                                  onChange={() => {
                                    formik.setFieldValue('isreservationoperator', 1);
                                  }}
                                />
                                <label htmlFor="isreservationoperatorTrue" className="truncate">
                                  {t('OrganizationUserManagement.enable')}
                                </label>
                              </div>
                              <div className="gap-3 jcac">
                                <Field
                                  component={InputRadioCheckbox}
                                  kind="radio"
                                  name="isreservationoperator"
                                  id="isreservationoperatorFalse"
                                  value={0}
                                  checked={formik.values.isreservationoperator === 0}
                                  onChange={() => {
                                    formik.setFieldValue('isreservationoperator', 0);
                                  }}
                                />
                                <label htmlFor="isreservationoperatorFalse" className="truncate">
                                  {t('OrganizationUserManagement.disable')}
                                </label>
                              </div>
                            </div>
                          }
                        />

                        {/* 프로젝트 관리자 지정 */}
                        <CustomCell
                          title={t('OrganizationUserManagement.projectAdmin')}
                          content={
                            <div className="w-full h-9 flex gap-6">
                              <div className="gap-3 jcac">
                                <Field
                                  component={InputRadioCheckbox}
                                  kind="radio"
                                  name="isprojectadmin"
                                  id="isProjectAdminTrue"
                                  value={1}
                                  checked={formik.values.isprojectadmin === 1}
                                  onChange={() => {
                                    formik.setFieldValue('isprojectadmin', 1);
                                  }}
                                />
                                <label htmlFor="isProjectAdminTrue" className="truncate">
                                  {t('OrganizationUserManagement.enable')}
                                </label>
                              </div>
                              <div className="gap-3 jcac">
                                <Field
                                  component={InputRadioCheckbox}
                                  kind="radio"
                                  name="isprojectadmin"
                                  id="isProjectAdminFalse"
                                  value={0}
                                  checked={formik.values.isprojectadmin === 0}
                                  onChange={() => {
                                    formik.setFieldValue('isprojectadmin', 0);
                                  }}
                                />
                                <label htmlFor="isProjectAdminFalse" className="truncate">
                                  {t('OrganizationUserManagement.disable')}
                                </label>
                              </div>
                            </div>
                          }
                        />
                        {/* <CustomCell
                          title="사진위치"
                          content={
                            <Field
                              component={InputTextFormik}
                              name={`picrul`}
                              value={`picrul`}
                              handleClear={() => {
                                formik.setFieldValue(`picrul`, '');
                              }}
                            />
                          }
                        />
                        <CustomCell
                          title="사용자 필드1"
                          content={
                            <Field
                              component={InputTextFormik}
                              name={`userfield1`}
                              value={`userfield1`}
                              handleClear={() => {
                                formik.setFieldValue(`userfield1`, '');
                              }}
                            />
                          }
                        />
                        <CustomCell
                          title="사용자 필드2"
                          content={
                            <Field
                              component={InputTextFormik}
                              name={`userfield2`}
                              value={`userfield2`}
                              handleClear={() => {
                                formik.setFieldValue(`userfield2`, '');
                              }}
                            />
                          }
                        />
                        <CustomCell
                          title="사용자 필드3"
                          content={
                            <Field
                              component={InputTextFormik}
                              name={`userfield3`}
                              value={`userfield3`}
                              handleClear={() => {
                                formik.setFieldValue(`userfield3`, '');
                              }}
                            />
                          }
                        />
                        <CustomCell
                          title="사용자 필드4"
                          content={
                            <Field
                              component={InputTextFormik}
                              name={`userfield4`}
                              value={`userfield4`}
                              handleClear={() => {
                                formik.setFieldValue(`userfield4`, '');
                              }}
                            />
                          }
                        />
                        <CustomCell
                          title="사용자 필드5"
                          content={
                            <Field
                              component={InputTextFormik}
                              name={`userfield5`}
                              value={`userfield5`}
                              handleClear={() => {
                                formik.setFieldValue(`userfield5`, '');
                              }}
                            />
                          }
                        /> */}
                      </div>
                      {/* 취소 / 저장 버튼 */}
                      <div className="min-h-[6rem] w-full  jcac">
                        <div className="flex gap-5">
                          <Button onClick={onClickClose} kind="cancel" label={t('OrganizationUserManagement.cancel')} />
                          <Button
                            onClick={() => {
                              if (formik.values.usernameEn === '') {
                                formik.setFieldValue('usernameEn', formik.values.usernameKo);
                              }
                              formik.handleSubmit();
                            }}
                            kind="primary"
                            label={t('OrganizationUserManagement.save')}
                          />
                        </div>
                      </div>
                      <AutoSubmitToken onClickClose={onClickClose} />
                    </Form>
                  );
                }}
              </Formik>
            ) : null}
          </div>
        </div>
      </Resizable>
    </Draggable>
  );
};

const AutoSubmitToken = ({ onClickClose }: { onClickClose: () => void }) => {
  const { userAddStatus } = useAppSelector((state) => state.organizationUserManagement.userInfo);
  // Grab values and submitForm from context
  const event = useFormikContext<IUserFormData>();

  useEffect(() => {
    if (userAddStatus === 'idle') return;
    if (userAddStatus === 'fail') {
      toast.error(String(t('OrganizationUserManagement.toastFail')));
      return;
    }
    if (userAddStatus === 'success') {
      toast.success(String(t('OrganizationUserManagement.toastModifySuccess')));
      // console.log('Token render');
      onClickClose();
    }
  }, [userAddStatus]);

  return null;
};

export default UserOrgAddUserModal;
