import React from 'react';

const TfaMail = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="0 0 60 60"
    >
      <path style={{ fill: 'none' }} d="M0 0h60v60H0z" />
      <g data-name="ico_mail">
        <g data-name="그룹 1991" transform="translate(14 20)">
          <path
            data-name="패스 324"
            d="m226.75 556.708 10.859 9.255 3.807 3.245 14.667-12.5"
            transform="translate(-225.417 -555.709)"
            style={{
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              stroke: '#1f62b9',
              strokeWidth: '2px',
              fill: 'none',
            }}
          />
          <path
            data-name="선 30"
            transform="translate(4.267 11)"
            style={{
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              stroke: '#1f62b9',
              strokeWidth: '2px',
              fill: 'none',
            }}
            d="m0 8 7.467-8"
          />
          <path
            data-name="선 31"
            transform="translate(20.267 11)"
            style={{
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              stroke: '#1f62b9',
              strokeWidth: '2px',
              fill: 'none',
            }}
            d="M7.467 8 0 0"
          />
          <rect
            data-name="사각형 1405"
            width="32"
            height="22"
            rx="2"
            style={{ stroke: '#1f62b9', strokeWidth: '2px', fill: 'none' }}
          />
        </g>
      </g>
    </svg>
  );
};

export default TfaMail;
