import axios from 'axios';
import { IpluralAxiosResponse } from '../../interfaces/api/I-axios';
import { axiosErrorHandler, axiosInstance } from '../axiosInstance';
import { SERVER_SUFFIX } from '../../const/server';

// const opt = {
//   headers: {
//     'Content-Type': 'application/json',
//     'X-API-KEY':
//       'SrrWKi+bq9pXG4ef5jYy4fLPPBgyIPnsJZfJ/8rhV4j2EIwxZqojIZ5AW75iiUB1R2CGgDAeI7x4jy4kfnFdqZ7pTJAjZJfsYUr0CKhZadeh6lma7sWbNvXy3Qc8S1xrTh76BywZBv2k4gQWJLbjeQ==',
//   },
// };
const adminGraphInfo = {
  url: {
    list: '/api/v1/admin-mg-tp/graph/dashboard/list',
  },
};

export interface IadminGraphListApiBody {
  tenantid: string;
  graphtype: number;
  refresh: number;
  lasthour: number;
  theme: 'light' | 'dark';
}
export interface IadminGraph {
  tenantid: string;
  orgcode: string;
  graphid: string;
  graphtype: number;
  graphname: string;
  graphdata: string;
  updatetime: string;
}

// list
export const adminGraphListApi = (props: IadminGraphListApiBody) => {
  const { url } = adminGraphInfo;

  const URL = `https://${props.tenantid}${SERVER_SUFFIX}${url.list}`;
  return axiosInstance.post<IpluralAxiosResponse<IadminGraph>>(URL, props);
};
