import { errorMessage } from '../const/formErrorMessage';
import { patchFileVersionRegEx } from '../const/sp/upgradeManagement';
import * as yup from 'yup';

// 아이디 중복 추가
import { userIdDup } from '../api/hrs/api-hrs-users';

export const loginFormSchema = (t: (key: string) => string) => {
  const ID_ERR = t('SpLoginPage.enterTheId');
  const PASSWORD_ERR = t('SpLoginPage.enterThePassword');

  return yup.object().shape({
    id: yup.string().required(ID_ERR),
    pwd: yup.string().required(PASSWORD_ERR),
  });
};

export const addFormSchema = (t: (key: string) => string) => {
  const DEFAULT_REQ_ERR = t('Helpers.formSchema.requireMsg');
  const DUPLICATE_ID_ERROR = 'This ID already Exists.';
  return yup.object().shape({
    usernameKo: yup.string().required(DEFAULT_REQ_ERR),
    usernameEn: yup.string().nullable(),
    userid: yup
      .string()
      .required(DEFAULT_REQ_ERR)
      .test('checkDup', DUPLICATE_ID_ERROR, async function (value) {
        try {
          const param = sessionStorage.getItem('tenantid') + '.' + value;
          const response = await userIdDup(param);
          return this.createError({
            message: t('Helpers.formSchema.idExist'),
          });
        } catch (error) {
          return true;
        }
      }),
    userpwd: yup.string().required(DEFAULT_REQ_ERR),
    position: yup.string().nullable(),
    duty: yup.string().nullable(),
    grade: yup.string().nullable(),
    deptlist: yup
      .array()
      .of(
        yup.object().shape({
          deptcode: yup.string().required(DEFAULT_REQ_ERR),
          deptname: yup.string().required(DEFAULT_REQ_ERR),
        }),
      )
      .required(),
    deptcode: yup.string().required(DEFAULT_REQ_ERR),
    deptname: yup.string().required(DEFAULT_REQ_ERR),
    empno: yup.string().nullable(),
    telhome: yup.string().nullable(),
    teloffice: yup.string().nullable(),
    telextension: yup.string().nullable(),
    telmobile: yup.string().nullable(),
    email: yup.string().nullable(),
    picurl: yup.string().nullable(),
    zipno: yup.string().nullable(),
    address: yup.string().nullable(),
    birthtype: yup.string().nullable(),
    birthday: yup.number().nullable(),
    gender: yup.string().nullable(),
    duties: yup.string().nullable(),
    officehour: yup.string().nullable(),
    workstatus: yup.string().nullable(),
    compcode: yup.string().nullable(),
    compname: yup.string().nullable(),
    userorder: yup.number().required(DEFAULT_REQ_ERR),
    userfield1: yup.string().nullable(),
    userfield2: yup.string().nullable(),
    userfield3: yup.string().nullable(),
    userfield4: yup.string().nullable(),
    userfield5: yup.string().nullable(),
    updatetype: yup.string().nullable(),
    userpwdhashtype: yup.string().required(DEFAULT_REQ_ERR),
  });
};

export const modifyFormSchema = (t: (key: string) => string) => {
  const DEFAULT_REQ_ERR = t('Helpers.formSchema.requireMsg');
  return yup.object().shape({
    usernameKo: yup.string().required(DEFAULT_REQ_ERR),
    usernameEn: yup.string().nullable(),
    userid: yup.string().required(DEFAULT_REQ_ERR),
    userpwd: yup.string().nullable(),
    position: yup.string().nullable(),
    duty: yup.string().nullable(),
    grade: yup.string().nullable(),
    deptcode: yup.string().nullable(),
    deptname: yup.string().nullable(),
    empno: yup.string().nullable(),
    telhome: yup.string().nullable(),
    teloffice: yup.string().nullable(),
    telextension: yup.string().nullable(),
    telmobile: yup.string().nullable(),
    email: yup.string().nullable(),
    picurl: yup.string().nullable(),
    zipno: yup.string().nullable(),
    address: yup.string().nullable(),
    birthtype: yup.string().nullable(),
    birthday: yup.number().nullable(),
    gender: yup.string().nullable(),
    duties: yup.string().nullable(),
    officehour: yup.string().nullable(),
    workstatus: yup.string().nullable(),
    compcode: yup.string().nullable(),
    compname: yup.string().nullable(),
    userorder: yup.number().required(DEFAULT_REQ_ERR),
    userfield1: yup.string().nullable(),
    userfield2: yup.string().nullable(),
    userfield3: yup.string().nullable(),
    userfield4: yup.string().nullable(),
    userfield5: yup.string().nullable(),
    updatetype: yup.string().nullable(),
    userpwdhashtype: yup.string().nullable(),
  });
};

// import * as yup from 'yup';
// import { errorMessage } from '../const/formErrorMessage';
// import { patchFileVersionRegEx } from '../const/sp/upgradeManagement';
// import { t } from 'i18next';

// const DEFAULT_REQ_ERR = String(t('Helpers.formSchema.requireMsg'));
// const NOT_NUM_ERR = String(t('Helpers.formSchema.requireNoMsg'));
// // 로그인
// export const spLoginFormSchema = yup.object().shape({
//   id: yup.string().required(errorMessage.sp.login.id),
//   pwd: yup.string().required(errorMessage.sp.login.pwd),
// });

// // 사용자 추가
// export const userAddFormSchema = yup.object().shape({
//   usernameKo: yup.string().required(DEFAULT_REQ_ERR),
//   usernameEn: yup.string().nullable(),
//   userid: yup.string().required(DEFAULT_REQ_ERR),
//   userpwd: yup.string().required(DEFAULT_REQ_ERR),
//   position: yup.string().nullable(),
//   duty: yup.string().nullable(),
//   grade: yup.string().nullable(),
//   deptcode: yup.string().required(DEFAULT_REQ_ERR),
//   deptname: yup.string().required(DEFAULT_REQ_ERR),
//   empno: yup.string().nullable(),
//   telhome: yup.string().nullable(),
//   teloffice: yup.string().nullable(),
//   telextension: yup.string().nullable(),
//   telmobile: yup.string().nullable(),
//   email: yup.string().nullable(),
//   picurl: yup.string().nullable(),
//   zipno: yup.string().nullable(),
//   address: yup.string().nullable(),
//   birthtype: yup.string().nullable(),
//   birthday: yup.number().nullable(),
//   gender: yup.string().nullable(),
//   duties: yup.string().nullable(),
//   officehour: yup.string().nullable(),
//   workstatus: yup.string().nullable(),
//   compcode: yup.string().nullable(),
//   compname: yup.string().nullable(),
//   userorder: yup.number().required(DEFAULT_REQ_ERR),
//   userfield1: yup.string().nullable(),
//   userfield2: yup.string().nullable(),
//   userfield3: yup.string().nullable(),
//   userfield4: yup.string().nullable(),
//   userfield5: yup.string().nullable(),
//   updatetype: yup.string().nullable(),
//   userpwdhashtype: yup.string().required(DEFAULT_REQ_ERR),
// });
// export const userModifyFormSchema = yup.object().shape({
//   usernameKo: yup.string().required(DEFAULT_REQ_ERR),
//   usernameEn: yup.string().nullable(),
//   userid: yup.string().required(DEFAULT_REQ_ERR),
//   userpwd: yup.string().nullable(),
//   position: yup.string().nullable(),
//   duty: yup.string().nullable(),
//   grade: yup.string().nullable(),
//   deptcode: yup.string().required(DEFAULT_REQ_ERR),
//   deptname: yup.string().required(DEFAULT_REQ_ERR),
//   empno: yup.string().nullable(),
//   telhome: yup.string().nullable(),
//   teloffice: yup.string().nullable(),
//   telextension: yup.string().nullable(),
//   telmobile: yup.string().nullable(),
//   email: yup.string().nullable(),
//   picurl: yup.string().nullable(),
//   zipno: yup.string().nullable(),
//   address: yup.string().nullable(),
//   birthtype: yup.string().nullable(),
//   birthday: yup.number().nullable(),
//   gender: yup.string().nullable(),
//   duties: yup.string().nullable(),
//   officehour: yup.string().nullable(),
//   workstatus: yup.string().nullable(),
//   compcode: yup.string().nullable(),
//   compname: yup.string().nullable(),
//   userorder: yup.number().required(DEFAULT_REQ_ERR),
//   userfield1: yup.string().nullable(),
//   userfield2: yup.string().nullable(),
//   userfield3: yup.string().nullable(),
//   userfield4: yup.string().nullable(),
//   userfield5: yup.string().nullable(),
//   updatetype: yup.string().nullable(),
//   userpwdhashtype: yup.string().nullable(),
// });

// // rule/func/mng 기능 추가
// export const ruleFuncAddFormSchema = yup.object().shape({
//   funccode: yup.string().required(DEFAULT_REQ_ERR),
//   funckind: yup.string().required(DEFAULT_REQ_ERR),
//   funcname: yup.string().required(DEFAULT_REQ_ERR),
//   allowdup: yup.number().required(DEFAULT_REQ_ERR),
//   funcfield1: yup.string().required(DEFAULT_REQ_ERR),
//   funcfield2: yup.string().required(DEFAULT_REQ_ERR),
//   defaultvalue: yup.string().required(DEFAULT_REQ_ERR),
// });

// // sp/client/patch/upload 환경파일 업로드
// export const clientPatchUploadSchema = yup.object().shape({
//   ver: yup
//     .string()
//     .matches(
//       patchFileVersionRegEx,
//       '정수.정수.정수 형식으로 입력해주세요 eg) 1.4.2',
//     )
//     .required(DEFAULT_REQ_ERR),
//   product: yup.string().required(DEFAULT_REQ_ERR),
//   dev: yup.string().required(DEFAULT_REQ_ERR),
//   subdev: yup.string().required(DEFAULT_REQ_ERR),
//   envFile: yup.mixed().required(DEFAULT_REQ_ERR),
// });

// // autoScale/apply
// export const autoScaleApplySchema = yup.object().shape({
//   replicasMin: yup
//     .number()
//     .max(20, '20 이하의 수 까지 허용됩니다.')
//     .typeError(NOT_NUM_ERR)
//     .required(DEFAULT_REQ_ERR),
//   replicasMax: yup
//     .number()
//     .max(20, '20 이하의 수 까지 허용됩니다.')
//     .typeError(NOT_NUM_ERR)
//     .required(DEFAULT_REQ_ERR),
//   behaviorUpPeriod: yup
//     .number()
//     .max(60, '60 이하의 수 까지 허용됩니다.')
//     .typeError(NOT_NUM_ERR)
//     .required(DEFAULT_REQ_ERR),
//   behaviorUpCount: yup
//     .number()
//     .max(20, '20 이하의 수 까지 허용됩니다.')
//     .typeError(NOT_NUM_ERR)
//     .required(DEFAULT_REQ_ERR),
//   behaviorDownPeriod: yup
//     .number()
//     .max(60, '60 이하의 수 까지 허용됩니다.')
//     .typeError(NOT_NUM_ERR)
//     .required(DEFAULT_REQ_ERR),
//   behaviorDownCount: yup
//     .number()
//     .max(20, '20 이하의 수 까지 허용됩니다.')
//     .typeError(NOT_NUM_ERR)
//     .required(DEFAULT_REQ_ERR),
//   metricAverage: yup
//     .number()
//     .max(90, '90 이하의 수 까지 허용됩니다.')
//     .typeError(NOT_NUM_ERR)
//     .required(DEFAULT_REQ_ERR),
// });

// // [
// //   {
// //     languagelist: yup.array().of(
// //       yup.object().shape({
// //         name: yup.string().required("Name required"),
// //         email: yup.string()
// //           .required("email required")
// //           .email("Enter valid email")
// //       })
// //     ),
// //     userid: yup.string().required(DEFAULT_REQ_ERR),
// //     userpwd: yup.string().required(DEFAULT_REQ_ERR),
// //     position: yup.string().nullable(),
// //     duty: yup.string().nullable(),
// //     grade: yup.string().nullable(),
// //     deptcode: yup.string().required(DEFAULT_REQ_ERR),
// //     deptname: yup.string().required(DEFAULT_REQ_ERR),
// //     empno: yup.string().nullable(),
// //     telhome: yup.string().nullable(),
// //     teloffice: yup.string().nullable(),
// //     telextension: yup.string().nullable(),
// //     telmobile: yup.string().nullable(),
// //     email: yup.string().nullable(),
// //     picurl: yup.string().nullable(),
// //     zipno: yup.string().nullable(),
// //     address: yup.string().nullable(),
// //     birthtype: yup.string().nullable(),
// //     birthday: yup.string().nullable(),
// //     gender: yup.string().nullable(),
// //     duties: yup.string().nullable(),
// //     officehour: yup.string().nullable(),
// //     workstatus: yup.string().nullable(),
// //     compcode: yup.string().nullable(),
// //     compname: yup.string().nullable(),
// //     userorder: yup.number().required(DEFAULT_REQ_ERR),
// //     userfield1: yup.string().nullable(),
// //     userfield2: yup.string().nullable(),
// //     userfield3: yup.string().nullable(),
// //     userfield4: yup.string().nullable(),
// //     userfield5: yup.string().nullable(),
// //     updatetype: yup.string().nullable(),
// //   },
// // ],
