import { useTranslation } from 'react-i18next';
import {
  ColDef,
  GridApi,
  GridReadyEvent,
  RowDoubleClickedEvent,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { spUksGetsApi } from '../../../../api/admin/service-provider/api-admin-sp-uks';
import { IspTenantListReturnValue_desc_T } from '../../../../interfaces/api/service-provider/I-api-admin-serviceProvider-fed';
import { IspUksGetsApiResponse } from '../../../../interfaces/api/service-provider/I-api-admin-sp-uks';
import InformaionModal from '../../../modals/tenantMng/InformaionModal';
import { TenantManagement_serviceOperate_column } from './columns';

interface Props {
  selectedTenant: IspTenantListReturnValue_desc_T | null;
}
const TenantManagement_serviceOperate_Table = ({ selectedTenant }: Props) => {
  const [modalData, setModalData] = useState<[string, string][] | null>(null);
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  // AG_GRID
  const [gridApi, setGridApi] = useState<GridApi<IspUksGetsApiResponse[]>>();
  const [rowData, setRowData] = useState<IspUksGetsApiResponse[]>([]);
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      resizable: true,
      minWidth: 100,
      sortable: true,
      filter: true,
    };
  }, []);

  const togleModal = () => {
    setShow((state) => !state);
  };

  const onGridReady = (params: GridReadyEvent<IspUksGetsApiResponse[]>) => {
    setGridApi(params.api);
  };

  const onRowDoubleClicked = (
    event: RowDoubleClickedEvent<IspUksGetsApiResponse>,
  ) => {
    if (event.data) {
      console.log(event.data);
      const { data } = event;
      const { podIPs, ...serviceInfo } = data;
      const dataForModal = Object.entries(serviceInfo);
      const podIP = podIPs[0];
      const podIP_keyVal = Object.entries(podIP);
      dataForModal.push(...podIP_keyVal);
      const dataForModalToString = dataForModal.map((v) => {
        const v1 = v[0].toString();
        const v2 = v[1].toString();
        return [v1, v2] as [string, string];
      });
      setModalData(dataForModalToString);
      togleModal();
    }
  };

  useEffect(() => {
    if (!gridApi || !selectedTenant) return;
  }, [selectedTenant, gridApi]);

  useEffect(() => {
    if (!selectedTenant) return;
    const fetchRowData = async () => {
      const namespace = `ucworks-${selectedTenant.tenantid}`;
      const kind = 'pods';
      try {
        const serverList = await spUksGetsApi({
          namespace,
          kind,
        });
        if (!serverList) return;

        const data = serverList.data.data.result;
        setRowData(data);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          return error;
        } else {
          return 'An unexpected error occurred';
        }
      }
    };
    fetchRowData();
  }, [selectedTenant]);

  useEffect(() => {
    setColumnDefs(TenantManagement_serviceOperate_column(t));
  }, [t]);

  return (
    <div className="w-full grow flex flex-col pb-defaultY overflow-hidden">
      <div className="ag-theme-alpine ag-theme-mycustomtheme grow w-full">
        <AgGridReact
          columnDefs={columnDefs}
          rowData={rowData}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          rowSelection={'single'}
          onRowDoubleClicked={onRowDoubleClicked}
          overlayNoRowsTemplate={'\xa0'}
        ></AgGridReact>
        {modalData && (
          <InformaionModal
            show={show}
            setShow={togleModal}
            data={modalData}
            title={t('ServiceMornitoring.serviceDetailInfo')}
          />
        )}
      </div>
    </div>
  );
};

export default TenantManagement_serviceOperate_Table;
