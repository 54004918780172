import React from 'react';
import { BeatLoader } from 'react-spinners';

interface Props {
  isLoading: boolean;
  color: string;
}
const Loader = ({ isLoading, color }: Props) => {
  return (
    <div className="flex flex-col grow">
      <div className="flex grow justify-center items-center">
        <BeatLoader
          color={color}
          loading={isLoading}
          // cssOverride={override}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    </div>
  );
};

export default Loader;
