import { t } from 'i18next';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
// 라이브러리
import { SplitPane } from 'react-multi-split-pane';
import { useResizeDetector } from 'react-resize-detector';

// 컴포넌트

import {
  handleViewPortSize,
  NestedPaneWithTenant,
  PaneProps,
  splitPaneViewportCase,
} from '../../../helpers/h-splitPane';
import TenantManagement_tenantList_Table from '../../tables/sp/tenantManagement/TenantManagement_tenantList_Table';
import TenantManagement_fedInfo_Table from '../../tables/sp/tenantManagement/TenantManagement_fedInfo_Table';
import { useAppDispatch } from '../../../redux/hooks';
import TenantManagement_serviceOperate_Table from '../../tables/sp/tenantManagement/TenantManagement_serviceOperate_Table';
import TenantManagement_workSpaceAdminInfo_Table from '../../tables/sp/tenantManagement/TenantManagement_workSpaceAdminInfo_Table';
import { initTenantManagementSlice } from '../../../redux/slice/tenantManagementSlice';
import MainPageTitleHeader from '../../common/MainPageTitleHeader';
import WorkspaceBillingHistory_Table from '../../tables/portal/WorkspaceBillingHistory_Table';
import { IspTenantListReturnValue_desc_T } from '../../../interfaces/api/service-provider/I-api-admin-serviceProvider-fed';

interface Ileft extends PaneProps {
  setSelectedTenant: Dispatch<
    SetStateAction<IspTenantListReturnValue_desc_T | null>
  >;
}
interface Iright extends PaneProps {
  selectedTenant: IspTenantListReturnValue_desc_T | null;
}
// 테넌트 관리
const TenantManagement = () => {
  const [splitPaneViewportKey, setSplitPaneViewportKey] = useState(
    splitPaneViewportCase.default,
  );
  const [selectedTenant, setSelectedTenant] =
    useState<IspTenantListReturnValue_desc_T | null>(null);

  return (
    <div className="h-full w-full">
      <SplitPane
        className="custom"
        split="vertical"
        defaultSizes={[3, 5]}
        minSize={[50, 50]}
      >
        <Left
          setSplitPaneViewportKey={setSplitPaneViewportKey}
          splitPaneUpdatedState={splitPaneViewportKey}
          setSelectedTenant={setSelectedTenant}
        />
        <Right
          setSplitPaneViewportKey={setSplitPaneViewportKey}
          splitPaneUpdatedState={splitPaneViewportKey}
          selectedTenant={selectedTenant}
        />
      </SplitPane>
    </div>
  );
};

const Left = ({
  setSplitPaneViewportKey,
  splitPaneUpdatedState,
  setSelectedTenant,
}: Ileft) => {
  const { width, ref } = useResizeDetector();

  useEffect(() => {
    ref.current.parentElement.style.flex = splitPaneUpdatedState.left;
  }, [splitPaneUpdatedState]);

  return (
    <div
      ref={ref}
      className="w-full flex flex-col px-6 border border-solid bg-white border-[#dfe2e8] rounded"
    >
      <MainPageTitleHeader
        onClick={() =>
          handleViewPortSize([setSplitPaneViewportKey, 'clickedLeft'])
        }
        text={t('TenantManagement.tenantList')}
        direction="left"
      />
      <TenantManagement_tenantList_Table
        setSelectedTenant={setSelectedTenant}
      />
      {/* <div className="grow pb-2 w-full"></div> */}
    </div>
  );
};

const RightUpper = ({
  onClick,
  splitPaneUpdatedState,
  selectedTenant,
}: NestedPaneWithTenant) => {
  const { ref } = useResizeDetector();
  const [isFirst, setisFirst] = useState(true);

  useEffect(() => {
    if (isFirst) return setisFirst(false);
    ref.current.parentElement.parentElement.style.flex =
      splitPaneUpdatedState.right;
  }, [splitPaneUpdatedState]);

  return (
    <div
      ref={ref}
      className="w-full flex flex-col h-full px-6 border border-solid border-[#dfe2e8] rounded bg-white"
    >
      <MainPageTitleHeader
        onClick={onClick}
        text={t('TenantManagement.federationInfo')}
        direction="right"
      />
      <TenantManagement_fedInfo_Table selectedTenant={selectedTenant} />
    </div>
  );
};
const RightMiddle = ({
  onClick,
  splitPaneUpdatedState,
  selectedTenant,
}: NestedPaneWithTenant) => {
  const { ref } = useResizeDetector();
  const [isFirst, setisFirst] = useState(true);

  useEffect(() => {
    if (isFirst) return setisFirst(false);
    ref.current.parentElement.parentElement.style.flex =
      splitPaneUpdatedState.right;
  }, [splitPaneUpdatedState]);

  return (
    <div
      ref={ref}
      className="w-full flex flex-col h-full px-6 border border-solid border-[#dfe2e8] rounded bg-white"
    >
      <MainPageTitleHeader
        onClick={onClick}
        text={t('TenantManagement.serviceOpInfo')}
        direction="right"
      />
      <TenantManagement_serviceOperate_Table selectedTenant={selectedTenant} />
    </div>
  );
};
const RightDown = ({
  onClick,
  splitPaneUpdatedState,
  selectedTenant,
}: NestedPaneWithTenant) => {
  const { ref } = useResizeDetector();
  const [isFirst, setisFirst] = useState(true);

  useEffect(() => {
    if (isFirst) return setisFirst(false);
    ref.current.parentElement.parentElement.style.flex =
      splitPaneUpdatedState.right;
  }, [splitPaneUpdatedState]);

  return (
    <div
      ref={ref}
      className="w-full flex flex-col h-full px-6 border border-solid border-[#dfe2e8] rounded bg-white"
    >
      <MainPageTitleHeader
        onClick={onClick}
        text={t('TenantManagement.productInfo')}
        direction="right"
      />
      <TenantManagement_workSpaceAdminInfo_Table
        selectedTenant={selectedTenant}
        fromPortal={false}
      />
    </div>
  );
};

const RightDownDown = ({
  onClick,
  splitPaneUpdatedState,
  selectedTenant,
}: NestedPaneWithTenant) => {
  const { ref } = useResizeDetector();
  const [isFirst, setisFirst] = useState(true);

  useEffect(() => {
    if (isFirst) return setisFirst(false);
    ref.current.parentElement.parentElement.style.flex =
      splitPaneUpdatedState.right;
  }, [splitPaneUpdatedState]);

  return (
    <div
      ref={ref}
      className="w-full flex flex-col h-full px-6 border border-solid border-[#dfe2e8] rounded bg-white"
    >
      <MainPageTitleHeader
        onClick={onClick}
        text={t('TenantManagement.billHistory')}
        direction="right"
      />
      <WorkspaceBillingHistory_Table
        selectedTenant={selectedTenant}
        fromPortal={false}
      />
    </div>
  );
};

const Right = ({
  setSplitPaneViewportKey,
  splitPaneUpdatedState,
  selectedTenant,
}: Iright) => {
  const dispatch = useAppDispatch();
  const { width, ref } = useResizeDetector();

  const splitPaneHandler = () =>
    handleViewPortSize([setSplitPaneViewportKey, 'clickedRight']);

  useEffect(() => {
    return () => {
      dispatch(initTenantManagementSlice());
    };
  }, []);

  return (
    <SplitPane className="custom" split="horizontal" defaultSizes={[1]}>
      <RightUpper
        onClick={splitPaneHandler}
        splitPaneUpdatedState={splitPaneUpdatedState}
        selectedTenant={selectedTenant}
      />
      <RightMiddle
        onClick={splitPaneHandler}
        splitPaneUpdatedState={splitPaneUpdatedState}
        selectedTenant={selectedTenant}
      />
      <RightDown
        onClick={splitPaneHandler}
        splitPaneUpdatedState={splitPaneUpdatedState}
        selectedTenant={selectedTenant}
      />
      <RightDownDown
        onClick={splitPaneHandler}
        splitPaneUpdatedState={splitPaneUpdatedState}
        selectedTenant={selectedTenant}
      />
    </SplitPane>
  );
};

export default TenantManagement;
