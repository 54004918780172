import { t } from 'i18next';
import ReactJoyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import '../../styles/splitPane.css';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Header from '../../components/header/Header';
import {
  Main,
  OrganizationManagement,
  OrganizationUserManagement,
  AdminIdManagement,
  Announcement,
  RuleDesignation,
  UploadEnvironmentFile,
  FunctionButtonManagement,
  CountButtonManagement,
  RuleFunctionManagement,
  DbManagement,
  SqlManagement,
  RealTimeStatistics,
  MessengerUsageStatistics,
  ConnectionHistory,
  LogViewer,
  AdminLog,
  FileTransferHistoryManagement,
  FederationManagement,
  ServiceMornitoring,
} from '../../components/mainPage';
import SideBar from '../../components/sidebar/SideBar';
import TestPage from '../../components/mainPage/TestPage';
import { useAppSelector } from '../../redux/hooks';
import { useEffect, useState } from 'react';
import { sidebarListsData } from '../../components/sidebar/datas';
import { IaccessMenu } from '../../interfaces/api/I-api-admin-access';
import { IsideBarMenuListData } from '../../interfaces/mainPage/I-sidebar';
import {
  sp_tenantListApi_desc_F,
  sp_tenantListApi_desc_T,
} from '../../api/admin/service-provider/api-admin-serviceProvider-fed';
import axios from 'axios';
import WelcomeModal from '../../components/modals/tenantMng/WelcomeModal';
import { RootState } from '../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { searchParam } from '../../api/axiosInstance';
import React from 'react';
import HeaderV2 from '../../components/header/HeaderV2';
import { setJoyride1stFinished, setJoyrideFinished } from '../../redux/slice/joyRideFinishedSlice';
import CounselingManagement from '../../components/mainPage/counseling-management/CounselingManagement';
import ClassManagement from '../../components/mainPage/class-management/ClassManagement';

// interface IvalidateRouter {
//   link: string;
//   RouterElement: () => JSX.Element;
// }

const MainPage = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleJoyrideCallback = (data: { action: string; index: number; type: string; status: string }) => {
    const { action, index, type, status } = data;
    if (status === STATUS.FINISHED) {
      dispatch(setJoyride1stFinished(true));
      navigate('/organization-user-management');
    }
  };

  const runJoyride = useSelector((state: RootState) => state.welcomeModal.runJoyride);
  const { menuList, userInfo } = useAppSelector((state) => state.user);
  const [sessionHeaderTitle, setSessionHeaderTitle] = useState({
    stid: sessionStorage.getItem('tenantid'),
    swsn: sessionStorage.getItem('workspacename'),
  });
  const [headerTitle, setHeaderTitle] = useState({
    tid: '',
    wsn: '',
  });
  const [sideBarMenuListData, setSideBarMenuListData] = useState<IsideBarMenuListData[] | null>(null);
  const [openSidebarByClick, setOpenSidebarByClick] = useState(true);

  /**
   * 라우터 검증 함수
   */
  const validateRouter = (link: string, RouterElement: () => JSX.Element) => {
    if (!sideBarMenuListData) return false;

    const validated = sideBarMenuListData.find((menu) => menu.link === link);

    if (validated) {
      return <Route path={link} element={<RouterElement />} />;
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (!userInfo) return;
    const lang = searchParam('lang');
    const userjwt = searchParam('jwt');

    if (userjwt) {
      if (lang == 'ko') {
        i18n.changeLanguage('ko');
      } else if (lang == 'ja') {
        i18n.changeLanguage('ja');
      } else if (lang == 'en') {
        i18n.changeLanguage('en');
      } else {
        const browserLang = navigator.language;
        i18n.changeLanguage(browserLang);
      }
    }

    const tenantid = userInfo.bid.split('.')[0];
    const fetchData = async () => {
      try {
        const res = await sp_tenantListApi_desc_T({
          tenantid,
        });
        if (!res) return;

        const workspaceName = res.data.data.result[0].name;
        setHeaderTitle({
          tid: tenantid,
          wsn: workspaceName,
        });
        sessionStorage.setItem('workspacename', workspaceName);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log('error: ', error);
        } else {
          console.log('unexpected error: ', error);
        }
      }
    };
    fetchData();
  }, [userInfo]);

  // // 원본
  // // access/menu/list 에서 얻은 정보를 기반으로 필요한 객체배열 생성.
  // useEffect(() => {
  //   if (!menuList) return;
  //   const menuData = sidebarListsData.filter((v, i) => {
  //     const arr: IaccessMenu[] = menuList.filter(
  //       (v2, i) => v2.menuurl === v.link,
  //     );
  //     if (arr.length > 0) {
  //       return v;
  //     }
  //   });

  //   if (menuData.length > 0) {
  //     const newMenuData = menuData.map((v, i) => {
  //       console.log(i);
  //       console.log(v);
  //       return {
  //         ...v,
  //         ...menuList[i],
  //       };
  //     });

  //     newMenuData.sort((a, b) => {
  //       if (a.menuorder > b.menuorder) {
  //         return 1;
  //       }
  //       if (a.menuorder < b.menuorder) {
  //         return -1;
  //       }
  //       // a must be equal to b
  //       return 0;
  //     });
  //     setSideBarMenuListData(newMenuData);
  //   } else {
  //     setSideBarMenuListData(null);
  //   }
  // }, [menuList]);

  // access/menu/list 에서 얻은 정보를 기반으로 필요한 객체배열 생성.
  useEffect(() => {
    if (!menuList) return;
    // console.log(menuList);
    const menuData = sidebarListsData.filter((v, i) => {
      const arr: IaccessMenu[] = menuList.filter((v2, i) => v2.menuurl === v.link);
      if (arr.length > 0) {
        return v;
      }
    });

    if (menuData.length > 0) {
      const newMenuData = menuData.reduce((acc, currentSidebarItem) => {
        // menuList에서 현재 sidebar item의 link와 일치하는 menuurl을 가진 항목을 찾습니다.
        const foundMenuListItem = menuList.find((menuListItem) => menuListItem.menuurl === currentSidebarItem.link);

        // 일치하는 항목이 있으면, 두 객체를 병합하여 배열에 추가합니다.
        if (foundMenuListItem) {
          // 여기서 타입스크립트 타입 체크를 만족하기 위해 필요한 속성들을 명시적으로 병합합니다.
          const mergedItem = {
            ...currentSidebarItem, // 기존 sidebar 항목의 속성들
            ...foundMenuListItem, // 찾은 menuList 항목의 속성들
          };
          acc.push(mergedItem);
        }
        return acc;
      }, [] as (IsideBarMenuListData & IaccessMenu)[]);
9
      newMenuData.sort((a, b) => a.menuorder - b.menuorder);

      const currentLang = localStorage.getItem('i18nextLng');

      // currentLang 값에 따라 menuname을 업데이트하는 함수
      const updateMenuNameByLang = (item: any) => {
        switch (currentLang) {
          case 'ko':
            return item;
          case 'ja':
            return {
              ...item,
              text: item.menunameja || item.menuname,
            };
          case 'en':
            return {
              ...item,
              text: item.menunameen || item.menuname,
            };
          default:
            return item;
        }
      };

      const updatedMenuData = newMenuData.map(updateMenuNameByLang);
      setSideBarMenuListData(updatedMenuData);
    } else {
      setSideBarMenuListData(null);
    }
  }, [menuList]);

  const steps = [
    {
      target: '#service-mornitoring',
      content: t('Joyride.serviceMenuDesc'),
      placement: 'right' as const,
      disableBeacon: true,
    },
    {
      target: '#admin-id-management',
      content: t('Joyride.adminMenuDesc'),
      placement: 'right' as const,
      disableBeacon: true,
    },
    {
      target: '#function-button-management',
      content: t('Joyride.functionMenuDesc'),
      placement: 'right' as const,
      disableBeacon: true,
    },
    {
      target: '#federation-management',
      content: t('Joyride.federationMenuDesc'),
      placement: 'right' as const,
      disableBeacon: true,
    },
    {
      target: '#organization-user-management',
      content: t('Joyride.orgUserMenuDesc'),
      placement: 'auto' as const,
      disableBeacon: false,
    },
  ];

  return (
    <>
      {<WelcomeModal />}
      {/* <Header */}
      <HeaderV2
        type="ta"
        tenantid={sessionHeaderTitle.stid ? sessionHeaderTitle.stid : headerTitle.tid}
        workspacename={sessionHeaderTitle.swsn ? sessionHeaderTitle.swsn : headerTitle.wsn}
        setOpenSidebarByClick={setOpenSidebarByClick}
      />
      {/* Down */}
      <div className=" h-[calc(100%_-_4.25rem)] flex mt-[4.25rem]">
        <SideBar sideBarMenuListData={sideBarMenuListData} openSidebarByClick={openSidebarByClick} />
        {/* mainContents */}
        <div className="p-3 w-full h-full bg-mainPagePaddingBg text-mainContentsPage-default">
          <Routes>
            {/* <Route index element={Auth(OrganizationUserManagement)} /> */}
            <Route index element={<ServiceMornitoring />} />
            {validateRouter('/main', Main)}
            {validateRouter('/organization-management', OrganizationManagement)}
            {validateRouter('/organization-user-management', OrganizationUserManagement)}
            {validateRouter('/admin-id-management', AdminIdManagement)}
            {validateRouter('/announcement', Announcement)}
            {validateRouter('/upload-environment-file', UploadEnvironmentFile)}
            {validateRouter('/function-button-management', FunctionButtonManagement)}
            {validateRouter('/count-button-management', CountButtonManagement)}
            {validateRouter('/rule-function-management', RuleFunctionManagement)}
            {validateRouter('/rule-designation', RuleDesignation)}
            {validateRouter('/db-management', DbManagement)}
            {validateRouter('/sql-management', SqlManagement)}
            {validateRouter('/real-time-statistics', RealTimeStatistics)}
            {validateRouter('/messenger-usage-statistics', MessengerUsageStatistics)}
            {validateRouter('/connection-history', ConnectionHistory)}
            {validateRouter('/log-viewer', LogViewer)}
            {validateRouter('/admin-log', AdminLog)}
            {validateRouter('/file-transfer-history-management', FileTransferHistoryManagement)}
            {validateRouter('/federation-management', FederationManagement)}
            <Route path={'/service-mornitoring'} element={<ServiceMornitoring />} />
            <Route path="/counseling-management" element={<CounselingManagement />} />
            <Route path="/class-management" element={<ClassManagement />} />
            <Route path="/test-page" element={<TestPage />} />
            {/* 예외 처리 */}
            <Route path="*" element={<div>404 not found</div>} />
          </Routes>
        </div>

        <ReactJoyride
          steps={steps}
          run={runJoyride}
          continuous={true} // 연속적으로 스텝을 진행
          showProgress={true} // 진행 상태 표시
          showSkipButton={false} // 스킵 버튼 표시
          //floaterProps={{ placement: "left" }}
          styles={{
            options: {
              zIndex: 10000,
              primaryColor: '#00bfff', // 파란색 계열로 버튼 색상 지정
              backgroundColor: '#f8f9fa', // 배경색 변경
              textColor: '#000', // 텍스트 색상 변경
              arrowColor: '#fff',
              beaconSize: 45,
              overlayColor: 'rgba(0, 0, 0, 0.5)',
              spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
              width: undefined,
            },
            tooltipContent: {
              fontSize: '0.875rem',
            },
            buttonNext: {
              backgroundColor: '#1f62b9',
              color: '#fff',
            },
            buttonBack: {
              color: '#000',
            },
          }}
          callback={handleJoyrideCallback}
        />
      </div>
    </>
  );
};

export default MainPage;
