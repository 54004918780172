import icon_add from '../../../assets/image/etc/icon_add.png';
import clsx from 'clsx'; // classNames 병합을 위해 clsx 라이브러리를 사용

interface Props {
  title: string;
  onButtonClick?: () => void;
  hide?: boolean;
  className?: string; // 상위로부터 className을 받는 prop 추가
}

const ClassSectionHeader = ({ title, onButtonClick, hide = false, className }: Props) => {
  return (
    <div className={clsx('h-12 p-4 flex justify-between items-center border-b border-[#dfe2e8]', className)}>
      <h3 className="text-sm font-semibold">{title}</h3>
      {!hide && (
        <button className="text-sm text-blue-500" onClick={onButtonClick}>
          <img src={icon_add} alt="Down" className="w-5 h-5 min-w-5 min-w-5" />
        </button>
      )}
    </div>
  );
};

export default ClassSectionHeader;
