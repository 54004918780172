const TenantAdminLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="268.812"
      height="32"
      viewBox="0 0 268.812 32"
    >
      <defs>
        <clipPath id="q27uw7qi4a">
          <path
            data-name="사각형 1965"
            style={{ fill: 'none' }}
            d="M0 0h102.625v12.925H0z"
          />
        </clipPath>
      </defs>
      <g data-name="그룹 402">
        <path
          data-name="패스 388"
          d="M497.561 129.537a2.221 2.221 0 0 0-.319-.776 2.306 2.306 0 0 0-.422-.5l-2.267-1.979a12.563 12.563 0 0 0-.3-10.807 12.234 12.234 0 0 0-4.529-4.9 16.226 16.226 0 0 1 .127 13.761A15.745 15.745 0 0 1 479.8 133a11.918 11.918 0 0 0 9.283-.821 12.175 12.175 0 0 0 2.531-1.787l5.592-.041c.3-.045.436-.366.353-.819"
          transform="translate(-457.885 -101.589)"
          style={{ fill: 'currentcolor' }}
        />
        <path
          data-name="패스 389"
          d="M459.2 121.648a12.5 12.5 0 0 1 5.173-16.7 11.959 11.959 0 0 1 11.872.366 15.782 15.782 0 0 0-7.489-7.491 15.585 15.585 0 0 0-20.865 7.755 16.234 16.234 0 0 0 .227 13.964l-2.813 2.687a2.985 2.985 0 0 0-.517.664 2.908 2.908 0 0 0-.367 1.019c-.083.59.115 1 .5 1.037l7.217-.275a15.689 15.689 0 0 0 3.366 2.158 15.377 15.377 0 0 0 10.813.91 12.18 12.18 0 0 1-7.118-6.089"
          transform="translate(-444.396 -96.322)"
          style={{ fill: 'currentcolor' }}
        />
        <path
          data-name="패스 390"
          d="M475.9 125.532a1.815 1.815 0 1 1-1.815-1.849 1.831 1.831 0 0 1 1.815 1.849"
          transform="translate(-455.015 -106.432)"
          style={{ fill: 'currentcolor' }}
        />
        <path
          data-name="패스 391"
          d="M490.416 125.532a1.815 1.815 0 1 1-1.815-1.849 1.831 1.831 0 0 1 1.815 1.849"
          transform="translate(-460.546 -106.432)"
          style={{ fill: 'currentcolor' }}
        />
      </g>
      <g data-name="그룹 405">
        <g data-name="그룹 403">
          <path
            data-name="패스 392"
            d="M523.118 116.879v-9.735h3.468v10.046c0 3.674 1.265 4.914 3.28 4.914 2.061 0 3.374-1.24 3.374-4.914v-10.046h3.327v9.735c0 5.87-2.46 8.28-6.7 8.28-4.218 0-6.749-2.41-6.749-8.28z"
            transform="translate(-474.387 -100.073)"
            style={{ fill: 'currentcolor' }}
          />
          <path
            data-name="패스 393"
            d="M543.806 115.987c0-5.822 3.677-9.235 8.059-9.235a7.016 7.016 0 0 1 5.083 2.267l-1.8 2.243a4.531 4.531 0 0 0-3.209-1.479c-2.624 0-4.592 2.291-4.592 6.085 0 3.841 1.78 6.155 4.522 6.155a4.748 4.748 0 0 0 3.583-1.741l1.828 2.2a6.969 6.969 0 0 1-5.53 2.6c-4.383-.005-7.944-3.202-7.944-9.095z"
            transform="translate(-479.001 -99.991)"
            style={{ fill: 'currentcolor' }}
          />
          <path
            data-name="패스 394"
            d="M561.446 107.144h3.515l1.382 8.638c.258 1.861.538 3.722.8 5.631h.095a301.62 301.62 0 0 1 1.1-5.631l2.014-8.638h2.975l1.991 8.638c.375 1.813.727 3.722 1.1 5.631h.117c.258-1.909.515-3.794.773-5.631l1.382-8.638h3.28l-3.209 17.681h-4.311l-1.945-8.733a90.746 90.746 0 0 1-.75-4.1h-.093c-.235 1.36-.469 2.721-.75 4.1l-1.9 8.733h-4.24z"
            transform="translate(-482.935 -100.073)"
            style={{ fill: 'currentcolor' }}
          />
          <path
            data-name="패스 395"
            d="M589.1 115.843c0-5.75 3.162-9.091 7.753-9.091s7.755 3.364 7.755 9.091-3.163 9.235-7.755 9.235-7.753-3.508-7.753-9.235zm11.971 0c0-3.794-1.64-6.06-4.218-6.06s-4.216 2.267-4.216 6.06c0 3.771 1.64 6.18 4.216 6.18s4.218-2.41 4.218-6.18z"
            transform="translate(-489.102 -99.991)"
            style={{ fill: 'currentcolor' }}
          />
          <path
            data-name="패스 396"
            d="M612.56 107.144h6.04c3.632 0 6.513 1.312 6.513 5.369a5.054 5.054 0 0 1-3.3 5.082l4.053 7.23H622l-3.561-6.681h-2.413v6.681h-3.466zm5.716 8.208c2.225 0 3.467-.954 3.467-2.839 0-1.909-1.242-2.553-3.467-2.553h-2.249v5.392z"
            transform="translate(-494.334 -100.073)"
            style={{ fill: 'currentcolor' }}
          />
          <path
            data-name="패스 397"
            d="M632.251 107.144h3.468v7.684h.071l5.739-7.684h3.819l-5.294 6.967 6.231 10.714h-3.795l-4.5-7.946-2.273 2.983v4.964h-3.468z"
            transform="translate(-498.726 -100.073)"
            style={{ fill: 'currentcolor' }}
          />
          <path
            data-name="패스 398"
            d="m649.983 122.5 1.991-2.434a6.759 6.759 0 0 0 4.4 1.956c1.8 0 2.765-.787 2.765-2.028 0-1.312-1.054-1.718-2.6-2.386l-2.32-1a5.1 5.1 0 0 1-3.513-4.748c0-2.887 2.482-5.106 6-5.106a7.625 7.625 0 0 1 5.435 2.267l-1.758 2.22a5.47 5.47 0 0 0-3.678-1.456c-1.523 0-2.484.692-2.484 1.861 0 1.265 1.218 1.718 2.695 2.315l2.272.978c2.109.883 3.491 2.243 3.491 4.8 0 2.887-2.367 5.345-6.372 5.345a9.15 9.15 0 0 1-6.324-2.584z"
            transform="translate(-502.68 -99.991)"
            style={{ fill: 'currentcolor' }}
          />
        </g>
      </g>
      <g data-name="그룹 2516">
        <path
          data-name="패스 1588"
          d="M.357.664 0 2.682h3.544L1.8 12.75h2.433L5.977 2.682h3.585L9.92.664z"
          style={{ fill: 'currentcolor' }}
          transform="translate(166.187 12.162)"
        />
        <g data-name="그룹 2515">
          <g
            data-name="그룹 2514"
            style={{ clipPath: 'url(#q27uw7qi4a)' }}
            transform="translate(166.187 12.162)"
          >
            <path
              data-name="패스 1589"
              d="M12.642 12.916a3.907 3.907 0 0 1-2.087-.577A3.5 3.5 0 0 1 9.2 10.778a4.346 4.346 0 0 1-.349-2.186l.025-.332a5.815 5.815 0 0 1 .764-2.409 4.6 4.6 0 0 1 1.644-1.688 4.063 4.063 0 0 1 2.221-.563 3.111 3.111 0 0 1 2.628 1.277 4.375 4.375 0 0 1 .676 3.146l-.133 1.023h-5.5a1.927 1.927 0 0 0 .423 1.394 1.613 1.613 0 0 0 1.245.54 2.873 2.873 0 0 0 2.241-1l1.071 1.328a3.331 3.331 0 0 1-1.461 1.191 4.777 4.777 0 0 1-2.05.411m.747-7.379a1.66 1.66 0 0 0-1.15.406 3.574 3.574 0 0 0-.9 1.469h3.22l.05-.215a2.234 2.234 0 0 0 .009-.631 1.21 1.21 0 0 0-.407-.752 1.277 1.277 0 0 0-.822-.277"
              style={{ fill: 'currentcolor' }}
            />
            <path
              data-name="패스 1590"
              d="m21.076 3.76-.2 1.062A3.232 3.232 0 0 1 23.492 3.6a2.154 2.154 0 0 1 1.876.929 3.752 3.752 0 0 1 .439 2.507l-.946 5.711H22.52l.963-5.736a2.7 2.7 0 0 0 .017-.589.952.952 0 0 0-1.063-.872 2.019 2.019 0 0 0-1.652.855l-1.12 6.342h-2.341l1.561-8.981z"
              style={{ fill: 'currentcolor' }}
            />
            <path
              data-name="패스 1591"
              d="M31.891 12.75a2.419 2.419 0 0 1-.091-.813 3.039 3.039 0 0 1-2.291.979 2.75 2.75 0 0 1-1.955-.767 2.325 2.325 0 0 1-.726-1.856 2.684 2.684 0 0 1 1.233-2.254 5.6 5.6 0 0 1 3.15-.784l1.095.017.117-.539a1.875 1.875 0 0 0 .024-.524.9.9 0 0 0-.294-.61 1.011 1.011 0 0 0-.668-.228 1.428 1.428 0 0 0-.93.27 1.3 1.3 0 0 0-.49.826l-2.349.008A2.613 2.613 0 0 1 28.9 4.387a4.58 4.58 0 0 1 2.775-.787 3.311 3.311 0 0 1 2.391.909 2.658 2.658 0 0 1 .722 2.254l-.689 4.3-.041.531a2.56 2.56 0 0 0 .141 1l-.008.158zM30.09 11.1a2.144 2.144 0 0 0 1.768-.879l.291-1.553-.814-.007a2.269 2.269 0 0 0-1.868.788 1.373 1.373 0 0 0-.257.672.794.794 0 0 0 .187.709.942.942 0 0 0 .693.27"
              style={{ fill: 'currentcolor' }}
            />
            <path
              data-name="패스 1592"
              d="m39.321 3.76-.2 1.062A3.232 3.232 0 0 1 41.737 3.6a2.154 2.154 0 0 1 1.876.929 3.752 3.752 0 0 1 .439 2.507l-.946 5.711h-2.341l.963-5.736a2.7 2.7 0 0 0 .017-.589.952.952 0 0 0-1.063-.872 2.019 2.019 0 0 0-1.652.855l-1.12 6.345h-2.341l1.561-8.981z"
              style={{ fill: 'currentcolor' }}
            />
            <path
              data-name="패스 1593"
              d="M49.688 1.561 49.3 3.769h1.494l-.307 1.76h-1.496l-.764 4.5a1.148 1.148 0 0 0 .066.647q.117.225.565.241c.088 0 .327-.008.714-.042l-.166 1.835a3.95 3.95 0 0 1-1.345.191 2.11 2.11 0 0 1-1.718-.722 2.61 2.61 0 0 1-.465-1.91l.772-4.739h-1.27l.307-1.76h1.27l.382-2.208z"
              style={{ fill: 'currentcolor' }}
            />
            <path
              data-name="패스 1594"
              d="M62.04 10.26h-4.217l-1.245 2.49H53.9L60.355.664H62.6l2.3 12.086h-2.478zm-3.221-2.018h2.914l-.681-4.5z"
              style={{ fill: 'currentcolor' }}
            />
            <path
              data-name="패스 1595"
              d="M70.424 3.594a2.555 2.555 0 0 1 2.025 1l.88-4.6h2.349L73.47 12.75h-2.092l.133-.963a2.9 2.9 0 0 1-2.341 1.137 2.577 2.577 0 0 1-1.469-.439 2.779 2.779 0 0 1-.976-1.242 5 5 0 0 1-.377-1.805 6.768 6.768 0 0 1 .025-1.012l.05-.374a7.169 7.169 0 0 1 .767-2.424 4.011 4.011 0 0 1 1.395-1.552 3.3 3.3 0 0 1 1.839-.482m-1.7 4.832a6.828 6.828 0 0 0-.042 1.062q.084 1.461 1.179 1.486a2 2 0 0 0 1.718-.93l.64-3.569a1.326 1.326 0 0 0-1.3-.93A1.707 1.707 0 0 0 69.5 6.2a4.434 4.434 0 0 0-.776 2.221"
              style={{ fill: 'currentcolor' }}
            />
            <path
              data-name="패스 1596"
              d="m79.073 3.76-.191 1.021A3.306 3.306 0 0 1 81.513 3.6a2.521 2.521 0 0 1 1.378.382 1.775 1.775 0 0 1 .739.988 3.455 3.455 0 0 1 2.789-1.37 2.256 2.256 0 0 1 1.863.846 3.477 3.477 0 0 1 .528 2.374l-.017.208-.954 5.719H85.49l.954-5.742a4.256 4.256 0 0 0 .025-.573.889.889 0 0 0-1-.9 1.976 1.976 0 0 0-1.652 1.031l-.025.19-1.042 5.997h-2.341l.955-5.719a4.256 4.256 0 0 0 .025-.573.889.889 0 0 0-.988-.905 1.988 1.988 0 0 0-1.6.839l-1.123 6.358h-2.34l1.552-8.981z"
              style={{ fill: 'currentcolor' }}
            />
            <path
              data-name="패스 1597"
              d="M92.172 12.75h-2.357l1.56-8.982h2.357zm-.523-11.264a1.171 1.171 0 0 1 .365-.922 1.406 1.406 0 0 1 1.843-.041 1.164 1.164 0 0 1 .407.879 1.2 1.2 0 0 1-.344.9 1.3 1.3 0 0 1-.943.4 1.309 1.309 0 0 1-.917-.315 1.157 1.157 0 0 1-.411-.9"
              style={{ fill: 'currentcolor' }}
            />
            <path
              data-name="패스 1598"
              d="m97.858 3.76-.2 1.062a3.232 3.232 0 0 1 2.616-1.222 2.154 2.154 0 0 1 1.876.929 3.752 3.752 0 0 1 .439 2.507l-.946 5.711H99.3l.963-5.736a2.7 2.7 0 0 0 .017-.589.952.952 0 0 0-1.063-.872 2.019 2.019 0 0 0-1.652.855l-1.12 6.342h-2.339l1.561-8.981z"
              style={{ fill: 'currentcolor' }}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default TenantAdminLogo;
