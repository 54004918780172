import { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import login_tenant_admin from '../../assets/image/login/login_tenant_admin.png';
import login_schoolline_admin from '../../assets/image/login/login_schoolline_admin.png';
import { authauthHandler, validateUserHandler } from '../../helpers/h-auth';
import { transToSha256String } from '../../utils/hashHandler';
import Button from '../../components/common/buttons/Button';
import { Tooltip } from 'react-tooltip';

const LoginPage = () => {
  const languageNames: { [key: string]: string } = {
    ko: 'Korean',
    ja: 'Japanese',
    en: 'English',
  };
  const [language, setLanguage] = useState('');
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.auth);
  const header = sessionStorage.getItem('type');

  if (user) {
    navigate('/service-mornitoring');
  }
  const [accountValue, setAccountValue] = useState({
    id: '',
    pwd: '',
  });

  const loginHandler = async () => {
    try {
      const hasedAccountValue = {
        id: accountValue.id,
        hashedPwd: transToSha256String(accountValue.pwd),
      };
      const userData = await authauthHandler({
        accountValue: hasedAccountValue,
      });

      if (!userData) return;

      await validateUserHandler({ userData });
    } catch (err) {
      console.log(err);
    }
  };

  const handleLanguageChange = (e: any) => {
    const selectedLanguage = e.target.value;
    setLanguage(selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
  };

  useEffect(() => {
    const currentLanguage = i18n.language;
    setLanguage(currentLanguage);
  }, [i18n]);

  return (
    <form
      className="text-center flex flex-col grow justify-center items-center"
      onSubmit={(e) => {
        e.preventDefault();
        loginHandler();
      }}
    >
      <div className="block text-center">
        <img className="w-36 inline-block" src={header === 'edu' ? login_schoolline_admin : login_tenant_admin} />
      </div>
      <input
        name="id"
        value={accountValue.id}
        onChange={({ target }) => setAccountValue({ ...accountValue, [target.name]: target.value })}
        type="text"
        className="border w-80 mt-11 h-2.628 rounded placeholder:font-bold placeHolder-placeHolder text-0.813 leading-1.2 p-2 pl-3.5 focus:outline-none focus:border-primary-default focus:ring-1"
        placeholder={t('LoginPage.id')}
      />
      <input
        name="pwd"
        value={accountValue.pwd}
        onChange={({ target }) => setAccountValue({ ...accountValue, [target.name]: target.value })}
        type="password"
        className="border w-80 mt-4 h-2.628 rounded placeholder:font-bold placeHolder-placeHolder text-0.813 leading-1.2 p-2 pl-3.5 focus:outline-none focus:border-primary-default focus:ring-1"
        placeholder={t('LoginPage.password')}
      />

      <Button
        kind={'primary'}
        label={t('LoginPage.login')}
        style={{ width: '320px', fontWeight: 'bold', marginTop: '16px' }}
        type="submit"
      />

      <select
        className="w-80 h-9 py-0.463rem px-3 mt-2.5 border border-#c8cace rounded text-base outline-none"
        onChange={handleLanguageChange}
        value={language}
      >
        {Object.keys(languageNames).map((lang) => (
          <option key={lang} value={lang}>
            {languageNames[lang]}
          </option>
        ))}
      </select>

      <div className="border rounded inline-block mt-6 w-80 h-10.5 border-loginBtnBoder bg-mainPagePaddingBg py-4 px-2.06 text-xs leading-1.67 text-notiFont">
        <Trans i18nKey="LoginPage.desc1"></Trans>
        <br />
        <Trans i18nKey="LoginPage.desc2"></Trans>
        <br />
        <Trans i18nKey="LoginPage.desc3"></Trans>
        <br />
        <Trans i18nKey="LoginPage.desc4"></Trans>
        <br />
        <Trans i18nKey="LoginPage.desc5"></Trans>
        <br />
        <br />
        <Trans i18nKey="LoginPage.desc6"></Trans>
        <br />
        <Trans i18nKey="LoginPage.desc7"></Trans>
        <br />
      </div>
    </form>
  );
};

export default LoginPage;
