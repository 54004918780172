import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import Modal from 'react-modal';
import { closeClassParentModal } from '../../../helpers/h-react-modals';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { AgGridReact } from 'ag-grid-react';
import { t } from 'i18next';
import axios from 'axios';
import { IUserInfo } from '../../../interfaces/api/I-api-hrs-users';
import { ColDef, GridApi, GridReadyEvent, IDatasource, SelectionChangedEvent } from 'ag-grid-community';
import { userListApi } from '../../../api/admin/api-admin-user';
import { USER_LIST_TALBLE_COLUMN } from '../../tables/adminIdMng/columns';
import InputWithSearch from '../../common/InputWithSearch';
import Button from '../../common/buttons/Button';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { hrsAdminUserSearchApi } from '../../../api/hrs/api-hrs-users';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { parentAddClass, parentList } from '../../../api/admin/class/api-class';
import { clearSelectedRelation, setStudentReloadStatus } from '../../../redux/slice/classManagementSlice';
import { IRelationPayload } from '../../../interfaces/api/I-api-class';

const customStyles = {
  overlay: {
    zIndex: 99,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '1000px',
    height: '600px',
    padding: 0,
  },
};

interface Props {
  isOpen: boolean;
}

const ClassParentModal: React.FC<Props> = ({ isOpen = false }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const selectedClassName = useSelector((state: RootState) => state.classManagement.selectedClassName);
  const selectedClassCode = useSelector((state: RootState) => state.classManagement.selectedClassCode);
  const { usersid } = useAppSelector((state) => state.user);
  const [selectedUsers, setSelectedUsers] = useState<IUserInfo[]>([]);
  const [gridApi, setGridApi] = useState<GridApi<IUserInfo[]>>();
  const gridRef = useRef<any>();
  const [userNameInputValue, setUserNameInputValue] = useState('');
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
  const selectedStudentId = useSelector((state: RootState) => state.classManagement.selectedStudentId);

  const defaultColDef = useMemo<ColDef>(
    () => ({
      flex: 1,
      resizable: true,
      minWidth: 100,
    }),
    [],
  );

  const dataSource: IDatasource = {
    rowCount: undefined,
    getRows: (params) => {
      const page = 1;
      const pagesize = 20000;
      !userNameInputValue
        ? userListApi({ page, pagesize })
            .then((resp) => ({
              userList: resp?.data.data.result,
              totpage: resp?.data.data.totpage,
              totcount: resp?.data.data.totcount,
            }))
            .then(async (data) => {
              if (!data || !selectedStudentId) return;
              const eduRs = await parentList({ studentIds: [selectedStudentId] });
              const parentIds = eduRs?.data.result.map((item) => item.parentId);
              const filteredUserList = data.userList.filter((user) => !parentIds?.includes(user.userid));
              const filteredCount = filteredUserList.length;
              params.successCallback(filteredUserList, filteredCount);
              updateSelection();
            })
            .catch(handleApiError)
        : hrsAdminUserSearchApi({
            cmd: '1102',
            sid: usersid ? usersid : '',
            reqpage: 1,
            reqcount: 100,
            kind: 'ALL',
            keyword: userNameInputValue,
            fields: 'FULL',
            language: 'ko',
            withadmin: true,
          })
            .then((resp) => ({
              userList: resp?.data.data.result,
              totpage: resp?.data.data.totpage,
              totcount: resp?.data.data.totcount,
            }))
            .then(async (data) => {
              if (!data || !selectedStudentId) return;
              const eduRs = await parentList({ studentIds: [selectedStudentId] });
              const parentIds = eduRs?.data.result.map((item) => item.parentId);
              const filteredUserList = data.userList.filter((user) => !parentIds?.includes(user.userid));
              const filteredCount = filteredUserList.length;
              params.successCallback(filteredUserList, filteredCount);
              updateSelection();
            })
            .catch(handleApiError);
    },
  };

  const handleApiError = (error: any) => {
    if (axios.isAxiosError(error)) {
      console.log('error: ', error);
    } else {
      console.log('unexpected error: ', error);
    }
  };

  const onSelectionChanged = useCallback((props: SelectionChangedEvent<IUserInfo>) => {
    const selectedRows = props.api.getSelectedRows();
    setSelectedUsers(selectedRows);
  }, []);

  const onGridReady = (params: GridReadyEvent<IUserInfo[]>) => {
    setGridApi(params.api);
    params.api.setDatasource(dataSource);
  };

  const updateSelection = () => {
    if (gridApi && selectedUsers.length > 0) {
      gridApi.forEachNode((node) => {
        const data = node.data as unknown as IUserInfo;
        if (selectedUsers.find((user) => user.userid === data.userid)) {
          node.setSelected(true);
        }
      });
    }
  };

  useEffect(() => {
    if (gridApi) {
      gridApi.deselectAll();
      gridApi.setDatasource(dataSource);
    }
  }, [gridApi]);

  useEffect(() => {
    setColumnDefs(USER_LIST_TALBLE_COLUMN(t));
  }, [t]);

  const onClickSearchUser = () => {
    if (!gridApi) return;
    gridApi.setDatasource(dataSource);
  };

  const onClickAddUser = async () => {
    if (selectedClassCode === null) {
      toast.error(String(t('ClassManagement.classDetailSel')));
      return;
    }

    if (selectedStudentId === null) {
      toast.error(String(t('ClassManagement.classDetailSel13')));
      return;
    }

    if (selectedUsers.length < 1) {
      toast.error(String(t('ClassManagement.classDetailSel14')));
      return;
    }

    const param: IRelationPayload = {
      relations: selectedUsers?.map((parent) => ({
        parentId: parent.userid,
        studentId: selectedStudentId,
      })),
    };

    const res = await parentAddClass(param);

    if (res?.data.result.count > 0) {
      toast.success(String(t('ClassManagement.classDetailSel15')));
      dispatch(setStudentReloadStatus(true));
      dispatch(clearSelectedRelation());
      setSelectedUsers([]);
    } else {
      toast.error(String(t('ClassManagement.classDetailSel16')));
    }

    gridApi?.deselectAll();
    closeClassParentModal();
  };

  useEffect(() => {
    if (userNameInputValue === '') {
      onClickSearchUser();
    }
  }, [userNameInputValue]);

  return (
    <Modal isOpen={isOpen} onRequestClose={closeClassParentModal} style={customStyles} contentLabel="Example Modal">
      <div className="w-950px grow flex flex-col overflow-hidden mx-auto">
        <div className="flex gap-1 mt-3 mb-1 overflow-hidden font-bold w-full truncate text-mainContentsPage-default">
          {t('ClassManagement.classDetailParentAdd')}
        </div>
        <div className="flex gap-1 py-defaultY overflow-hidden">
          <InputWithSearch
            className="w-80"
            value={userNameInputValue}
            placeholder={t('AdminIdManagement.searchUserPlaceholder')}
            onClickSearch={onClickSearchUser}
            handleChangeInput={(e) => setUserNameInputValue(e.target.value)}
            handleClear={() => setUserNameInputValue('')}
          />
          <div style={{ marginLeft: 'auto' }}>
            <Button label={t('AdminIdManagement.add')} kind="primary" onClick={onClickAddUser} />
          </div>
        </div>
        <div className="ag-theme-alpine ag-theme-mycustomtheme grow w-full h-480">
          <AgGridReact
            ref={gridRef}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowModelType={'infinite'}
            cacheBlockSize={50}
            onGridReady={onGridReady}
            rowMultiSelectWithClick
            rowSelection="multiple"
            onSelectionChanged={onSelectionChanged}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ClassParentModal;
