import InputText from '../../common/inputs/InputText';

const List = () => {
  return (
    <div className="flex py-[0.25rem]">
      <div className="w-[7.25rem] self-center text-center ">그룹 코드</div>
      <div className="w-full">
        <InputText placeholder="ORG001" />
      </div>
    </div>
  );
};

const Form = () => {
  return (
    <div className="flex flex-col">
      <List />
      <List />
      <List />
      <List />
    </div>
  );
};

export default Form;
