import { ChangeEvent } from 'react';

interface Props {
  kind: 'radio' | 'checkbox';
  name?: string;
  id?: string;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
}

const InputRadioCheckbox = ({ kind, value, onChange, checked, ...props }: Props) => {
  const styleDefault =
    'appearance-none border border-solid border-C-c8cace rounded-full w-3.5 h-3.5 checked:border-none';
  let styleKind;

  switch (kind) {
    case 'radio':
      styleKind = 'checked:bg-input-radio';
      break;

    case 'checkbox':
      styleKind = 'checked:bg-input-checkbox';
      break;

    default:
      break;
  }

  return (
    <input
      type={kind}
      className={[styleDefault, styleKind].join(' ')}
      value={value}
      onChange={onChange}
      checked={checked}
      {...props}
    />
  );
};

export default InputRadioCheckbox;
