import icon_add from '../../../assets/image/etc/icon_add.png';

interface Props {
  title: string;
  onButtonClick?: () => void;
}

const SectionHeader = ({ title, onButtonClick }: Props) => {
  return (
    <div className=" h-12 p-4 flex justify-between items-center border-b border-[#dfe2e8]">
      <h3 className="text-sm font-semibold">{title}</h3>
      <button className="text-sm text-blue-500" onClick={onButtonClick}>
        <img src={icon_add} alt="Down" className="w-5 h-5 min-w-5 min-w-5" />
      </button>
    </div>
  );
};

export default SectionHeader;
