import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import ClassSectionHeader from './ClassSectionHeader';
import ClassDetails from './ClassDetails';

const ClassRight = () => {
  const selectedClassName = useSelector((state: RootState) => state.classManagement.selectedClassName);

  return (
    <div className="flex-[3] mr-2">
      <div className="w-full h-full border border-solid border-[#dfe2e8] bg-white">
        <ClassSectionHeader title={selectedClassName ? selectedClassName : ''} hide={true} />
        <ClassDetails />
      </div>
    </div>
  );
};

export default ClassRight;
