import React from 'react';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import { fedCancelHandler } from '../../../helpers/h-federationMng';
import { closeFederation_cancel_Modal } from '../../../helpers/h-modals';
import { IserviceProviderFedCancelApiBody } from '../../../interfaces/api/service-provider/I-api-admin-serviceProvider-fed';
import { useAppSelector } from '../../../redux/hooks';
import FederationMng_ask_common from './FederationMng_ask_common';

const FederationMng_cancel_Modal = () => {
  const { selectedOtherFederation } = useAppSelector(
    (state) => state.federatinManagement,
  );

  // 신청 취소
  const handleCancelRequest = () => {
    if (!selectedOtherFederation)
      return toast.error('Please select item first');

    const body = {
      srctenantid: selectedOtherFederation.srctenantid,
      desttenantid: selectedOtherFederation.desttenantid,
    };

    if (
      selectedOtherFederation.status === 1 ||
      selectedOtherFederation.status === 5
    ) {
      fedCancelHandler(body);
    }
  };

  return (
    <FederationMng_ask_common
      label={t('FederationManagement.cancelFederationRelation')}
      onClickHandler={() => {
        handleCancelRequest();
        closeFederation_cancel_Modal();
      }}
      onClickClose={closeFederation_cancel_Modal}
    />
  );
};

export default FederationMng_cancel_Modal;
