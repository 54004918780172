import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import Button from '../common/buttons/Button';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

const customStyles = {
  overlay: {
    zIndex: 9999,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '21.875rem',
    padding: 0,
    // minHeight: '12.25rem',
  },
};

interface Props {
  label: JSX.Element | string; // JSX.Element로 변경
  isOpen: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

const ConfirmModal = ({ label, isOpen = false, onConfirm, onClose }: Props) => {
  const subTitle = useRef<HTMLHeadingElement | null>(null);
  const { t } = useTranslation();

  function afterOpenModal() {
    if (subTitle.current === null) return;
    console.log(subTitle);
    subTitle.current.style.color = 'red';
  }

  return (
    <div>
      <Modal isOpen={isOpen} onAfterOpen={afterOpenModal} onRequestClose={onClose} style={customStyles} contentLabel="">
        <div
          className="flex flex-col h-full px-3
            relative border border-modal-subTitle bg-white z-50 shadow-listbox-options
            rounded
          "
        >
          {/* HEADER */}
          <div className="min-h-[3.5rem] w-full flex flex-col relative">
            {/* <div className="grow font-bold w-full flex items-center truncate">
                페더레이션 신청
              </div> */}
          </div>
          {/* BODY */}
          <div className="grow w-full jcac gap-3 overflow-y-auto text-sm text-center leading-7">{label}</div>
          {/* FOOTER */}
          <div className="min-h-[6rem] w-full jcac gap-2">
            <Button kind="cancel" label={t('ContextMenu.treeContextMenu.cancel')} onClick={onClose} />
            <Button kind="primary" label={t('ContextMenu.treeContextMenu.ok')} onClick={onConfirm} />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ConfirmModal;
