import React from 'react';
import ListTitle from '../../common/ListTitle';

const dummyData = new Array(30).fill(null).map(() => ({
  dbType: 'Int64',
  database: 'sql',
  user: '유씨웨어',
  dbHostName: '유씨웨어',
  port: '3036',
  description: '유씨웨어',
  dbAtribute: '유씨웨어',
  dbCodeValue: '215631',
}));

const UpperListContainer = () => {
  return (
    <div className="h-[calc(100%_-_6.25rem)]">
      <ListTitle
        titles={[
          'DB 종류',
          '데이터베이스',
          '사용자',
          'DB 호스트명',
          '포트',
          '설명',
          'DB속성',
          'DB 코드값',
        ]}
      />
      <div className="overflow-auto h-full">
        {dummyData.map((data, i) => (
          <List data={data} key={i} index={i} />
        ))}
      </div>
    </div>
  );
};

interface Data {
  dbType: string;
  database: string;
  user: string;
  dbHostName: string;
  port: string;
  description: string;
  dbAtribute: string;
  dbCodeValue: string;
}

type Props = {
  data: Data;
  index: number;
};

const List: React.FC<Props> = ({ data, index }) => {
  return (
    <div
      className={`grid grid-cols-8 border-b border-solid py-[0.813rem] border-[#dfe2e8] ${
        index < 1 ? 'bg-[#f1f3f6]' : ''
      }`}
    >
      <div className="text-center">{data.dbType}</div>
      <div className="text-center">{data.database}</div>
      <div className="text-center">{data.user}</div>
      <div className="text-center">{data.dbHostName}</div>
      <div className="text-center">{data.port}</div>
      <div className="text-center">{data.description}</div>
      <div className="text-center">{data.dbAtribute}</div>
      <div className="text-center">{data.dbCodeValue}</div>
    </div>
  );
};

export default UpperListContainer;
