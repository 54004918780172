const HambergerToggle = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="16"
      viewBox="0 0 18 16"
    >
      <path style={{ fill: 'none' }} d="M0 0h18v16H0z" />
      <path
        data-name="선 84"
        transform="translate(1 1)"
        style={{
          stroke: 'currentcolor',
          strokeLinecap: 'round',
          strokeWidth: '2px',
          fill: 'none',
        }}
        d="M0 0h16"
      />
      <path
        data-name="선 85"
        transform="translate(1 8)"
        style={{
          stroke: 'currentcolor',
          strokeLinecap: 'round',
          strokeWidth: '2px',
          fill: 'none',
        }}
        d="M0 0h16"
      />
      <path
        data-name="선 86"
        transform="translate(1 15)"
        style={{
          stroke: 'currentcolor',
          strokeLinecap: 'round',
          strokeWidth: '2px',
          fill: 'none',
        }}
        d="M0 0h16"
      />
    </svg>
  );
};

export default HambergerToggle;
