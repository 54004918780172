export const padTo2Digits = (num: number) => {
  return num.toString().padStart(2, '0');
};

export const formatToDateTime = (date: Date) => {
  return (
    [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-') +
    ' ' +
    [
      padTo2Digits(date.getHours()),
      padTo2Digits(date.getMinutes()),
      padTo2Digits(date.getSeconds()),
    ].join(':')
  );
};

export const splitDateTime = (date: string) => {
  const year = date.slice(0, 4);
  const month = date.slice(4, 6);
  const day = date.slice(6, 8);
  const hour = date.slice(8, 10);
  const min = date.slice(10, 12);
  const sec = date.slice(12, 14);

  const utcDateForm = `${year}-${month}-${day} ${hour}:${min}:${sec}`;
  return utcDateForm;
};

export const utcDateStringToLocaleDateString = (fullDateString: string) => {
  const year = fullDateString.slice(0, 4);
  const month = fullDateString.slice(4, 6);
  const day = fullDateString.slice(6, 8);
  const hour = fullDateString.slice(8, 10);
  const min = fullDateString.slice(10, 12);
  const sec = fullDateString.slice(12, 14);

  const utcDateForm = `${year}-${month}-${day}T${hour}:${min}:${sec}Z`;
  const localDate = new Date(utcDateForm);
  const localeDateString = formatToDateTime(localDate);
  return localeDateString;
};
