import React from 'react';
import ListTitle from '../../common/ListTitle';

const dummyData = new Array(30).fill(null).map(() => ({
  logFileName: '2022_05_25_jettylog',
  fileSize: '1 MB',
}));

const UpperListContainer = () => {
  return (
    <div className="h-[calc(100%_-_9.5rem)]">
      <ListTitle titles={['로그 파일명', '파일 크기']} />
      <div className="overflow-auto h-full">
        {dummyData.map((data, i) => (
          <List data={data} key={i} index={i} />
        ))}
      </div>
    </div>
  );
};

interface Data {
  logFileName: string;
  fileSize: string;
}

type Props = {
  data: Data;
  index: number;
};

const List: React.FC<Props> = ({ data, index }) => {
  const dataValue = Object.values(data);

  return (
    <div
      className={`grid grid-cols-2 border-b border-solid py-[0.813rem] border-[#dfe2e8] ${
        index < 1 ? 'bg-[#f1f3f6]' : ''
      }`}
    >
      {dataValue.map((data, i) => (
        <div key={i} className="text-center">
          {data}
        </div>
      ))}
    </div>
  );
};

export default UpperListContainer;
