import { t } from 'i18next';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IChannelDTO } from '../../interfaces/api/counsel/I-api-admin-counsel-channel';

interface InitialState {
  selectedClassCode: string | null;
  selectedClassName: string | undefined;
  selectedChannel: IChannelDTO | null; // 추가
  classReloadStatus: boolean;
  teacherReloadStatus: boolean;
  studentReloadStatus: boolean;
  selectedStudentId: string | null;
  selectedParentId: string | null;
}

const initialState: InitialState = {
  selectedClassCode: null,
  selectedClassName: undefined,
  selectedChannel: null,
  classReloadStatus: false,
  teacherReloadStatus: false,
  studentReloadStatus: false,
  selectedStudentId: null,
  selectedParentId: null,
};

export const classManagementSlice = createSlice({
  name: 'classManagement',
  initialState,
  reducers: {
    initClassManagementSlice: () => initialState,
    setSelectedClass: (state, action: PayloadAction<{ deptname: string | undefined; deptcode: string }>) => {
      state.selectedClassCode = action.payload.deptcode;
      state.selectedClassName = action.payload.deptname;
    },
    clearSelectedClass: (state) => {
      state.selectedClassCode = null;
      state.selectedClassName = undefined;
    },
    setClassReloadStatus: (state, action: PayloadAction<boolean>) => {
      state.classReloadStatus = action.payload;
    },
    setTeacherReloadStatus: (state, action: PayloadAction<boolean>) => {
      state.teacherReloadStatus = action.payload;
    },
    setStudentReloadStatus: (state, action: PayloadAction<boolean>) => {
      state.studentReloadStatus = action.payload;
    },
    setSelectedRelation: (
      state,
      action: PayloadAction<{ selectedStudentId: string | null; selectedParentId: string | null }>,
    ) => {
      state.selectedStudentId = action.payload.selectedStudentId;
      state.selectedParentId = action.payload.selectedParentId;
    },
    clearSelectedRelation: (state) => {
      state.selectedStudentId = null;
      state.selectedParentId = null;
    },
  },
});

export const {
  initClassManagementSlice,
  setSelectedClass,
  clearSelectedClass,
  setClassReloadStatus,
  setTeacherReloadStatus,
  setStudentReloadStatus,
  setSelectedRelation,
  clearSelectedRelation,
} = classManagementSlice.actions;

export default classManagementSlice.reducer;
