import { createSlice } from '@reduxjs/toolkit';

// 통합된 초기 상태
const initialState = {
  isJoyride1stFinished: false,
  isJoyrideFinished: false,
};

export const joyrideStateSlice = createSlice({
  name: 'joyRide',
  initialState,
  reducers: {
    // 메인 페이지의 조이라이드 완료 상태 설정 (메인의 joyride가 끝나야 조직/사용자 관리의 joyride 실행되게)
    setJoyride1stFinished: (state, action) => {
      state.isJoyride1stFinished = action.payload;
    },
    // 메인 페이지의 조이라이드 완료 상태 초기화 (메인의 joyride가 끝나야 조직/사용자 관리의 joyride 실행되게)
    resetJoyride1stFinished: (state) => {
      state.isJoyride1stFinished = false;
    },
    // 조직/사용자 관리의 joyride 이후 콜백으로 실행할 이벤트값 설정
    setJoyrideFinished: (state, action) => {
      state.isJoyrideFinished = action.payload;
    },
    // 조직/사용자 관리의 joyride 이후 콜백으로 실행할 이벤트값 초기화
    resetJoyrideFinished: (state) => {
      state.isJoyrideFinished = false;
    },
  },
});

// 액션 생성자를 내보냅니다.
export const { setJoyride1stFinished, resetJoyride1stFinished, setJoyrideFinished, resetJoyrideFinished } = joyrideStateSlice.actions;

// 리듀서를 내보냅니다.
export default joyrideStateSlice.reducer;
