import React, { useState } from 'react';
import Button from '../../common/buttons/Button';
import InputDatePicker from '../../common/inputs/InputDatePicker';
import InputText from '../../common/inputs/InputText';
import { SelectListValue } from '../../../interfaces/TypeSelectListbox';
import SelectListbox from '../../common/selectList/SelectListbox';

const selectValues: SelectListValue[] = [
  { id: '1', name: '유씨웨어' },
  { id: '2', name: '유씨웨어2' },
  { id: '3', name: '유씨웨어3' },
];
const UpperTopBar = () => {
  const [selected, setSelected] = useState(selectValues[0]);

  const [pickedDate, setPickedDate] = useState<Date | null>(null);

  return (
    <div className="flex justify-between gap-8 py-defaultY">
      <div className="flex gap-4">
        {/* 날짜 */}
        <div className="flex gap-2">
          <div className="flex items-center gap-2">
            <span>날짜</span>
            <div className="w-32">
              <InputDatePicker
                pickedDate={pickedDate}
                setPickedDate={(date) => setPickedDate(date)}
                placeholder="시작일"
              />
            </div>
          </div>
          <div className="flex justify-center items-center">~</div>
          <div className="w-32">
            <InputDatePicker
              pickedDate={pickedDate}
              setPickedDate={(date) => setPickedDate(date)}
              placeholder=""
            />
          </div>
          <InputText placeholder="아이디 검색" />
          <InputText placeholder="사용자 검색" />
        </div>
        {/* 디바이스 종류 */}
        <div className="flex gap-2">
          <div className="flex items-center gap-2">
            <span>디바이스 종류</span>
            <div className="w-32 h-9">
              <SelectListbox
                options={selectValues}
                value={selected}
                optionsHeight={12}
                // width={'5rem'}
                onChange={(value) => {
                  setSelected(value);
                }}
              />
            </div>
          </div>
          <div className="flex items-center gap-2">
            <span>접속 종류</span>
            <div className="w-32 h-9">
              <SelectListbox
                options={selectValues}
                value={selected}
                optionsHeight={12}
                // width={'5rem'}
                onChange={(value) => {
                  setSelected(value);
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div>
        <Button kind="cancel" label="엑셀 다운로드" />
      </div>
    </div>
  );
};

export default UpperTopBar;
