import InputText from '../../common/inputs/InputText';
import { ChangeEvent, MouseEvent } from 'react';
//  리덕스
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { changeSelectedCntBtn } from '../../../redux/slice/countButtonManagementSlice';
import InputRadioCheckbox from '../../common/inputs/InputRadioCheckbox';

const DownFormContainer = () => {
  const dispatch = useAppDispatch();
  const selectedButton = useAppSelector(
    (state) => state.countButtonManagement.selectedCntBtn,
  );

  const handleInputValue = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    dispatch(changeSelectedCntBtn({ name, value }));
  };

  const handleClearInputValue = (e: MouseEvent<HTMLButtonElement>) => {
    const name = e.currentTarget.name;
    const value = '';
    dispatch(changeSelectedCntBtn({ name, value }));
  };

  return (
    <div className="h-full w-full flex flex-col gap-1.5 overflow-auto">
      {/* 버튼 종류 */}
      <form className="w-full h-9 flex gap-6" name="btnmethod">
        <div className="gap-3 jcac">
          <InputRadioCheckbox
            kind="radio"
            name="btnmethod"
            id="cntBtnMethodUrl"
            value="URL"
            onChange={(e) => {
              dispatch(
                changeSelectedCntBtn({
                  name: 'btnmethod',
                  value: e.target.value,
                }),
              );
            }}
            checked={selectedButton.btnmethod === 'URL'}
          />
          <label htmlFor="cntBtnMethodUrl">URL</label>
        </div>
        <div className="gap-3 jcac">
          <InputRadioCheckbox
            kind="radio"
            name="btnmethod"
            id={'cntBtnMethodDB'}
            value={'DB'}
            onChange={(e) => {
              dispatch(
                changeSelectedCntBtn({
                  name: 'btnmethod',
                  value: e.target.value,
                }),
              );
            }}
            checked={selectedButton.btnmethod === 'DB'}
          />
          <label htmlFor={'cntBtnMethodDB'}>DB</label>
        </div>
      </form>
      {/* 버튼 이름 */}
      <div className="flex">
        <div className="min-w-[14rem] flex items-center text-[0.875rem] leading-[0.86rem]">
          버튼 이름
        </div>
        <div className="w-full">
          <InputText
            name="btnname"
            // placeholder="placeholder"
            value={selectedButton ? selectedButton.btnname : ''}
            handleChangeInput={handleInputValue}
            handleClear={handleClearInputValue}
          />
        </div>
      </div>
      {/* 버튼 설명 */}
      <div className="flex">
        <div className="min-w-[14rem] flex items-center text-[0.875rem] leading-[0.86rem]">
          버튼 설명
        </div>
        <div className="w-full">
          <InputText
            name="btncomment"
            // placeholder="placeholder"
            value={selectedButton ? selectedButton.btncomment : ''}
            handleChangeInput={handleInputValue}
            handleClear={handleClearInputValue}
          />
        </div>
      </div>
      {/* count url */}
      <div className="flex">
        <div className="min-w-[14rem] flex items-center text-[0.875rem] leading-[0.86rem]">
          count URL
        </div>
        <div className="w-full">
          {/* ChangeEvent<HTMLInputElement> */}
          <InputText
            name="btnurlexe"
            // placeholder="placeholder"
            value={selectedButton ? selectedButton.btnurlexe : ''}
            handleChangeInput={handleInputValue}
            handleClear={handleClearInputValue}
          />
        </div>
      </div>
      {/* count url 옵션 */}
      <div className="flex">
        <div className="min-w-[14rem] flex items-center text-[0.875rem] leading-[0.86rem]">
          count URL 옵션
        </div>
        <div className="w-full">
          {/* ChangeEvent<HTMLInputElement> */}
          <InputText
            name="btnurlexeopt"
            // placeholder="placeholder"
            value={selectedButton ? selectedButton.btnurlexeopt : ''}
            handleChangeInput={handleInputValue}
            handleClear={handleClearInputValue}
          />
        </div>
      </div>
      {/* 보기 url */}
      <div className="flex">
        <div className="min-w-[14rem] flex items-center text-[0.875rem] leading-[0.86rem]">
          보기 URL
        </div>
        <div className="w-full">
          {/* ChangeEvent<HTMLInputElement> */}
          <InputText
            name="btnviewurl"
            // placeholder="placeholder"
            value={selectedButton ? selectedButton.btnviewurl : ''}
            handleChangeInput={handleInputValue}
            handleClear={handleClearInputValue}
          />
        </div>
      </div>
      {/* 보기 url 옵션 */}
      <div className="flex">
        <div className="min-w-[14rem] flex items-center text-[0.875rem] leading-[0.86rem]">
          보기 URL 옵션
        </div>
        <div className="w-full">
          {/* ChangeEvent<HTMLInputElement> */}
          <InputText
            name="btnviewopt"
            // placeholder="placeholder"
            value={selectedButton ? selectedButton.btnviewopt : ''}
            handleChangeInput={handleInputValue}
            handleClear={handleClearInputValue}
          />
        </div>
      </div>
      {/* 버튼 이미지 주소 */}
      <div className="flex">
        <div className="min-w-[14rem] flex items-center text-[0.875rem] leading-[0.86rem]">
          버튼 이미지 src
        </div>
        <div className="w-full">
          {/* ChangeEvent<HTMLInputElement> */}
          <InputText
            name="btnimgsrc"
            // placeholder="placeholder"
            value={selectedButton ? selectedButton.btnimgsrc : ''}
            handleChangeInput={handleInputValue}
            handleClear={handleClearInputValue}
          />
        </div>
      </div>
    </div>
  );
};

export default DownFormContainer;
