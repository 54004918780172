import { forwardRef, FormEvent, ChangeEvent, MouseEvent } from 'react';
import CloseButton from '../buttons/CloseButton';

interface Props {
  name?: string;
  type?: 'password' | 'text';
  placeholder?: string;
  disabled?: boolean;
  isReadOnly?: boolean;
  value?: string;
  onClick?: (event: FormEvent<HTMLInputElement>) => void;
  handleChangeInput?: (event: ChangeEvent<HTMLInputElement>) => void;
  handleClear?: (e: MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  grow?: boolean;
  maxLength?: number;
}

const InputText = forwardRef<HTMLInputElement, Props>(function textInput(
  {
    name,
    type,
    placeholder,
    disabled = false,
    isReadOnly = false,
    value,
    onClick,
    handleChangeInput,
    handleClear,
    className,
    grow,
    maxLength,
  },
  ref,
) {
  return (
    <div className={`relative ${grow ? 'grow' : ''}`}>
      {/* <div className={`text-center relative ${grow ? 'grow' : ''}`}> */}
      <input
        name={name}
        type={type ? type : 'text'}
        ref={ref}
        placeholder={placeholder}
        disabled={disabled}
        className={`${className} border border-solid border-C-c8cace h-9 rounded 
        placeholder:text-C-9d9ea2 focus:border-C-1f62b9 text-C-555555 
        disabled:bg-C-f1f3f6 disabled:text-C-b4b5b8 px-3 text-sm w-full`}
        readOnly={isReadOnly}
        value={value}
        onClick={onClick}
        onChange={handleChangeInput}
        maxLength={maxLength ? maxLength : 524288}
      />
      {value && (
        <CloseButton
          width={13}
          height={13}
          className="absolute right-2 top-1/3"
          classNameSVG="stroke-C-c8cace hover:stroke-C-9d9ea2"
          name={name}
          onClick={handleClear}
          type="reset"
        />
      )}
    </div>
  );
});

export default InputText;
