import { IspTenantListReturnValue_desc_T } from './../../../../interfaces/api/service-provider/I-api-admin-serviceProvider-fed';
import { ColDef } from 'ag-grid-community';
import {
  dateValueGetter,
  CustomCell_fed,
  uksGetsDateValueGetter,
} from '../../../../helpers/h-agGrid';
import {
  IfedListItem,
  IserviceProviderFedTenant,
} from '../../../../interfaces/api/service-provider/I-api-admin-serviceProvider-fed';
import { IspUksGetsApiResponse } from '../../../../interfaces/api/service-provider/I-api-admin-sp-uks';
import { IworkspaceRowData_col } from '../../../../interfaces/I-ag-column';
import { AgCellTextCenter } from '../../../common/AgCustomCell';

//export const TenantManagement_tenantList_column: ColDef<IspTenantListReturnValue_desc_T>[] =
export const TenantManagement_tenantList_column  = (t: (key: string) => string) => {
  return [
    {
      field: 'tenantid',
      headerName: t('TenantManagement.h-leftTenantId'),
      // headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<IserviceProviderFedTenant>,
    },
    {
      field: 'name',
      headerName: t('TenantManagement.h-leftTenantName'),
      // headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<IserviceProviderFedTenant>,
    },
    {
      field: 'updatetime',
      headerName: t('TenantManagement.h-leftUpdateTime'),
      valueGetter: dateValueGetter,
      // headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<IserviceProviderFedTenant>,
    },
  ];
};

export const TenantManagement_fedInfo_column  = (t: (key: string) => string) => {
    return [
      {
        // headerComponent: AgHeaderTextCenter,
        field: 'status',
        headerName: t('TenantManagement.h-rightUpperStatus'),
        cellRenderer: CustomCell_fed,
        // lockPosition: true,
        // headerCheckboxSelection: true,
        // checkboxSelection: true,
        // resizable: false,
        // minWidth: 182,
        // maxWidth: 182,
        // flex: 1,
      },
      {
        // headerComponent: AgHeaderTextCenter,
        cellRenderer: AgCellTextCenter<IfedListItem>,
        field: 'destname',
        headerName: t('TenantManagement.h-rightUpperName'),
      },
      {
        // headerComponent: AgHeaderTextCenter,
        cellRenderer: AgCellTextCenter<IfedListItem>,
        headerName: t('TenantManagement.h-rightUpperDate'),
        valueGetter: dateValueGetter,
      },
  ];
};

//export const UserInfoList_Table_Column: ColDef<IspUksGetsApiResponse>[] =
export const TenantManagement_serviceOperate_column  = (t: (key: string) => string) => {
  return [
    {
      field: 'name',
      headerName: t('ServiceMornitoring.h-upperName'),
      // headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<IspUksGetsApiResponse>,
    },
    {
      field: 'status',
      headerName: t('ServiceMornitoring.h-upperStatus'),
      cellRenderer: AgCellTextCenter<IspUksGetsApiResponse>,
      // headerComponent: AgHeaderTextCenter,
      valueGetter: (params: { data: { status: string; }; }) => {
        if (params.data?.status === 'Running') {
          return t('ServiceMornitoring.h-upperRunning');
        } else if (params.data?.status === 'Pending') {
          return t('ServiceMornitoring.h-upperPending');
        }
      },
      cellClassRules: {
        'ag-green': (params: { value: string; }) => params.value === t('ServiceMornitoring.h-upperRunning'),
        'ag-red': (params: { value: string; }) => params.value === t('ServiceMornitoring.h-upperPending'),
      },
    },
    {
      field: 'creationTimestamp',
      headerName: t('ServiceMornitoring.h-upperCreationTimestamp'),
      cellRenderer: AgCellTextCenter<IspUksGetsApiResponse>,
      // headerComponent: AgHeaderTextCenter,
      valueGetter: uksGetsDateValueGetter,
    },
    {
      // 도커 이미지 정보
      field: 'image',
      headerName: t('ServiceMornitoring.h-upperImage'),
      valueGetter: (params: { data: { image: string; }; }) => {
        if (params.data?.image) {
          const v = params.data?.image.split(':');
          const dockerVersion = v[v.length - 1];
          return dockerVersion;
        }
      },
      cellRenderer: AgCellTextCenter<IspUksGetsApiResponse>,
      // headerComponent: AgHeaderTextCenter,
    },
  ];
};

export const TenantManagement_workSpaceAdminInfo_column  = (t: (key: string) => string) => {
  return [
    {
      field: 'workspaceName',
      headerName: t('ServiceMornitoring.h-leftWorkspaceName'),
      cellRenderer: AgCellTextCenter<IworkspaceRowData_col>,
      tooltipField: 'workspaceName',
    },
    {
      field: 'planName',
      headerName: t('ServiceMornitoring.h-leftPlanName'),
      cellRenderer: AgCellTextCenter<IworkspaceRowData_col>,
    },
    {
      field: 'licenseCount',
      headerName: t('ServiceMornitoring.h-leftLicenseCount'),
      cellRenderer: AgCellTextCenter<IworkspaceRowData_col>,
    },
    {
      field: 'totalPrice',
      headerName: t('ServiceMornitoring.h-leftTotalPrice'),
      cellRenderer: AgCellTextCenter<IworkspaceRowData_col>,
    },
    {
      field: 'storage',
      headerName: t('ServiceMornitoring.h-leftStorage'),
      cellRenderer: AgCellTextCenter<IworkspaceRowData_col>,
    },
    {
      field: 'updateTime',
      headerName: t('ServiceMornitoring.h-leftUpdateTime'),
      //valueGetter: dateValueGetter,
      cellRenderer: AgCellTextCenter<IworkspaceRowData_col>,
    },
  ];
};
