// 라이브러리
import { SplitPane } from 'react-multi-split-pane';
import MainPageTitleHeader from '../../common/MainPageTitleHeader';

// 컴포넌트
import CenterListContainer from './CenterListContainer';
import DownFormContainer from './DownFormContainer';
import DownTopBar from './DownTopBar';
import UpperListContainer from './UpperListContainer';

const SqlManagement = () => {
  return (
    <div className="h-full w-full">
      <SplitPane
        className="custom"
        split="horizontal"
        minSize={[100, 100, 100]}
        defaultSizes={[1, 1, 1]}
      >
        <Upper />
        <Center />
        <Down />
      </SplitPane>
    </div>
  );
};

const Upper = () => (
  <div className="w-full h-full border border-solid border-[#dfe2e8] rounded">
    <div className="w-full h-full px-[1.5rem] flex flex-col bg-white">
      <MainPageTitleHeader text="SQL 문장관리" />
      <UpperListContainer />
    </div>
  </div>
);

const Center = () => (
  <div className="w-full h-full border border-solid border-[#dfe2e8] rounded">
    <div className="w-full h-full px-[1.5rem] flex flex-col bg-white">
      <MainPageTitleHeader text="EVENT 구분" />
      <CenterListContainer />
    </div>
  </div>
);

const Down = () => (
  <div className="w-full h-full px-[1.5rem] border border-solid border-[#dfe2e8] rounded bg-white ">
    <DownTopBar />
    <DownFormContainer />
  </div>
);

export default SqlManagement;
