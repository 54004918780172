import React from 'react';
import InputText from '../../common/inputs/InputText';

const DownFormContainer = () => {
  return (
    <form className="flex">
      <label className="w-[7.25rem] flex items-center" htmlFor="announce_title">
        제목
      </label>
      <InputText placeholder="공지 제목" />
    </form>
  );
};

export default DownFormContainer;
