import Arrow from '../../../assets/svg/arrows/Arrow';
import Button from '../../common/buttons/Button';

interface Props {
  onClick: () => void;
}

const DownTopBar = ({ onClick }: Props) => {
  return (
    <div className="py-[0.563rem] flex justify-between text-fontColor-default">
      <div className="flex gap-1">
        <Button kind="cancel" label="추가" />
        <Button kind="cancel" label="수정" />
        <Button kind="cancel" label="삭제" />
        <Button kind="cancel" label="Test" />
        <Button kind="cancel" label="적용" />
      </div>
      <Arrow direction="down" onClick={onClick} />
    </div>
  );
};

export default DownTopBar;
