import {
  IadminConfListApiBody,
  IadminConfListApiresponse,
} from '../../interfaces/api/I-api-admin-conf';
import { IpluralAxiosResponse } from '../../interfaces/api/I-axios';
import { axiosErrorHandler, axiosInstance } from '../axiosInstance';

const adminConfInfo = {
  url: {
    list: '/api/v1/admin-mg-tp/conf/list',
    add: '/api/v1/admin-mg-tp/conf/add',
    remove: '/api/v1/admin-mg-tp/conf/remove',
    modify: '/api/v1/admin-mg-tp/conf/modify',
  },
};

// list
export const adminConfListApi = (props: IadminConfListApiBody) => {
  const { url } = adminConfInfo;

  return axiosInstance.post<IpluralAxiosResponse<IadminConfListApiresponse>>(
    url.list,
    props,
  );
};
