// style
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../../../styles/ag-grid.css';

import { CellClickedEvent, ColDef, IDatasource } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IAdminButtonGroup } from '../../../interfaces/api/button/I-api-admin-btn-group';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setSelectedCntBtnGroupModal } from '../../../redux/slice/countButtonManagementSlice';
import { CntBtnGroupList_Table_Column } from './columns';

const CntBtnGroupList_Table = () => {
  const dispatch = useAppDispatch();
  const { cntBtnGroupList } = useAppSelector(
    (state) => state.countButtonManagement,
  );

  const [columnDefs, setColumnDefs] = useState<ColDef[]>(
    CntBtnGroupList_Table_Column,
  );
  const [rowData, setRowData] = useState<IAdminButtonGroup[]>([]);
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      resizable: true,
      minWidth: 100,
    };
  }, []);

  const cellClickedListener = useCallback(
    (event: CellClickedEvent<any>) => {
      // console.log('cellClicked', event);
      const buttonInfo: IAdminButtonGroup = event.data;
      dispatch(setSelectedCntBtnGroupModal(buttonInfo));
    },
    [cntBtnGroupList],
  );

  // 테이블 데이터 설정
  useEffect(() => {
    if (cntBtnGroupList.length < 1) {
      setRowData([]);
      return;
    }
    setRowData(cntBtnGroupList);
  }, [cntBtnGroupList]);

  return (
    <div className="w-full grow flex flex-col overflow-hidden">
      <div className="ag-theme-alpine w-full grow ag-theme-mycustomtheme">
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onCellClicked={cellClickedListener}
          rowSelection={'single'}
          overlayNoRowsTemplate={'\xa0'}
        ></AgGridReact>
      </div>
    </div>
  );
};

export default CntBtnGroupList_Table;
