import { Listbox } from '@headlessui/react';
import { useEffect, useState } from 'react';
import { ErrorMessage } from 'formik';
import FormikError from '../FormikError';
import { SelectListValue } from '../../../interfaces/TypeSelectListbox';
import SelectDown from '../icons/SelectDown';

interface Props {
  options: SelectListValue[];
  width?: string;
  height?: string;
  optionsHeight?: number;
  withoutBorder?: boolean;
  selectedOption: SelectListValue;
  onChange: (value: SelectListValue) => void;
  name?: string;
  value: string;
  // formik에서 넘어오는 props
  field: any;
  form: { touched: any; errors: any };
  meta: any;
}

const SelectListboxFormik = ({
  options,
  width,
  height,
  optionsHeight,
  withoutBorder = false,
  selectedOption,
  onChange,
  name,
  value,
  // formik에서 넘어오는 props
  field,
  form: { touched, errors },
  meta,
}: Props) => {
  const [zIndex, setZIndex] = useState(false);

  useEffect(() => {
    setZIndex(!zIndex);
    return () => {
      setZIndex(!zIndex);
    };
  }, []);

  return (
    <div>
      <Listbox value={selectedOption} onChange={onChange}>
        {({ open }) => (
          <div
            style={{
              width: width ? `${width}` : '',
              height: height ? height : '2.25rem',
            }}
          >
            <Listbox.Button
              className={`${
                !withoutBorder ? 'border border-solid' : ''
              } h-9 rounded text-C-555555 pl-3 pr-2 text-sm flex flex-row items-center cursor-default w-full ${
                open && !withoutBorder ? 'border-C-1f62b9' : 'border-C-c8cace'
              }`}
            >
              <span className="block truncate grow text-left">
                {selectedOption.name}
              </span>
              <SelectDown
                className={`block flex-none ${open ? 'rotate-180' : ''}`}
              />
            </Listbox.Button>
            <Listbox.Options
              className="text-C-555555 text-sm cursor-default mt-1 p-1 rounded shadow-listbox-options bg-white overflow-auto relative"
              style={{
                maxHeight: optionsHeight ? `${optionsHeight}rem` : 'auto',
                zIndex: zIndex ? '999' : '',
              }}
            >
              {/* truncate 추가... */}
              {options.map((option) => (
                <Listbox.Option
                  key={option.id}
                  value={option}
                  disabled={option.disabled}
                  className={({ active }) =>
                    `h-7 px-2 leading-7 truncate ${active ? 'bg-C-f1f3f6' : ''}`
                  }
                >
                  {option.name}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </div>
        )}
      </Listbox>
      <ErrorMessage {...field} component={FormikError} />
    </div>
  );
};

export default SelectListboxFormik;
