import { createSlice } from '@reduxjs/toolkit';
import { IconfData } from '../../interfaces/api/I-api-admin-conf';

interface InitialState {
  confData: IconfData | null;
}

const initialState: InitialState = {
  confData: null,
};

export const confSlice = createSlice({
  name: 'conf',
  initialState,
  reducers: {
    initConfSlice: () => initialState,
    setConfData: (state, { payload: confData }: { payload: IconfData }) => {
      state.confData = confData;
    },
  },
});

export const { initConfSlice, setConfData } = confSlice.actions;

export default confSlice.reducer;
