import React, { useState } from 'react';
import Draggable from 'react-draggable';
import { Resizable } from 'react-resizable';
import Button from '../../common/buttons/Button';
import InputText from '../../common/inputs/InputText';
import { ResizeCallbackData } from '../../../interfaces/I-modals';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { closeRuleFuncRuleAddModal } from '../../../helpers/h-modals';
import { addRuleCodeHandler } from '../../../helpers/h-ruleFuncMng';
import { initSelectedRuleFunc } from '../../../redux/slice/ruleFunctionManagementSlice';

interface Props {
  onClickClose?: () => void;
}
const RuleFuncMng_Rule_Modal_ADD = (props: Props) => {
  const { ruleCodeList } = useAppSelector(
    (state) => state.ruleFunctionManagement,
  );

  const [size, setSize] = useState({
    width: 352,
    height: 236,
  });
  const [rulename, setRulename] = useState('');

  const handleOnSubmit = () => {
    addRuleCodeHandler(rulename, ruleCodeList ? ruleCodeList.length : 0);
  };

  const onResize = (
    e: React.SyntheticEvent<Element, Event>,
    data: ResizeCallbackData,
  ) => {
    setSize({
      width: data.size.width,
      height: data.size.height,
    });
  };

  return (
    <Draggable handle="#handle">
      <Resizable
        handle={
          <div
            className="resizable-custom-handler absolute"
            style={{
              left: size.width - 19,
              top: size.height - 19,
            }}
          ></div>
        }
        height={size.height}
        width={size.width}
        onResize={onResize}
      >
        <div className="modal-wrapper">
          {/* WRAPPER */}
          <div
            className="box drag-box  flex flex-col px-[1.25rem]
            relative border border-modal-subTitle bg-white z-50 shadow-listbox-options
            rounded
          "
            style={{ width: `${size.width}px`, height: `${size.height}px` }}
          >
            {/* HEADER */}
            <div
              id="handle"
              className="min-h-[3.5rem] cursor-move w-full flex flex-col relative"
            >
              <div className="grow font-bold w-full flex items-center truncate">
                규칙 추가
              </div>
            </div>
            {/* BODY */}
            <div className="grow w-full flex flex-col gap-3 overflow-y-auto">
              rule 이름
              <InputText
                value={rulename}
                handleChangeInput={(e) => setRulename(e.target.value)}
              />
              {/* 테이블 */}
            </div>
            {/* FOOTER */}
            <div className="min-h-[6rem] w-full  jcac">
              <div className="flex gap-5">
                <Button
                  onClick={closeRuleFuncRuleAddModal}
                  kind="cancel"
                  label="취소"
                />
                <Button
                  onClick={() => {
                    handleOnSubmit();
                    closeRuleFuncRuleAddModal();
                  }}
                  kind="primary"
                  label="저장"
                />
              </div>
            </div>
            {/* 닫기 버튼 */}
            <button
              onClick={closeRuleFuncRuleAddModal}
              className="absolute right-1 top-1"
            >
              X
            </button>
          </div>
        </div>
      </Resizable>
    </Draggable>
  );
};

export default RuleFuncMng_Rule_Modal_ADD;
