import { IaccessMenuListApiResponse } from '../../interfaces/api/I-api-admin-access';
import { axiosErrorHandler, axiosInstance } from '../axiosInstance';

const adminAccessInfo = {
  url: {
    menu_list: '/api/v1/admin-mg-tp/access/menu/list',
  },
};

// list
export const adminAccessMenuListApi = (props: { bid: string }) => {
  const { url } = adminAccessInfo;
  return axiosInstance.post<IaccessMenuListApiResponse>(url.menu_list, props);
};
