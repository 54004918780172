import { useEffect, useMemo, useState } from 'react';
import Button from '../../common/buttons/Button';
import { removeFuncBtnFromBtnGroupHandler } from '../../../helpers/h-funcBtnMng';
import { openFuncBtnGroupMainModal } from '../../../helpers/h-modals';
import { SelectListValue } from '../../../interfaces/TypeSelectListbox';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  setSelectedFuncBtnGroup,
  setSelectedFuncBtnGroupsBtn,
} from '../../../redux/slice/funcButtonManagementSlice';
import SelectListbox from '../../common/selectList/SelectListbox';

const UpperRightTopBar = () => {
  const dispatch = useAppDispatch();
  const { funcBtnGroupList, selectedFuncBtnGroupsBtn, selectedFuncBtnGroup } =
    useAppSelector((state) => state.funcButtonManagement);
  // LISTBOX
  const deafaultSelectValues: SelectListValue[] = useMemo(() => {
    return [{ id: '1', name: 'empty' }];
  }, []);
  const [selected, setSelected] = useState(deafaultSelectValues[0]);
  const [buttonGroupSelectList, setButtonGroupSelectList] = useState<
    SelectListValue[] | null
  >(null);

  // selectedFuncBtnGroup 설정
  const handleSelectFuncBtnGroup = (index: number) => {
    if (funcBtnGroupList === null) return;
    dispatch(setSelectedFuncBtnGroup(funcBtnGroupList[index]));
  };

  // LISTBOX VALUE 설정
  useEffect(() => {
    if (buttonGroupSelectList === null) return;
    setSelected(buttonGroupSelectList[0]);
  }, [buttonGroupSelectList]);

  // 버튼그룹 SELECT-LISTBOX DATA 설정
  useEffect(() => {
    if (funcBtnGroupList === null) return;
    else if (funcBtnGroupList.length > 0) {
      setButtonGroupSelectList(
        funcBtnGroupList.map((buttonGroup, index) => ({
          id: index.toString(),
          name: buttonGroup.groupname,
        })),
      );
    } else {
      setButtonGroupSelectList(deafaultSelectValues);
    }
  }, [funcBtnGroupList]);

  return (
    <div className="py-[0.563rem] gap-1 flex text-fontColor-default ">
      <Button
        kind="cancel"
        label="버튼 그룹 관리"
        onClick={openFuncBtnGroupMainModal}
      />
      <SelectListbox
        options={
          buttonGroupSelectList ? buttonGroupSelectList : deafaultSelectValues
        }
        value={selected}
        optionsHeight={12}
        width={'9.62rem'}
        onChange={(value) => {
          setSelected(value);
          if (funcBtnGroupList.length < 1) return;
          handleSelectFuncBtnGroup(parseInt(value.id));
        }}
      />
      <Button
        kind="cancel"
        label="버튼 그룹에서 삭제"
        onClick={() => {
          removeFuncBtnFromBtnGroupHandler(
            selectedFuncBtnGroupsBtn,
            selectedFuncBtnGroup,
          );
          dispatch(setSelectedFuncBtnGroupsBtn(null));
        }}
      />
      {/* <Button kind="cancel" label="조직 삭제" /> */}
    </div>
  );
};

export default UpperRightTopBar;
