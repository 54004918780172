import CounselingMainLeft from './CounselingMainLeft';
import CounselingMainRight from './CounselingMainRight';

const CounselingMain = () => {
  return (
    <div className="flex w-full h-full mt-5 mb-5 select-none">
      <CounselingMainLeft />
      <CounselingMainRight />
    </div>
  );
};

export default CounselingMain;
