import { IpluralAxiosResponse } from './../../../interfaces/api/I-axios';
import {
  IAdminButtonGroupHub,
  IAdminButtonGroupHubAddApiBody,
  IAdminButtonGroupHubInfoApiBody,
  IAdminButtonGroupHubListApiBody,
  IAdminButtonGroupHubModifyApiBody,
  IAdminButtonGroupHubRemoveApiBody,
} from '../../../interfaces/api/button/I-api-admin-btn-group-hub';
import { axiosErrorHandler, axiosInstance } from '../../axiosInstance';

const adminButtonGroupHubInfo = {
  url: {
    list: '/api/v1/admin-mg-tp/button/group/hub/list',
    add: 'api/v1/admin-mg-tp/button/group/hub/add',
    info: 'api/v1/admin-mg-tp/button/group/hub/info',
    modify: 'api/v1/admin-mg-tp/button/group/hub/modify',
    remove: 'api/v1/admin-mg-tp/button/group/hub/remove',
  },
};

// list
export const adminButtonGroupHubListApi = (
  props?: IAdminButtonGroupHubListApiBody,
) => {
  const { url } = adminButtonGroupHubInfo;

  return axiosInstance.post<IpluralAxiosResponse<IAdminButtonGroupHub>>(
    url.list,
    {
      pagesize: 50,
      page: 1,
      ...props,
    },
  );
};

// add
export const adminButtonGroupHubAddApi = (
  props: IAdminButtonGroupHubAddApiBody,
) => {
  const { url } = adminButtonGroupHubInfo;
  return axiosInstance.post(url.add, {
    ...props,
  });
};

// info
export const adminButtonGroupHubInfoApi = (
  props: IAdminButtonGroupHubInfoApiBody,
) => {
  const { url } = adminButtonGroupHubInfo;
  return axiosInstance.post(url.info, {
    ...props,
  });
};

// modify
export const adminButtonGroupHubModifyApi = (
  props: IAdminButtonGroupHubModifyApiBody,
) => {
  const { url } = adminButtonGroupHubInfo;
  return axiosInstance.post(url.modify, {
    ...props,
  });
};

// remove
export const adminButtonGroupHubRemoveApi = (
  props: IAdminButtonGroupHubRemoveApiBody,
) => {
  const { url } = adminButtonGroupHubInfo;
  return axiosInstance.post(url.remove, {
    ...props,
  });
};
