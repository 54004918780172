import { createSlice } from '@reduxjs/toolkit';
import { IspTenantListReturnValue_desc_F } from '../../interfaces/api/service-provider/I-api-admin-serviceProvider-fed';

interface InitialState {
  selectedTenant: IspTenantListReturnValue_desc_F | null;
}

const initialState: InitialState = {
  selectedTenant: null,
};

// 현재 이 스토어 사용중 아님....추후 사용할수 있으니 삭제는 안함.
export const tenantManagementSlice = createSlice({
  name: 'tenantManagement',
  initialState,
  reducers: {
    initTenantManagementSlice: () => initialState,
    setSelectedTenant: (
      state,
      { payload: selectedTenant }: { payload: IspTenantListReturnValue_desc_F },
    ) => {
      state.selectedTenant = selectedTenant;
    },
  },
});

export const { initTenantManagementSlice, setSelectedTenant } =
  tenantManagementSlice.actions;

export default tenantManagementSlice.reducer;
