import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { closeProfileImg } from '../../../helpers/h-react-modals';

interface Props {
  isOpen: boolean;
}

const ProfileModal = ({ isOpen = false }: Props) => {
  const [modalStyle, setModalStyle] = useState({});
  const url = useSelector((state: RootState) => state.profile.profileUrl);

  const handleImageLoad = (event: any) => {
    const { naturalWidth, naturalHeight } = event.target;
    const screenHeight = window.innerHeight * 0.8;
    const screenWidth = window.innerWidth * 0.8;

    let ratio = Math.min(
      screenWidth / naturalWidth,
      screenHeight / naturalHeight,
    );

    if (ratio > 1) ratio = 1;

    const displayWidth = naturalWidth * ratio;
    const displayHeight = naturalHeight * ratio;

    console.log('Image dimensions:', naturalWidth, naturalHeight);
    console.log('Display dimensions:', displayWidth, displayHeight);

    setModalStyle({
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: `${displayWidth}px`,
        height: `${displayHeight}px`,
        padding: 0,
        overflow: 'hidden',
      },
      overlay: {
        zIndex: 1000,
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
      },
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeProfileImg}
      style={modalStyle}
      contentLabel="Profile Image Modal"
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          src={url}
          alt="Profile"
          onLoad={handleImageLoad}
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
            objectFit: 'contain',
          }}
        />
      </div>
    </Modal>
  );
};

export default ProfileModal;
