import MainPageTitleHeader from '../../common/MainPageTitleHeader';
import ListContainer from './ListContainer';
import NavBar from './NavBar';
import TopBar from './TopBar';

const ConnectionHistory = () => {
  return (
    <div className="w-full h-full px-[1.5rem] flex flex-col border border-solid border-[#dfe2e8] bg-white  rounded">
      <MainPageTitleHeader text="접속 이력" />
      <TopBar />
      <NavBar />
      <ListContainer />
    </div>
  );
};

export default ConnectionHistory;
