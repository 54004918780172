import MainPageTitleHeader from '../../common/MainPageTitleHeader';
import ListContainer from './ListContainer';
import TopBar from './TopBar';

const FileTransferHistoryManagement = () => {
  return (
    <div className="w-full h-full px-[1.5rem] flex flex-col border border-solid border-[#dfe2e8] bg-white  rounded">
      <MainPageTitleHeader text="파일전송 이력관리" />
      <TopBar />
      <ListContainer />
    </div>
  );
};

export default FileTransferHistoryManagement;
