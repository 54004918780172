import { t } from 'i18next';
import Draggable from 'react-draggable';
import Button from '../../common/buttons/Button';
import party from '../../../assets/image/modal/party.png';
import { useDispatch, useSelector } from 'react-redux';
import { setRunJoyride, setShowModal, toggleModal } from '../../../redux/slice/welcomeModalSlice';
import { RootState } from '../../../redux/store';
import InputRadioCheckbox from '../../common/inputs/InputRadioCheckbox';
import { useEffect, useState } from 'react';

const WelcomeModal = () => {
  const dispatch = useDispatch();
  const show = useSelector((state: RootState) => state.welcomeModal.showModal);

  // 쿠키를 설정하는 함수
  const setCookie = (name: string, value: string, seconds: number) => {
    let expires = "";
    if (seconds) {
      const date = new Date();
      date.setTime(date.getTime() + (seconds * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
  };

  // 쿠키를 가져오는 함수
  const getCookie = (name: string) => {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for(let i=0;i < ca.length;i++) {
        let c = ca[i];
        while (c.charAt(0)===' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  };

  // 모달을 숨기고 쿠키에 현재 시간을 저장하는 함수
  const saveHideTimeAndCloseModal = () => {
    setCookie('hideWelcomeModalTime', 'true', 60 * 10); //10분
    setCookie('hideWelcomeModalTime', 'true', 60 * 60 * 24); //24시간
    dispatch(setShowModal(false));
  };

  // 모달을 표시할지 결정하는 로직
  useEffect(() => {
    const isHidden = getCookie('hideWelcomeModalTime');
    if (isHidden) {
      dispatch(setShowModal(false));
    }
  }, [dispatch]);

  if (!show) return null;

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
        <Draggable handle="#handle" positionOffset={{ x: '-50%', y: '-50%' }}>
          <div className="z-50 fixed bg-white left-[50%] top-[50%] flex flex-col shadow-listbox-options rounded-lg border border-solid border-borderLight w-100 h-auto">
            {/* HEADER */}
            <div id="handle" className="cursor-move py-2 px-4 font-bold text-blue-700 mt-2">
              <div className="text-center text-xl">
                {t('Welcome.title') as string}
                <img src={party} alt="Fireworks" className="ml-1 mb-1 mx-auto w-6 inline-block" />
              </div>
            </div>
            {/* BODY */}
            <div className="flex flex-col py-2 px-4 text-center text-sm mt-2 text-slate-600 h-24 justify-center">
              {t('Welcome.desc1') as string}<br />
              {t('Welcome.desc2') as string}<br />
              {t('Welcome.desc3') as string}
            </div>
            {/* BUTTONS */}
            <div className="flex justify-center gap-2 py-2 mt-3 mb-1">
              <Button label={t('Welcome.alright')} onClick={saveHideTimeAndCloseModal} kind="cancel" />
              <Button label={t('Welcome.toDetail')} onClick={() => {
                dispatch(toggleModal()); // 모달 상태 토글
                dispatch(setRunJoyride(true)); // ReactJoyride 실행하기
              }} kind="primary" />
            </div>
          </div>
        </Draggable>
      </div>
    </>
  );
};

export default WelcomeModal;
