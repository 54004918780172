import { combineReducers, configureStore } from '@reduxjs/toolkit';
// RTK QUERY
import { setupListeners } from '@reduxjs/toolkit/dist/query';
// react-persist
import { persistStore, persistReducer, FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to sessionStorage for web
import storageSession from 'redux-persist/lib/storage/session';

// slice
import authSlice from './slice/authSlice';
import dragDropSlice from './slice/dragDropSlice';
import userSlice from './slice/userSlice';
import toggleSlice from './slice/toggleSlice';
import organizationUserManagementSlice from './slice/organizationUserManagementSlice';
import funcButtonManagementSlice from './slice/funcButtonManagementSlice';
import countButtonManagementSlice from './slice/countButtonManagementSlice';
import ruleFunctionManagementSlice from './slice/ruleFunctionManagementSlice';
import federatinManagementSlice from './slice/federatinManagementSlice';
import adminIdManagementSlice from './slice/adminIdManagementSlice';
import confSlice from './slice/confSlice';
import tenantManagementSlice from './slice/tenantManagementSlice';
import upgradeManagementSlice from './slice/sp/upgradeManagementSlice';
import autoScaleManagementSlice from './slice/sp/autoScaleManagementSlice';
import spAuthSlice from './slice/sp/sp_authSlice';
import welcomeModalSlice from './slice/welcomeModalSlice';
import joyRideFinishedSlice from './slice/joyRideFinishedSlice';
import profileSlice from './slice/profileSlice';
import counselingManagementSlice from './slice/counselingManagementSlice';
import classManagementSlice from './slice/classManagementSlice';

// redux-persist setting
const persistConfig = {
  key: 'root',
  storage: storageSession,
  whitelist: ['user', 'conf', 'spAuth'],
};

// const persistedReducer = persistReducer(persistConfig, userSlice);

// export const store = configureStore({
//   reducer: persistedReducer,
// devTools: true,
// middleware: [thunk],
// });

const rootReducer = combineReducers({
  auth: authSlice,
  spAuth: spAuthSlice,
  user: userSlice,
  conf: confSlice,
  toggle: toggleSlice,
  organizationUserManagement: organizationUserManagementSlice,
  funcButtonManagement: funcButtonManagementSlice,
  countButtonManagement: countButtonManagementSlice,
  ruleFunctionManagement: ruleFunctionManagementSlice,
  federatinManagement: federatinManagementSlice,
  adminIdManagement: adminIdManagementSlice,
  counselingManagement: counselingManagementSlice,
  classManagement: classManagementSlice,
  tenantManagement: tenantManagementSlice,
  upgradeManagement: upgradeManagementSlice,
  autoScaleManagement: autoScaleManagementSlice,
  welcomeModal: welcomeModalSlice,
  joyRide: joyRideFinishedSlice,
  dragDrop: dragDropSlice,
  profile: profileSlice,
});

const PERSISTREDUCER = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: PERSISTREDUCER,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);
