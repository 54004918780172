import { useState } from 'react';
import Arrow from '../../../assets/svg/arrows/Arrow';
import SearchIcon from '../../common/icons/SearchIcon';
import InputText from '../../common/inputs/InputText';
import { SelectListValue } from '../../../interfaces/TypeSelectListbox';
import SelectListbox from '../../common/selectList/SelectListbox';

const selectValues: SelectListValue[] = [
  { id: '1', name: '전체 읽기(저용량)' },
  { id: '2', name: '전체 읽기(저용량)2' },
  { id: '3', name: '전체 읽기(저용량)3' },
];

const selectValues2: SelectListValue[] = [
  { id: '1', name: '20' },
  { id: '2', name: '60' },
  { id: '3', name: '180' },
];

interface Props {
  onClick: () => void;
}

const DownTopBar = ({ onClick }: Props) => {
  const [selected, setSelected] = useState(selectValues[0]);
  const [selected2, setSelected2] = useState(selectValues2[0]);

  return (
    <div className="flex justify-between">
      <div className="flex gap-5">
        <form
          onSubmit={() => alert('ddd')}
          className="w-[19.5rem] h-9 relative"
        >
          <button className="absolute left-2 top-0 bottom-0 z-10">
            <SearchIcon />
          </button>
          <InputText className="pl-8" placeholder="로그 검색" />
        </form>
        <div className="flex gap-3">
          <div className="jcac">읽기 방식</div>
          <div className="w-40 h-9">
            <SelectListbox
              options={selectValues}
              value={selected}
              optionsHeight={12}
              onChange={(value) => {
                setSelected(value);
              }}
            />
          </div>
        </div>
        <div className="flex gap-3">
          <div className="jcac">라인수</div>
          <div className="w-40 h-9">
            <SelectListbox
              options={selectValues2}
              value={selected2}
              optionsHeight={12}
              onChange={(value) => {
                setSelected2(value);
              }}
            />
          </div>
        </div>
        <div className="flex gap-3">
          <div className="jcac">글자색</div>
        </div>
        <div className="flex gap-3">
          <div className="jcac">배경색</div>
        </div>
      </div>
      <Arrow direction="down" onClick={onClick} />
    </div>
  );
};

export default DownTopBar;
