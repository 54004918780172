import { t } from 'i18next';
import {
  ColDef,
  IDatasource,
  GridApi,
  GridReadyEvent,
  SelectionChangedEvent,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { serviceProvider_fed_list_api } from '../../../../api/admin/service-provider/api-admin-serviceProvider-fed';
import {
  IfedListItem,
  IspTenantListReturnValue_desc_T,
} from '../../../../interfaces/api/service-provider/I-api-admin-serviceProvider-fed';
import { TenantManagement_fedInfo_column } from './columns';

interface Props {
  selectedTenant: IspTenantListReturnValue_desc_T | null;
}
const TenantManagement_fedInfo_Table = ({ selectedTenant }: Props) => {
  // AG_GRID
  const [gridApi, setGridApi] = useState<GridApi<IfedListItem[]>>();

  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
  // const [columnDefs, setColumnDefs] = useState<ColDef[]>(
  //   TenantManagement_fedInfo_column,
  // );
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      resizable: true,
      minWidth: 100,
    };
  }, []);

  const onSelectionChanged = useCallback(
    (props: SelectionChangedEvent<IfedListItem>) => {
      const selectedRows = props.api.getSelectedRows();
      const fedInfo = selectedRows[0];
      // console.log('selected fedInfo:', fedInfo);
    },
    [],
  );

  // 테이블 인피니트 스크롤용
  let page = 1;
  const dataSource: IDatasource = {
    rowCount: undefined,
    getRows: (params) => {
      serviceProvider_fed_list_api({
        tenantid: selectedTenant ? selectedTenant.tenantid : '',
        pagesize: 50,
        page,
      })
        .then((resp) => {
          if (!resp) return params.failCallback();
          if (resp.data.data.result.length < 1) return params.failCallback();
          return {
            fedList: resp.data.data.result,
            totpage: resp?.data.data.totpage,
            totcount: resp?.data.data.totcount,
          };
        })
        .then((data) => {
          if (!data) return params.failCallback();
          const lastPage =
            page < data.totpage ? 50 * page + 1 : parseInt(data.totcount);
          params.successCallback(data.fedList, lastPage);
          page++;
        })
        .catch((error) => {
          if (axios.isAxiosError(error)) {
            console.log('error: ', error);
            return error;
          } else {
            console.log('unexpected error: ', error);
            return 'An unexpected error occurred';
          }
        });
    },
  };

  const onGridReady = (params: GridReadyEvent<IfedListItem[]>) => {
    setGridApi(params.api);
    // if (!selectedTenant) return;
    // params.api.setDatasource(dataSource);
  };

  useEffect(() => {
    if (!gridApi || !selectedTenant) return;

    gridApi.setDatasource(dataSource);
  }, [selectedTenant, gridApi]);

  useEffect(() => {
    setColumnDefs(TenantManagement_fedInfo_column(t));
  }, [t]);

  return (
    <div className="w-full grow flex flex-col pb-defaultY overflow-hidden">
      <div className="ag-theme-alpine ag-theme-mycustomtheme grow w-full">
        <AgGridReact
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowModelType={'infinite'}
          cacheBlockSize={50}
          onGridReady={onGridReady}
          rowMultiSelectWithClick
          rowSelection={'single'}
          // onCellClicked={cellClickedListener}
          onSelectionChanged={onSelectionChanged}
          overlayNoRowsTemplate={'\xa0'}
        ></AgGridReact>
      </div>
    </div>
  );
};

export default TenantManagement_fedInfo_Table;
