import '../../styles/treeView-default.css';
import { useEffect, useRef, useState } from 'react';
import Tree from 'rc-tree';
import { t } from 'i18next';
// API
import { hrsOrgsListApi } from '../../api/hrs/api-hrs-orgs';
import { setSelectedDepartments } from '../../redux/slice/organizationUserManagementSlice';

// REDUX
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
// COMP
import { IhrsOrgDeptFull, IhrsOrgUserAll } from '../../interfaces/api/I-api-hrs-orgs';
import { EventDataNode, Key } from 'rc-tree/lib/interface';
import { getChildDept, getChildDeptV2, getChildDeptV3 } from '../../helpers/h-treeView';
import { getMyOrgCode, getMyRootDeptCode, getMyTenantId } from '../../helpers/h-userInfo';
import _ from 'lodash';
import 'rc-tree/assets/index.css';
import { customTitle, dropIndicatorRender, switcherIconHandler } from '../../helpers/h-treeView-ui';
import { OnExpand, OnSelect } from '../../interfaces/I-treeView';
import { useResizeDetector } from 'react-resize-detector';
import Loader from '../common/Loader';
import Button from '../common/buttons/Button';
import { closeAddDepartmentModal } from '../../helpers/h-react-modals';
import MainPageTitleHeader from '../common/MainPageTitleHeader';
import MainPageTitleHeaderDesc from '../common/MainPageTitleHeaderDesc';
import { toast } from 'react-toastify';

export interface TreeData {
  title: string;
  key: string;
  isLeaf?: boolean;
  children?: TreeData[];
  data: IhrsOrgDeptFull | IhrsOrgUserAll;
}

interface Props {
  width: number | undefined;
}

const prefixCls = 'ucTenantTree';

// MAIN
const TreeViewConcurrentDept = ({ width }: Props) => {
  const { deptStatus } = useAppSelector(
    (state: { organizationUserManagement: { orgInfo: any } }) => state.organizationUserManagement.orgInfo,
  );
  const { userModifyStatus } = useAppSelector(
    (state: { organizationUserManagement: { userInfo: any } }) => state.organizationUserManagement.userInfo,
  );
  const { usersid } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const treeRef = useRef<Tree<TreeData> | null>(null);
  const { width: treeWrapperWidth, height: treeWrapperHeight, ref: treeWrapperRef } = useResizeDetector();
  const [selectedDeparts, setSelectedDeparts] = useState<{ deptcode: string; deptname: string }[]>([]);
  const deptlist = useAppSelector((state) => state.organizationUserManagement.orgInfo.deptlist);
  const [treeData, setTreeData] = useState<TreeData[] | null>(null);
  const [selectedKeys, setSelectedKeys] = useState<Key[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [didSearch, setDidSearch] = useState(false);
  const [_keyToScroll, _setKeyToScroll] = useState<string | null>(null);

  const handleRegistDepartment = async () => {
    if (selectedDeparts.length === 0) {
      toast.error(t('OrganizationUserManagement.departmentAlert') as string);
      return;
    }
    const payload = {
      deptlist: selectedDeparts,
    };

    dispatch(setSelectedDepartments(payload.deptlist));
    closeAddDepartmentModal();
  };

  const initializeSelectedKeys = () => {
    const initialSelectedKeys = deptlist.map((dept) => dept.deptcode);
    setSelectedKeys(initialSelectedKeys);
  };

  useEffect(() => {
    initializeSelectedKeys();

    if (deptlist.length > 0) {
      const initialSelectedDeparts = deptlist.map((dept) => ({
        deptcode: dept.deptcode,
        deptname: dept.deptname,
      }));
      setSelectedDeparts(initialSelectedDeparts);
    }
  }, [deptlist]);

  useEffect(() => {
    const fetchData = async () => {
      const tenantid = getMyTenantId();
      const orgcode = getMyOrgCode();
      const rootDept = getMyRootDeptCode();

      if (!(tenantid && orgcode && rootDept)) {
        console.log('에러... fetchData');
        return;
      }
      const res = await hrsOrgsListApi({
        cmd: '1103',
        sid: usersid ? usersid : '',
        what: [{ gid: `${tenantid}.${orgcode}.${rootDept}` }],
        fields: 'FULL',
        include: 'DEPT',
        kind: 'DEPT',
        extra: 'ALL',
      });

      if (!res || !res.data.data.result.dept || res.data.data.result.dept.data.length < 1) return;

      const deptData = res.data.data.result.dept.data[0];
      const rootDeptNode: TreeData = {
        title: deptData.deptname,
        key: deptData.deptcode,
        data: { ...deptData },
        children: [],
      };

      // 하위 부서 데이터 가져오기
      const childRes = await hrsOrgsListApi({
        cmd: '1103',
        sid: usersid || '',
        kind: 'CHILD',
        fields: 'FULL',
        include: 'DEPT',
        extra: 'ALL',
        what: [{ gid: `${tenantid}.${orgcode}.${rootDept}` }],
      });

      if (childRes && childRes.data.data.result.dept) {
        rootDeptNode.children = childRes.data.data.result.dept.data.map((child) => ({
          title: child.deptname,
          key: child.deptcode,
          data: { ...child },
          children: [],
        }));
      }

      setTreeData([rootDeptNode]);

      // 초기 expandedKeys 설정
      if (deptlist.length > 0) {
        const deptcode = deptlist[0].deptcode; // 첫 번째 부서의 deptcode
        const expandedKeys: Key[] = [rootDeptNode.key];

        // 재귀적으로 부모 노드 키 찾기
        const findParentKeys = (nodes: TreeData[], targetKey: string, parents: Key[]): boolean => {
          for (const node of nodes) {
            if (node.key === targetKey) {
              expandedKeys.push(...parents);
              return true;
            }
            if (node.children?.length) {
              if (findParentKeys(node.children, targetKey, [...parents, node.key])) {
                return true;
              }
            }
          }
          return false;
        };

        findParentKeys([rootDeptNode], deptcode, []);
        setExpandedKeys(expandedKeys);
      }
    };

    fetchData();
  }, [deptlist]);

  // // 초기 ROOT 부서 불러오기
  // useEffect(() => {
  //   const fetchData = async () => {
  //     const tenantid = getMyTenantId();
  //     const orgcode = getMyOrgCode();
  //     const rootDept = getMyRootDeptCode();

  //     if (!(tenantid && orgcode && rootDept)) return alert('error from treeView.fetchData');

  //     const res = await hrsOrgsListApi({
  //       cmd: '1103',
  //       sid: usersid ? usersid : '',
  //       what: [{ gid: `${tenantid}.${orgcode}.${rootDept}` }],
  //       fields: 'FULL',
  //       include: 'DEPT',
  //       kind: 'DEPT',
  //       extra: 'ALL',
  //     });
  //     if (!res) return;
  //     if (!res.data.data.result.dept || res.data.data.result.dept.data.length < 1) return;

  //     const deptData = res.data.data.result.dept.data[0];
  //     const deptCode: string = res?.data.data.result.dept.data[0].deptcode;

  //     setTreeData([
  //       {
  //         title: deptData.deptname,
  //         key: deptCode,
  //         data: {
  //           ...deptData,
  //         },
  //       },
  //     ]);
  //   };

  //   setTimeout(() => {
  //     fetchData();
  //   }, 30);
  // }, []);

  const onSelect: OnSelect = (selectedKeys, info) => {
    setSelectedDeparts((prevDepartments) => {
      const selectedDept = {
        deptcode: info.node.data.deptcode,
        deptname: info.node.data.deptname,
      };

      const exists = prevDepartments.some((dept) => dept.deptcode === selectedDept.deptcode);

      if (exists) {
        const updatedDepartments = prevDepartments.filter((dept) => dept.deptcode !== selectedDept.deptcode);
        setSelectedKeys(updatedDepartments.map((dept) => dept.deptcode));
        return updatedDepartments;
      } else if (prevDepartments.length >= 3) {
        toast.error(t('OrganizationUserManagement.departmentAlert2') as string);
        return prevDepartments;
      } else {
        const updatedDepartments = [...prevDepartments, selectedDept];
        setSelectedKeys(updatedDepartments.map((dept) => dept.deptcode));
        return updatedDepartments;
      }
    });
  };

  const onExpand: OnExpand = (expandedKeys, info) => {
    if (info.nativeEvent.type === 'dragenter') return;
    setAutoExpandParent(false);
    setExpandedKeys(expandedKeys);
  };

  const onLoadData = async (selectedNode: EventDataNode<TreeData>) => {
    if (didSearch) return;
    getChildDeptV3({ selectedNode, treeData, setTreeData });
  };

  return (
    <div className="h-full w-full flex flex-col pl-4 pr-4">
      <MainPageTitleHeader text={t('OrganizationUserManagement.department')} textOnly />
      <MainPageTitleHeaderDesc text={<>{t('ClassManagement.classTreeDesc')}</>} />
      <div
        id="treeView"
        ref={treeWrapperRef}
        className="grow flex flex-col h-[200px] border border-gray-300 mt-5 pt-1 pl-3 w-[95%] mx-auto"
      >
        {treeData && deptStatus !== 'pending' && userModifyStatus !== 'pending' ? (
          <Tree
            ref={treeRef}
            prefixCls={prefixCls}
            className="CUSTOM_TREE bg-white h-full"
            autoExpandParent={autoExpandParent}
            expandedKeys={expandedKeys}
            selectedKeys={selectedKeys}
            multiple={true}
            draggable
            loadData={onLoadData}
            onSelect={onSelect}
            onExpand={onExpand}
            height={treeWrapperHeight}
            treeData={treeData}
            switcherIcon={switcherIconHandler}
            dropIndicatorRender={dropIndicatorRender}
            titleRender={customTitle}
          />
        ) : (
          <Loader color="#1f62b9" isLoading={true} />
        )}
      </div>
      <div className="min-h-[5rem] w-full  jcac">
        <div className="flex gap-2">
          <Button kind="cancel" label={t('ClassManagement.classCancel')} onClick={closeAddDepartmentModal} />
          <Button kind="primary" label={t('ClassManagement.classConfirm')} onClick={handleRegistDepartment} />
        </div>
      </div>
    </div>
  );
};

export default TreeViewConcurrentDept;
