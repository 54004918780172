import { ICellRendererParams } from 'ag-grid-community';

export function AgCellTextCenter<T>(props: ICellRendererParams<T>) {
  return <div className="text-center truncate">{props.value}</div>;
}

export const userField5CellRenderer = (params: { value: any; t: any }) => {
  const { value, t } = params;

  switch (value) {
    case 's':
      return (
        <div className="text-center truncate">
          {t('OrganizationUserManagement.student')}
        </div>
      );
    case 'p':
      return (
        <div className="text-center truncate">
          {t('OrganizationUserManagement.parent')}
        </div>
      );
    case 't':
      return (
        <div className="text-center truncate">
          {t('OrganizationUserManagement.teacher')}
        </div>
      );
    default:
      return <div className="text-center truncate">{value}</div>;
  }
};
