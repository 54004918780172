import AddPersonButton from '../../common/buttons/AddPersonButton';
import InputText from '../../common/inputs/InputText';
import { useState } from 'react';
import { SelectListValue } from '../../../interfaces/TypeSelectListbox';
import InputRadioCheckbox from '../../common/inputs/InputRadioCheckbox';
import {
  funcBtnFormInputClearHandler,
  funcBtnFormInputHandler,
  funcCheckBoxHandler,
} from '../../../helpers/h-funcBtnMng';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { changeSelectedFuncBtn } from '../../../redux/slice/funcButtonManagementSlice';
import SelectListbox from '../../common/selectList/SelectListbox';

// 이미지 사이즈
const btnImgSizeValues: SelectListValue[] = [
  { id: '0', name: '없음' },
  { id: '1', name: '30 * 30' },
  { id: '2', name: '40 * 40' },
];

// 버튼 종류 (URL | EXE)
const btnTypeValues: SelectListValue[] = [
  { id: '0', name: 'URL' },
  { id: '1', name: 'EXE' },
];

// 파라미터 구분
const btndelimeterValues: SelectListValue[] = [
  { id: '0', name: ',' },
  { id: '1', name: ':' },
  { id: '2', name: ';' },
  { id: '3', name: '|' },
];

// 버튼 노출 설정
const btnVisibleValues: SelectListValue[] = [
  { id: '0', name: '보이기' },
  { id: '1', name: '보이기 (사용자 설정 불가)' },
  { id: '2', name: '팝업 메뉴만 보이기' },
  { id: '3', name: '보이기 (서비스탭)' },
  { id: '4', name: '보이기 (서비스탭 + 기능버튼)' },
  { id: '5', name: '보이지 않기)' },
];

// 사용자 선택
const btnSelectUserValues: SelectListValue[] = [
  { id: '0', name: '사용자 선택 안함' },
  { id: '1', name: '온라인 + 오프라인 사용자' },
  { id: '2', name: '온라인 사용자' },
  { id: '3', name: '로그인 사용자' },
];

interface ICell {
  title: string;
  children: React.ReactNode;
}
const TextCell: React.FC<ICell> = (props) => {
  return (
    <div className="flex w-full">
      <div className="min-w-[14rem] flex items-center text-[0.875rem] leading-[0.86rem]">
        {props.title}
      </div>
      <div className="grow flex gap-1">{props.children}</div>
    </div>
  );
};
const SelectCell: React.FC<ICell> = (props) => {
  return (
    <div className="flex">
      <div className="min-w-[14rem] flex items-center text-[0.875rem] leading-[0.86rem]">
        {props.title}
      </div>
      {props.children}
    </div>
  );
};
const CheckCell: React.FC<ICell> = (props) => {
  return (
    <div className="flex min-h-[36px]">
      <div className="w-[14rem] flex items-center text-[0.875rem] leading-[0.86rem]">
        {props.title}
      </div>
      <div className="flex items-center">{props.children}</div>
    </div>
  );
};

const DownFormContainer = () => {
  const dispatch = useAppDispatch();
  const { selectedFuncBtn } = useAppSelector(
    (state) => state.funcButtonManagement,
  );
  const [selected, setSelected] = useState({
    btnSize: btnImgSizeValues[0],
    btnType: btnTypeValues[0],
    btndelimeter: btndelimeterValues[0],
    btnVisible: btnVisibleValues[0],
    btnSelectUser: btnSelectUserValues[0],
  });

  // 버튼 이미지 크기
  const btnImgSizeSelectBoxValueHandler = (type: number) => {
    switch (type) {
      case 0:
        return btnImgSizeValues[0];
        break;
      case 30 * 30:
        return btnImgSizeValues[1];
        break;
      case 40 * 40:
        return btnImgSizeValues[2];
        break;
      default:
        return btnImgSizeValues[0];
        break;
    }
  };
  const btnImgSizeOnChangeHandler = (value: SelectListValue) => {
    switch (parseInt(value.id)) {
      case 0:
        return 0;
        break;
      case 1:
        return 30 * 30;
        break;
      case 2:
        return 40 * 40;
        break;

      default:
        return 0;
        break;
    }
  };
  // 버튼 종류
  const btnTypeSelectBoxValueHandler = (type: string) => {
    switch (type) {
      case 'URL':
        return btnTypeValues[0];
        break;
      case 'EXE':
        return btnTypeValues[1];
        break;
      default:
        return btnTypeValues[0];
        break;
    }
  };
  // 파라미터 구분
  const btndelimeterSelectBoxValueHandler = (type: string) => {
    switch (type) {
      case ',':
        return btndelimeterValues[0];
        break;
      case ':':
        return btndelimeterValues[1];
        break;
      case ';':
        return btndelimeterValues[2];
        break;
      case '|':
        return btndelimeterValues[3];
        break;
      default:
        return btndelimeterValues[0];
        break;
    }
  };
  // 버튼 노출 설정
  const btnVisibleSelectBoxValueHandler = (type: string) => {
    switch (type) {
      case '0':
        return btnVisibleValues[0];
        break;
      case '1':
        return btnVisibleValues[1];
        break;
      case '2':
        return btnVisibleValues[2];
        break;
      case '3':
        return btnVisibleValues[3];
        break;
      case '4':
        return btnVisibleValues[4];
        break;
      case '5':
        return btnVisibleValues[5];
        break;
      default:
        return btnVisibleValues[0];
        break;
    }
  };
  // 사용자 설정
  const btnSelectUserSelectBoxValueHandler = (type: string) => {
    switch (type) {
      case '0':
        return btnSelectUserValues[0];
        break;
      case '1':
        return btnSelectUserValues[1];
        break;
      case '2':
        return btnSelectUserValues[2];
        break;
      case '3':
        return btnSelectUserValues[3];
        break;
      default:
        return btnSelectUserValues[0];
        break;
    }
  };

  return (
    <div className="grow w-full flex flex-col gap-1.5 overflow-auto">
      {/* 버튼 이미지 */}
      {/* <TextCell title="버튼 이미지">
        <InputText
          placeholder="placeholder"
          value={inputValue}
          handleChangeInput={(e) => setInputValue(e.target.value)}
          handleClear={() => setInputValue('')}
          grow
        />
        <AddPersonButton />
      </TextCell> */}
      {/* 버튼 이미지 크기 */}
      <SelectCell title="버튼 이미지 크기">
        <SelectListbox
          options={btnImgSizeValues}
          value={btnImgSizeSelectBoxValueHandler(selectedFuncBtn.btnsize)}
          name="btnsize"
          optionsHeight={12}
          width={'100%'}
          onChange={(value) => {
            dispatch(
              changeSelectedFuncBtn({
                name: 'btnsize',
                value: btnImgSizeOnChangeHandler(value),
              }),
            );
          }}
        />
      </SelectCell>
      {/* 버튼 종류 */}
      <SelectCell title="버튼 종류">
        <SelectListbox
          options={btnTypeValues}
          value={btnTypeSelectBoxValueHandler(selectedFuncBtn.btntype)}
          name="btntype"
          optionsHeight={12}
          width={'100%'}
          onChange={(value) => {
            dispatch(
              changeSelectedFuncBtn({
                name: 'btntype',
                value: value.name,
              }),
            );
          }}
        />
      </SelectCell>
      {/* 버튼 이름 */}
      <TextCell title="버튼 이름">
        <InputText
          placeholder="버튼 이름"
          name="btnname"
          value={selectedFuncBtn.btnname}
          handleChangeInput={funcBtnFormInputHandler}
          handleClear={funcBtnFormInputClearHandler}
          grow
        />
      </TextCell>
      {/* 버튼 설명 */}
      <TextCell title="버튼 설명">
        <InputText
          placeholder="버튼 설명"
          name="btncomment"
          value={selectedFuncBtn.btncomment}
          handleChangeInput={funcBtnFormInputHandler}
          handleClear={funcBtnFormInputClearHandler}
          grow
        />
      </TextCell>
      {/* 파라미터 구분 */}
      <SelectCell title="파라미터 구분">
        <SelectListbox
          options={btndelimeterValues}
          value={btndelimeterSelectBoxValueHandler(
            selectedFuncBtn.btndelimeter,
          )}
          name="btndelimeter"
          optionsHeight={12}
          width={'100%'}
          onChange={(value) => {
            dispatch(
              changeSelectedFuncBtn({
                name: 'btndelimeter',
                value: value.name,
              }),
            );
          }}
        />
      </SelectCell>
      {/* URL/EXE */}
      <TextCell title="URL / EXE">
        <InputText
          placeholder="URL / EXE"
          name="btnurlexe"
          value={selectedFuncBtn.btnurlexe}
          handleChangeInput={funcBtnFormInputHandler}
          handleClear={funcBtnFormInputClearHandler}
          grow
        />
      </TextCell>
      {/* URL / EXE 옵션 */}
      <TextCell title="URL / EXE 옵션">
        <InputText
          placeholder="URL / EXE 옵션"
          name="btnurlexeopt"
          value={selectedFuncBtn.btnurlexeopt}
          handleChangeInput={funcBtnFormInputHandler}
          handleClear={funcBtnFormInputClearHandler}
          grow
        />
      </TextCell>
      {/* 서버 파일 유무 */}
      <CheckCell title="서버 파일 유무">
        <InputRadioCheckbox
          kind="checkbox"
          onChange={funcCheckBoxHandler}
          name="btnusesvrfile"
          checked={!!parseInt(selectedFuncBtn.btnusesvrfile)}
        />
      </CheckCell>
      {/* 버전 */}
      <TextCell title="버전">
        <InputText
          placeholder="버전"
          name="btnsvrver"
          value={selectedFuncBtn.btnsvrver}
          handleChangeInput={funcBtnFormInputHandler}
          handleClear={funcBtnFormInputClearHandler}
          maxLength={10}
          grow
        />
      </TextCell>
      {/* 다운로드 후 실행할 파일명 */}
      <TextCell title="다운로드 후 실행할 파일명">
        <InputText
          placeholder="다운로드 후 실행할 파일명"
          name="btnsvrrunexe"
          value={selectedFuncBtn.btnsvrrunexe}
          handleChangeInput={funcBtnFormInputHandler}
          handleClear={funcBtnFormInputClearHandler}
          grow
        />
      </TextCell>
      {/* 파일 */}
      {/* <TextCell title="파일">
        <InputText placeholder="파일"  grow />
      </TextCell> */}
      {/* 버튼 노출 설정 */}
      <SelectCell title="버튼 노출 설정">
        <SelectListbox
          options={btnVisibleValues}
          value={btnVisibleSelectBoxValueHandler(selectedFuncBtn.btnvisible)}
          optionsHeight={12}
          width={'100%'}
          onChange={(value) => {
            dispatch(
              changeSelectedFuncBtn({
                name: 'btnvisible',
                value: value.id,
              }),
            );
          }}
        />
      </SelectCell>
      {/* URL 실행 설정 (메신저 내 실행) */}
      <CheckCell title="URL 실행 설정(메신저 내 실행)">
        <InputRadioCheckbox
          kind="checkbox"
          name="btninmessenger"
          onChange={funcCheckBoxHandler}
          checked={!!parseInt(selectedFuncBtn.btninmessenger)}
        />
      </CheckCell>
      {/* 팝업 메뉴 명칭 */}
      <TextCell title="팝업 메뉴 명칭">
        <InputText
          placeholder="팝업 메뉴 명칭"
          name="btnpopupname"
          value={selectedFuncBtn.btnpopupname}
          handleChangeInput={funcBtnFormInputHandler}
          handleClear={funcBtnFormInputClearHandler}
          grow
        />
      </TextCell>
      {/* 팝업 옵션 */}
      <TextCell title="팝업 옵션">
        <InputText
          placeholder="팝업 옵션"
          name="btnpopupopt"
          value={selectedFuncBtn.btnpopupopt}
          handleChangeInput={funcBtnFormInputHandler}
          handleClear={funcBtnFormInputClearHandler}
          grow
        />
      </TextCell>
      {/* 활성화 */}
      <CheckCell title="팝업 활성화">
        <InputRadioCheckbox
          kind="checkbox"
          name="btnusepopup"
          onChange={funcCheckBoxHandler}
          checked={!!parseInt(selectedFuncBtn.btnusepopup)}
        />
      </CheckCell>
      {/* 사용자 선택 */}
      <SelectCell title="사용자 선택">
        <SelectListbox
          options={btnSelectUserValues}
          value={btnSelectUserSelectBoxValueHandler(
            selectedFuncBtn.btnselectuser,
          )}
          optionsHeight={12}
          width={'100%'}
          onChange={(value) => {
            dispatch(
              changeSelectedFuncBtn({
                name: 'btnselectuser',
                value: value.id,
              }),
            );
          }}
        />
      </SelectCell>
      {/* 팝업 URL / EXE */}
      <TextCell title="팝업 창 주소">
        <InputText
          placeholder="팝업 창 주소"
          name="btnpopupurl"
          value={selectedFuncBtn.btnpopupurl}
          handleChangeInput={funcBtnFormInputHandler}
          handleClear={funcBtnFormInputClearHandler}
          grow
        />
      </TextCell>
    </div>
  );
};

export default DownFormContainer;
