import './styles/App.css';
// 라이브러리
// 컴포넌트
import MainPage from './pages/main/MainPage';
import LoginPage from './pages/login/LoginPage';
import { useEffect } from 'react';
// 스테이트
import { useAppSelector } from './redux/hooks';
import { Route, Routes, useNavigate } from 'react-router-dom';
import ModalView from './components/modals/ModalView';
import { searchParam } from './api/axiosInstance';
import Modal from 'react-modal';
import ReactModalView from './components/react-modal/ReactModalView';
import { Slide, ToastContainer } from 'react-toastify';
import Auth from './components/mainPage/Auth';
import { I18nextProvider } from 'react-i18next';
import i18n from './translations/i18n';

Modal.setAppElement('#root');

function App() {
  const { user } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();

  // 로그인 상태가 아닐시에 /login 페이지로 이동
  useEffect(() => {
    const fromPortal = searchParam('jwt');

    // 로그인 상태가 아니고 UCPORTAL 에서 넘어온 사용자가 아닐시 로그인 페이지로 이동
    if (!user) {
      if (!fromPortal) {
        navigate('/login');
      }
    }
  }, [user]);

  // 전역 우클릭 방지
  useEffect(() => {
    const preventRightClick = (event: { preventDefault: () => any }) =>
      event.preventDefault();
    window.addEventListener('contextmenu', preventRightClick);

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => window.removeEventListener('contextmenu', preventRightClick);
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <div className="flex flex-col h-screen">
        <Routes>
          <Route path="/*" element={Auth(MainPage)} />
          <Route path="/login" element={<LoginPage />} />
        </Routes>
        <ModalView />
        <ReactModalView />
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar
          closeButton={false}
          newestOnTop
          closeOnClick
          draggable={false}
          transition={Slide}
          theme="colored"
        />
      </div>
    </I18nextProvider>
  );
}

export default App;
