import { Dispatch, SetStateAction } from 'react';
import { IspTenantListReturnValue_desc_T } from '../interfaces/api/service-provider/I-api-admin-serviceProvider-fed';

// 기본 Pane Props
export interface PaneProps {
  setSplitPaneViewportKey: Dispatch<SetStateAction<Case>>;
  splitPaneUpdatedState: Case;
}

export interface PanePropsV1 {
  setLeftReady: (isReady: boolean) => void;
  setSplitPaneViewportKey: Dispatch<SetStateAction<Case>>;
  splitPaneUpdatedState: Case;
}

// 기본 Pane Props
export interface PanePropsV2 {
  leftReady: boolean; // `Left` 컴포넌트가 준비되었는지 나타내는 boolean 값
  setSplitPaneViewportKey: Dispatch<SetStateAction<Case>>;
  splitPaneUpdatedState: Case;
}

// 이중 split-pane 컴포넌트 Props
export interface NestedPane {
  onClick: () => void;
  splitPaneUpdatedState: Case;
}

export interface NestedPaneWithTenant extends NestedPane {
  selectedTenant: IspTenantListReturnValue_desc_T | null;
}

// flex 비율
export interface Case {
  upper?: string;
  down?: string;
  left?: string;
  right?: string;
  count: number;
}

// 상황별 flex 비율 값들
interface SplitPaneViewportCase {
  [index: string]: Case;
  clickedUpper: Case;
  clickedDown: Case;
  clickedLeft: Case;
  clickedRight: Case;
  default: Case;
}

type SplitPaneHandlerProps = [Dispatch<SetStateAction<Case>>, string];

export const splitPaneViewportCase: SplitPaneViewportCase = {
  clickedUpper: {
    upper: '1 1 auto',
    down: '9999 9999 auto',
    count: 0,
  },
  clickedDown: {
    upper: '9999 9999 auto',
    down: '1 1 auto',
    count: 0,
  },
  clickedLeft: {
    left: '1 1 auto',
    right: '9999 9999 auto',
    count: 0,
  },
  clickedRight: {
    left: '9999 9999 auto',
    right: '1 1 auto',
    count: 0,
  },
  default: {
    upper: '1 1 auto',
    down: '1 1 auto',
    count: 0,
  },
};

// splitPane handler
export const handleViewPortSize = ([
  setState,
  clickedType,
]: SplitPaneHandlerProps) => {
  setState({
    ...splitPaneViewportCase[clickedType],
    count: Math.random(),
  });
};

// export const handleViewPortSizeV1 = ([setSplitPaneViewportKey, caseForRotate]: [
//   Dispatch<SetStateAction<Case>>,
//   Case,
// ]) => {
//   setSplitPaneViewportKey({
//     ...caseForRotate,
//     count: Math.random(),
//   });
// };
