import '../../styles/splitPane.css';
import { Route, Routes } from 'react-router-dom';
import Header from '../../components/header/Header';
import { useEffect, useState } from 'react';
import { sidebarListsDataSp } from '../../components/sidebar/datas_sp';
import { IsideBarMenuListData } from '../../interfaces/mainPage/I-sidebar';
import SpSideBar from '../sp/SpSideBar';
import {
  TenantManagement,
  TenantMornitor,
  UpgradeManagement,
  AutoScaleManagement,
  TenantLogManagement,
  AdminLogManagement,
  TenantUsageStatistics,
} from '../../components/sp';

// interface IvalidateRouter {
//   link: string;
//   RouterElement: () => JSX.Element;
// }

interface Props {
  setSpAuth: React.Dispatch<React.SetStateAction<boolean>>;
}
const SpMainPage = ({ setSpAuth }: Props) => {
  const [sideBarMenuListData, setSideBarMenuListData] = useState<
    IsideBarMenuListData[] | null
  >(null);
  const [openSidebarByClick, setOpenSidebarByClick] = useState(false);

  // access/menu/list 에서 얻은 정보를 기반으로 필요한 객체배열 생성.
  useEffect(() => {
    const 서비스프로바이더메뉴 = sidebarListsDataSp.slice(-9, -2).map((v) => ({
      ...v,
      menuid: '',
      menugid: '',
      menutype: 0,
      menuurl: '',
      menuname: '',
      menuorder: 0,
      menulevel: 0,
      updatetime: '',
    }));
    서비스프로바이더메뉴.push({
      text: '로그아웃',
      link: '/sp/login',
      svgFile: function () {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path style={{ fill: 'none' }} d="M0 0h18v18H0z" />
            <path
              data-name="패스 1344"
              d="M824.587 242.729h-9a.9.9 0 0 1-.9-.9v-16.2a.9.9 0 0 1 .9-.9h8.588a.9.9 0 1 1 0 1.8h-7.688v14.4h8.1a.9.9 0 0 1 0 1.8z"
              style={{ fill: 'currentcolor' }}
              transform="translate(-813.687 -224.729)"
            />
            <path
              data-name="패스 1345"
              d="m831.223 233.593-3.6-3.6a.9.9 0 0 0-1.273 1.273l2.064 2.064h-6.828a.9.9 0 0 0 0 1.8h6.828l-2.064 2.064a.9.9 0 1 0 1.273 1.273l3.6-3.6a.9.9 0 0 0 0-1.274z"
              transform="translate(-814.487 -225.229)"
              style={{ fill: 'currentcolor' }}
            />
          </svg>
        );
      },
      menuid: '',
      menugid: '',
      menutype: 0,
      menuurl: '',
      menuname: '',
      menuorder: 0,
      menulevel: 0,
      updatetime: '',
    });

    setSideBarMenuListData(서비스프로바이더메뉴);
    console.log(서비스프로바이더메뉴);
  }, []);

  return (
    <>
      <Header type="sp" setOpenSidebarByClick={setOpenSidebarByClick} />
      {/* Down */}
      <div className=" h-[calc(100%_-_4.25rem)] flex mt-[4.25rem]">
        <SpSideBar
          sideBarMenuListData={sideBarMenuListData}
          setSpAuth={setSpAuth}
          openSidebarByClick={openSidebarByClick}
        />
        {/* mainContents */}
        <div className="p-3 w-full h-full bg-mainPagePaddingBg text-mainContentsPage-default">
          <Routes>
            {/* <Route index element={Auth(OrganizationUserManagement)} /> */}
            <Route index element={<TenantManagement />} />
            <Route path="tenant-management" element={<TenantManagement />} />
            <Route path="tenant-mornitor" element={<TenantMornitor />} />
            <Route
              path="tenant-log-management"
              element={<TenantLogManagement />}
            />
            <Route
              path="admin-log-management"
              element={<AdminLogManagement />}
            />
            <Route
              path="tenant-usage-statistics"
              element={<TenantUsageStatistics />}
            />
            <Route path="upgrade-management" element={<UpgradeManagement />} />
            <Route
              path="auto-scale-management"
              element={<AutoScaleManagement />}
            />
            <Route path="*" element={<div>404 not found</div>} />
          </Routes>
        </div>
      </div>
    </>
  );
};

export default SpMainPage;
