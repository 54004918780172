import React, { useState } from 'react';
import { SelectListValue } from '../../../interfaces/TypeSelectListbox';
import SelectListbox from '../../common/selectList/SelectListbox';

const selectValues: SelectListValue[] = [
  { id: '1', name: '전체보기' },
  { id: '2', name: '60' },
  { id: '3', name: '180' },
];
const DownTopBar = () => {
  const [selected, setSelected] = useState(selectValues[0]);

  return (
    <div className="flex py-defaultY">
      <div className="flex items-center gap-2">
        <span>월별 통계</span>
        <div className="w-40 h-9">
          <SelectListbox
            options={selectValues}
            value={selected}
            optionsHeight={12}
            // width={'5rem'}
            onChange={(value) => {
              setSelected(value);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DownTopBar;
