import axios, { AxiosError, AxiosInstance } from 'axios';
import { initUserSlice, setTenantId, setType } from '../redux/slice/userSlice';
import { store } from '../redux/store';
import { authauthHandler, directAuthHandler, validateDirectUserHandler, validateUserHandler } from '../helpers/h-auth';
import { SP_SERVER, SERVER_SUFFIX } from '../const/server';
import base64 from 'base-64';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { initAuthSlice } from '../redux/slice/authSlice';
import { initConfSlice } from '../redux/slice/confSlice';

const dispatch = store.dispatch;

export const axiosEduInstance: AxiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

export const spAxiosInstance: AxiosInstance = axios.create({
  baseURL: SP_SERVER,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosEduInstance.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem('jwtToken');
    if (token) {
      if (!config.headers) {
        config.headers = {};
      }
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

axiosEduInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const jwtToken = sessionStorage.getItem('jwtToken');
      const refToken = sessionStorage.getItem('refToken');

      if (jwtToken && refToken) {
        try {
          const newAccessToken = await refreshAccessToken(jwtToken, refToken);
          sessionStorage.setItem('jwtToken', newAccessToken);
          originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
          return axiosEduInstance(originalRequest);
        } catch (err) {
          console.error('토큰 갱신 실패:', err);
          logout();
          return Promise.reject(err);
        }
      }
    }
    return Promise.reject(error);
  },
);

//리프레시 토큰 받아오기
const refreshAccessToken = async (jwtToken: string, refToken: string) => {
  try {
    const response = await axios.post(axiosEduInstance.defaults.baseURL + '/api/v1/crs-id-auth/reissue/userjwt', {
      cmd: '1004',
      userjwt: jwtToken,
      refjwt: refToken,
    });
    //console.log(response);
    console.log(response.data.data.userjwt);
    return response.data.data.userjwt;
  } catch (error) {
    throw new Error('Failed to refresh access token');
  }
};

//let isAlertShown = false; // 전역 변수로 설정

const logout = () => {
  //console.log(isAlertShown);
  // if (isAlertShown) return; // 이미 알림이 표시된 경우 함수 종료
  // isAlertShown = true; // 알림 표시 상태로 변경

  // 1. 세션 정보 삭제
  sessionStorage.removeItem('Auth');
  sessionStorage.removeItem('jwtToken');
  sessionStorage.removeItem('refToken');
  sessionStorage.removeItem('userInfo');

  // 2. 리다이렉트
  //window.location.href = '/login';

  // 3. 상태 초기화
  dispatch(initAuthSlice());
  dispatch(initUserSlice());
  dispatch(initConfSlice());

  // 4. 사용자에게 로그아웃 알림
  alert('Your session has expired. Please login again.');
};

export const axiosErrorHandler = (error: any) => {
  if (error instanceof AxiosError) {
    const errorMessage = error.response?.data.message;
    throw new Error(errorMessage);
  }
};

export const setAxiosEduDefaultAuth = () => {
  axiosEduInstance.defaults.headers.common['Authorization'] = sessionStorage.getItem('Auth') ?? '';
};

export const setAxiosEduUrl = async () => {
  const tenantid = searchParam('tenantid');
  const jwt = searchParam('jwt');
  const refjwt = searchParam('refjwt');

  // 최초 방문일시...
  if (tenantid) {
    sessionStorage.setItem('tenantid', tenantid);
    axiosEduInstance.defaults.baseURL = `https://${tenantid}${SERVER_SUFFIX}`;
    console.log(axiosEduInstance.defaults.baseURL);

    // 포탈에서 넘어온 사용자라면..토큰값을 받는다 가정
  } else if (jwt && refjwt) {
    sessionStorage.setItem('jwtToken', jwt ?? '');
    sessionStorage.setItem('refToken', refjwt ?? '');

    const payload = jwt.substring(jwt.indexOf('.') + 1, jwt.lastIndexOf('.'));
    const decodedJwt = JSON.parse(base64.decode(payload));
    const issValue = decodedJwt.iss;
    const tenant = issValue.substring(0, issValue.indexOf('.'));
    sessionStorage.setItem('tenantid', tenant);
    axiosEduInstance.defaults.baseURL = `https://${issValue}`;
    console.log(axiosEduInstance.defaults.baseURL);
  } else {
    const tenantid = sessionStorage.getItem('tenantid');
    if (tenantid) {
      axiosEduInstance.defaults.baseURL = `https://${tenantid}${SERVER_SUFFIX}`;
      console.log(axiosEduInstance.defaults.baseURL);
      return;
    } else {
      return toast.error(t('LoginPage.loginFailDesc3') as string);
    }
  }
};

export const searchParam = (key: string) => {
  return new URLSearchParams(location.search).get(key);
};
