import React from 'react';
import ListTitle from '../../common/ListTitle';

const dummyData = new Array(30).fill(null).map(() => ({
  eventSeperate: 'SYNC',
  eventState: 'STOP',
  disturbanceTime: '00:00:13',
  startTime: '2022-05-25 02:00:00',
  endTime: '2022-05-25 02:00:00',
}));

const CenterListContainer = () => {
  return (
    <div className="h-[calc(100%_-_6.25rem)]">
      <ListTitle
        titles={[
          'EVENT 구분',
          'EVENT 상태',
          '소요 시간',
          '시작 시간',
          '완료 시간',
        ]}
      />
      <div className="overflow-auto h-full">
        {dummyData.map((data, i) => (
          <List data={data} key={i} index={i} />
        ))}
      </div>
    </div>
  );
};

interface Data {
  eventSeperate: string;
  eventState: string;
  disturbanceTime: string;
  startTime: string;
  endTime: string;
}

type Props = {
  data: Data;
  index: number;
};

const List: React.FC<Props> = ({ data, index }) => {
  return (
    <div
      className={`grid grid-cols-5 border-b border-solid py-[0.813rem] border-[#dfe2e8] ${
        index < 1 ? 'bg-[#f1f3f6]' : ''
      }`}
    >
      <div className="text-center">{data.eventSeperate}</div>
      <div className="text-center">{data.eventState}</div>
      <div className="text-center">{data.disturbanceTime}</div>
      <div className="text-center">{data.startTime}</div>
      <div className="text-center">{data.endTime}</div>
    </div>
  );
};

export default CenterListContainer;
