import React from 'react';
import Arrow from '../../assets/svg/arrows/Arrow';
import backImg from '../../assets/image/etc/back3.png';
import { useAppDispatch } from '../../redux/hooks';

interface Props {
  text: string;
  textOnly?: boolean;
  onClick?: () => void;
  direction?: string;
  back?: boolean;
}

const MainPageTitleHeader: React.FC<Props> = ({ text, textOnly = false, onClick, direction, back }) => {
  return (
    <div className="flex justify-between w-full text-[#555555] py-[0.688rem]">
      <div className="font-bold w-full truncate">{text}</div>
      {!textOnly && (
        <div className="">
          <Arrow direction={direction} onClick={onClick} />
        </div>
      )}
    </div>
  );
};

export default MainPageTitleHeader;
function dispatch(arg0: { payload: undefined; type: string }) {
  throw new Error('Function not implemented.');
}
