import React from 'react';

interface Data {
  groupCode: string;
  groupName: string;
  groupCaption: string;
  domain: string;
}

type Props = {
  data: Data;
  index: number;
};

const Lists: React.FC<Props> = ({ data, index }) => {
  return (
    <div
      className={`grid grid-cols-4 border-b border-solid py-[0.813rem] border-[#dfe2e8] ${
        index < 1 ? 'bg-[#f1f3f6]' : ''
      }`}
    >
      <div className="text-center">{data.groupCode}</div>
      <div className="text-center">{data.groupName}</div>
      <div className="text-center">{data.groupCaption}</div>
      <div className="text-center">{data.domain}</div>
    </div>
  );
};

export default Lists;
