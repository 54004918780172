import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import ClassDetailsTeacher from './ClassDetailsTeacher';
import ClassDetailsStudent from './ClassDetailsStudent';

const ClassDetails = () => {
  const selectedClassCode = useSelector((state: RootState) => state.classManagement.selectedClassCode);

  return (
    <div className="pr-2 pl-2 category-list-container h-auto">
      {selectedClassCode ? (
        <>
          <ClassDetailsTeacher />
          <ClassDetailsStudent />
        </>
      ) : (
        <div className="text-center text-gray-400 text-base mt-[270px]">
          {String(t('ClassManagement.classDetailSel'))}
        </div>
      )}
    </div>
  );
};

export default ClassDetails;
