import React from 'react';

interface Props {
  onClick?: () => void;
  direction?: string;
}

interface DirectionStyle {
  [index: string]: string;
  right: string;
  down: string;
  left: string;
}

const Arrow = ({ onClick, direction }: Props) => {
  const directionStyle: DirectionStyle = {
    default: '',
    right: 'rotate-right',
    down: 'rotate-down',
    left: 'rotate-left',
  };
  const rotate = directionStyle[direction ? direction : 'default'];

  return (
    <svg
      className={`${rotate} hover:scale-125 cursor-pointer`}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      onClick={onClick}
    >
      <path data-name="사각형 428" style={{ fill: 'none' }} d="M0 0h16v16H0z" />
      <path
        data-name="패스 1079"
        d="m3.4 8.753 5-5 5 5"
        transform="translate(-.404 1.247)"
        style={{
          stroke: '#636465',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: '2px',
          fill: 'none',
        }}
      />
    </svg>
  );
};

export default Arrow;
