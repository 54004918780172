import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import Button from '../../common/buttons/Button';
import { openRuleFuncRuleModal } from '../../../helpers/h-modals';
import { handleRemoveUserFromRule } from '../../../helpers/h-ruleDesignation';
import { fetchAndSetRuleCodeList } from '../../../helpers/h-ruleFuncMng';
import {
  IhrsUserListApiResponseFull,
  IUserInfo,
} from '../../../interfaces/api/I-api-hrs-users';
import { SelectListValue } from '../../../interfaces/TypeSelectListbox';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  initRuleFuncMngSlice,
  setSelectedRuleCode,
} from '../../../redux/slice/ruleFunctionManagementSlice';
import SelectListbox from '../../common/selectList/SelectListbox';

interface Props {
  selectedUsers: IhrsUserListApiResponseFull[];
  setSelectedUsers: Dispatch<SetStateAction<IhrsUserListApiResponseFull[]>>;
}

const LeftTopBar = (props: Props) => {
  const dispatch = useAppDispatch();
  const { ruleCodeList, selectedRuleCode } = useAppSelector(
    (state) => state.ruleFunctionManagement,
  );
  // LIST_BOX
  const deafaultSelectValues: SelectListValue[] = useMemo(() => {
    return [{ id: '1', name: 'empty' }];
  }, []);
  const [selected, setSelected] = useState(deafaultSelectValues[0]);
  const [localRuleCodeList, setLocalRuleCodeList] = useState<
    SelectListValue[] | null
  >(null);

  // selectedRuleCode 설정
  const handleSelectedRuleCode = (index: number) => {
    if (ruleCodeList.length < 1) return;
    dispatch(setSelectedRuleCode(ruleCodeList[index]));
  };

  // SelectListbox value 설정
  useEffect(() => {
    if (localRuleCodeList === null) return;

    setSelected(localRuleCodeList[0]);
  }, [localRuleCodeList]);

  // SelectListBox options를 위한 localRuleCodeList 설정
  useEffect(() => {
    if (ruleCodeList.length < 1) return;
    else if (ruleCodeList.length > 0) {
      setLocalRuleCodeList(
        ruleCodeList.map((rule, index) => ({
          id: index.toString(),
          name: rule.rulename,
        })),
      );
    } else {
      setLocalRuleCodeList(deafaultSelectValues);
    }
  }, [ruleCodeList]);

  // 초기화 함수
  useEffect(() => {
    return () => {
      dispatch(initRuleFuncMngSlice());
    };
  }, []);

  const onClickRemove = () => {
    if (props.selectedUsers.length < 1) {
      toast.error('사용자를 선택해주세요');
      return;
    }

    handleRemoveUserFromRule(props.selectedUsers, selectedRuleCode.rulecode);
    props.setSelectedUsers([]);
  };

  return (
    <div className="py-[0.563rem] gap-1 flex justify-between text-fontColor-default">
      <div className="flex grow gap-1">
        <div className="flex whitespace-nowrap justify-center items-center pr-3">
          Rule_(규칙)
        </div>
        <SelectListbox
          options={localRuleCodeList ? localRuleCodeList : deafaultSelectValues}
          value={selected}
          optionsHeight={12}
          width={'100%'}
          onChange={(value) => {
            setSelected(value);
            handleSelectedRuleCode(parseInt(value.id));
          }}
        />
      </div>
      <div className="flex gap-1">
        <Button
          kind="cancel"
          label="규칙 수정"
          onClick={openRuleFuncRuleModal}
        />
        <Button kind="cancel" label="삭제" onClick={onClickRemove} />
      </div>
    </div>
  );
};

export default LeftTopBar;
