import React, { useState } from 'react';
import { SelectListValue } from '../../../interfaces/TypeSelectListbox';
import SelectListbox from '../../common/selectList/SelectListbox';

const selectValues: SelectListValue[] = [
  { id: '1', name: '10' },
  { id: '2', name: '60' },
  { id: '3', name: '180' },
];
const DownTopBar = () => {
  const [selected, setSelected] = useState(selectValues[0]);

  return (
    <div className="flex gap-8 py-defaultY">
      <div className="flex items-center gap-2">
        <span>모니터링 시간(초)</span>
        <div className="h-9">
          <SelectListbox
            options={selectValues}
            value={selected}
            optionsHeight={12}
            width={'5rem'}
            onChange={(value) => {
              setSelected(value);
            }}
          />
        </div>
      </div>
      <div className="flex items-center gap-2">
        <span>최대 차트 데이터 갯수</span>
        <div className="h-9">
          <SelectListbox
            options={selectValues}
            value={selected}
            optionsHeight={12}
            width={'5rem'}
            onChange={(value) => {
              setSelected(value);
            }}
          />
        </div>
      </div>
      <div className="flex items-center gap-2">
        <span>현재 로그인 사용자 :</span>
        <span>11</span>
      </div>
    </div>
  );
};

export default DownTopBar;
