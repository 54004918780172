import React from 'react';

interface Props {
  text: string | JSX.Element; // JSX.Element로 변경
  textOnly?: boolean;
  onClick?: () => void;
  direction?: string;
  back?: boolean;
}

const MainPageTitleHeaderDesc: React.FC<Props> = ({ text }) => {
  return <div className="w-full text-xs ml-0.5 text-[#555555]">{text}</div>;
};

export default MainPageTitleHeaderDesc;
