import React from 'react';
import ListTitle from '../../common/ListTitle';

const dummyData = new Array(30).fill(null).map(() => ({
  time: '2022-05-13 18:06:25',
  type: 'Login',
  userID: 'G002TestUser03',
  userName: '조진환',
  deviceType: '임 pc',
  os: 'Windows',
  macAddress: '00-E0-4C-68-0B-7E',
  ipAddress: '222.121.60.22',
  deviceModel: 'DESKTOP-GGDI4D',
  messangerVersion: '732',
}));

const ListContainer = () => {
  return (
    <div className="flex flex-col h-[calc(100%_-_7rem)]">
      <ListTitle
        titles={[
          '시간',
          '관리자ID',
          '종류',
          '사용자ID',
          '부서 코드',
          '부서 이름',
          '검색 시작 시간',
          '검색 끌 시간',
          '검색',
          '비고',
        ]}
      />
      <div className="overflow-auto">
        {dummyData.map((data, i) => (
          <List data={data} key={i} index={i} />
        ))}
      </div>
    </div>
  );
};

interface Data {
  time: string;
  type: string;
  userID: string;
  userName: string;
  deviceType: string;
  os: string;
  macAddress: string;
  ipAddress: string;
  deviceModel: string;
  messangerVersion: string;
}

type Props = {
  data: Data;
  index: number;
};

const List: React.FC<Props> = ({ data, index }) => {
  const dataValue = Object.values(data);

  return (
    <div
      className={`grid grid-cols-10 border-b border-solid py-[0.813rem] border-[#dfe2e8] ${
        index < 1 ? 'bg-[#f1f3f6]' : ''
      }`}
    >
      {dataValue.map((data, i) => (
        <div key={i} className="text-center">
          {data}
        </div>
      ))}
    </div>
  );
};

export default ListContainer;
