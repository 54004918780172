import { t } from 'i18next';
import { GridApi } from 'ag-grid-community';
import { toast } from 'react-toastify';
import Button from '../../common/buttons/Button';
import { handleRemoveUserFromRole } from '../../../helpers/h-adminIdMng';
import { IroleListApiListResponse } from '../../../interfaces/api/I-api-admin-role';
import { openAdminIdMngUserList_Table, openUserOrgMngReactModal_add_dept } from '../../../helpers/h-react-modals';
import { store } from '../../../redux/store';

interface Props {
  gridApi: GridApi<IroleListApiListResponse[]> | undefined;
  selectedUsers: IroleListApiListResponse[];
  setSelectedUsers: React.Dispatch<React.SetStateAction<IroleListApiListResponse[]>>;
}
const RightTopBar = (props: Props) => {
  const {
    user: { userInfo },
  } = store.getState();

  const onClickRemoveUser = () => {
    if (props.selectedUsers.length < 1) {
      toast.error(String(t('AdminIdManagement.toast2')));
      return;
    }

    const hasDuplicate = props.selectedUsers.some((user) => user.userid === userInfo?.userid);

    if (hasDuplicate) {
      toast.error(String(t('AdminIdManagement.toast9')));
      return;
    }

    handleRemoveUserFromRole(props.selectedUsers);
    props.setSelectedUsers([]);
  };

  return (
    <div className="py-defaultY flex gap-1 overflow-hidden">
      <Button label={t('AdminIdManagement.addAdmin')} kind="primary" onClick={openAdminIdMngUserList_Table} />
      <Button label={t('AdminIdManagement.deleteAdmin')} kind="cancel" onClick={onClickRemoveUser} />
    </div>
  );
};

export default RightTopBar;
