const BtnMore = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="4"
      height="16"
      viewBox="0 0 4 16"
    >
      <g id="btn_more" transform="translate(-0.006)">
        <circle
          id="타원_364"
          cx="2"
          cy="2"
          r="2"
          style={{ fill: 'currentcolor' }}
          transform="translate(0.006)"
        />
        <circle
          id="타원_365"
          cx="2"
          cy="2"
          r="2"
          style={{ fill: 'currentcolor' }}
          transform="translate(0.006 6)"
        />
        <circle
          id="타원_366"
          cx="2"
          cy="2"
          r="2"
          style={{ fill: 'currentcolor' }}
          transform="translate(0.006 12)"
        />
      </g>
    </svg>
  );
};

export default BtnMore;
