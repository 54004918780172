import { t } from 'i18next';
import React from 'react';
import { IChannelDTO } from '../../interfaces/api/counsel/I-api-admin-counsel-channel';

type ContextMenuProps = {
  top: number;
  left: number;
  show: boolean;
  onClose: () => void;
  onEdit: () => void; // 수정 핸들러
  onDelete: () => void; // 삭제 핸들러
  selectedNodeId: string | IChannelDTO | null;
};

const CounselingCategoryContextMenu: React.FC<ContextMenuProps> = ({
  top,
  left,
  show,
  onClose,
  onEdit,
  onDelete,
  selectedNodeId,
}) => {
  if (!show) return null;

  return (
    <div
      className="fixed z-50 bg-white shadow-xl rounded border-gray-950 w-32"
      style={{ top: `${top}px`, left: `${left}px` }}
    >
      <ul className="list-none p-2 m-0">
        <li className="px-4 py-2 rounded cursor-pointer hover:bg-gray-100 text-sm" onClick={onEdit}>
          {t('CounselingManagement.contextMenuMod') as string}
        </li>
        <li className="px-4 py-2 rounded cursor-pointer hover:bg-gray-100 text-sm" onClick={onDelete}>
          {t('CounselingManagement.contextMenuDel') as string}
        </li>
      </ul>
    </div>
  );
};

export default CounselingCategoryContextMenu;
