import { ColDef } from 'ag-grid-community';
import { IAdminButtonCntListApiResult } from '../../../interfaces/api/button/I-api-admin-btn-cnt';
import { IAdminButtonGroup } from '../../../interfaces/api/button/I-api-admin-btn-group';
import { AgCellTextCenter } from '../../common/AgCustomCell';
import { AgHeaderTextCenter } from '../../common/AgCustomHeader';

export const CntBtnList_Table_Column: ColDef<IAdminButtonCntListApiResult>[] = [
  {
    field: 'btnimgsrc',
    headerName: '버튼 이미지',
    headerComponent: AgHeaderTextCenter,
    cellRenderer: AgCellTextCenter<IAdminButtonCntListApiResult>,
  },
  {
    field: 'btnname',
    headerName: '버튼 이름',
    headerComponent: AgHeaderTextCenter,
    cellRenderer: AgCellTextCenter<IAdminButtonCntListApiResult>,
  },
  {
    field: 'btncomment',
    headerName: '버튼 설명',
    headerComponent: AgHeaderTextCenter,
    cellRenderer: AgCellTextCenter<IAdminButtonCntListApiResult>,
  },
];

export const CntBtnGroupHubList_Table_Column: ColDef<IAdminButtonCntListApiResult>[] =
  [
    {
      field: 'btnimgsrc',
      headerName: '버튼 이미지',
      headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<IAdminButtonCntListApiResult>,
    },
    {
      field: 'btnmethod',
      headerName: '버튼 종류',
      headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<IAdminButtonCntListApiResult>,
    },
    {
      field: 'btnname',
      headerName: '버튼 이름',
      headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<IAdminButtonCntListApiResult>,
    },
    {
      field: 'btncomment',
      headerName: '버튼 설명',
      headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<IAdminButtonCntListApiResult>,
    },
    // {
    //   field: 'groupver',
    //   headerName: '버튼 버전',
    // },
  ];

export const CntBtnGroupList_Table_Column: ColDef<IAdminButtonGroup>[] = [
  {
    field: 'groupname',
    headerName: '버튼 그룹 이름',
    headerComponent: AgHeaderTextCenter,
    cellRenderer: AgCellTextCenter<IAdminButtonGroup>,
  },
  {
    field: 'groupver',
    headerName: '버튼 그룹 버전',
    headerComponent: AgHeaderTextCenter,
    cellRenderer: AgCellTextCenter<IAdminButtonGroup>,
  },
  {
    field: 'grouporder',
    headerName: '버튼 그룹 순서',
    cellRenderer: AgCellTextCenter<IAdminButtonGroup>,
  },
];
