import Button from '../../common/buttons/Button';
import InputText from '../../common/inputs/InputText';

const UpperTopBar = () => {
  return (
    <div className="flex justify-between gap-8 py-defaultY">
      <div className="w-[19.5rem]">
        <InputText placeholder="로그 파일명 검색" />
      </div>
      <Button kind="cancel" label="로그 다운로드" />
    </div>
  );
};

export default UpperTopBar;
