import { ColDef } from 'ag-grid-community';
import { IadminRuleCode } from '../../../interfaces/api/rule/I-api-admin-rule-code';
import { IadminRuleFunc } from '../../../interfaces/api/rule/I-api-admin-rule-func';
import { IadminRulefuncHub } from '../../../interfaces/api/rule/I-api-admin-rule-func-hub';
import { AgCellTextCenter } from '../../common/AgCustomCell';
import { AgHeaderTextCenter } from '../../common/AgCustomHeader';

export const RULE_CODE_LIST_TALBLE_COLUMN: ColDef<IadminRuleCode>[] = [
  {
    field: 'rulename',
    headerName: 'rule 명칭',
    headerComponent: AgHeaderTextCenter,
    cellRenderer: AgCellTextCenter<IadminRuleCode>,
  },
  {
    field: 'rulecode',
    headerName: 'rule 코드',
    headerComponent: AgHeaderTextCenter,
    cellRenderer: AgCellTextCenter<IadminRuleCode>,
  },
];

export const RULE_FUNC_LIST_TALBLE_COLUMN: ColDef<IadminRuleFunc>[] = [
  {
    field: 'funckind',
    headerName: '기능 구분',
    headerComponent: AgHeaderTextCenter,
    cellRenderer: AgCellTextCenter<IadminRuleFunc>,
  },
  {
    field: 'funcname',
    headerName: '기능 명',
    headerComponent: AgHeaderTextCenter,
    cellRenderer: AgCellTextCenter<IadminRuleFunc>,
  },
];

export const RULE_FUNC_HUB_LIST_TALBLE_COLUMN: ColDef<IadminRulefuncHub>[] = [
  {
    field: 'funckind',
    headerName: '구분',
    headerComponent: AgHeaderTextCenter,
    cellRenderer: AgCellTextCenter<IadminRulefuncHub>,
  },
  {
    field: 'funcname',
    headerName: '기능 명',
    headerComponent: AgHeaderTextCenter,
    cellRenderer: AgCellTextCenter<IadminRulefuncHub>,
  },
  {
    field: 'funcfield1',
    headerName: '옵션 1',
    headerComponent: AgHeaderTextCenter,
    cellRenderer: AgCellTextCenter<IadminRulefuncHub>,
  },
  {
    field: 'funcfield2',
    headerName: '옵션 2',
    headerComponent: AgHeaderTextCenter,
    cellRenderer: AgCellTextCenter<IadminRulefuncHub>,
  },
];

export const RULE_OPTION_LIST_TABLE_COLUMN: ColDef<any>[] = [
  {
    field: 'key',
    headerName: '이름',
    headerComponent: AgHeaderTextCenter,
    cellRenderer: AgCellTextCenter<any>,
  },
  {
    field: 'value',
    headerName: '값',
    headerComponent: AgHeaderTextCenter,
    cellRenderer: AgCellTextCenter<any>,
  },
];
