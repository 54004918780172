import React from 'react';
import { toast } from 'react-toastify';
import { fedRejectHandler } from '../../../helpers/h-federationMng';
import { closeFederation_reject_Modal } from '../../../helpers/h-modals';
import { IserviceProviderFedRejectApiBody } from '../../../interfaces/api/service-provider/I-api-admin-serviceProvider-fed';
import { useAppSelector } from '../../../redux/hooks';
import FederationMng_ask_common from './FederationMng_ask_common';

const FederationMng_rejectApply_Modal = () => {
  const { selectedOtherFederation } = useAppSelector(
    (state) => state.federatinManagement,
  );

  // 연결 신청 / 연결 해제 요청 거부
  const handleRejectRequest = () => {
    if (!selectedOtherFederation)
      return toast.error('Please select item first');

    if (
      selectedOtherFederation.status !== 2 &&
      selectedOtherFederation.status !== 6
    )
      return toast.error('연결 신청 받음 | 연결 해제 요청 받음이 아닙니다. ');

    const body = {
      srctenantid: selectedOtherFederation.srctenantid,
      desttenantid: selectedOtherFederation.desttenantid,
    };

    fedRejectHandler(body);
  };

  return (
    <FederationMng_ask_common
      label="선택된 항목을 거부하시겠습니까?"
      onClickClose={closeFederation_reject_Modal}
      onClickHandler={() => {
        handleRejectRequest();
        closeFederation_reject_Modal();
      }}
    />
  );
};

export default FederationMng_rejectApply_Modal;
