import React from 'react';
import { SERVER_SUFFIX } from '../../../const/server';

const TenantUsageStatistics = () => {
  return (
    <div className="w-full h-full flex flex-col border border-solid bg-white border-[#dfe2e8] rounded">
      {/* <MainPageTitleHeader text="로그" textOnly /> */}
      <iframe
        src={`https://oss${SERVER_SUFFIX}/kibana/app/dashboards?auth_provider_hint=anonymous1#/view/1a457650-4555-11ed-a6ce-3711e0756c8e?embed=true&_g=(filters%3A!()%2CrefreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3Anow-15m%2Cto%3Anow))&show-time-filter=true&hide-filter-bar=true`}
        height={'100%'}
        width={'100%'}
      ></iframe>
    </div>
  );
};

export default TenantUsageStatistics;
