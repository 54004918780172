import React from 'react';

interface Props {
  titles: string[];
}

const ListTitle: React.FC<Props> = ({ titles }) => {
  const gridCols = `grid-cols-${titles.length}`;
  return (
    <div className={`grid ${gridCols} bg-[#dfe2e8] font-bold py-[0.813rem]`}>
      {titles.map((title, i) => (
        <div key={i} className="text-center truncate">
          {title}
        </div>
      ))}
    </div>
  );
};

export default ListTitle;
