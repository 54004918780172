import { useEffect, useState } from 'react';

// 라이브러리
import { SplitPane } from 'react-multi-split-pane';
import { useResizeDetector } from 'react-resize-detector';

// 컴포넌트
import DownTopBar from './DownTopBar';
import UpperFormContainer from './UpperFormContainer';
import {
  handleViewPortSize,
  PaneProps,
  splitPaneViewportCase,
} from '../../../helpers/h-splitPane';
import MainPageTitleHeader from '../../common/MainPageTitleHeader';

const UploadEnvironmentFile = () => {
  const [splitPaneViewportKey, setSplitPaneViewportKey] = useState(
    splitPaneViewportCase.default,
  );

  return (
    <div className="h-full w-full">
      <SplitPane
        className="custom"
        split="horizontal"
        minSize={[50, 50]}
        defaultSizes={[259, 717]}
      >
        <Upper
          setSplitPaneViewportKey={setSplitPaneViewportKey}
          splitPaneUpdatedState={splitPaneViewportKey}
        />
        <Down
          setSplitPaneViewportKey={setSplitPaneViewportKey}
          splitPaneUpdatedState={splitPaneViewportKey}
        />
      </SplitPane>
    </div>
  );
};

const Upper = ({
  setSplitPaneViewportKey,
  splitPaneUpdatedState,
}: PaneProps) => {
  const { ref } = useResizeDetector();

  useEffect(() => {
    ref.current.parentElement.style.flex = splitPaneUpdatedState.upper;
  }, [splitPaneUpdatedState]);

  return (
    <div
      ref={ref}
      className="w-full h-full border border-solid border-[#dfe2e8] rounded"
    >
      <div className="w-full h-full px-[1.5rem] flex flex-col bg-white">
        <MainPageTitleHeader
          text="환경파일 업로드"
          onClick={() =>
            handleViewPortSize([setSplitPaneViewportKey, 'clickedUpper'])
          }
        />
        <UpperFormContainer />
      </div>
    </div>
  );
};

const Down = ({
  setSplitPaneViewportKey,
  splitPaneUpdatedState,
}: PaneProps) => {
  const { ref } = useResizeDetector();

  useEffect(() => {
    ref.current.parentElement.style.flex = splitPaneUpdatedState.down;
  }, [splitPaneUpdatedState]);

  return (
    <div
      ref={ref}
      className="w-full h-full px-[1.5rem] border border-solid border-[#dfe2e8] rounded bg-white overflow-auto"
    >
      <MainPageTitleHeader
        text="환경설정 에디터"
        onClick={() =>
          handleViewPortSize([setSplitPaneViewportKey, 'clickedDown'])
        }
        direction="down"
      />
      <DownTopBar />
      {/* 에디터 */}
      <div></div>
    </div>
  );
};

export default UploadEnvironmentFile;
