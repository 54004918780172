import { useTranslation } from 'react-i18next';
import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useEffect, useMemo, useState } from 'react';
import Button from '../../../common/buttons/Button';
import { IspTenantListReturnValue_desc_T } from '../../../../interfaces/api/service-provider/I-api-admin-serviceProvider-fed';
import { UPGRADE_MNG_Version_LIST_TABLE_COL } from './columns';
import axios from 'axios';
import {
  spTenantUpgrade,
  spUksGetsApi_deployment,
} from '../../../../api/admin/service-provider/api-admin-sp-uks';
import { IspUksGetsApiResponse_deployment } from '../../../../interfaces/api/service-provider/I-api-admin-sp-uks';

interface Props {
  selectedTenant: IspTenantListReturnValue_desc_T | null;
}
const UpgradeManagement_VersionControl_Table = ({ selectedTenant }: Props) => {
  const { t } = useTranslation();
  // AG_GRID
  const [gridApi, setGridApi] =
    useState<GridApi<IspUksGetsApiResponse_deployment[]>>();
  const [rowData, setRowData] = useState<IspUksGetsApiResponse_deployment[]>(
    [],
  );
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
  // const [columnDefs, setColumnDefs] = useState<ColDef[]>(
  //   UPGRADE_MNG_Version_LIST_TABLE_COL,
  // );
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      resizable: true,
      minWidth: 100,
      // sortable: true,
      // filter: true,
      // editable: true,
    };
  }, []);
  const [versionUpgradable, setVersionUpgradable] = useState(false);

  const onGridReady = (
    params: GridReadyEvent<IspUksGetsApiResponse_deployment[]>,
  ) => {
    setGridApi(params.api);
  };

  const fetchRowData = async (tenantid: string) => {
    try {
      const versionList = await spUksGetsApi_deployment({
        namespace: `ucworks-${tenantid}`,
        kind: 'deployment',
      });
      if (!versionList) return;

      const data = versionList.data.data.result;
      const isUpgradable = data.filter((v) => v.upgradable === true).length;
      if (isUpgradable) {
        setVersionUpgradable(true);
      } else {
        setVersionUpgradable(false);
      }
      console.log(data);
      setRowData(data);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
      } else {
        console.log('unexpected error: ', error);
      }
    }
  };

  const upgradeVersion = async () => {
    if (!selectedTenant) return;

    try {
      const res = await spTenantUpgrade({
        tenantid: selectedTenant.tenantid,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
      } else {
        console.log('unexpected error: ', error);
      }
    }
  };

  useEffect(() => {
    if (!gridApi || !selectedTenant) return;
  }, [selectedTenant, gridApi]);

  useEffect(() => {
    if (!selectedTenant) return;

    fetchRowData(selectedTenant.tenantid);
  }, [selectedTenant]);

  useEffect(() => {
    setColumnDefs(UPGRADE_MNG_Version_LIST_TABLE_COL(t));
  }, [t]);

  return (
    <div className="w-full grow flex flex-col pb-defaultY overflow-hidden">
      {/* 탑 바 */}
      {versionUpgradable && (
        <div className="pb-[0.563rem] gap-1 flex justify-end text-fontColor-default">
          <div>
            <Button
              kind="cancel"
              label={t('UpgradeManagement.applyLatestVersion')}
              onClick={upgradeVersion}
            />
          </div>
        </div>
      )}
      {/* 테이블 */}
      <div className="ag-theme-alpine ag-theme-mycustomtheme grow w-full">
        <AgGridReact
          columnDefs={columnDefs}
          rowData={rowData}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          rowSelection={'single'}
          overlayNoRowsTemplate={'\xa0'}
        ></AgGridReact>
      </div>
    </div>
  );
};

export default UpgradeManagement_VersionControl_Table;
