import {
  IhrsUserListApiResponseFull,
  IHrsUsersListApiBody,
  IHrsUsersSearchApiBody,
  IhrsUsersSearchApiResponse_FULL,
} from '../../interfaces/api/I-api-hrs-users';
import {
  IpluralAxiosHrsUsersResponse,
  IpluralAxiosHrsUsersSearch,
} from '../../interfaces/api/I-axios';
import { axiosInstance } from '../axiosInstance';

const hrsUserInfo = {
  url: {
    dup: '/api/v1/crs-id-hrs/users',
    list: '/api/v1/crs-id-hrs/users/list',
    search: '/api/v1/crs-id-hrs/users/search',
  },
};

// 사용자 추가시 중복 api
export const userIdDup = (userId: string) => {
  const { url } = hrsUserInfo;
  return axiosInstance.post<
    IpluralAxiosHrsUsersResponse<IhrsUserListApiResponseFull>
  >(url.dup, { lang: 'ko', cmd: '1105', bid: userId });
};

// list
export const hrsUserListApi = (props: IHrsUsersListApiBody) => {
  const { url } = hrsUserInfo;

  return axiosInstance.post<
    IpluralAxiosHrsUsersResponse<IhrsUserListApiResponseFull>
  >(url.list, props);
};

// user/search 일반 유저 검색
export const hrsUserSearchApi = (props: IHrsUsersSearchApiBody) => {
  const { url } = hrsUserInfo;

  return axiosInstance.post<
    IpluralAxiosHrsUsersSearch<IhrsUsersSearchApiResponse_FULL>
  >(url.search, props);
};

// admin/search 어드민이 아닌 일반유저 검색
export const hrsAdminUserSearchApi = (props: IHrsUsersSearchApiBody) => {
  const { url } = hrsUserInfo;

  return axiosInstance.post<
    IpluralAxiosHrsUsersSearch<IhrsUsersSearchApiResponse_FULL>
  >(url.search, props);
};
