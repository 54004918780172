import ClassLeft from './ClassLeft';
import ClassRight from './ClassRight';

const ClassMain = () => {
  return (
    <div className="flex w-full h-full mt-5 mb-5 select-none">
      <ClassLeft />
      <ClassRight />
    </div>
  );
};

export default ClassMain;
