import React, { useState } from 'react';
import Button from '../../common/buttons/Button';
import InputDatePicker from '../../common/inputs/InputDatePicker';
import InputText from '../../common/inputs/InputText';
import { SelectListValue } from '../../../interfaces/TypeSelectListbox';
import SelectListbox from '../../common/selectList/SelectListbox';

const selectValues: SelectListValue[] = [
  { id: '1', name: '발신자ID' },
  { id: '2', name: '발신자 이름' },
  { id: '3', name: '발신자 코드' },
];
const UpperTopBar = () => {
  const [selected, setSelected] = useState(selectValues[0]);

  const [pickedDate, setPickedDate] = useState<Date | null>(null);

  return (
    <div className="flex justify-between gap-8 py-defaultY">
      <div className="flex gap-4">
        {/* 조회 기간 */}
        <div className="flex gap-2">
          <div className="flex items-center gap-2">
            <span className="truncate">조회 기간</span>
            <div className="w-32">
              <InputDatePicker
                pickedDate={pickedDate}
                setPickedDate={(date) => setPickedDate(date)}
                placeholder="시작일"
              />
            </div>
          </div>
          <div className="flex justify-center items-center">~</div>
          <div className="w-32">
            <InputDatePicker
              pickedDate={pickedDate}
              setPickedDate={(date) => setPickedDate(date)}
              placeholder=""
            />
          </div>
        </div>
        {/* 상세 검색 */}
        <div className="flex items-center gap-2">
          <span className="truncate">상세 검색</span>
          <div className="w-32 h-9">
            <SelectListbox
              options={selectValues}
              value={selected}
              optionsHeight={12}
              // width={'5rem'}
              onChange={(value) => {
                setSelected(value);
              }}
            />
          </div>
          <InputText placeholder="검색" />
        </div>
      </div>
      <Button kind="cancel" label="엑셀 다운로드" />
    </div>
  );
};

export default UpperTopBar;
