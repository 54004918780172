export const serverListDummy = [
  {
    serverName: 'SCS#ap1',
    pid: '34541',
    state: '실행중',
    updatedTime: '2022-02-16 08:46:20',
    cpuUsage: '0',
    memoryUsage: '0',
  },
  {
    serverName: 'SCS#ap1',
    pid: '34541',
    state: '실행중',
    updatedTime: '2022-02-16 08:46:20',
    cpuUsage: '0',
    memoryUsage: '0',
  },
  {
    serverName: 'SCS#ap1',
    pid: '34541',
    state: '실행중',
    updatedTime: '2022-02-16 08:46:20',
    cpuUsage: '0',
    memoryUsage: '0',
  },
  {
    serverName: 'SCS#ap1',
    pid: '34541',
    state: '실행중',
    updatedTime: '2022-02-16 08:46:20',
    cpuUsage: '0',
    memoryUsage: '0',
  },
  {
    serverName: 'SCS#ap1',
    pid: '34541',
    state: '실행중',
    updatedTime: '2022-02-16 08:46:20',
    cpuUsage: '0',
    memoryUsage: '0',
  },
  {
    serverName: 'SCS#ap1',
    pid: '34541',
    state: '실행중',
    updatedTime: '2022-02-16 08:46:20',
    cpuUsage: '0',
    memoryUsage: '0',
  },
  {
    serverName: 'SCS#ap1',
    pid: '34541',
    state: '실행중',
    updatedTime: '2022-02-16 08:46:20',
    cpuUsage: '0',
    memoryUsage: '0',
  },
];

export const serviceListDummy = [
  {
    division: 'DS',
    serviceName: 'DS@ap1',
    pid: '9718',
    state: '실행중',
    updatedTime: '2022-02-16 08:46:20',
    cpuUsage: '0',
    memoryUsage: '0',
    master: '1',
  },
  {
    division: 'DS',
    serviceName: 'DS@ap1',
    pid: '9718',
    state: '실행중',
    updatedTime: '2022-02-16 08:46:20',
    cpuUsage: '0',
    memoryUsage: '0',
    master: '1',
  },
  {
    division: 'DS',
    serviceName: 'DS@ap1',
    pid: '9718',
    state: '실행중',
    updatedTime: '2022-02-16 08:46:20',
    cpuUsage: '0',
    memoryUsage: '0',
    master: '1',
  },
  {
    division: 'DS',
    serviceName: 'DS@ap1',
    pid: '9718',
    state: '실행중',
    updatedTime: '2022-02-16 08:46:20',
    cpuUsage: '0',
    memoryUsage: '0',
    master: '1',
  },
  {
    division: 'DS',
    serviceName: 'DS@ap1',
    pid: '9718',
    state: '실행중',
    updatedTime: '2022-02-16 08:46:20',
    cpuUsage: '0',
    memoryUsage: '0',
    master: '1',
  },
  {
    division: 'DS',
    serviceName: 'DS@ap1',
    pid: '9718',
    state: '실행중',
    updatedTime: '2022-02-16 08:46:20',
    cpuUsage: '0',
    memoryUsage: '0',
    master: '1',
  },
  {
    division: 'DS',
    serviceName: 'DS@ap1',
    pid: '9718',
    state: '실행중',
    updatedTime: '2022-02-16 08:46:20',
    cpuUsage: '0',
    memoryUsage: '0',
    master: '1',
  },
];
