import DatePicker, {
  ReactDatePickerCustomHeaderProps,
  registerLocale,
} from 'react-datepicker';
import InputText from './InputText';
import { getYear, getMonth, addYears, format } from 'date-fns';
import { ko } from 'date-fns/locale';
import _ from 'lodash';
import '../../../styles/datePicker.css';
import SelectListbox from '../selectList/SelectListbox';

interface Props {
  rangeYearStart?: number;
  rangeYearEnd?: number;
  placeholder: string;
  isShowTimeInput?: boolean;
  pickedDate: Date | null;
  setPickedDate: (date: Date | null) => void;
}

interface CustomTimeInputProps {
  value?: string;
  onChange?: any;
}

const locale = ko;
registerLocale('ko', locale);

const months = _.range(0, 12).map((month, i) => ({
  id: month.toString(),
  name: locale.localize?.month(i, { width: 'abbreviated' }) as string,
}));

const now = new Date();
const nowYear = getYear(now);
const addedYear = getYear(addYears(now, 10));

const CustomTimeInput = ({ value, onChange }: CustomTimeInputProps) => (
  <input
    type="time"
    className="border border-solid border-C-c8cace h-9 rounded placeholder:text-C-9d9ea2 focus:border-C-1f62b9 text-C-555555 disabled:bg-C-f1f3f6 disabled:text-C-b4b5b8 px-3 text-sm w-32"
    value={value ? value : `${format(now, 'HH')}:${format(now, 'mm')}`}
    onChange={(e) => {
      if (onChange) {
        onChange(e.target.value);
      }
    }}
  />
);

const InputDatePicker = ({
  rangeYearStart = nowYear,
  rangeYearEnd = addedYear,
  placeholder,
  isShowTimeInput = false,
  pickedDate,
  setPickedDate,
}: Props) => {
  const years = _.range(rangeYearStart, rangeYearEnd + 1).map((year) => ({
    id: year.toString(),
    name: year.toString(),
  }));

  return (
    <DatePicker
      selected={pickedDate}
      dateFormat={isShowTimeInput ? 'yyyy-MM-dd aa hh:mm' : 'yyyy-MM-dd'}
      onChange={setPickedDate}
      placeholderText={placeholder}
      customInput={
        <InputText handleClear={() => setPickedDate(null)} isReadOnly />
      }
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
      }: ReactDatePickerCustomHeaderProps) => {
        const yearValue = _.find(years, { name: getYear(date).toString() });
        const monthValue = _.find(months, { id: getMonth(date).toString() });

        return (
          <div className="flex flex-row justify-center h-9">
            <SelectListbox
              options={years}
              optionsHeight={12}
              value={yearValue ? yearValue : years[0]}
              withoutBorder
              onChange={(v) => changeYear(+v.id)}
            />

            <SelectListbox
              options={months}
              optionsHeight={12}
              value={monthValue ? monthValue : months[0]}
              withoutBorder
              onChange={(v) => changeMonth(+v.id)}
            />
          </div>
        );
      }}
      locale="ko"
      timeInputLabel="시간"
      showTimeInput={isShowTimeInput}
      customTimeInput={<CustomTimeInput />}
      shouldCloseOnSelect={!isShowTimeInput}
    />
  );
};

export default InputDatePicker;
