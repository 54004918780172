import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import usTranslations from './lang.en.json';
import krTranslations from './lang.ko.json';
import jpTranslations from './lang.ja.json';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  en: {
    translation: usTranslations,
  },
  ko: {
    translation: krTranslations,
  },
  ja: {
    translation: jpTranslations,
  }
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)  // 언어 감지기 사용
  .init({
    resources,
    fallbackLng: 'en', // 기본 언어 설정
    debug: true,
    //initImmediate: false, // 동기화 방식으로 초기화
    interpolation: { escapeValue: true },
    returnObjects: false,
    returnEmptyString: true,
    returnNull: true,
    detection: {
      //order: ['navigator'],  // 브라우저 언어 설정을 감지하기 위한 순서
      caches: ['localStorage', 'cookie'], // 사용자 언어 설정을 캐시
    },
  });

export default i18n;