import { getMyOrgCode, getMyTenantId } from './h-userInfo';
// REDUX
import { store as rootStore } from '../redux/store';
import { IAdminButtonCntListApiResult } from '../interfaces/api/button/I-api-admin-btn-cnt';
import { v4 as uuidv4 } from 'uuid';
import {
  addCntBtn,
  addCntBtnGroup,
  addCntBtnToBtnGroup,
  initSelectedCntBtnGroupModal,
  modifyCntBtn,
  modifyCntBtnGroup,
  removeCntBtn,
  removeCntBtnFromBtnGroup,
  removeCntBtnGroup,
  setCntBtnGroupList,
  setCntBtnList,
  setSelectedCntBtnGroup,
} from '../redux/slice/countButtonManagementSlice';
import {
  IAdminButtonGroup,
  IAdminButtonGroupAddApiBody,
} from '../interfaces/api/button/I-api-admin-btn-group';
import { adminButtonGroupListApi } from '../api/admin/button/api-admin-btn-group';
import {
  closeCntBtnGroupAddModal,
  closeCntBtnGroupModifyModal,
} from './h-modals';
import { addRuleFuncHandler } from './h-ruleFuncMng';
import { toast } from 'react-toastify';
import axios from 'axios';
import { adminButtonCntListApi } from '../api/admin/button/api-admin-btn-cnt';

const state = rootStore.getState();
const dispatch = rootStore.dispatch;

// 카운트 버튼
// LIST
export const fetchAndSetCntBtnList = async () => {
  const tenantid = getMyTenantId();
  const orgcode = getMyOrgCode();
  if (!tenantid || !orgcode) return;

  try {
    const res = await adminButtonCntListApi({
      tenantid,
      orgcode,
      pagesize: 100,
      page: 1,
    });
    if (!res) return;
    const cntBtnList = res.data.data.result;
    dispatch(setCntBtnList(cntBtnList));
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log('error: ', error);
      return error.message;
    } else {
      console.log('unexpected error: ', error);
    }
  }
};
// ADD
export const addCtnHandler = (selectedCntBtn: IAdminButtonCntListApiResult) => {
  // const { tenantid, orgcode, ...others } = selectedCntBtn;
  // const cntBtn = { ...others, btncode: uuidv4().split('-').join('') };
  // console.log('cntBtn', cntBtn);
  // 임시 값
  const BODY = {
    btncode: uuidv4().split('-').join(''),
    btnname: selectedCntBtn.btnname ? selectedCntBtn.btnname : 'NEW',
    btnmethod: selectedCntBtn.btnmethod ? selectedCntBtn.btnmethod : ' ',
    btnurlexe: selectedCntBtn.btnurlexe ? selectedCntBtn.btnurlexe : ' ',
    btnurlexeopt: selectedCntBtn.btnurlexeopt
      ? selectedCntBtn.btnurlexeopt
      : ' ',
    btnviewurl: selectedCntBtn.btnviewurl ? selectedCntBtn.btnviewurl : ' ',
    btnviewopt: selectedCntBtn.btnviewopt ? selectedCntBtn.btnviewopt : ' ',
    btncomment: selectedCntBtn.btncomment ? selectedCntBtn.btncomment : ' ',
    btnimgsrc: selectedCntBtn.btnimgsrc ? selectedCntBtn.btnimgsrc : ' ',
  };
  dispatch(addCntBtn(BODY));
};
// MODIFY
export const modifyCtnHandler = (
  selectedCntBtn: IAdminButtonCntListApiResult,
) => {
  const { tenantid, orgcode, ...others } = selectedCntBtn;
  const cntBtn = { ...others };

  dispatch(modifyCntBtn(cntBtn));
};
// REMOVE
export const removeCntBtnHandler = (
  selectedCntBtn: IAdminButtonCntListApiResult,
) => {
  const { btncode } = selectedCntBtn;
  const btnlist = [
    {
      btncode,
    },
  ];
  // console.log({ btnlist });
  dispatch(removeCntBtn(btnlist));
};

// 카운트 버튼 그룹
// LIST
export const fetchAndSetCntBtnGroupList = async () => {
  try {
    const res = await adminButtonGroupListApi({
      groupkind: 'C',
    });
    if (!res) return;

    const cntBtnGroupList = res.data.data.result;
    if (cntBtnGroupList.length > 0) {
      dispatch(setCntBtnGroupList(cntBtnGroupList));
      dispatch(setSelectedCntBtnGroup(cntBtnGroupList[0]));
    } else {
      dispatch(setCntBtnGroupList([]));
      dispatch(
        setSelectedCntBtnGroup({
          tenantid: '',
          orgcode: '',
          groupcode: '',
          groupkind: 'C',
          groupname: '',
          groupver: 0,
          grouporder: 0,
        }),
      );
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log('error: ', error);
    } else {
      console.log('unexpected error: ', error);
    }
  }
};
// ADD
export const addCntBtnGroupHandler = (
  groupname: string,
  grouporder: number,
) => {
  if (groupname === '') {
    toast.error('한글자 이상 입력해주세요.');
    return;
  }

  const groupcode = uuidv4().split('-').join('');
  const cntBtnGroup: IAdminButtonGroupAddApiBody = {
    groupcode,
    groupkind: 'C',
    groupname,
    groupver: 0,
    grouporder,
  };
  dispatch(addCntBtnGroup(cntBtnGroup));
  // rule/func 추가에 추가
  const ruleFunc = {
    funccode: groupcode,
    funckind: 'BUTTON-COUNT(G)',
    funcname: groupname,
    allowdup: 0,
    funcfield1: '버튼그룹 종류',
    funcfield2: 'COUNT BUTTON',
    defaultvalue: '0',
  };
  addRuleFuncHandler(ruleFunc);
  closeCntBtnGroupAddModal();
};
// REMOVE
export const removeCntBtnGroupHandler = (
  selectedCntBtnGroupModal: IAdminButtonGroup | null,
) => {
  if (selectedCntBtnGroupModal === null) {
    toast.error('버튼 그룹을 선택해주세요.');
    return;
  }

  const groupcode = selectedCntBtnGroupModal.groupcode;
  dispatch(removeCntBtnGroup({ groupcode }));
  dispatch(initSelectedCntBtnGroupModal());
};
// MODIFY
export const modifyCntBtnGroupHandler = (
  selectedCntBtnGroup: IAdminButtonGroup | null,
) => {
  if (selectedCntBtnGroup === null) return alert('null selectedCntBtnGroup');
  const { tenantid, orgcode, ...cntBtnGroup } = selectedCntBtnGroup;

  dispatch(modifyCntBtnGroup(cntBtnGroup));
  closeCntBtnGroupModifyModal();
};

// 카운트 버튼 그룹 허브
// ADD
export const addCntBtnToBtnGroupHandler = (
  selectedCntBtn: IAdminButtonCntListApiResult,
  selectedCntBtnGroup: IAdminButtonGroup,
) => {
  if (selectedCntBtn.btncode.length !== 32) {
    toast.error('count 버튼을 선택해주세요.');
    return;
  }
  if (selectedCntBtnGroup.groupcode.length !== 32) {
    toast.error('count 버튼 그룹을 선택해주세요.');
    return;
  }
  dispatch(addCntBtnToBtnGroup({ selectedCntBtn, selectedCntBtnGroup }));
};
// REMOVE
export const removeCntBtnFromBtnGroupHandler = (
  selectedCntBtnGroupsBtn: IAdminButtonCntListApiResult | null,
  selectedCntBtnGroup: IAdminButtonGroup,
) => {
  if (
    selectedCntBtnGroupsBtn === null ||
    selectedCntBtnGroupsBtn.btncode.length !== 32
  ) {
    toast.error('버튼그룹에서 버튼을 선택해주세요.');
    return;
  }
  if (selectedCntBtnGroup.groupcode.length !== 32) {
    toast.error('버튼그룹을 선택해주세요.');
    return;
  }
  dispatch(
    removeCntBtnFromBtnGroup({ selectedCntBtnGroupsBtn, selectedCntBtnGroup }),
  );
};

// MODALS
// API
