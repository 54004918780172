import { ColDef } from 'ag-grid-community';
import { CustomCell_fed, dateValueGetter } from '../../../helpers/h-agGrid';
import {
  IfedListItem,
  IfedTenantListItem,
} from '../../../interfaces/api/service-provider/I-api-admin-serviceProvider-fed';
import { AgCellTextCenter } from '../../common/AgCustomCell';
import { AgHeaderTextCenter } from '../../common/AgCustomHeader';

//export const FEDERATION_CONNECTED_TABLE_COLUMN: ColDef<IfedListItem>[] = [
export const FEDERATION_CONNECTED_TABLE_COLUMN  = (t: (key: string) => string) => {
  return [
    {
      field: 'checkbox',
      headerName: ' ',
      checkboxSelection: true,
      maxWidth: 50,
      resizable: false,
      // headerCheckboxSelection: true,
    },
    {
      field: 'status',
      headerName: t('FederationManagement.h-upperStatus'),
      headerComponent: AgHeaderTextCenter,
      cellRenderer: CustomCell_fed,
      // lockPosition: true,
      // headerCheckboxSelection: true,
      // checkboxSelection: true,
      // resizable: false,
      // minWidth: 182,
      // maxWidth: 182,
      // flex: 1,
    },
    {
      headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<IfedListItem>,

      field: 'destname',
      headerName: t('FederationManagement.h-upperName'),
    },
    {
      headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<IfedListItem>,
      headerName: t('FederationManagement.h-upperProcessingDate'),
      valueGetter: dateValueGetter,
    },
  ];
};

//export const FEDERATION_WILLCONNECT_TABLE_COLUMN: ColDef<IfedListItem>[] = [
export const FEDERATION_WILLCONNECT_TABLE_COLUMN  = (t: (key: string) => string) => {
    return [
      {
      field: 'checkbox',
      headerName: ' ',
      checkboxSelection: true,
      maxWidth: 50,
      resizable: false,
      // headerCheckboxSelection: true,
    },
    {
      headerComponent: AgHeaderTextCenter,
      field: 'status',
      headerName: t('FederationManagement.h-downStatus'),
      cellRenderer: CustomCell_fed,
      // lockPosition: true,
      // headerCheckboxSelection: true,
      // checkboxSelection: true,
      // resizable: false,
      // minWidth: 182,
      // maxWidth: 182,
      // flex: 1,
    },
    {
      headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<IfedListItem>,
      field: 'destname',
      headerName: t('FederationManagement.h-downName'),
    },
    {
      headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<IfedListItem>,
      headerName: t('FederationManagement.h-downRegistDate'),
      valueGetter: dateValueGetter,
    },
  ];
};

export const FEDERATION_connectApply_TABLE_COLUMN  = (t: (key: string) => string) => {
//export const FEDERATION_connectApply_TABLE_COLUMN: ColDef<IfedTenantListItem>[] =
  return [
    {
      headerComponent: AgHeaderTextCenter,
      field: 'name',
      headerName: t('FederationManagement.h-upperApplicableTeams'),
      cellRenderer: AgCellTextCenter<IfedTenantListItem>,
    },
  ];
};
