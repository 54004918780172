import { IsingularAxiosResponse } from './../../../interfaces/api/I-axios';
import { IpluralAxiosResponse } from '../../../interfaces/api/I-axios';
import {
  IspTenantUpgradeApiBody,
  IspTenantUpgradeApiResponse,
  IspUksGetsApiBody,
  IspUksGetsApiResponse,
  IspUksGetsApiResponse_deployment,
} from '../../../interfaces/api/service-provider/I-api-admin-sp-uks';
import { spAxiosInstance } from '../../axiosInstance';
import axios from 'axios';
import { SP_SERVER } from '../../../const/server';

const adminSpUksInfo = {
  url: {
    gets: `${SP_SERVER}/api/v1/admin-mg-sp/uks/gets`,
    upgrade: `${SP_SERVER}/api/v1/admin-mg-sp/tenant/upgrade`,
  },
};

// gets
export const spUksGetsApi = (props: IspUksGetsApiBody) => {
  const { url } = adminSpUksInfo;
  return spAxiosInstance.post<IpluralAxiosResponse<IspUksGetsApiResponse>>(
    url.gets,
    props,
  );
};
export const spUksGetsApi_deployment = (props: IspUksGetsApiBody) => {
  const { url } = adminSpUksInfo;
  return spAxiosInstance.post<
    IpluralAxiosResponse<IspUksGetsApiResponse_deployment>
  >(url.gets, props);
};

// upgrade
export const spTenantUpgrade = (props: IspTenantUpgradeApiBody) => {
  const { url } = adminSpUksInfo;
  return spAxiosInstance.post<
    IsingularAxiosResponse<IspTenantUpgradeApiResponse>
  >(url.upgrade, props);
};
