import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import InputWithSearch from '../../common/InputWithSearch';
import iconOrg from '../../../assets/image/etc/icon_org.png';
import iconDownload from '../../../assets/image/etc/icon_download.png';
import { Tooltip } from 'react-tooltip';
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';
import { t } from 'i18next';
import i18n from '../../../translations/i18n';
import { useAppSelector } from '../../../redux/hooks';
import { openBulkInsertDept_Table } from '../../../helpers/h-react-modals';
import {
  getMyOrgCode,
  getMyRootDeptCode,
  getMyTenantId,
} from '../../../helpers/h-userInfo';
import { hrsOrgsListApi } from '../../../api/hrs/api-hrs-orgs';
// import { TreeData } from '../../treeView/TreeViewV4';

interface Props {
  width: number | undefined;
  searchInputValue: string;
  setSearchInputValue: Dispatch<SetStateAction<string>>;
  fetchTree: () => Promise<void>;
}

const LeftTopBar = ({
  width,
  searchInputValue,
  setSearchInputValue,
  fetchTree,
}: Props) => {
  const handleSearchUserInputValue = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue(e.target.value);
  };
  const { usersid } = useAppSelector((state: { user: any }) => state.user);

  const exportOrgXlsx = async () => {
    const currentLanguage = i18n.language;
    let filePath = '/assets/xlsx/export/org_export_en.xlsx';
    let sheetName = 'Organization';

    if (currentLanguage == 'ko') {
      filePath = '/assets/xlsx/export/org_export_ko.xlsx';
      sheetName = '조직';
    } else if (currentLanguage == 'ja') {
      filePath = '/assets/xlsx/export/org_export_ja.xlsx';
      sheetName = '組織';
    }

    const response = await fetch(filePath);
    if (!response.ok) throw new Error('Failed to fetch the template');
    const arrayBuffer = await response.arrayBuffer();
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(arrayBuffer);
    const worksheet = workbook.getWorksheet(sheetName);
    const tenantid = getMyTenantId();
    const orgcode = getMyOrgCode();
    const rootDept = getMyRootDeptCode();

    const res = await hrsOrgsListApi({
      cmd: '1103',
      sid: usersid ? usersid : '',
      what: [{ gid: `${tenantid}.${orgcode}.${rootDept}` }],
      fields: 'SHORT',
      include: 'DEPT',
      kind: 'DEPT',
      extra: 'ALL',
    });

    if (!res) return;
    if (!res.data.data.result.dept || res.data.data.result.dept.data.length < 1)
      return;

    const deptData = res.data.data.result.dept.data[0];
    const deptCode: string = res?.data.data.result.dept.data[0].deptcode;
    const deptChildRes = await hrsOrgsListApi({
      cmd: '1103',
      sid: usersid ? usersid : '',
      what: [{ gid: `${tenantid}.${orgcode}.${deptCode}` }],
      kind: 'CHILDREN',
      fields: 'SHORT',
      include: 'DEPT',
      extra: 'ALL',
    });

    if (!deptChildRes) return;

    const deptChild = deptChildRes.data.data.result.dept;
    const data: any[] = [];

    if (deptData) {
      const { deptcode, deptname, parentdeptcode, deptorder } = deptData;
      data.push({ deptcode, deptname, parentdeptcode, deptorder });
    }

    if (deptChild && Array.isArray(deptChild.data)) {
      deptChild.data.forEach((child) => {
        const { deptcode, deptname, parentdeptcode, deptorder } = child;
        data.push({ deptcode, deptname, parentdeptcode, deptorder });
      });
    }

    if (worksheet != null) {
      data.forEach((item, index) => {
        const rowIndex = 2 + index;
        const row = worksheet.getRow(rowIndex);
        row.getCell(1).value = item.deptcode;
        row.getCell(2).value = item.deptname;
        row.getCell(3).value = item.parentdeptcode;
        row.getCell(4).value = item.deptorder;
        row.commit();
      });
    }

    const buf = await workbook.xlsx.writeBuffer();
    saveAs(
      new Blob([buf], { type: 'application/octet-stream' }),
      'org_info.xlsx',
    );
  };

  return (
    <div className={`flex py-defaultY items-center `}>
      <InputWithSearch
        value={searchInputValue}
        placeholder={t('OrganizationUserManagement.searchDepartUser')}
        handleChangeInput={(e) => handleSearchUserInputValue(e)}
        handleClear={() => setSearchInputValue('')}
        className={`flex-grow`}
        onClickSearch={() => {
          fetchTree();
        }}
      />

      {/* 조직 일괄 등록 */}
      {width && width > 350 ? (
        <button
          className="min-w-[3.25rem] h-9 rounded text-sm px-3 truncate bg-white hover:border-gray-400 active:bg-C-b8d3fe text-C-555555 border border-C-c8cace flex items-center justify-center space-x-1 ml-1"
          onClick={openBulkInsertDept_Table}
        >
          <img src={iconOrg} alt="Down" className="w-5 h-5 mr-0.5" />
          <span>{t('OrganizationUserManagement.deptImportXls') as string}</span>
        </button>
      ) : (
        <button
          className="min-w-[3.25rem] sm:min-w-[3.25rem] h-9 rounded text-sm px-3 truncate bg-white hover:border-gray-400 active:bg-C-b8d3fe text-C-555555 border border-C-c8cace flex items-center justify-center space-x-1 ml-1"
          onClick={openBulkInsertDept_Table}
        >
          <img src={iconOrg} alt="Down" className="w-5 h-5 mr-0.5" />
          <span className="hidden sm:block">
            {t('OrganizationUserManagement.deptImportXls') as string}
          </span>
        </button>
      )}

      {/* 조직 export 기능 */}
      <button
        data-tooltip-id="my-tooltips"
        className="min-w-[3rem] h-9 rounded text-sm px-3 truncate bg-white hover:border-gray-400 active:bg-C-b8d3fe text-C-555555 border border-C-c8cace flex items-center justify-center space-x-1 ml-1"
        onClick={exportOrgXlsx}
      >
        <img
          src={iconDownload}
          alt="Down"
          className="w-5 h-5 min-w-5 min-w-5"
        />
      </button>

      <Tooltip
        id="my-tooltips"
        place="bottom-end"
        style={{ backgroundColor: '#0055ab', zIndex: '1', color: '#19231b' }}
      >
        <div className={`block mt-4 mb-4`}>
          <div className={`block text-sm mb-1 text-white`}>
            {t('OrganizationUserManagement.exportDeptDesc1') as string}
          </div>
          <div className={`block text-sm mb-1 text-white`}>
            {t('OrganizationUserManagement.exportDeptDesc2') as string}
          </div>
        </div>
      </Tooltip>
    </div>
  );
};

export default LeftTopBar;
