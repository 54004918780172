import { ButtonProps } from '../../../interfaces/ButtonProps';

const DefaultAvatar = ({ width = 44, height = 44, ...props }: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44">
    <defs>
      <clipPath id="clipPath">
        <circle id="타원_368" data-name="타원 368" cx="22" cy="22" r="22" style={{ fill: 'none', stroke: '#707070' }} />
      </clipPath>
    </defs>
    <g id="profile_photo">
      <circle id="타원_369" data-name="타원 369" cx="22" cy="22" r="22" style={{ fill: '#e1e1e1' }} />
      <g id="마스크_그룹_1" data-name="마스크 그룹 1">
        <g id="그룹_1625" data-name="그룹 1625" transform="translate(9.377 7.213)">
          <circle
            id="타원_367"
            data-name="타원 367"
            style={{ fill: '#fff' }}
            cx="7.574"
            cy="7.574"
            r="7.574"
            transform="translate(5.049)"
          />
          <rect
            id="사각형_1161"
            data-name="사각형 1161"
            style={{ fill: '#fff' }}
            width="25.246"
            height="55.541"
            rx="12.623"
            transform="translate(0 18.033)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default DefaultAvatar;
