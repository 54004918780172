export const announcementDummy = [
  {
    devision: '일반',
    title: 'feeling random words for future data...',
    department: '전체',
    creator: '홍길동; Hong Gil...',
    ID: 'demo01',
    duration: '2022-01-01-2022-01-01',
    createTime: '2022-01-01 10:00:13',
  },
  {
    devision: '일반',
    title: 'feeling random words for future data...',
    department: '전체',
    creator: '홍길동; Hong Gil...',
    ID: 'demo01',
    duration: '2022-01-01-2022-01-01',
    createTime: '2022-01-01 10:00:13',
  },
  {
    devision: '일반',
    title: 'feeling random words for future data...',
    department: '전체',
    creator: '홍길동; Hong Gil...',
    ID: 'demo01',
    duration: '2022-01-01-2022-01-01',
    createTime: '2022-01-01 10:00:13',
  },
  {
    devision: '일반',
    title: 'feeling random words for future data...',
    department: '전체',
    creator: '홍길동; Hong Gil...',
    ID: 'demo01',
    duration: '2022-01-01-2022-01-01',
    createTime: '2022-01-01 10:00:13',
  },
  {
    devision: '일반',
    title: 'feeling random words for future data...',
    department: '전체',
    creator: '홍길동; Hong Gil...',
    ID: 'demo01',
    duration: '2022-01-01-2022-01-01',
    createTime: '2022-01-01 10:00:13',
  },
  {
    devision: '일반',
    title: 'feeling random words for future data...',
    department: '전체',
    creator: '홍길동; Hong Gil...',
    ID: 'demo01',
    duration: '2022-01-01-2022-01-01',
    createTime: '2022-01-01 10:00:13',
  },
  {
    devision: '일반',
    title: 'feeling random words for future data...',
    department: '전체',
    creator: '홍길동; Hong Gil...',
    ID: 'demo01',
    duration: '2022-01-01-2022-01-01',
    createTime: '2022-01-01 10:00:13',
  },
  {
    devision: '일반',
    title: 'feeling random words for future data...',
    department: '전체',
    creator: '홍길동; Hong Gil...',
    ID: 'demo01',
    duration: '2022-01-01-2022-01-01',
    createTime: '2022-01-01 10:00:13',
  },
  {
    devision: '일반',
    title: 'feeling random words for future data...',
    department: '전체',
    creator: '홍길동; Hong Gil...',
    ID: 'demo01',
    duration: '2022-01-01-2022-01-01',
    createTime: '2022-01-01 10:00:13',
  },
  {
    devision: '일반',
    title: 'feeling random words for future data...',
    department: '전체',
    creator: '홍길동; Hong Gil...',
    ID: 'demo01',
    duration: '2022-01-01-2022-01-01',
    createTime: '2022-01-01 10:00:13',
  },
];
