import {
  IAdminButtonGroup,
  IAdminButtonGroupAddApiBody,
  IAdminButtonGroupInfoApiBody,
  IAdminButtonGroupListApiBody,
  IAdminButtonGroupModifyApiBody,
  IAdminButtonGroupRemoveApiBody,
} from './../../../interfaces/api/button/I-api-admin-btn-group';
import { axiosInstance } from '../../axiosInstance';
import { getMyTenantId, getOrgCode } from '../../../helpers/h-userInfo';
import { IpluralAxiosResponse } from '../../../interfaces/api/I-axios';

const adminButtonGroupInfo = {
  url: {
    list: '/api/v1/admin-mg-tp/button/group/list',
    add: 'api/v1/admin-mg-tp/button/group/add',
    info: 'api/v1/admin-mg-tp/button/group/info',
    modify: 'api/v1/admin-mg-tp/button/group/modify',
    remove: 'api/v1/admin-mg-tp/button/group/remove',
  },
};

// list
export const adminButtonGroupListApi = (
  props?: IAdminButtonGroupListApiBody,
) => {
  const { url } = adminButtonGroupInfo;

  return axiosInstance.post<IpluralAxiosResponse<IAdminButtonGroup>>(url.list, {
    tenantid: getMyTenantId(),
    orgcode: getOrgCode(),
    pagesize: 50,
    page: 1,
    ...props,
  });
};

// add
export const adminButtonGroupAddApi = (props: IAdminButtonGroupAddApiBody) => {
  const { url } = adminButtonGroupInfo;

  return axiosInstance.post(url.add, {
    tenantid: getMyTenantId(),
    orgcode: getOrgCode(),
    ...props,
  });
};

// info
export const adminButtonGroupInfoApi = (
  props: IAdminButtonGroupInfoApiBody,
) => {
  const { url } = adminButtonGroupInfo;
  return axiosInstance.post(url.info, {
    ...props,
  });
};

// modify
export const adminButtonGroupModifyApi = (
  props: IAdminButtonGroupModifyApiBody,
) => {
  const { url } = adminButtonGroupInfo;
  return axiosInstance.post(url.modify, {
    ...props,
  });
};

// remove
export const adminButtonGroupRemoveApi = (
  props: IAdminButtonGroupRemoveApiBody,
) => {
  const { url } = adminButtonGroupInfo;

  return axiosInstance.post(url.remove, {
    ...props,
  });
};
