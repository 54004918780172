import { useEffect, useState } from 'react';
// 라이브러리
import { SplitPane } from 'react-multi-split-pane';
import { useResizeDetector } from 'react-resize-detector';

// 컴포넌트
import GroupList from './GroupList';
import Form from './Form';
import DownTopBar from './DownTopBar';

import {
  handleViewPortSize,
  PaneProps,
  splitPaneViewportCase,
} from '../../../helpers/h-splitPane';
import MainPageTitleHeader from '../../common/MainPageTitleHeader';

const OrganizationManagement = () => {
  const [splitPaneViewportKey, setSplitPaneViewportKey] = useState(
    splitPaneViewportCase.default,
  );

  return (
    <div className="h-full w-full">
      <SplitPane className="custom" split="horizontal" minSize={[50, 50]}>
        <Upper
          setSplitPaneViewportKey={setSplitPaneViewportKey}
          splitPaneUpdatedState={splitPaneViewportKey}
        />
        <Down
          setSplitPaneViewportKey={setSplitPaneViewportKey}
          splitPaneUpdatedState={splitPaneViewportKey}
        />
      </SplitPane>
    </div>
  );
};

const Upper = ({
  setSplitPaneViewportKey,
  splitPaneUpdatedState,
}: PaneProps) => {
  const { ref } = useResizeDetector();

  useEffect(() => {
    ref.current.parentElement.style.flex = splitPaneUpdatedState.upper;
  }, [splitPaneUpdatedState]);

  return (
    <div
      ref={ref}
      className="w-full h-full border border-solid border-[#dfe2e8] rounded"
    >
      <div className="w-full h-full px-[1.5rem] flex flex-col bg-white">
        <MainPageTitleHeader
          text="그룹 목록"
          onClick={() =>
            handleViewPortSize([setSplitPaneViewportKey, 'clickedUpper'])
          }
        />
        <GroupList />
      </div>
    </div>
  );
};

const Down = ({
  setSplitPaneViewportKey,
  splitPaneUpdatedState,
}: PaneProps) => {
  const { ref } = useResizeDetector();

  useEffect(() => {
    ref.current.parentElement.style.flex = splitPaneUpdatedState.down;
  }, [splitPaneUpdatedState]);

  return (
    <div
      ref={ref}
      className="w-full h-full px-[1.5rem] border border-solid border-[#dfe2e8] rounded bg-white"
    >
      <MainPageTitleHeader
        text=""
        onClick={() =>
          handleViewPortSize([setSplitPaneViewportKey, 'clickedDown'])
        }
        direction="down"
      />
      <DownTopBar />
      <Form />
    </div>
  );
};

export default OrganizationManagement;
