// style
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../../../styles/ag-grid.css';

import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RULE_USER_LIST_TALBLE_COLUMN } from './columns';
import {
  changeToBidObjArray,
  getRuleUserListHandler,
  getUsersByBids,
} from '../../../helpers/h-ruleDesignation';
import { IadminRuleUser } from '../../../interfaces/api/rule/I-api-admin-rule-user';
import { IhrsUserListApiResponseFull } from '../../../interfaces/api/I-api-hrs-users';
import LeftTopBar from '../../mainPage/rule-designation/LeftTopBar';
import {
  initAddedUsersBid,
  setAddedUsersBids,
} from '../../../redux/slice/ruleFunctionManagementSlice';

const RulDesignationRuleUserList_Table = () => {
  const dispatch = useAppDispatch();
  const { selectedRuleCode, ruleUserStatus } = useAppSelector(
    (state) => state.ruleFunctionManagement,
  );

  const [ruleUserList, setRuleUserList] = useState<IadminRuleUser[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<
    IhrsUserListApiResponseFull[]
  >([]);

  // AG_GRID
  const [gridApi, setGridApi] =
    useState<GridApi<IhrsUserListApiResponseFull> | null>(null);
  const [columnDefs, setColumnDefs] = useState<ColDef[]>(
    RULE_USER_LIST_TALBLE_COLUMN,
  );
  const [rowData, setRowData] = useState<IhrsUserListApiResponseFull[]>([]);
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      resizable: true,
      minWidth: 100,
      editable: true,
    };
  }, []);

  const onSelectionChanged = useCallback(() => {
    if (!gridApi) return;
    const selectedRows = gridApi.getSelectedRows();
    console.log(selectedRows);
    setSelectedUsers(selectedRows);
  }, [gridApi]);

  const onGridReady = (params: GridReadyEvent<IhrsUserListApiResponseFull>) => {
    setGridApi(params.api);
  };

  // hrs/users/list 구하기
  useEffect(() => {
    if (ruleUserList.length < 1) return;

    const bid = changeToBidObjArray(ruleUserList);
    getUsersByBids(bid)
      .then((userList) => {
        if (!userList) return;

        if (userList.length > 0) {
          const bids = userList.map((user) => user.bid);
          setRowData(userList);
          dispatch(setAddedUsersBids(bids));
        }
      })
      .catch((err) => console.log(err));
  }, [ruleUserList]);

  // rule/user/list 구하기
  useEffect(() => {
    if (selectedRuleCode.rulecode.length < 1) return;

    getRuleUserListHandler(selectedRuleCode.rulecode)
      .then((ruleUserList) => {
        if (!ruleUserList) return;
        if (ruleUserList.length > 0) {
          setRuleUserList(ruleUserList);
        } else {
          setRuleUserList([]);
          setRowData([]);
        }
      })
      .catch((err) => console.log(err));
  }, [selectedRuleCode]);

  // 옵저버
  useEffect(() => {
    if (ruleUserStatus !== 'success') return;

    getRuleUserListHandler(selectedRuleCode.rulecode)
      .then((ruleUserList) => {
        if (!ruleUserList) return;

        if (ruleUserList.length > 0) {
          setRuleUserList(ruleUserList);
        } else {
          setRuleUserList([]);
          setRowData([]);
          dispatch(initAddedUsersBid());
        }
      })
      .catch((err) => console.log(err));
    if (gridApi) {
      gridApi.deselectAll();
    }
  }, [ruleUserStatus]);

  useEffect(() => {
    return () => {
      dispatch(initAddedUsersBid());
    };
  }, []);

  return (
    <div className="w-full grow flex flex-col overflow-hidden">
      <LeftTopBar
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
      />
      <div className="ag-theme-alpine ag-theme-mycustomtheme w-full grow">
        <AgGridReact
          onGridReady={onGridReady}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowMultiSelectWithClick
          rowSelection={'multiple'}
          onSelectionChanged={onSelectionChanged}
          overlayNoRowsTemplate={'\xa0'}
          // onCellClicked={cellClickedListener}
        ></AgGridReact>
      </div>
    </div>
  );
};

export default RulDesignationRuleUserList_Table;
