import { useCallback, useEffect, useMemo, useState } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import { CellClickedEvent, ColDef, IDatasource } from 'ag-grid-community';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { CntBtnList_Table_Column } from './columns';
import { setSelectedCntBtn } from '../../../redux/slice/countButtonManagementSlice';
import { IAdminButtonCntListApiResult } from '../../../interfaces/api/button/I-api-admin-btn-cnt';

const CntBtnList_Table_AG = () => {
  const dispatch = useAppDispatch();
  const { cntBtnList } = useAppSelector((state) => state.countButtonManagement);

  const [columnDefs, setColumnDefs] = useState<ColDef[]>(
    CntBtnList_Table_Column,
  );
  const [rowData, setRowData] = useState<IAdminButtonCntListApiResult[] | null>(
    null,
  );
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      resizable: true,
      minWidth: 100,
    };
  }, []);

  const cellClickedListener = useCallback((event: CellClickedEvent<any>) => {
    // console.log('cellClicked', event);
    const buttonInfo = event.data;
    dispatch(setSelectedCntBtn(buttonInfo));
  }, []);

  // 테이블 데이터 설정
  useEffect(() => {
    setRowData(cntBtnList);
  }, [cntBtnList]);

  return (
    <div className="w-full grow flex flex-col overflow-hidden">
      <div className="ag-theme-alpine ag-theme-mycustomtheme w-full grow">
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onCellClicked={cellClickedListener}
          rowSelection={'single'}
          overlayNoRowsTemplate={'\xa0'}
        ></AgGridReact>
      </div>
    </div>
  );
};

export default CntBtnList_Table_AG;
