import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  getSideBarToggleValue,
  toggleSideBar,
} from '../../redux/slice/toggleSlice';
import { initAuthSlice } from '../../redux/slice/authSlice';
import { initUserSlice } from '../../redux/slice/userSlice';
import { NavLink } from 'react-router-dom';
import { IsideBarMenuListData } from '../../interfaces/mainPage/I-sidebar';
import { initConfSlice } from '../../redux/slice/confSlice';

interface ListsProps {
  showSideBar: boolean;
  sideBarMenuListData: IsideBarMenuListData[] | null;
}

const OPEN_SIDEBAR_MIN_W = 'min-w-[200px]';
const OPEN_SIDEBAR_W = 'w-[200px]';
const CLOSE_SIDEBAR_MIN_W = 'min-w-[50px]';
const CLOSE_SIDEBAR_W = 'w-[50px]';

const Lists = ({ showSideBar, sideBarMenuListData }: ListsProps) => {
  const dispatch = useAppDispatch();
  const activeLink =
    'group flex gap-3 pl-5 items-center h-[2.25rem] cursor-pointer bg-[#e9f0f8] bg-opacity-90 rounded text-primary-default';
  const normalLink =
    'group flex gap-3 pl-5 items-center h-[2.25rem] cursor-pointer text-sideBarText hover:bg-[#e9f0f8] hover:rounded';

  const handleLogout = (t: string) => {
    if (t === '/logout') {
      sessionStorage.removeItem('Auth');
      sessionStorage.removeItem('jwtToken');
      sessionStorage.removeItem('refToken');
      sessionStorage.removeItem('workspacename');
      dispatch(initAuthSlice());
      dispatch(initUserSlice());
      dispatch(initConfSlice());
    }
  };

  return (
    <div>
      {sideBarMenuListData &&
        sideBarMenuListData.map((d, i) => (
          <NavLink
            id={d.link.replace('/', '')}
            to={d.link !== '/logout' ? `${d.link}` : '/login'}
            key={i}
            className={({ isActive }) =>
              isActive ||
              (d.link === '/service-mornitoring' &&
                window.location.pathname === '/')
                ? activeLink
                : normalLink
            }
            onClick={() => handleLogout(d.link)}
          >
            <div className={`w-[1.125rem] h-[1.125rem]`}>{d.svgFile()}</div>
            <span className={`text-inherit truncate`}>{d.text}</span>
          </NavLink>
        ))}
    </div>
  );
};

interface SideBarProps {
  sideBarMenuListData: IsideBarMenuListData[] | null;
  openSidebarByClick: boolean;
}
const SideBar = ({ sideBarMenuListData, openSidebarByClick }: SideBarProps) => {
  const showSideBar = useAppSelector(getSideBarToggleValue);
  // const handleToggle = () => {
  //   dispatch(toggleSideBar());
  // };

  return (
    <div
      // onMouseEnter={() => {
      //   if (!showSideBar) {
      //     handleToggle();
      //   }
      // }}
      // onMouseLeave={() => {
      //   if (!openSidebarByClick && showSideBar) {
      //     handleToggle();
      //   }
      // }}
      className={`bg-white px-[0.375rem] flex flex-col overflow-auto duration-500 overflow-x-hidden 
      ${showSideBar ? OPEN_SIDEBAR_MIN_W : CLOSE_SIDEBAR_MIN_W} ${
        showSideBar ? OPEN_SIDEBAR_W : CLOSE_SIDEBAR_W
      }`}
    >
      {/* 상단  */}
      {showSideBar ? (
        <div className="h-[4.5rem] flex items-center">
          <span
            className={`text-2xl text-[#555] font-bold ml-[1.125rem] truncate`}
          >
            {sessionStorage.getItem('workspacename')}
          </span>
        </div>
      ) : (
        <div className="h-[2rem]"></div>
      )}
      {/* 하단 리스트 */}
      <Lists
        showSideBar={showSideBar}
        sideBarMenuListData={sideBarMenuListData}
      />
    </div>
  );
};

export default SideBar;
