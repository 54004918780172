import React, { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { Resizable } from 'react-resizable';
import Button from '../../common/buttons/Button';
import { removeRuleCodeHandler } from '../../../helpers/h-ruleFuncMng';
import {
  closeAllFuncBtnModal,
  closeAllRuleFuncModal,
  closeRuleFuncRuleModal,
  openRuleFuncRuleAddModal,
  openRuleFuncRuleModifyModal,
} from '../../../helpers/h-modals';
import { ResizeCallbackData } from '../../../interfaces/I-modals';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  initRuleFuncMngSlice,
  initRuleFuncMngStatus,
  initSelectedRuleCodeModal,
} from '../../../redux/slice/ruleFunctionManagementSlice';
import RuleFuncMngRuleList_Table from '../../tables/ruleFuncMng/RuleFuncMngRuleList_Table';
import { toast } from 'react-toastify';

interface Props {
  onClickClose?: () => void;
}

const RuleFuncMng_Rule_Modal = (props: Props) => {
  const dispatch = useAppDispatch();
  const { selectedRuleCodeModal } = useAppSelector(
    (state) => state.ruleFunctionManagement,
  );

  const [size, setSize] = useState({
    width: 600,
    height: 600,
  });

  const onResize = (
    e: React.SyntheticEvent<Element, Event>,
    data: ResizeCallbackData,
  ) => {
    setSize({
      width: data.size.width,
      height: data.size.height,
    });
  };

  // FUNC 버튼 그룹 수정 모달 핸들러
  const handleModifyRule = () => {
    if (selectedRuleCodeModal === null) {
      toast.error('규칙 선택해주세요.');
      return;
    }
    openRuleFuncRuleModifyModal();
  };

  // FUNC 버튼 그룹 삭제 핸들러
  const handleRemoveRule = () => {
    if (selectedRuleCodeModal === null) {
      toast.error('규칙을 선택해주세요.');
      return;
    }
    removeRuleCodeHandler(selectedRuleCodeModal.rulecode);
    dispatch(initSelectedRuleCodeModal());
  };

  // 클리어 함수
  useEffect(() => {
    return () => {
      closeAllRuleFuncModal();
      dispatch(initRuleFuncMngStatus());
    };
  }, []);

  return (
    <Draggable handle="#handle">
      <Resizable
        handle={
          <div
            className="resizable-custom-handler absolute"
            style={{
              left: size.width - 19,
              top: size.height - 19,
            }}
          ></div>
        }
        height={size.height}
        width={size.width}
        onResize={onResize}
      >
        <div className="modal-wrapper">
          {/* WRAPPER */}
          <div
            className="box drag-box  flex flex-col px-[1.25rem]
            relative border border-modal-subTitle bg-white z-50 shadow-listbox-options
            rounded
          "
            style={{ width: `${size.width}px`, height: `${size.height}px` }}
          >
            {/* HEADER */}
            <div
              id="handle"
              className="min-h-[3.5rem] cursor-move w-full flex flex-col relative"
            >
              <div className="grow font-bold w-full flex items-center truncate">
                규칙 관리
              </div>
            </div>
            {/* BODY */}
            <div className="grow w-full flex flex-col gap-3 overflow-y-auto">
              {/* 버튼 */}
              <div className="flex gap-1 my-1">
                <Button
                  kind="cancel"
                  label="규칙 추가"
                  onClick={openRuleFuncRuleAddModal}
                />
                <Button
                  kind="cancel"
                  label="규칙 수정"
                  onClick={handleModifyRule}
                />
                <Button
                  kind="cancel"
                  label="규칙 삭제"
                  onClick={handleRemoveRule}
                />
              </div>
              <RuleFuncMngRuleList_Table />
              {/* 테이블 */}
            </div>
            {/* FOOTER */}
            <div className="min-h-[6rem] w-full  jcac">
              <Button
                onClick={closeRuleFuncRuleModal}
                kind="primary"
                label="확인"
              />
            </div>
            {/* 닫기 버튼 */}
            <button
              onClick={closeRuleFuncRuleModal}
              className="absolute right-1 top-1"
            >
              X
            </button>
          </div>
        </div>
      </Resizable>
    </Draggable>
  );
};

export default RuleFuncMng_Rule_Modal;
