import { t } from 'i18next';
import React, { useEffect } from 'react';
import { addDeptHandler } from '../../helpers/h-orgUserMng';
import {
  openUserOrgMngReactModal_add_dept,
  openUserOrgMngReactModal_modify_dept,
  openUserOrgMngReactModal_remove_dept,
} from '../../helpers/h-react-modals';
import { getChildDeptCountByDeptCode } from '../../helpers/h-treeView';
import { useAppSelector } from '../../redux/hooks';

interface Props {
  x: number;
  y: number;
  isRoot?: boolean;
}
const TreeContextMenu = ({ x = 0, y = 0 }: Props) => {
  const { contextDeptInfo } = useAppSelector(
    (state: { organizationUserManagement: { orgInfo: any } }) =>
      state.organizationUserManagement.orgInfo,
  );

  return (
    <div
      className="py-3 px-1 min-w-[150px] my-1 border border-black border-solid bg-white rounded text-sm z-50"
      style={{
        position: 'fixed',
        left: x,
        top: y,
      }}
    >
      <div className="flex flex-col m-0 p-1 text-xs">
        <span className="jcac font-bold">{contextDeptInfo?.deptname}</span>
      </div>
      <ul className="m-0 p-1">
        <li
          className={`bg-white hover:bg-[#f1f3f7] py-2 m-1 flex justify-center items-center cursor-pointer border-black border-solid mt-0`}
          onClick={openUserOrgMngReactModal_add_dept}
        >
          {t('ContextMenu.treeContextMenu.add') as string}
        </li>
        <li
          className={`bg-white hover:bg-[#f1f3f7] py-2 m-1 flex justify-center items-center cursor-pointer border-t border-black border-solid';`}
          onClick={openUserOrgMngReactModal_modify_dept}
        >
          {t('ContextMenu.treeContextMenu.modify') as string}
        </li>
        {contextDeptInfo?.deptlevel !== 1 && (
          <>
            <li
              className={`bg-white hover:bg-[#f1f3f7] py-2 m-1 flex justify-center items-center cursor-pointer border-black border-solid border-t mb-0`}
              onClick={openUserOrgMngReactModal_remove_dept}
            >
              {t('ContextMenu.treeContextMenu.delete') as string}
            </li>
          </>
        )}
      </ul>
    </div>
  );
};

export default TreeContextMenu;
