import { ColDef } from 'ag-grid-community';
import { IroleListApiListResponse } from '../../../interfaces/api/I-api-admin-role';
import { IUserInfo } from '../../../interfaces/api/I-api-hrs-users';
import { AgCellTextCenter } from '../../common/AgCustomCell';
import { AgHeaderTextCenter } from '../../common/AgCustomHeader';

export const RULE_USER_LIST_TALBLE_COLUMN: ColDef<IroleListApiListResponse>[] =
  [
    {
      field: 'checkbox',
      headerName: ' ',
      // headerCheckboxSelection: true,
      checkboxSelection: true,
      maxWidth: 50,
      resizable: false,
    },
    {
      field: 'userid',
      headerName: '사용자 ID',
      headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<IroleListApiListResponse>,
    },
    {
      field: 'username',
      headerName: '사용자명',
      headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<IroleListApiListResponse>,
    },
  ];

export const USER_LIST_TALBLE_COLUMN  = (t: (key: string) => string) => {
  return [
    {
      field: 'checkbox',
      headerName: ' ',
      // headerCheckboxSelection: true,
      checkboxSelection: true,
      maxWidth: 50,
      resizable: false,
      headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<IUserInfo>,
    },
    {
      field: 'userid',
      headerName: t('AdminIdManagement.userId'),
      headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<IUserInfo>,
      //sortable:true,
    },
    {
      field: 'username',
      headerName: t('AdminIdManagement.userName'),
      headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<IUserInfo>,
      //sortable:true,
    },
    {
      field: 'deptname',
      headerName: t('AdminIdManagement.userDepart'),
      headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<IUserInfo>,
      //sortable:true,
    },
    {
      field: 'position',
      headerName: t('AdminIdManagement.userPosition'),
      headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<IUserInfo>,
      //sortable:true,
    },
    {
      field: 'duty',
      headerName: t('AdminIdManagement.userDuty'),
      headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<IUserInfo>,
      //sortable:true,
    },
  ];
}