import * as yup from 'yup';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import login_sp from '../../assets/image/login/login_sp.png';
import { user } from '../sp/sp_conf';
import { toast } from 'react-toastify';
import {
  spAuthLoginApi,
  spAuthMailSendApi,
  spAuthMailVerifyApi,
  spAuthSmsSendApi,
  spAuthSmsVerifyApi,
} from '../../api/auth/api-sp-auth';
import axios from 'axios';
import Button from '../../components/common/buttons/Button';
import { useFormik } from 'formik';
import { loginFormSchema } from '../../helpers/h-formSchema';
import TfaSms from '../../assets/svg/icons/TfaSms';
import TfaMail from '../../assets/svg/icons/TfaMail';
import useInterval from '../../hooks/useInterval';

interface Props {
  spAuth: boolean;
  setSpAuth: React.Dispatch<React.SetStateAction<boolean>>;
}
interface IaccountValue {
  id: string;
  pwd: string;
}
interface ItfaInfo {
  loginAttempt: null | number;
  loginBlocked: null | number;
  tfa: boolean;
  tfaMode: 'sms' | 'email' | null;
  prevId: null | string;
}
const initTfaInfo: ItfaInfo = {
  loginAttempt: null,
  loginBlocked: null,
  tfa: false,
  tfaMode: null,
  prevId: null,
};

const loginErrorMessageClassName =
  'text-red-400 text-xs mt-1 pl-[14px] text-left';
const loginInputClassName =
  'border w-80 h-2.628 rounded placeholder:font-bold placeHolder-placeHolder text-0.813 leading-1.2 p-2 pl-3.5 focus:outline-none focus:border-primary-default focus:ring-1 disabled:bg-[#ebedf1] disabled:text-[#b4b5b8] disabled:cursor-not-allowed';

interface IloginBlockedTime {
  [key: number]: string;
  1: string;
  2: string;
}
const initLoginform = {
  id: '',
  pwd: '',
};

const loginBlockedTime: IloginBlockedTime = {
  1: String(t('SpLoginPage.tryAgainIn5Min')),
  2:  String(t('SpLoginPage.tryAgainIn10Min')),
};

const SpLoginPage = ({ spAuth, setSpAuth }: Props) => {
  const languageNames: { [key: string]: string } = {
    ko: "Korean",
    ja: "Japanese",
    en: "English"
  };
  const [formSchema, setFormSchema] = useState<yup.ObjectSchema<any> | null>(null);

  const [language, setLanguage] = useState('');
  const { t, i18n, ready } = useTranslation();

  const navigate = useNavigate();
  if (sessionStorage.getItem('spAuthKey') === user.key) {
    navigate('/sp/tenant-management');
  }

  const [loginStep, setLoginStep] = useState(1);
  const [loginFailed, setLoginFailed] = useState(false);
  const [prevId, setprevId] = useState<string | null>(null);
  // const [accountValue, setAccountValue] = useState(initAccValue);
  const [tfaInfo, setTfaInfo] = useState<ItfaInfo>(initTfaInfo);
  const [tfaTimeLimit, setTfaTimeLimit] = useState<number | null>(null);
  const [tfaTimer, setTfaTimer] = useState(0);
  const [tfaTimerDelay, setTfaTimerDelay] = useState<number | null>(null);
  const [tfaInputCharLength, setTfaInputCharLength] = useState<number | null>(
    null,
  );
  const [tfaInputValue, setTfaInputValue] = useState<string[]>([]);
  const [tfaDisable, setTfaDisable] = useState(true);
  const loginFormik = useFormik({
    initialValues: initLoginform,
    onSubmit: (v, actions) => {
      console.log(v);
      loginHandler(v);
    },
    validationSchema: formSchema,
  });

  const initLoginPage = () => {
    setLoginStep(1);
    setLoginFailed(false);
    setTfaInfo(initTfaInfo);
    setTfaDisable(true);
    setTfaInputValue([]);
    setTfaInputCharLength(null);
    setprevId(null);
    loginFormik.resetForm();
    setTfaTimeLimit(null);
    setTfaTimerDelay(null);
    setTfaTimer(0);
  };
  const nextStep = () => {
    setLoginStep((prev) => prev + 1);
  };
  const prevStep = () => {
    setLoginStep((prev) => prev - 1);
  };

  const tfaDisableHandler = () => {
    if (tfaInputValue.includes('')) {
      setTfaDisable(true);
    } else {
      setTfaDisable(false);
    }
  };

  const loginHandler = async ({ id, pwd }: IaccountValue) => {
    try {
      const resFromSpLogin = await spAuthLoginApi({
        adminid: id,
        adminpwd: pwd,
      });
      // 로그인 성공
      if (resFromSpLogin.status === 200) {
        sessionStorage.setItem('spAuthKey', user.key);
        navigate('/sp/tenant-management');
        return;
      }
      // 로그인 실패
    } catch (error) {
      setLoginFailed(true);
      setprevId(id);
      if (axios.isAxiosError(error)) {
        console.log(error.response?.data);
        if (error.response) {
          const resErrorData: any = error.response.data;
          const resErrorDataResult: any = resErrorData.data.result;
          const statusCode = error.response.status;

          // wrong password 401
          if (statusCode === 401) {
            toast.error(String(t('SpLoginPage.loginFailed')));
            if (resErrorDataResult.blocked) {
              setTfaInfo({
                ...tfaInfo,
                loginAttempt: resErrorDataResult.attempt,
                loginBlocked: resErrorDataResult.blocked,
              });
              return;
            }
            setTfaInfo({
              ...tfaInfo,
              loginAttempt: resErrorDataResult.attempt,
              loginBlocked: 0,
            });
            return;
          }
          // blocked 403
          if (statusCode === 403) {
            if (resErrorDataResult.blocked === 3) {
              toast.error(String(t('SpLoginPage.secondAuthRequired')));
              setTfaInfo({
                ...tfaInfo,
                tfa: true,
              });
              return;
            }
            if (resErrorDataResult.tfa) {
              toast.error(String(t('SpLoginPage.secondAuthRequired')));
              setTfaInfo({
                ...tfaInfo,
                tfa: true,
              });
              return;
            }
            toast.error(String(t('SpLoginPage.accountLocked')));
            setTfaInfo({
              ...tfaInfo,
              loginAttempt: resErrorDataResult.attempt,
              loginBlocked: resErrorDataResult.blocked,
            });
            return;
          }
          if (statusCode === 500) {
            toast.error('status:500, Invalid Server.');
          }
        }
        return error;
      } else {
        alert('unexpected error');
        console.log('unexpected error: ', error);
      }
    }
  };

  const tfaTimerFormatter = (time: number) => {
    return new Date(1000 * time).toISOString().substr(15, 4);
  };

  const handleLanguageChange = (e : any) => {
    const selectedLanguage = e.target.value;
    setLanguage(selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
  };

  useEffect(() => {
    const currentLanguage = i18n.language;
    setLanguage(currentLanguage);
  }, [i18n]);

  //다국어 처리
  useEffect(() => {
    if (ready) {
      setFormSchema(loginFormSchema(t));
    }
  }, [t, ready]);

  const step1 = () => {
    return (
      <>
        <form className="text-center" onSubmit={loginFormik.handleSubmit}>
          <div className="flex flex-col mt-11">
            {/* 아이디 */}
            <input
              name="id"
              value={loginFormik.values.id}
              onChange={loginFormik.handleChange}
              onBlur={loginFormik.handleBlur}
              type="text"
              className={loginInputClassName}
              placeholder={t('SpLoginPage.id')}
              disabled={tfaInfo.tfa ? true : false}
            />
            <SpLoginErrorMessage
              touched={loginFormik.touched.id}
              message={loginFormik.errors.id}
            />

            {/* 비밀번호 */}
            <input
              name="pwd"
              value={loginFormik.values.pwd}
              onChange={loginFormik.handleChange}
              onBlur={loginFormik.handleBlur}
              type="password"
              className={loginInputClassName + ' ' + 'mt-4'}
              placeholder={t('SpLoginPage.password')}
              disabled={tfaInfo.tfa ? true : false}
            />
            <SpLoginErrorMessage
              touched={loginFormik.touched.pwd}
              message={loginFormik.errors.pwd}
            />
          </div>

          <select
            className="w-80 h-9 py-0.463rem px-3 mt-2.5 border border-#c8cace rounded text-base outline-none"
            onChange={handleLanguageChange} value={language}>
            {Object.keys(languageNames).map(lang => (
              <option key={lang} value={lang}>{languageNames[lang]}</option>
            ))}
          </select>

          {/* 로그인 버튼 */}
          {!tfaInfo.tfa && (
            <div className="w-full mt-6">
              <Button
                // onClick={() => console.log('click')}
                label={t('SpLoginPage.login')}
                kind="primary"
                type="submit"
                style={{
                  width: '100%',
                }}
              />
            </div>
          )}
        </form>

        {/* 에러 메세지 */}
        {!tfaInfo.tfa &&
          loginFailed &&
          tfaInfo.loginAttempt &&
          tfaInfo.loginAttempt > 0 && (
            <div className="text-red-400 text-xs flex flex-col mt-4">
              <div className="text-center">
                {tfaInfo.loginAttempt}{t('SpLoginPage.attemptFailed')}
              </div>
              <div className="text-center">{t('SpLoginPage.accountLockAfter3Attempts')}</div>
              {tfaInfo.loginBlocked ? (
                <div className="text-center">
                  <span className="font-bold">
                    {tfaInfo.loginBlocked}{t('SpLoginPage.lockAfterAttempt')}{` `}
                  </span>
                  {loginBlockedTime[tfaInfo.loginBlocked]}
                </div>
              ) : null}
            </div>
          )}

        {tfaInfo.tfa && (
          <div className="flex flex-col">
            <div className="text-red-400 text-xs flex flex-col mt-4">
              <div className="text-center">{t('SpLoginPage.secondAuthRequired')}</div>
            </div>
            <Button
              name="dd"
              onClick={nextStep}
              label={t('SpLoginPage.doSecondAuth')}
              kind="secondary"
              style={{
                marginTop: '10px',
                width: '320px',
              }}
            />
          </div>
        )}
      </>
    );
  };

  const step2 = () => {
    const buttonWrapperClassName =
      'w-[10rem] h-[8.5rem] flex flex-col items-center justify-center gap-2 border border-primary-default border-solid rounded';
    return (
      <div className="flex flex-col">
        <div className="jcac text-[#555555] font-bold my-8">{t('SpLoginPage.twoStepOTPAuth')}</div>
        <div className="jcac gap-6 text-primary-default">
          {/* SMS */}
          <button
            className={buttonWrapperClassName}
            onClick={async () => {
              try {
                if (!prevId) return;
                const res = await spAuthSmsSendApi({
                  adminid: prevId,
                });
                if (res) {
                  nextStep();
                  setTfaInfo({
                    ...tfaInfo,
                    tfaMode: 'sms',
                  });
                }
              } catch (error) {
                if (axios.isAxiosError(error)) {
                  console.log('error: ', error);
                } else {
                  console.log('unexpected error: ', error);
                }
              }
            }}
          >
            <div className="jcac">
              <TfaSms />
            </div>
            <div className="font-bold jcac">{t('SpLoginPage.authViaSMS')}</div>
          </button>
          {/* MAIL */}
          <button
            className={buttonWrapperClassName}
            onClick={async () => {
              try {
                if (!prevId) return;
                const res = await spAuthMailSendApi({
                  adminid: prevId,
                });
                if (res) {
                  nextStep();
                  setTfaInfo({
                    ...tfaInfo,
                    tfaMode: 'email',
                  });
                }
              } catch (error) {
                if (axios.isAxiosError(error)) {
                  console.log('error: ', error);
                } else {
                  console.log('unexpected error: ', error);
                }
              }
            }}
          >
            <div className="jcac">
              <TfaMail />
            </div>
            <div className="font-bold">{t('SpLoginPage.authViaEmail')}</div>
          </button>
        </div>
      </div>
    );
  };

  const step3 = () => {
    const inputCharLength = tfaInfo.tfaMode === 'email' ? 8 : 6;
    const des =
      tfaInfo.tfaMode === 'email' ? 'noa*****@**re.net' : '+82 01*******11';
    const des2 =
      tfaInfo.tfaMode === 'email'
        ? String(t('SpLoginPage.enterAuthCodeFromMail'))
        : String(t('SpLoginPage.enterAuthCodeFromSMS'));

    const inputHandler = (e: ChangeEvent<HTMLInputElement>, i: number) => {
      if (!tfaInputValue) return;

      const arr = [...tfaInputValue];
      arr.splice(i, 1, e.target.value);
      setTfaInputValue(arr);
    };

    const submitHandler = async () => {
      if (!tfaInfo.tfaMode || !prevId) return;

      const tfaValueString = tfaInputValue.join('');
      try {
        if (tfaInfo.tfaMode === 'email') {
          const res = await spAuthMailVerifyApi({
            adminid: prevId,
            authcode: tfaValueString,
          });
          if (res.status === 200) {
            toast.success(String(t('SpLoginPage.authSuccessLoginAgain')), {
              autoClose: 3000,
            });
            initLoginPage();
          }
        } else if (tfaInfo.tfaMode === 'sms') {
          const res = await spAuthSmsVerifyApi({
            adminid: prevId,
            authcode: tfaValueString,
          });
          if (res.status === 200) {
            toast.success(String(t('SpLoginPage.authSuccessLoginAgain')), {
              autoClose: 3000,
            });
            initLoginPage();
          }
        } else {
          return;
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log('error: ', error);
        } else {
          console.log('unexpected error: ', error);
        }
      }
    };

    const sendAgain = async () => {
      if (!prevId) return;
      try {
        if (tfaInfo.tfaMode === 'sms') {
          const res = await spAuthSmsSendApi({
            adminid: prevId,
          });
          if (res.status === 200) {
            toast.success('인증번호가 다시 전송 되었습니다.', {
              autoClose: 3000,
            });
            setTfaInputValue([...tfaInputValue].fill(''));
            setTfaTimer(60);
          }
        } else if (tfaInfo.tfaMode === 'email') {
          const res = await spAuthMailSendApi({
            adminid: prevId,
          });
          if (res.status === 200) {
            toast.success(String(t('SpLoginPage.authCodeResent')), {
              autoClose: 3000,
            });
            setTfaInputValue([...tfaInputValue].fill(''));
            setTfaTimer(300);
          }
        } else {
          return;
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log('error: ', error);
        } else {
          console.log('unexpected error: ', error);
        }
      }
    };

    return (
      <div className="flex flex-col">
        <div className="jcac text-[#555555] font-bold my-8">{t('SpLoginPage.twoStepOTPAuth')}</div>
        {tfaTimer > 0 && (
          <div className="flex flex-col">
            <div className="text-center text-[#555555] text-xs">{des}</div>
            <div className="text-center text-[#555555] text-xs">{des2}</div>
          </div>
        )}
        <div className="jcac text-[#da3a3b] font-bold text-xs py-3">
          {tfaTimerFormatter(tfaTimer)}
        </div>
        {tfaTimer > 0 && (
          <>
            <div className="flex justify-between gap-3">
              {Array(inputCharLength)
                .fill('')
                .map((v, i) => (
                  <input
                    key={i}
                    maxLength={1}
                    value={tfaInputValue[i]}
                    type={i % 2 === 0 ? 'text' : 'password'}
                    className="w-[2.875rem] h-[3.25rem] border border-[#c8cace] rounded text-center font-bold"
                    onChange={(e) => inputHandler(e, i)}
                  ></input>
                ))}
            </div>
            <div className="mt-4 w-full">
              <Button
                kind={'primary'}
                label={t('SpLoginPage.authenticate')}
                style={{ width: '100%' }}
                disabled={tfaDisable}
                onClick={submitHandler}
              />
            </div>
          </>
        )}
        <div className="text-center">
          <span
            className="text-xs underline text-[#636465] cursor-pointer"
            onClick={sendAgain}
          >
            {t('SpLoginPage.resendAuthCode')}
          </span>
        </div>
      </div>
    );
  };

  const renderer = () => {
    switch (loginStep) {
      case 1:
        return step1();
      case 2:
        return step2();
      case 3:
        return step3();
      default:
        return step1();
        break;
    }
  };

  useInterval(() => setTfaTimer((prev) => prev - 1), tfaTimerDelay);

  useEffect(() => {
    if (tfaTimer < 1) {
      setTfaTimerDelay(null);
      return;
    }
    setTfaTimerDelay(1000);
  }, [tfaTimer]);

  useEffect(() => {
    if (!tfaTimeLimit) {
      setTfaTimer(0);
      return;
    }

    setTfaTimer(tfaTimeLimit);
  }, [tfaTimeLimit]);

  useEffect(() => {
    if (!tfaInfo.tfaMode) return;

    if (tfaInfo.tfaMode === 'email') {
      setTfaInputCharLength(8);
      setTfaTimeLimit(300);
      return;
    } else if (tfaInfo.tfaMode === 'sms') {
      setTfaInputCharLength(6);
      setTfaTimeLimit(60);
      return;
    }
    return;
  }, [tfaInfo.tfaMode]);

  useEffect(() => {
    if (!tfaInputCharLength) return;
    setTfaInputValue(Array(tfaInputCharLength).fill(''));
  }, [tfaInputCharLength]);

  useEffect(() => {
    tfaDisableHandler();
  }, [tfaInputValue]);

  return (
    <div className="w-full flex grow flex-col items-center justify-center">
      <div
        className="cursor-pointer hover:scale-[1.1] duration-150"
        onClick={initLoginPage}
      >
        <img className="w-36 inline-block" src={login_sp} />
      </div>
      {renderer()}
      {/* 테스트용 버튼 */}
      {/* <div className="jcac gap-2">
        <button className="border border-black border-solid" onClick={prevStep}>
          prev
        </button>
        <button className="border border-black border-solid" onClick={nextStep}>
          next
        </button>
      </div> */}
    </div>
  );
};

export default SpLoginPage;

interface IloginErrorMessage {
  touched: boolean | undefined;
  message: string | undefined;
}
const SpLoginErrorMessage = ({ touched, message }: IloginErrorMessage) => {
  return touched && message ? (
    <div className={loginErrorMessageClassName}>{message}</div>
  ) : null;
};
