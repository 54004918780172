import { t } from 'i18next';
import { IDatasource } from 'ag-grid-community';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { adminUserListApi } from '../../../api/admin/api-admin-user';
import { hrsUserSearchApi } from '../../../api/hrs/api-hrs-users';
import Button from '../../common/buttons/Button';
import { handleAddUserToRule } from '../../../helpers/h-ruleDesignation';
import { IadminUserListApiResponse } from '../../../interfaces/api/I-api-admin-users';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import InputWithSearch from '../../common/InputWithSearch';
import axios from 'axios';

interface Props {
  gridApi: any;
  selectedUsers: IadminUserListApiResponse[];
  setSelectedUsers: React.Dispatch<
    React.SetStateAction<IadminUserListApiResponse[]>
  >;
}
const RightTopBar = (props: Props) => {
  const dispatch = useAppDispatch();
  const { selectedRuleCode } = useAppSelector(
    (state) => state.ruleFunctionManagement,
  );
  const { usersid } = useAppSelector((state) => state.user);

  const [userNameInputValue, setUserNameInputValue] = useState('');

  let page = 1;
  const dataSource: IDatasource = {
    rowCount: undefined,
    getRows: (params) => {
      !userNameInputValue
        ? adminUserListApi({ page })
            .then((resp) => ({
              userList: resp?.data.data.result,
              totpage: resp?.data.data.totpage,
              totcount: resp?.data.data.totcount,
            }))
            .then((data) => {
              if (!data) return;
              if (!data.totcount || !data.totpage || !data.userList) return;
              const lastPage =
                page < data.totpage ? 50 * page + 1 : parseInt(data.totcount);
              params.successCallback(data.userList, lastPage);
              page++;
            })
            .catch((error) => {
              if (axios.isAxiosError(error)) {
                console.log('error: ', error);
              } else {
                console.log('unexpected error: ', error);
              }
            })
        : hrsUserSearchApi({
            cmd: '1102',
            sid: usersid ? usersid : '',
            reqpage: 1,
            reqcount: 100,
            kind: 'ALL',
            keyword: userNameInputValue,
            fields: 'FULL',
            language: 'ko',
          })
            .then((resp) => ({
              userList: resp?.data.data.result,
              totpage: resp?.data.data.totpage,
              totcount: resp?.data.data.totcount,
            }))
            .then((data) => {
              if (!data) return;
              if (!data.totcount || !data.totpage || !data.userList) return;
              const lastPage =
                page < data.totpage ? 50 * page + 1 : data.totcount;
              params.successCallback(data.userList, lastPage);
              page++;
            })
            .catch((error) => {
              if (axios.isAxiosError(error)) {
                console.log('error: ', error);
              } else {
                console.log('unexpected error: ', error);
              }
            });
    },
  };

  const onClickAdd = () => {
    if (props.selectedUsers.length < 1) {
      toast.error('사용자를 선택해주세요');
      return;
    }
    handleAddUserToRule(props.selectedUsers, selectedRuleCode.rulecode);
    props.setSelectedUsers([]);
  };

  const onClickSearchUser = () => {
    page = 1;
    props.gridApi.setDatasource(dataSource);
  };

  return (
    <div className="py-[0.563rem] gap-1 flex justify-between text-fontColor-default">
      <div>
        <InputWithSearch
          value={userNameInputValue}
          placeholder="사용자 검색..."
          onClickSearch={onClickSearchUser}
          handleChangeInput={(e) => setUserNameInputValue(e.target.value)}
          handleClear={() => setUserNameInputValue('')}
        />
      </div>
      <div>
        <Button kind="cancel" label="규칙에 추가" onClick={onClickAdd} />
      </div>
    </div>
  );
};

export default RightTopBar;
