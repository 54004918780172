// style
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../../../styles/ag-grid.css';

import { CellClickedEvent, ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IAdminButtonGroup } from '../../../interfaces/api/button/I-api-admin-btn-group';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RULE_CODE_LIST_TALBLE_COLUMN } from './columns';
import { setSelectedFuncBtnGroupModal } from '../../../redux/slice/funcButtonManagementSlice';
import { IadminRuleCode } from '../../../interfaces/api/rule/I-api-admin-rule-code';
import {
  setSelectedRuleCode,
  setSelectedRuleCodeModal,
} from '../../../redux/slice/ruleFunctionManagementSlice';

const RuleFuncMngRuleList_Table = () => {
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const dispatch = useAppDispatch();
  const { ruleCodeList } = useAppSelector(
    (state) => state.ruleFunctionManagement,
  );

  const [columnDefs, setColumnDefs] = useState<ColDef[]>(
    RULE_CODE_LIST_TALBLE_COLUMN,
  );
  const [rowData, setRowData] = useState<IadminRuleCode[]>();
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      resizable: true,
      minWidth: 100,
      editable: true,
    };
  }, []);

  const cellClickedListener = useCallback(
    (event: CellClickedEvent<any>) => {
      console.log('cellClicked', event);
      const rule: IadminRuleCode = event.data;
      dispatch(setSelectedRuleCodeModal(rule));
    },
    [ruleCodeList],
  );

  // 테이블 데이터 설정
  useEffect(() => {
    // if (ruleCodeList.length < 1) return;
    // console.log('ruleCodeList', ruleCodeList);

    setRowData(ruleCodeList);
  }, [ruleCodeList]);

  return (
    <div className="w-full grow flex flex-col overflow-hidden">
      <div style={gridStyle} className="ag-theme-alpine ag-theme-mycustomtheme">
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onCellClicked={cellClickedListener}
          rowSelection={'single'}
          overlayNoRowsTemplate={'\xa0'}
        ></AgGridReact>
      </div>
    </div>
  );
};

export default RuleFuncMngRuleList_Table;
