import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import iconUser from '../../../assets/image/etc/icon_user.png';
import iconDownload from '../../../assets/image/etc/icon_download.png';
import { Tooltip } from 'react-tooltip';
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';
import i18n from '../../../translations/i18n';

//컴포넌트
import { Button } from '../../common/buttons/Button';
import { SelectListValue } from '../../../interfaces/TypeSelectListbox';
// 리덕스
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
// API
import {
  adminUserRemoveApi,
  userListApi,
} from '../../../api/admin/api-admin-user';
import { hrsUserSearchApi } from '../../../api/hrs/api-hrs-users';
// 인터페이스
import { IDatasource } from 'ag-grid-community';
import { toast } from 'react-toastify';
import {
  openModifyUserModal,
  openUserOrgMngModal,
} from '../../../helpers/h-modals';
import SelectListbox from '../../common/selectList/SelectListbox';
import InputWithSearch from '../../common/InputWithSearch';
import axios from 'axios';
import { applySyncDataApi } from '../../../api/sync/api-sync';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { openBulkInsertUser_Table } from '../../../helpers/h-react-modals';
import { hrsOrgsListApi } from '../../../api/hrs/api-hrs-orgs';
import {
  getMyOrgCode,
  getMyRootDeptCode,
  getMyTenantId,
} from '../../../helpers/h-userInfo';
import {
  initUserRemoveStatus,
  removeUser,
} from '../../../redux/slice/organizationUserManagementSlice';
import { removeUserHandler } from '../../../helpers/h-orgUserMng';

interface Props {
  width?: number | undefined;
  gridApi?: any;
}

const RightTopBar: React.FC<Props> = ({ width, gridApi }) => {
  const userButtons: SelectListValue[] = [{ id: '1', name: '전체 사용자' }];
  const optionButtons: SelectListValue[] = [
    { id: 'b1', name: t('OrganizationUserManagement.insertUser') },
    { id: 'b2', name: t('OrganizationUserManagement.updateUser') },
    { id: 'b3', name: t('OrganizationUserManagement.deleteUser') },
    { id: 'b4', name: t('OrganizationUserManagement.userImportXls') },
  ];

  const { selectedUser } = useAppSelector(
    (state: any) => state.organizationUserManagement.userInfo,
  );
  const { selectedUsers } = useAppSelector(
    (state: any) => state.organizationUserManagement.userInfo,
  );

  const { usersid } = useAppSelector((state) => state.user);
  const isJoyrideFinished = useSelector(
    (state: RootState) => state.joyRide.isJoyrideFinished,
  );
  const [userSelected, setUserButtons] = useState(userButtons[0]);
  const [optionSelected, setOptionSelected] = useState(optionButtons[0]);
  const [userNameInputValue, setUserNameInputValue] = useState('');
  const dispatch = useAppDispatch();

  let page = 1;
  const dataSource: IDatasource = {
    rowCount: undefined,
    getRows: (params) => {
      !userNameInputValue
        ? userListApi({ page })
            .then((resp) => ({
              userList: resp?.data.data.result,
              totpage: resp?.data.data.totpage,
              totcount: resp?.data.data.totcount,
            }))
            .then((data) => {
              if (!data) return;
              if (!data.totcount || !data.totpage || !data.userList) return;

              const lastPage =
                page < data.totpage ? 50 * page + 1 : parseInt(data.totcount);
              params.successCallback(data.userList, lastPage);
              page++;
            })
            .catch((error) => {
              if (axios.isAxiosError(error)) {
                console.log('error: ', error);
              } else {
                console.log('unexpected error: ', error);
              }
            })
        : hrsUserSearchApi({
            cmd: '1102',
            sid: usersid ? usersid : '',
            reqpage: 1,
            reqcount: 100,
            kind: 'ALL',
            keyword: userNameInputValue,
            fields: 'FULL',
            language: 'ko',
          })
            .then((resp) => ({
              userList: resp?.data.data.result,
              totpage: resp?.data.data.totpage,
              totcount: resp?.data.data.totcount,
            }))
            .then((data) => {
              if (!data) return;
              if (!data.totcount || !data.totpage || !data.userList) {
                params.successCallback([], 0);
                return;
              }

              const lastPage =
                page < data.totpage ? 50 * page + 1 : data.totcount;
              params.successCallback(data.userList, lastPage);
              page++;
            })
            .catch((error) => {
              if (axios.isAxiosError(error)) {
                console.log('error: ', error);
              } else {
                console.log('unexpected error: ', error);
              }
            });
    },
  };

  const onClickSearchUser = () => {
    if (!gridApi) {
      //console.error('gridApi is not initialized.');
      return;
    }

    page = 1;
    gridApi.setDatasource(dataSource);
  };

  // 초기화 함수
  useEffect(() => {
    return () => {
      dispatch(initUserRemoveStatus());
    };
  }, []);

  const selecOptionListHandler = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    switch (e.currentTarget.id) {
      case 'b1':
        openUserOrgMngModal();
        break;
      case 'b2':
        if (!selectedUser) {
          toast.error(String(t('OrganizationUserManagement.toast1')));
          return;
        } else if (selectedUsers?.length !== 1) {
          toast.error(String(t('OrganizationUserManagement.toast3')));
          return;
        }
        openModifyUserModal();
        break;
      case 'b3':
        if (!selectedUsers || selectedUsers.length === 0) {
          toast.error(String(t('OrganizationUserManagement.toast1')));
          return;
        } else {
          try {
            const userlist = selectedUsers.map((user: any) => ({
              bid: user.bid,
            }));
            const body = { userlist };
            removeUserHandler(body);
            onClickSearchUser();
            toast.success(
              String(t('OrganizationUserManagement.toastRemoveSuccess')),
            );
            break;
            // const res = await removeUserHandler(body);

            // if (res?.status === 200) {
            //   onClickSearchUser();
            //   toast.success(
            //     String(t('OrganizationUserManagement.toastRemoveSuccess')),
            //   );
            //   return;
            // } else {
            //   toast.error(
            //     String(t('OrganizationUserManagement.toastRemoveFail')),
            //   );
            //   return;
            // }
            // return alert('err from try block');
          } catch (error) {
            if (axios.isAxiosError(error)) {
              console.log('error: ', error);
            } else {
              console.log('unexpected error: ', error);
            }
          }
        }
        break;
      case 'b4':
        openBulkInsertUser_Table();
        break;
      case 'b5':
        /*
          1. 조직도에서 부서 선택 -> redux orgInfo.selectedOrg 에 저장
          2. 테이블에서 사용자 선택 -> redux userInfo.selectedUser 에 저장
          3. /api/v1/admin-tp-user/user/modify 에서 deptcode를 1번의 orgInfo.selectedOrg.deptcode로 변경 ??? 중복 부서는??
        */
        // console.log('add to Group');
        break;
      case 'b6':
        // console.log('excel upload');
        break;
      case 'b7':
        // console.log('excel download');
        break;
      case 'b8':
        // console.log('help');
        break;
      default:
        break;
    }
  };

  const exportUserXlsx = async () => {
    const currentLanguage = i18n.language;
    let filePath = '/assets/xlsx/export/user_export_en.xlsx';
    let sheetName = 'User';

    if (currentLanguage == 'ko') {
      filePath = '/assets/xlsx/export/user_export_ko.xlsx';
      sheetName = '사용자';
    } else if (currentLanguage == 'ja') {
      filePath = '/assets/xlsx/export/user_export_ja.xlsx';
      sheetName = 'ユーザ';
    }

    const response = await fetch(filePath);
    if (!response.ok) throw new Error('Failed to fetch the template');
    const arrayBuffer = await response.arrayBuffer();
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(arrayBuffer);
    const worksheet = workbook.getWorksheet(sheetName);

    const pagesize = 1000000;
    const res = await userListApi({ page, pagesize });
    if (!res || res.data.code !== 200) {
      console.error('Failed to fetch user data');
      return;
    }

    if (worksheet != null) {
      res.data.data.result.forEach((item, index) => {
        const rowIndex = 2 + index;
        console.log(item);
        const row = worksheet.getRow(rowIndex);
        row.getCell(1).value = item.deptcode;
        row.getCell(2).value = item.userid;
        row.getCell(3).value = item.userpwd;
        row.getCell(4).value =
          item.multiname.find((mn) => mn.language === 'ko')?.name || '';
        row.getCell(5).value =
          item.multiname.find((mn) => mn.language === 'en')?.name || '';
        row.getCell(6).value = item.position;
        row.commit();
      });
    }

    const buf = await workbook.xlsx.writeBuffer();
    saveAs(
      new Blob([buf], { type: 'application/octet-stream' }),
      'user_info.xlsx',
    );
  };

  useEffect(() => {
    if (isJoyrideFinished) {
      const timer = setTimeout(() => {
        openUserOrgMngModal();
      }, 400);
      return () => clearTimeout(timer);
    }
  }, [isJoyrideFinished]);

  // useEffect(() => {
  //   if (userNameInputValue == '') {
  //     console.log('dddddzd');
  //     onClickSearchUser();
  //   }
  // }, [userNameInputValue, gridApi]);

  useEffect(() => {
    if (userNameInputValue === '') {
      onClickSearchUser();
    }
  }, [userNameInputValue]);

  return (
    <div
      className={`flex gap-1 ${
        width && width > 510 ? '' : 'flex-col'
      } py-defaultY z-20`}
    >
      <InputWithSearch
        value={userNameInputValue}
        placeholder={t('OrganizationUserManagement.searchUser')}
        onClickSearch={onClickSearchUser}
        handleChangeInput={(e) => setUserNameInputValue(e.target.value)}
        handleClear={() => setUserNameInputValue('')}
        className={`${width && width > 510 ? 'w-[19.5rem]' : 'w-full'}`}
      />
      {width && width > 600 ? (
        <div className="flex gap-x-1">
          <Button
            kind="primary"
            id="b1"
            label={t('OrganizationUserManagement.insertUser')}
            name={t('OrganizationUserManagement.insertUser')}
            onClick={selecOptionListHandler}
          />
          <Button
            kind="primary"
            id="b2"
            label={t('OrganizationUserManagement.updateUser')}
            name={t('OrganizationUserManagement.updateUser')}
            onClick={selecOptionListHandler}
          />
          <Button
            kind="primary"
            id="b3"
            label={t('OrganizationUserManagement.deleteUser')}
            name={t('OrganizationUserManagement.deleteUser')}
            onClick={selecOptionListHandler}
          />

          {/* 사용자 일괄 등록 */}
          {width && width > 700 ? (
            <button
              className="min-w-[3.25rem] h-9 rounded text-sm px-3 truncate bg-white hover:border-gray-400 active:bg-C-b8d3fe text-C-555555 border border-C-c8cace flex items-center justify-center space-x-1 "
              id="b4"
              name={t('OrganizationUserManagement.userImportXls') as string}
              onClick={openBulkInsertUser_Table}
            >
              <img src={iconUser} alt="Down" className="w-5 h-5 mr-0.5 " />
              <span>
                {t('OrganizationUserManagement.userImportXls') as string}
              </span>
            </button>
          ) : (
            <button
              className="min-w-[3.25rem] h-9 rounded text-sm px-3 truncate bg-white hover:border-gray-400 active:bg-C-b8d3fe text-C-555555 border border-C-c8cace flex items-center justify-center space-x-1 "
              id="b4"
              name={t('OrganizationUserManagement.userImportXls') as string}
              onClick={openBulkInsertUser_Table}
            >
              <img src={iconUser} alt="Down" className="w-5 h-5 mr-0.5 " />
              <span className="hidden sm:block">
                {t('OrganizationUserManagement.userImportXls') as string}
              </span>
            </button>
          )}

          {/* 조직 export 기능 */}
          <button
            data-tooltip-id="my-tooltip"
            className="min-w-[3rem] h-9 rounded text-sm px-3 truncate bg-white hover:border-gray-400 active:bg-C-b8d3fe text-C-555555 border border-C-c8cace flex items-center justify-center space-x-1"
            onClick={exportUserXlsx}
          >
            <img src={iconDownload} alt="Down" className="w-5 h-5" />
          </button>

          <Tooltip
            id="my-tooltip"
            place="bottom-end"
            style={{
              backgroundColor: '#0055ab',
              zIndex: '1',
              color: '#19231b',
            }}
          >
            <div className={`block mt-4 mb-4`}>
              <div className={`block text-sm mb-1 text-white`}>
                {t('OrganizationUserManagement.exportUserDesc1') as string}
              </div>
              <div className={`block text-sm mb-1 text-white`}>
                {t('OrganizationUserManagement.exportUserDesc2') as string}
              </div>
            </div>
          </Tooltip>
        </div>
      ) : (
        // 반응형 버튼 리스트
        <div className="h-9 w-full flex gap-1">
          <SelectListbox
            options={optionButtons}
            value={optionSelected}
            optionsHeight={12}
            width={'100%'}
            onChange={(value) => {
              setOptionSelected(value);
            }}
          />
          <Button
            kind="primary"
            label={t('OrganizationUserManagement.confirm')}
            id={optionSelected.id}
            name={optionSelected.id}
            onClick={selecOptionListHandler}
          />
        </div>
      )}
    </div>
  );
};

export default RightTopBar;
