import Button from '../../common/buttons/Button';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { IUserInfo } from '../../../interfaces/api/I-api-hrs-users';
import { GridApi, IDatasource } from 'ag-grid-community';
import { adminUserListApi } from '../../../api/admin/api-admin-user';
import { hrsAdminUserSearchApi, hrsUserSearchApi } from '../../../api/hrs/api-hrs-users';
import { useAppSelector } from '../../../redux/hooks';
import { toast } from 'react-toastify';
import { handleAddUserToRole } from '../../../helpers/h-adminIdMng';
import InputWithSearch from '../../common/InputWithSearch';
import axios from 'axios';

interface Props {
  gridApi: GridApi<IUserInfo[]> | undefined;
  selectedUsers: IUserInfo[];
  setSelectedUsers: React.Dispatch<React.SetStateAction<IUserInfo[]>>;
}
const LeftTopBar = (props: Props) => {
  const { usersid } = useAppSelector((state) => state.user);
  const [userNameInputValue, setUserNameInputValue] = useState('');
  let page = 1;
  const dataSource: IDatasource = {
    rowCount: undefined,
    getRows: (params) => {
      !userNameInputValue
        ? adminUserListApi({ page })
            .then((resp) => ({
              userList: resp?.data.data.result,
              totpage: resp?.data.data.totpage,
              totcount: resp?.data.data.totcount,
            }))
            .then((data) => {
              console.log(data);
              if (!data) return;
              if (!data.totcount || !data.totpage || !data.userList) return;

              const lastPage = page < data.totpage ? 50 * page + 1 : parseInt(data.totcount);
              params.successCallback(data.userList, lastPage);
              page++;
            })
            .catch((error) => {
              if (axios.isAxiosError(error)) {
                console.log('error: ', error);
              } else {
                console.log('unexpected error: ', error);
              }
            })
        : hrsAdminUserSearchApi({
            cmd: '1102',
            sid: usersid ? usersid : '',
            reqpage: 1,
            reqcount: 100,
            kind: 'ALL',
            keyword: userNameInputValue,
            fields: 'FULL',
            language: 'ko',
            withadmin: false,
          })
            .then((resp) => ({
              userList: resp?.data.data.result,
              totpage: resp?.data.data.totpage,
              totcount: resp?.data.data.totcount,
            }))
            .then((data) => {
              console.log('data1 : ', data);
              if (!data) return;
              //if (!data.totcount || !data.totpage || !data.userList) return;
              const lastPage = page < data.totpage ? 50 * page + 1 : data.totcount;
              params.successCallback(data.userList, lastPage);
              page++;
              console.log('data2 : ', data);
            })
            .catch((error) => {
              if (axios.isAxiosError(error)) {
                console.log('error: ', error);
              } else {
                console.log('unexpected error: ', error);
              }
            });
    },
  };

  const onClickSearchUser = () => {
    if (!props.gridApi) return;
    page = 1;
    props.gridApi.setDatasource(dataSource);
    console.log(dataSource);
  };

  const onClickAddUser = () => {
    if (props.selectedUsers.length < 1) {
      toast.error(String(t('AdminIdManagement.toast1')));
      return;
    }
    console.log(props.selectedUsers);
    handleAddUserToRole(props.selectedUsers);
    //props.setSelectedUsers([]);
    props.gridApi?.deselectAll();
  };

  useEffect(() => {
    if (userNameInputValue === '') {
      onClickSearchUser();
    }
  }, [userNameInputValue]);

  return (
    <>
      <div className="flex gap-1 mt-3 mb-1 overflow-hidden font-bold w-full truncate text-mainContentsPage-default">
        {t('AdminIdManagement.addAdmin') as string}
      </div>
      <div className="flex gap-1 py-defaultY overflow-hidden">
        <InputWithSearch
          className={`w-80`}
          value={userNameInputValue}
          placeholder={t('AdminIdManagement.searchUserPlaceholder')}
          onClickSearch={onClickSearchUser}
          handleChangeInput={(e) => setUserNameInputValue(e.target.value)}
          handleClear={() => setUserNameInputValue('')}
        />
        <div style={{ marginLeft: 'auto' }}>
          <Button label={t('AdminIdManagement.add')} kind="primary" onClick={onClickAddUser} />
        </div>
      </div>
    </>
  );
};

export default LeftTopBar;
