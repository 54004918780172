import React, { useState } from 'react';
import InputDatePicker from '../../common/inputs/InputDatePicker';
import { SelectListValue } from '../../../interfaces/TypeSelectListbox';

const UpperTopBar = () => {
  const [pickedDate, setPickedDate] = useState<Date | null>(null);

  return (
    <div className="flex justify-between gap-8 py-defaultY">
      <div className="flex gap-4">
        {/* 날짜 */}
        <div className="flex gap-2">
          <div className="flex items-center gap-2">
            <span className="truncate">날짜</span>
            <div className="w-32">
              <InputDatePicker
                pickedDate={pickedDate}
                setPickedDate={(date) => setPickedDate(date)}
                placeholder="시작일"
              />
            </div>
          </div>
          <div className="flex justify-center items-center">~</div>
          <div className="w-32">
            <InputDatePicker
              pickedDate={pickedDate}
              setPickedDate={(date) => setPickedDate(date)}
              placeholder=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpperTopBar;
