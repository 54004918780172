import { t } from 'i18next';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IClass } from '../../../interfaces/api/I-api-class';
import { RootState } from '../../../redux/store';
import { clearSelectedClass, setClassReloadStatus, setSelectedClass } from '../../../redux/slice/classManagementSlice';
import icon_trash from '../../../assets/image/etc/icon_trash.png';
import ConfirmModal from '../../react-modal/ConfirmModal';
import { removeClass } from '../../../api/admin/class/api-class';
import { toast } from 'react-toastify';

interface Props {
  classes: IClass[];
}

const ClassList: React.FC<Props> = ({ classes }) => {
  const dispatch = useDispatch();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const selectedClassCode = useSelector((state: RootState) => state.classManagement.selectedClassCode);
  const [hoveredClassCode, setHoveredClassCode] = useState<string | null>(null);
  const [removeClassCode, setRemoveClassCode] = useState<string | null>(null);

  const handleDelete = (classCode: string) => {
    setShowConfirmModal(true);
    setRemoveClassCode(classCode);
  };

  const handleConfirm = async () => {
    if (!removeClassCode) return;
    try {
      const data = {
        deptcode: removeClassCode,
      };
      const res = await removeClass(data);
      if (res?.data.code === 200) {
        toast.success(String(t('ClassManagement.classListToast1')));
        dispatch(setClassReloadStatus(true));
      }
    } catch (error) {
      toast.success(String(t('ClassManagement.classListToast2')));
      console.error(error);
    } finally {
      dispatch(clearSelectedClass());
      setShowConfirmModal(false);
      setHoveredClassCode(null);
    }
  };

  const handleCancel = () => {
    setShowConfirmModal(false);
  };

  return (
    <div className="p-2 class-list-container">
      {classes.map((item: any) => (
        <div
          key={item.deptcode}
          onClick={(e) => {
            e.stopPropagation();
            dispatch(setSelectedClass({ deptcode: item.deptcode, deptname: item.deptname }));
          }}
          onMouseEnter={() => setHoveredClassCode(item.deptcode)}
          onMouseLeave={() => setHoveredClassCode(null)}
          className={`py-2 px-3 text-sm text-gray-800 rounded cursor-pointer hover:bg-gray-100 flex justify-between items-center ${
            selectedClassCode === item.deptcode ? 'bg-[#e9f0f8]' : ''
          }`}
        >
          <span>{item.deptname}</span>
          <img
            src={icon_trash}
            className="w-5 h-5 min-w-5 ml-1"
            onClick={() => handleDelete(item.deptcode)}
            style={{
              opacity: hoveredClassCode === item.deptcode ? 1 : 0,
              transition: 'opacity 0.3s ease',
              cursor: 'pointer',
            }}
          />
        </div>
      ))}
      <ConfirmModal
        label={
          <>
            {t('ClassManagement.classListToast3')} <br />
            {t('ClassManagement.classListToast4')}
          </>
        }
        isOpen={showConfirmModal}
        onConfirm={handleConfirm}
        onClose={handleCancel}
      />
    </div>
  );
};

export default ClassList;
