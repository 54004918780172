import { ColDef } from 'ag-grid-community';
import { dateValueGetter } from '../../../../helpers/h-agGrid';
import {
  IserviceProviderFedTenant,
  IspTenantListReturnValue_desc_F,
} from '../../../../interfaces/api/service-provider/I-api-admin-serviceProvider-fed';
import { AgCellTextCenter } from '../../../common/AgCustomCell';
import { AgHeaderTextCenter } from '../../../common/AgCustomHeader';


//export const TenantMornitoring_tenantList_column: ColDef<IspTenantListReturnValue_desc_F>[] =
export const TenantMornitoring_tenantList_column  = (t: (key: string) => string) => {
  return [
    {
      field: 'tenantid',
      headerName: t('TenantMornitor.h-upperTenantId'),
      headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<IserviceProviderFedTenant>,
    },
    {
      field: 'name',
      headerName: t('TenantMornitor.h-upperTenantName'),
      headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<IserviceProviderFedTenant>,
    },
    {
      field: 'updatetime',
      headerName: t('TenantMornitor.h-upperUpdateTime'),
      valueGetter: dateValueGetter,
      headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<IserviceProviderFedTenant>,
    },
  ];
};
