import { t } from 'i18next';
import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  counselCategoryAddApi,
  counselCategoryModifyApi,
  counselCategoryRemoveApi,
} from '../../api/admin/counseling/api-admin-counsel-category';
import {
  IAdminCounselCategoryReq,
  IAdminCounselCategoryUpdateReq,
  IAdminCounselChannelReq,
  IChannel,
} from '../../interfaces/api/counsel/I-api-admin-counsel-category';
import { ICounselorInfo } from '../../interfaces/api/I-api-hrs-users';
import { counselChannelAddApi, counselChannelRemoveApi } from '../../api/admin/counseling/api-admin-counsel-channel';
import { IChannelDTO } from '../../interfaces/api/counsel/I-api-admin-counsel-channel';

interface InitialState {
  categoryAddStatus: 'idle' | 'pending' | 'success' | 'fail';
  categoryModifyStatus: 'idle' | 'pending' | 'success' | 'fail';
  categoryRemoveStatus: 'idle' | 'pending' | 'success' | 'fail';
  selectedCategoryId: string | null;
  selectedCategoryName: string | null;
  selectedChannel: IChannelDTO | null; // 추가
  selectedUsers: ICounselorInfo[];
  channelReloadStatus: boolean;
}

const initialState: InitialState = {
  categoryAddStatus: 'idle',
  categoryModifyStatus: 'idle',
  categoryRemoveStatus: 'idle',
  selectedCategoryId: null,
  selectedCategoryName: null,
  selectedChannel: null,
  selectedUsers: [],
  channelReloadStatus: false,
};

// add
export const addCategory = createAsyncThunk(
  'category/add',
  async (category: IAdminCounselCategoryReq, { rejectWithValue }) => {
    try {
      const response = await counselCategoryAddApi(category);

      if (response?.status === 200) {
        toast.success(String(t('CounselingManagement.toast1')));
      }

      return response?.data.data.result;
    } catch (error) {
      toast.error(String(t('CounselingManagement.toast2')));
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
        return rejectWithValue(error.message);
      } else {
        console.log('unexpected error: ', error);
        return rejectWithValue('unexpected error');
      }
    }
  },
);

// modify
export const modifyCategory = createAsyncThunk(
  'category/modify',
  async (rule: IAdminCounselCategoryUpdateReq, { rejectWithValue }) => {
    try {
      const response = await counselCategoryModifyApi(rule);

      if (response?.status === 200) {
        toast.success(String(t('CounselingManagement.toast3')));
      }

      return response?.data.data.result;
    } catch (error) {
      toast.error(String(t('CounselingManagement.toast4')));
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
        return rejectWithValue(error.message);
      } else {
        console.log('unexpected error: ', error);
        return rejectWithValue('unexpected error');
      }
    }
  },
);

// modify
export const modifyCategoryOrder = createAsyncThunk(
  'category/modify',
  async (rule: IAdminCounselCategoryUpdateReq, { rejectWithValue }) => {
    try {
      const response = await counselCategoryModifyApi(rule);
      if (response?.status === 200) return response?.data.data.result;
    } catch (error) {
      toast.error(String(t('CounselingManagement.toast5')));
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
        return rejectWithValue(error.message);
      } else {
        console.log('unexpected error: ', error);
        return rejectWithValue('unexpected error');
      }
    }
  },
);

// remove
export const removeCategory = createAsyncThunk(
  'category/remove',
  async (category: IAdminCounselCategoryReq, { rejectWithValue }) => {
    try {
      const response = await counselCategoryRemoveApi(category);

      if (response?.status === 200) {
        toast.success(String(t('CounselingManagement.toast6')));
      }
      return response?.data.data.result;
    } catch (error) {
      toast.error(String(t('CounselingManagement.toast7')));
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
        return rejectWithValue(error.message);
      } else {
        console.log('unexpected error: ', error);
        return rejectWithValue('unexpected error');
      }
    }
  },
);

// add
export const addChannel = createAsyncThunk(
  'category/add',
  async (categoryid: IAdminCounselChannelReq, { rejectWithValue }) => {
    try {
      const response = await counselChannelAddApi(categoryid);

      if (response?.status === 200) {
        toast.success(String(t('CounselingManagement.toast8')));
      }

      return response?.data.data.result;
    } catch (error) {
      toast.error(String(t('CounselingManagement.toast9')));
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
        return rejectWithValue(error.message);
      } else {
        console.log('unexpected error: ', error);
        return rejectWithValue('unexpected error');
      }
    }
  },
);

// remove
export const removeChannel = createAsyncThunk('channel/remove', async (data: IChannel, { rejectWithValue }) => {
  try {
    const response = await counselChannelRemoveApi(data);

    if (response?.status === 200) {
      toast.success(String(t('CounselingManagement.toast10')));
    }

    return response?.data.data.result;
  } catch (error) {
    toast.error(String(t('CounselingManagement.toast11')));
    if (axios.isAxiosError(error)) {
      console.log('error: ', error);
      return rejectWithValue(error.message);
    } else {
      console.log('unexpected error: ', error);
      return rejectWithValue('unexpected error');
    }
  }
});

// add
export const addCounselor = createAsyncThunk(
  'category/add',
  async (category: IAdminCounselCategoryReq, { rejectWithValue }) => {
    try {
      const response = await counselCategoryAddApi(category);

      if (response?.status === 200) {
        String(t('CounselingManagement.toast12'));
      }

      return response?.data.data.result;
    } catch (error) {
      toast.error(String(t('CounselingManagement.toast13')));
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
        return rejectWithValue(error.message);
      } else {
        console.log('unexpected error: ', error);
        return rejectWithValue('unexpected error');
      }
    }
  },
);

export const counselingManagementSlice = createSlice({
  name: 'counselingManagement',
  initialState,
  reducers: {
    initCounselingManagementSlice: () => initialState,
    setSelectedCategory: (state, action: PayloadAction<{ id: string | null; name: string | null }>) => {
      state.selectedCategoryId = action.payload.id;
      state.selectedCategoryName = action.payload.name;
    },
    setSelectedChannel: (state, action: PayloadAction<IChannelDTO | null>) => {
      state.selectedChannel = action.payload;
    },
    setSelectedUsers: (state, action: PayloadAction<ICounselorInfo[]>) => {
      state.selectedUsers = action.payload;
    },
    setChannelReloadStatus: (state, action: PayloadAction<boolean>) => {
      state.channelReloadStatus = action.payload;
    },
    clearSelectedCategory: (state) => {
      state.selectedCategoryId = null;
      state.selectedCategoryName = null;
    },
    clearSelectedChannel: (state) => {
      state.selectedChannel = null;
    },
    clearSelectedUsers: (state) => {
      state.selectedUsers = [];
    },
  },
  extraReducers(builder) {
    builder
      // add
      .addCase(addCategory.pending, (state) => {
        state.categoryAddStatus = 'pending';
      })
      .addCase(addCategory.fulfilled, (state) => {
        state.categoryAddStatus = 'success';
      })
      .addCase(addCategory.rejected, (state) => {
        state.categoryAddStatus = 'fail';
      })

      // modify
      .addCase(modifyCategory.pending, (state) => {
        state.categoryModifyStatus = 'pending';
      })
      .addCase(modifyCategory.fulfilled, (state) => {
        state.categoryModifyStatus = 'success';
      })
      .addCase(modifyCategory.rejected, (state) => {
        state.categoryModifyStatus = 'fail';
      })

      // delete
      .addCase(removeCategory.pending, (state) => {
        state.categoryRemoveStatus = 'pending';
      })
      .addCase(removeCategory.fulfilled, (state) => {
        state.categoryRemoveStatus = 'success';
      })
      .addCase(removeCategory.rejected, (state) => {
        state.categoryRemoveStatus = 'fail';
      });
  },
});

export const {
  initCounselingManagementSlice,
  setSelectedCategory,
  setSelectedChannel,
  setSelectedUsers,
  setChannelReloadStatus,
  clearSelectedCategory,
  clearSelectedChannel,
  clearSelectedUsers,
} = counselingManagementSlice.actions;

export default counselingManagementSlice.reducer;
