import { IpluralAxiosResponse } from './../../../interfaces/api/I-axios';
import { getMyTenantId, getOrgCode } from '../../../helpers/h-userInfo';
import {
  IAdminButtonFunc,
  IAdminButtonFuncAddApiBody,
  IAdminButtonFuncModifyApiBody,
  IAdminButtonFuncRemoveApi,
} from '../../../interfaces/api/button/I-api-admin-btn-func';
import { axiosErrorHandler, axiosInstance } from '../../axiosInstance';
import {
  IAdminCounselCategoryListRes,
  IAdminCounselCategoryReq,
  IAdminCounselCategoryUpdateReq,
} from '../../../interfaces/api/counsel/I-api-admin-counsel-category';

const adminCounselCategoryInfo = {
  url: {
    list: 'api/v1/admin-mg-tp/counsel/category/list',
    add: 'api/v1/admin-mg-tp/counsel/category/add',
    modify: 'api/v1/admin-mg-tp/counsel/category/modify',
    remove: 'api/v1/admin-mg-tp/counsel/category/remove',
  },
};

// list
export const counselCategoryListApi = () => {
  const req: IAdminCounselCategoryReq = {
    cmd: '1501',
    orgcode: 'ORG01',
  };

  const { url } = adminCounselCategoryInfo;
  return axiosInstance.post<IpluralAxiosResponse<IAdminCounselCategoryListRes>>(url.list, req);
};

// add
export const counselCategoryAddApi = (category: IAdminCounselCategoryReq) => {
  const { url } = adminCounselCategoryInfo;
  return axiosInstance.post(url.add, category);
};

// remove
export const counselCategoryRemoveApi = (category: IAdminCounselCategoryReq) => {
  const { url } = adminCounselCategoryInfo;
  return axiosInstance.post(url.remove, category);
};

// modify
export const counselCategoryModifyApi = (category: IAdminCounselCategoryUpdateReq) => {
  const { url } = adminCounselCategoryInfo;
  return axiosInstance.post(url.modify, category);
};
