import { t } from 'i18next';
import Button from '../../common/buttons/Button';
import { openClassStudentModal, openClassTeacherModal } from '../../../helpers/h-react-modals';
import { RootState } from '../../../redux/store';
import { useSelector } from 'react-redux';
import { studentRemoveClass, teacherRemoveClass } from '../../../api/admin/class/api-class';
import { IhrsUserListApiResponseFull } from '../../../interfaces/api/I-api-hrs-users';
import { toast } from 'react-toastify';
import { setStudentReloadStatus, setTeacherReloadStatus } from '../../../redux/slice/classManagementSlice';
import { useAppDispatch } from '../../../redux/hooks';
import ConfirmModal from '../../react-modal/ConfirmModal';
import { useState } from 'react';

interface StudentParentInfo {
  studentId: string;
  studentName: string;
  userId: string;
  userField: string;
  parent1?: ParentInfo;
  parent2?: ParentInfo;
}

interface ParentInfo {
  name: string;
  id: string;
}

interface Props {
  isTeacherMode?: boolean;
  selectedTeachers?: Array<IhrsUserListApiResponseFull>;
  selectedStudents?: Array<StudentParentInfo>;
}

const ClassDetailsBtn: React.FC<Props> = ({ isTeacherMode = true, selectedTeachers, selectedStudents }) => {
  const dispatch = useAppDispatch();
  const selectedClassCode = useSelector((state: RootState) => state.classManagement.selectedClassCode);
  const selectedClassName = useSelector((state: RootState) => state.classManagement.selectedClassName);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const handleConfirmTeacher = async () => {
    if (!selectedClassCode) {
      toast.error(String(t('ClassManagement.classDetailSel')));
      return;
    }
    if (selectedTeachers?.length === 0) {
      toast.error(String(t('ClassManagement.classDetailSel4')));
      return;
    }

    const teacherIds = selectedTeachers ? selectedTeachers.map((user: { userid: string }) => user.userid) : [];
    const param = {
      deptcode: selectedClassCode,
      teacherIds,
    };

    const res = await teacherRemoveClass(param);

    if (res?.data.result.count > 0) {
      toast.success(`${selectedClassName} ${t('ClassManagement.classDetailSel5')}`);
      dispatch(setTeacherReloadStatus(true));
    } else {
      toast.error(`${selectedClassName} ${t('ClassManagement.classDetailSel6')}`);
    }
    setShowConfirmModal(false);
  };

  const handleConfirmStudent = async () => {
    if (!selectedClassCode) {
      toast.error(String(t('ClassManagement.classDetailSel3')));
      return;
    }
    if (selectedStudents?.length === 0) {
      toast.error(String(t('ClassManagement.classDetailSel7')));
      return;
    }

    const relations = selectedStudents?.map((student) => ({
      studentId: student.studentId,
      deptcode: selectedClassCode,
    }));

    if (!relations) return;

    const param = {
      relations,
    };
    console.log(param);

    try {
      const res = await studentRemoveClass(param);

      if (res?.data.result.count > 0) {
        toast.success(`${res?.data.result.count}`);
        dispatch(setStudentReloadStatus(true));
      } else {
        toast.error(String(t('ClassManagement.classDetailSel9')));
      }
    } catch (error) {
      console.error('Error removing students:', error);
      toast.error(String(t('ClassManagement.classDetailSel10')));
    } finally {
      setShowConfirmModal(false);
    }
  };

  //선생 삭제
  const deleteTeacher = async () => {
    if (!selectedClassCode) {
      toast.error(String(t('ClassManagement.classDetailSel3')));
      return;
    }
    if (selectedTeachers?.length === 0) {
      toast.error(String(t('ClassManagement.classDetailSel4')));
      return;
    }
    setShowConfirmModal(true);
  };

  //학생 삭제
  const deleteStudent = async () => {
    if (!selectedClassCode) {
      toast.error(String(t('ClassManagement.classDetailSel3')));
      return;
    }
    if (selectedStudents?.length === 0) {
      toast.error(String(t('ClassManagement.classDetailSel7')));
      return;
    }
    setShowConfirmModal(true);
  };

  const handleCancel = () => {
    setShowConfirmModal(false);
  };

  return (
    <div className="w-full">
      <div className="flex gap-2">
        {isTeacherMode ? (
          <>
            {/* <Button kind="cancel" label={t('ClassManagement.classDetailMove')} className={`min-w-[4.5rem] text-xs height-[33px]`} /> */}
            <Button
              kind="cancel"
              onClick={deleteTeacher}
              label={t('ClassManagement.classDetailTException')}
              className={`min-w-[4.5rem] text-xs h-[33px]`}
            />
            <Button
              kind="primary"
              onClick={openClassTeacherModal}
              label={t('ClassManagement.classDetailTanAdd')}
              className={`min-w-[4.25rem] text-xs h-[33px]`}
            />
            <ConfirmModal
              label={<>{t('ClassManagement.classDetailSel11')}</>}
              isOpen={showConfirmModal}
              onConfirm={handleConfirmTeacher}
              onClose={handleCancel}
            />
          </>
        ) : (
          <>
            {/* <Button kind="cancel" label={t('ClassManagement.classDetailMove')} className={`min-w-[4.5rem] text-xs height-[33px]`} /> */}
            <Button
              kind="cancel"
              onClick={deleteStudent}
              label={t('ClassManagement.classDetailTException')}
              className={`min-w-[4.5rem] text-xs h-[33px]`}
            />
            <Button
              kind="primary"
              onClick={openClassStudentModal}
              label={t('ClassManagement.classDetailStudentAdd')}
              className={`min-w-[4.25rem] text-xs h-[33px]`}
            />
            <ConfirmModal
              label={<>{t('ClassManagement.classDetailSel11')}</>}
              isOpen={showConfirmModal}
              onConfirm={handleConfirmStudent}
              onClose={handleCancel}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ClassDetailsBtn;
