import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { adminClientPatchUploadApi } from '../../../api/admin/client/api-admin-client';
import { IclientPatchUploadApiBody } from '../../../interfaces/api/client/I-api-admin-client';
import axios from 'axios';

interface InitialState {
  clientPatchUploadStatus: 'idle' | 'pending' | 'success' | 'fail';
}

const initialState: InitialState = {
  clientPatchUploadStatus: 'idle',
};

// client
// patch/upload
export const clientPatchUpload = createAsyncThunk(
  'client/patch/upload',
  async (body: IclientPatchUploadApiBody, { rejectWithValue }) => {
    try {
      const response = await adminClientPatchUploadApi(body);

      if (response?.status === 200) {
        toast.success('파일 업로드 성공.');
      }

      return response?.data.data.result;
    } catch (error) {
      toast.error('파일 업로드 실패');
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
        return rejectWithValue(error.message);
      } else {
        console.log('unexpected error: ', error);
        return rejectWithValue('unexpected error');
      }
    }
  },
);

export const upgradeManagementSlice = createSlice({
  name: 'upgradeManagement',
  initialState,
  reducers: {
    initUpgradeManagementSlice: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(clientPatchUpload.pending, (state) => {
        state.clientPatchUploadStatus = 'pending';
      })
      .addCase(clientPatchUpload.fulfilled, (state) => {
        state.clientPatchUploadStatus = 'success';
      })
      .addCase(clientPatchUpload.rejected, (state) => {
        state.clientPatchUploadStatus = 'fail';
      });
  },
});

export const { initUpgradeManagementSlice } = upgradeManagementSlice.actions;

export default upgradeManagementSlice.reducer;
