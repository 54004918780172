import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import Modal from 'react-modal';
import { closeCounselingCounselorModal } from '../../../helpers/h-react-modals';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { AgGridReact } from 'ag-grid-react';
import { t } from 'i18next';
import axios from 'axios';
import { ICounselorInfo, IUserInfo } from '../../../interfaces/api/I-api-hrs-users';
import { ColDef, GridApi, GridReadyEvent, IDatasource, SelectionChangedEvent } from 'ag-grid-community';
import { adminUserListApi } from '../../../api/admin/api-admin-user';
import { USER_LIST_TALBLE_COLUMN } from '../../tables/adminIdMng/columns';
import { setSelectedUsers } from '../../../redux/slice/counselingManagementSlice';
import InputWithSearch from '../../common/InputWithSearch';
import Button from '../../common/buttons/Button';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { hrsAdminUserSearchApi } from '../../../api/hrs/api-hrs-users';

const customStyles = {
  overlay: {
    zIndex: 99,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '1000px',
    height: '600px',
    padding: 0,
  },
};

interface Props {
  isOpen: boolean;
}

const UserListReactModal: React.FC<Props> = ({ isOpen = false }) => {
  const dispatch = useAppDispatch();
  const { usersid } = useAppSelector((state) => state.user);
  const { adminRoleStatus } = useAppSelector((state) => state.adminIdManagement);
  const { selectedUsers } = useAppSelector((state) => state.counselingManagement);
  const [localSelectedUsers, setLocalSelectedUsers] = useState<ICounselorInfo[]>([]);
  const [gridApi, setGridApi] = useState<GridApi<IUserInfo[]>>();
  const gridRef = useRef<any>();
  const [userNameInputValue, setUserNameInputValue] = useState('');
  const { t } = useTranslation();
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  const defaultColDef = useMemo<ColDef>(
    () => ({
      flex: 1,
      resizable: true,
      minWidth: 100,
    }),
    [],
  );

  const dataSource: IDatasource = {
    rowCount: undefined,
    getRows: (params) => {
      const page = 1;
      !userNameInputValue
        ? adminUserListApi({ page })
            .then((resp) => ({
              userList: resp?.data.data.result,
              totpage: resp?.data.data.totpage,
              totcount: resp?.data.data.totcount,
            }))
            .then((data) => {
              if (!data) return;
              const lastPage = page < data.totpage ? 50 * page + 1 : parseInt(data.totcount);
              params.successCallback(data.userList, lastPage);
              updateSelection(); // 데이터를 로드한 후 선택된 사용자 체크
            })
            .catch(handleApiError)
        : hrsAdminUserSearchApi({
            cmd: '1102',
            sid: usersid ? usersid : '',
            reqpage: 1,
            reqcount: 100,
            kind: 'ALL',
            keyword: userNameInputValue,
            fields: 'FULL',
            language: 'ko',
            withadmin: false,
          })
            .then((resp) => ({
              userList: resp?.data.data.result,
              totpage: resp?.data.data.totpage,
              totcount: resp?.data.data.totcount,
            }))
            .then((data) => {
              if (!data) return;
              const lastPage = page < data.totpage ? 50 * page + 1 : data.totcount;
              params.successCallback(data.userList, lastPage);
              updateSelection(); // 데이터를 로드한 후 선택된 사용자 체크
            })
            .catch(handleApiError);
    },
  };

  const handleApiError = (error: any) => {
    if (axios.isAxiosError(error)) {
      console.log('error: ', error);
    } else {
      console.log('unexpected error: ', error);
    }
  };

  // const onSelectionChanged = useCallback((props: SelectionChangedEvent<IUserInfo>) => {
  //   const selectedRows = props.api.getSelectedRows();
  //   setLocalSelectedUsers(selectedRows);
  // }, []);

  const onSelectionChanged = useCallback((props: SelectionChangedEvent<IUserInfo>) => {
    const selectedRows = props.api.getSelectedRows();

    // ICounselorInfo 타입으로 변환
    const mappedRows: ICounselorInfo[] = selectedRows.map((row) => ({
      bid: row.bid,
      userid: row.userid,
      username: row.username,
    }));

    setLocalSelectedUsers(mappedRows);
  }, []);

  const onGridReady = (params: GridReadyEvent<IUserInfo[]>) => {
    setGridApi(params.api);
    params.api.setDatasource(dataSource);
  };

  // useEffect(() => {
  //   if (gridApi && selectedUsers.length > 0) {
  //     const interval = setInterval(() => {
  //       // 데이터가 로드되었는지 확인
  //       if (gridApi.getDisplayedRowCount() > 0) {
  //         gridApi.forEachNode((node) => {
  //           const data = node.data as unknown as IUserInfo;
  //           if (selectedUsers.find((user) => user.userid === data.userid)) {
  //             node.setSelected(true);
  //           }
  //         });
  //         clearInterval(interval); // 데이터가 로드된 후에는 Interval 제거
  //       }
  //     }, 100); // 데이터 로드 확인을 위해 100ms마다 실행

  //     return () => clearInterval(interval); // 컴포넌트가 unmount될 때 Interval 제거
  //   }
  // }, [gridApi, selectedUsers]);

  const updateSelection = () => {
    if (gridApi && selectedUsers.length > 0) {
      gridApi.forEachNode((node) => {
        const data = node.data as unknown as IUserInfo;
        if (selectedUsers.find((user) => user.userid === data.userid)) {
          node.setSelected(true);
        }
      });
    }
  };

  useEffect(() => {
    if (gridApi) {
      gridApi.deselectAll();
      gridApi.setDatasource(dataSource);
    }
  }, [gridApi]);

  useEffect(() => {
    setColumnDefs(USER_LIST_TALBLE_COLUMN(t));
  }, [t]);

  useEffect(() => {
    if (adminRoleStatus === 'success' && gridApi) {
      gridApi.deselectAll();
      gridApi.setDatasource(dataSource);
      closeCounselingCounselorModal();
    }
  }, [adminRoleStatus, gridApi]);

  const onClickSearchUser = () => {
    if (!gridApi) return;
    gridApi.setDatasource(dataSource);
  };

  const onClickAddUser = () => {
    if (localSelectedUsers.length < 1) {
      return;
    }
    dispatch(setSelectedUsers(localSelectedUsers));
    gridApi?.deselectAll();
    closeCounselingCounselorModal();
  };

  useEffect(() => {
    if (userNameInputValue === '') {
      onClickSearchUser();
    }
  }, [userNameInputValue]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeCounselingCounselorModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="w-950px grow flex flex-col overflow-hidden mx-auto">
        <div className="flex gap-1 mt-3 mb-1 overflow-hidden font-bold w-full truncate text-mainContentsPage-default">
          {t('CounselingManagement.counselorAdd') as string}
        </div>
        <div className="flex gap-1 py-defaultY overflow-hidden">
          <InputWithSearch
            className="w-80"
            value={userNameInputValue}
            placeholder={t('AdminIdManagement.searchUserPlaceholder')}
            onClickSearch={onClickSearchUser}
            handleChangeInput={(e) => setUserNameInputValue(e.target.value)}
            handleClear={() => setUserNameInputValue('')}
          />
          <div style={{ marginLeft: 'auto' }}>
            <Button label={t('AdminIdManagement.add')} kind="primary" onClick={onClickAddUser} />
          </div>
        </div>
        <div className="ag-theme-alpine ag-theme-mycustomtheme grow w-full h-480">
          <AgGridReact
            ref={gridRef}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowModelType={'infinite'}
            cacheBlockSize={50}
            onGridReady={onGridReady}
            rowMultiSelectWithClick
            rowSelection="multiple"
            onSelectionChanged={onSelectionChanged}
          />
        </div>
      </div>
    </Modal>
  );
};

export default UserListReactModal;
