// style
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../../../styles/ag-grid.css';

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { CellClickedEvent, ColDef } from 'ag-grid-community';
import { CntBtnGroupHubList_Table_Column } from './columns';
import { AgGridReact } from 'ag-grid-react';
import { adminButtonGroupHubListApi } from '../../../api/admin/button/api-admin-btn-group-hub';
import { IAdminButtonGroupHub } from '../../../interfaces/api/button/I-api-admin-btn-group-hub';
import { adminButtonCntInfoApi } from '../../../api/admin/button/api-admin-btn-cnt';
import { IAdminButtonCntListApiResult } from '../../../interfaces/api/button/I-api-admin-btn-cnt';
import { setSelectedCntBtnGroupsBtn } from '../../../redux/slice/countButtonManagementSlice';
import axios from 'axios';

const CntBtnGroupHubList_Table = () => {
  const dispatch = useAppDispatch();
  // STATE
  const { selectedCntBtnGroup, CntBtnGroupsBtnStatus, cntBtnStatus } =
    useAppSelector((state) => state.countButtonManagement);
  const [selectedBtnGroupsBtnCodeList, setSelectedBtnGroupsBtnCodeList] =
    useState<IAdminButtonGroupHub[]>([]);
  const [groupButtons, setGroupButtons] = useState<
    IAdminButtonCntListApiResult[]
  >([]);

  // AG_GRID
  const [columnDefs, setColumnDefs] = useState<ColDef[]>(
    CntBtnGroupHubList_Table_Column,
  );
  const [rowData, setRowData] = useState<IAdminButtonCntListApiResult[]>([]);
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      resizable: true,
      minWidth: 100,
    };
  }, []);

  // 버튼그룹의 버튼 선택시..
  const cellClickedListener = useCallback((event: CellClickedEvent<any>) => {
    const buttonInfo: IAdminButtonCntListApiResult = event.data;
    dispatch(setSelectedCntBtnGroupsBtn(buttonInfo));
    // console.log('버튼 그룹의 버튼 정보', buttonInfo);
  }, []);

  // buttoncode 리스트로 버튼 상세 정보 배열 구하기 --> AG_ROWDATA 설정
  const fetchCntBtnInfo = async (list: IAdminButtonGroupHub[]) => {
    const countbuttonsInfoList: IAdminButtonCntListApiResult[] = [];
    try {
      for (const btn of list) {
        const res = await adminButtonCntInfoApi({ btncode: btn.btncode });
        const btnInfo: IAdminButtonCntListApiResult = res?.data.data.result;
        countbuttonsInfoList.push(btnInfo);
      }
      console.log('countbuttonsInfoList', countbuttonsInfoList);
      setRowData(countbuttonsInfoList);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
      } else {
        console.log('unexpected error: ', error);
      }
    }
  };

  // 선택된 버튼 그룹의 btncode 리스트 구하기.
  const getBtnGroupsCodeList = async () => {
    try {
      const res = await adminButtonGroupHubListApi({
        groupcode: selectedCntBtnGroup.groupcode,
        btnkind: 'C',
      });
      if (!res) return;
      const groupsBtnCodeList = res?.data.data.result;
      return groupsBtnCodeList;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
      } else {
        console.log('unexpected error: ', error);
      }
    }
  };

  // AG_ROWDATA 구하기 전 조건 설정
  useEffect(() => {
    if (selectedBtnGroupsBtnCodeList.length < 1) {
      setRowData([]);
      return;
    }
    fetchCntBtnInfo(selectedBtnGroupsBtnCodeList);
  }, [selectedBtnGroupsBtnCodeList]);

  // 선택한 버튼 그룹의 버튼코드 리스트 구하기.
  useEffect(() => {
    if (selectedCntBtnGroup.groupcode.length !== 32) return;
    getBtnGroupsCodeList()
      .then((groupsBtnCodeList) => {
        if (!groupsBtnCodeList || groupsBtnCodeList.length === 0) {
          setSelectedBtnGroupsBtnCodeList([]);
        } else {
          setSelectedBtnGroupsBtnCodeList(groupsBtnCodeList);
        }
      })
      .catch((err) => console.log(err));
  }, [selectedCntBtnGroup]);

  // 옵저버
  useEffect(() => {
    if (CntBtnGroupsBtnStatus !== 'success' && cntBtnStatus !== 'success')
      return;

    // console.log('옵저버!!');

    getBtnGroupsCodeList()
      .then((groupsBtnCodeList) => {
        if (!groupsBtnCodeList || groupsBtnCodeList.length === 0) {
          setSelectedBtnGroupsBtnCodeList([]);
        } else {
          setSelectedBtnGroupsBtnCodeList(groupsBtnCodeList);
        }
      })
      .catch((err) => console.log(err));
  }, [CntBtnGroupsBtnStatus, cntBtnStatus]);

  return (
    <div className="w-full grow flex flex-col overflow-hidden">
      <div className="ag-theme-alpine w-full grow ag-theme-mycustomtheme">
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowSelection={'single'}
          onCellClicked={cellClickedListener}
          overlayNoRowsTemplate={'\xa0'}
        ></AgGridReact>
      </div>
    </div>
  );
};

export default CntBtnGroupHubList_Table;
