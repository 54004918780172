import { t } from 'i18next';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import ClassSectionHeader from './ClassSectionHeader';
import ClassDetailsBtn from './ClassDetailsBtn';
import { parentList, studentList } from '../../../api/admin/class/api-class';
import { useAppSelector } from '../../../redux/hooks';
import { getMyTenantId } from '../../../helpers/h-userInfo';
import { hrsUserListApi } from '../../../api/hrs/api-hrs-users';
import { AgGridReact } from 'ag-grid-react';
import { AgHeaderTextCenter } from '../../common/AgCustomHeader';
import {
  CellClassParams,
  CellClickedEvent,
  GridApi,
  SelectionChangedEvent,
  ValueGetterParams,
} from 'ag-grid-community';
import { openClassParentInfoModal, openClassParentModal } from '../../../helpers/h-react-modals';
import { setSelectedRelation, setStudentReloadStatus } from '../../../redux/slice/classManagementSlice';

interface StudentParentInfo {
  studentId: string;
  studentName: string;
  userId: string;
  userField: string;
  parent1?: ParentInfo;
  parent2?: ParentInfo;
}

interface ParentInfo {
  name: string;
  id: string;
}

const ClassDetailStudent = () => {
  const dispatch = useDispatch();
  const tenantid = getMyTenantId();
  const selectedClassCode = useSelector((state: RootState) => state.classManagement.selectedClassCode);
  const { usersid } = useAppSelector((state) => state.user);
  const gridRef = useRef<AgGridReact<StudentParentInfo>>(null);
  const [gridApi, setGridApi] = useState<GridApi<StudentParentInfo>>();
  const [data, setData] = useState<Array<StudentParentInfo>>([]);
  const [selectedStudents, setSelectedStudents] = useState<Array<StudentParentInfo>>([]);
  const studentReloadStatus = useSelector((state: RootState) => state.classManagement.studentReloadStatus);

  useEffect(() => {
    if (!selectedClassCode) return;
    fetchData(selectedClassCode);
  }, [selectedClassCode]);

  useEffect(() => {
    if (!selectedClassCode) return;
    fetchData(selectedClassCode);
    dispatch(setStudentReloadStatus(false));
    setSelectedStudents([]);
  }, [studentReloadStatus]);

  const fetchData = async (selectedClassCode: string) => {
    try {
      const eduRs = await studentList(selectedClassCode);
      if (!eduRs?.data.result.students) return;

      const studentIds = eduRs?.data.result.students.map((student: string) => student);
      if (!studentIds || studentIds.length === 0) {
        setData([]);
        return;
      }
      const parentRs = await parentList({ studentIds });
      if (!parentRs?.data.result) return;

      const parentRelations = parentRs.data.result;

      // 부모 관계를 매핑
      const parentMap: { [key: string]: string[] } = {};
      parentRelations.forEach((relation) => {
        const { studentId, parentId } = relation;
        if (!parentMap[studentId]) {
          parentMap[studentId] = [];
        }
        parentMap[studentId].push(parentId);
      });

      const who = [
        ...studentIds.map((studentId) => ({ bid: `${tenantid}.${studentId}` })),
        ...parentRelations.map((relation) => ({ bid: `${tenantid}.${relation.parentId}` })),
      ];

      const commonRs = await hrsUserListApi({
        cmd: '1101',
        sid: usersid ? usersid : '',
        who,
        language: 'ko',
        fields: 'FULL',
      });

      const fullUserData = commonRs.data.data.result;

      // 학생 정보를 부모와 매핑하여 새로운 타입으로 변환
      const mappedData: StudentParentInfo[] = studentIds.map((studentId) => {
        const studentInfo = fullUserData.find((user) => user.userid === studentId);
        const parents = parentMap[studentId] || [];

        // 부모1, 부모2 이름과 아이디 찾기
        const parent1 = parents[0]
          ? {
              name: fullUserData.find((user) => user.userid === parents[0])?.username || '',
              id: parents[0],
            }
          : undefined;

        const parent2 = parents[1]
          ? {
              name: fullUserData.find((user) => user.userid === parents[1])?.username || '',
              id: parents[1],
            }
          : undefined;

        return {
          studentId: studentInfo?.userid || '',
          studentName: studentInfo?.username || '',
          userId: studentInfo?.userid || '',
          userField: studentInfo?.userfield5 === 's' ? '学生' : '',
          parent1, // ParentInfo 타입으로 저장
          parent2, // ParentInfo 타입으로 저장
        };
      });

      setData(mappedData); // 데이터를 상태로 저장
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const columnDefs = useMemo(
    () => [
      {
        headerName: ' ',
        field: 'checkbox',
        checkboxSelection: true,
        maxWidth: 50,
        resizable: false,
      },
      {
        headerName: t('ClassManagement.classDetailName') as string,
        field: 'studentName',
        editable: false,
        headerComponent: AgHeaderTextCenter,
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: t('ClassManagement.classDetailId') as string,
        field: 'userId',
        editable: false,
        headerComponent: AgHeaderTextCenter,
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: t('ClassManagement.classDetailKind') as string,
        field: 'userField',
        editable: false,
        headerComponent: AgHeaderTextCenter,
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: t('ClassManagement.classDetailParent1') as string,
        field: 'parent1',
        editable: false,
        headerComponent: AgHeaderTextCenter,
        cellStyle: (params: CellClassParams) => {
          return {
            textAlign: 'center',
            color: params.data?.parent1 ? 'blue' : 'gray', // 부모1 데이터가 없으면 회색, 있으면 파란색
            textDecoration: params.data?.parent1 ? 'none' : 'underline',
            cursor: 'pointer',
          };
        },
        valueGetter: (params: ValueGetterParams<StudentParentInfo>) =>
          params.data?.parent1?.name || t('ClassManagement.classDetailSpecifying'),
        onCellClicked: (params: CellClickedEvent<StudentParentInfo>) => {
          if (params.data?.parent1) {
            dispatch(
              setSelectedRelation({
                selectedStudentId: params.data?.studentId || null,
                selectedParentId: params.data?.parent1.id || null,
              }),
            );
            openClassParentInfoModal();
          } else {
            dispatch(
              setSelectedRelation({
                selectedStudentId: params.data?.studentId || null,
                selectedParentId: null,
              }),
            );
            openClassParentModal();
          }
        },
      },
      {
        headerName: t('ClassManagement.classDetailParent2') as string,
        field: 'parent2',
        editable: false,
        headerComponent: AgHeaderTextCenter,
        cellStyle: (params: CellClassParams) => {
          return {
            textAlign: 'center',
            color: params.data?.parent2 ? 'blue' : 'gray',
            textDecoration: params.data?.parent2 ? 'none' : 'underline',
            cursor: 'pointer',
          };
        },
        valueGetter: (params: ValueGetterParams<StudentParentInfo>) =>
          params.data?.parent2?.name || t('ClassManagement.classDetailSpecifying'),
        onCellClicked: (params: CellClickedEvent<StudentParentInfo>) => {
          // params.api.deselectNode(params.node);

          if (params.data?.parent2) {
            dispatch(
              setSelectedRelation({
                selectedStudentId: params.data?.studentId || null,
                selectedParentId: params.data?.parent2.id || null,
              }),
            );
            openClassParentInfoModal();
          } else {
            dispatch(
              setSelectedRelation({
                selectedStudentId: params.data?.studentId || null,
                selectedParentId: null,
              }),
            );
            openClassParentModal();
          }
        },
        // suppressRowClickSelection: true, // 부모1 셀을 클릭해도 행이 선택되지 않도록 설정
      },
    ],
    [],
  );

  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      resizable: false,
      sortable: false,
    }),
    [],
  );

  const onGridReady = useCallback(
    (params: { api: GridApi<StudentParentInfo> }) => {
      setGridApi(params.api);
      gridRef.current?.api.setRowData(data);
    },
    [data],
  );

  // 선택된 행 데이터를 관리하는 함수
  const onSelectionChanged = useCallback((event: SelectionChangedEvent<StudentParentInfo>) => {
    const selectedRows = event.api.getSelectedRows();
    setSelectedStudents(selectedRows);
  }, []);

  return (
    <div className="p-2 pt-0 category-list-container h-auto">
      <ClassSectionHeader title={t('ClassManagement.classDetailStudent')} hide={true} className={`p-0 border-none`} />
      <ClassDetailsBtn isTeacherMode={false} selectedStudents={selectedStudents} />
      <div className="ag-theme-alpine mt-2" style={{ height: 340, width: 'auto' }}>
        <AgGridReact
          rowHeight={40}
          headerHeight={40}
          ref={gridRef}
          rowData={data}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowMultiSelectWithClick
          rowSelection={'multiple'}
          onGridReady={onGridReady}
          onSelectionChanged={onSelectionChanged}
          overlayNoRowsTemplate={`<span style="margin-top:40px;">${t('ClassManagement.classDetailSel12')}</span>`}
        />
      </div>
    </div>
  );
};

export default ClassDetailStudent;
