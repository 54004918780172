export const patchFileDevices = {
  Desktop_Windows: 'D_W',
  Desktop_Macintosh: 'D_M',
  Desktop_Linux: 'D_L',
  Mobile_Ios: 'M_I',
  Mobile_Android: 'M_A',
  Web_Standard: 'W_S',
  Web_Embedded: 'W_E',
};

interface IpatchFileDevicess {
  [key: string]: string;
  D_W: string;
  D_M: string;
  D_L: string;
  M_I: string;
  M_A: string;
  W_S: string;
  W_E: string;
}
export const patchFileDevicess: IpatchFileDevicess = {
  D_W: 'Desktop_Windows',
  D_M: 'Desktop_Macintosh',
  D_L: 'Desktop_Linux',
  M_I: 'Mobile_Ios',
  M_A: 'Mobile_Android',
  W_S: 'Web_Standard',
  W_E: 'Web_Embedded',
};

interface IpatchFileProduct {
  [key: string]: string;
  M: string;
  C: string;
  V: string;
  T: string;
}
export const patchFileProduct: IpatchFileProduct = {
  M: '메신저(소통)',
  C: '협업',
  V: '화상회의(WebRTC)',
  T: 'IPT(RCC)',
};

export const patchFileVersionRegEx = /^\d+\.\d+\.\d+$/;
