import { IpluralAxiosResponse } from '../../../interfaces/api/I-axios';
import {
  IfedListItem,
  IserviceProviderFedAcceptApiBody,
  IserviceProviderFedCancelApiBody,
  IserviceProviderFedListApiBody,
  IserviceProviderFedManageApiBody,
  IserviceProviderFedRejectApiBody,
  IserviceProviderFedRequestApiBody,
  IserviceProviderFedTenant,
  IserviceProviderFedTenantInfoApiBody,
  IserviceProviderFedTenantListApiBody,
  IspTenantListApiBody,
  IspTenantListReturnValue_desc_F,
  IspTenantListReturnValue_desc_T,
} from '../../../interfaces/api/service-provider/I-api-admin-serviceProvider-fed';
import { axiosErrorHandler, spAxiosInstance } from '../../axiosInstance';
import { store } from '../../../redux/store';
import { SP_SERVER } from '../../../const/server';

const adminServiceProviderFedInfo = {
  url: {
    list: `${SP_SERVER}/api/v1/admin-mg-sp/fed/list`,
    accept: `${SP_SERVER}/api/v1/admin-mg-sp/fed/accept`,
    manage: `${SP_SERVER}/api/v1/admin-mg-sp/fed/manage`,
    reject: `${SP_SERVER}/api/v1/admin-mg-sp/fed/reject`,
    request: `${SP_SERVER}/api/v1/admin-mg-sp/fed/request`,
    cancel: `${SP_SERVER}/api/v1/admin-mg-sp/fed/cancel`,
    tenantList: `${SP_SERVER}/api/v1/admin-mg-sp/fed/tenant/list`,
    tenantinfo: `${SP_SERVER}/api/v1/admin-mg-sp/fed/tenant/info`,
    spTenantList: `${SP_SERVER}/api/v1/admin-mg-sp/tenant/list`,
  },
};

// list 페더레이션 status 리스트
export const serviceProvider_fed_list_api = (
  props: IserviceProviderFedListApiBody,
) => {
  const { url } = adminServiceProviderFedInfo;
  return spAxiosInstance.post<IpluralAxiosResponse<IfedListItem>>(
    url.list,
    props,
  );
};

// accept 테넌트 연결 요청/해제 수락
export const servicepProvider_fed_accept_api = (
  props: IserviceProviderFedAcceptApiBody,
) => {
  const { url } = adminServiceProviderFedInfo;
  return spAxiosInstance.post(url.accept, props);
};

// manage 페더레이션 신청 허용/허용 안함
export const servicepProvider_fed_manage_api = (
  props: IserviceProviderFedManageApiBody,
) => {
  const { url } = adminServiceProviderFedInfo;
  return spAxiosInstance.post(url.manage, props);
};

// reject 요청 거부
export const servicepProvider_fed_reject_api = (
  props: IserviceProviderFedRejectApiBody,
) => {
  const { url } = adminServiceProviderFedInfo;
  return spAxiosInstance.post(url.reject, props);
};

// request 테넌트 연결/연결 해제 요청
export const servicepProvider_fed_request_api = (
  props: IserviceProviderFedRequestApiBody,
) => {
  const { url } = adminServiceProviderFedInfo;
  return spAxiosInstance.post(url.request, props);
};

// cancel 페더레이션 연결 신청 취소
export const servicepProvider_fed_cancel_api = (
  props: IserviceProviderFedCancelApiBody,
) => {
  const { url } = adminServiceProviderFedInfo;
  return spAxiosInstance.post(url.cancel, props);
};

// tenantList
export const servicepProvider_fed_tenantList_api = (
  props: IserviceProviderFedTenantListApiBody,
) => {
  const { url } = adminServiceProviderFedInfo;
  return spAxiosInstance.post<IpluralAxiosResponse<IserviceProviderFedTenant>>(
    url.tenantList,
    props,
  );
};

// tenant-info 테넌트 정보 조회
export const servicepProvider_fed_tenantInfo_api = (
  props: IserviceProviderFedTenantInfoApiBody,
) => {
  const { url } = adminServiceProviderFedInfo;
  return spAxiosInstance.post(url.tenantinfo, props);
};

// 워크스페이스 정보까지 끌어온 테넌트 정보
export const sp_tenantListApi_desc_F = (props: IspTenantListApiBody) => {
  const { url } = adminServiceProviderFedInfo;
  return spAxiosInstance.post<
    IpluralAxiosResponse<IspTenantListReturnValue_desc_F>
  >(url.spTenantList, { ...props, desc: false });
};

export const sp_tenantListApi_desc_T = (props: IspTenantListApiBody) => {
  const { url } = adminServiceProviderFedInfo;
  return spAxiosInstance.post<
    IpluralAxiosResponse<IspTenantListReturnValue_desc_T>
  >(url.spTenantList, { ...props, desc: true });
};
