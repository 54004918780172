import { Field, Form, Formik, FormikProps } from 'formik';
import Draggable from 'react-draggable';
import Button from '../../common/buttons/Button';
import InputTextFormik from '../../common/inputs/InputTextFormik';
import { CustomCell } from '../../../helpers/h-formik';
//import { autoScaleApplySchema } from '../../../helpers/h-formSchema';
import { IautoScaleApplysApiBody } from '../../../interfaces/api/service-provider/I-api-autoscale';
import { useAppDispatch } from '../../../redux/hooks';
import { autoScaleApply } from '../../../redux/slice/sp/autoScaleManagementSlice';

interface Props {
  show: boolean;
  setShow: () => void;
  title: string;
  tenantid: string;
}
const AutoScaleMng_apply_modal = ({
  show,
  setShow,
  title,
  tenantid,
}: Props) => {
  const dispatch = useAppDispatch();

  if (show) {
    return (
      <Draggable handle="#handle" positionOffset={{ x: '-50%', y: '-50%' }}>
        <div className="z-[999] min-w-[500px] min-h-[550px] fixed bg-white left-[50%] top-[50%] flex flex-col shadow-listbox-options rounded border border-solid border-borderLight">
          {/* HEADER */}
          <div
            id="handle"
            className="cursor-move py-defaultY px-defaultX font-bold"
          >
            <div className="py-defaultY">{title}</div>
          </div>
          {/* BODY */}
          <Formik
            initialValues={{
              replicasMin: '',
              replicasMax: '',
              behaviorUpPeriod: '',
              behaviorUpCount: '',
              behaviorDownPeriod: '',
              behaviorDownCount: '',
              metricAverage: '',
            }}
            //validationSchema={autoScaleApplySchema}
            onSubmit={async (values) => {
              // alert(JSON.stringify(values, null, 2));
              // console.log(values);

              const BODY: IautoScaleApplysApiBody = {
                meta: {
                  name: 'gateway',
                  namespace: `ucworks-${tenantid}`,
                },
                replicas: {
                  min: parseInt(values.replicasMin),
                  max: parseInt(values.replicasMax),
                },
                behavior: {
                  up: {
                    period: parseInt(values.behaviorUpPeriod),
                    count: parseInt(values.behaviorUpCount),
                  },
                  down: {
                    period: parseInt(values.behaviorDownPeriod),
                    count: parseInt(values.behaviorDownCount),
                  },
                },
                metric: {
                  average: parseInt(values.metricAverage),
                },
              };
              dispatch(autoScaleApply(BODY));
              setShow();
            }}
          >
            {(formik: FormikProps<any>) => {
              return (
                <Form className="grow px-defaultX w-full flex flex-col justify-between overflow-y-auto">
                  {/* 텍스트 */}
                  <div className="grow w-full flex flex-col gap-3 overflow-y-auto">
                    <CustomCell
                      title="최소 서비스 개수"
                      required
                      content={
                        <Field
                          component={InputTextFormik}
                          name={`replicasMin`}
                          value={`replicasMin`}
                          handleClear={() => {
                            formik.setFieldValue(`replicasMin`, '');
                          }}
                        />
                      }
                    />
                    <CustomCell
                      title="최대 서비스 개수"
                      required
                      content={
                        <Field
                          component={InputTextFormik}
                          name={`replicasMax`}
                          value={`replicasMax`}
                          handleClear={() => {
                            formik.setFieldValue(`replicasMax`, '');
                          }}
                        />
                      }
                    />
                    <CustomCell
                      title="scale up 시행 간격 (단위: 초)"
                      required
                      content={
                        <Field
                          component={InputTextFormik}
                          name={`behaviorUpPeriod`}
                          value={`behaviorUpPeriod`}
                          handleClear={() => {
                            formik.setFieldValue(`behaviorUpPeriod`, '');
                          }}
                        />
                      }
                    />
                    <CustomCell
                      title="scale up 시행 서비스 개수"
                      required
                      content={
                        <Field
                          component={InputTextFormik}
                          name={`behaviorUpCount`}
                          value={`behaviorUpCount`}
                          handleClear={() => {
                            formik.setFieldValue(`behaviorUpCount`, '');
                          }}
                        />
                      }
                    />
                    <CustomCell
                      title="scale down 시행 간격 (단위: 초)"
                      required
                      content={
                        <Field
                          component={InputTextFormik}
                          name={`behaviorDownPeriod`}
                          value={`behaviorDownPeriod`}
                          handleClear={() => {
                            formik.setFieldValue(`behaviorDownPeriod`, '');
                          }}
                        />
                      }
                    />
                    <CustomCell
                      title="scale down 시행 서비스 개수"
                      required
                      content={
                        <Field
                          component={InputTextFormik}
                          name={`behaviorDownCount`}
                          value={`behaviorDownCount`}
                          handleClear={() => {
                            formik.setFieldValue(`behaviorDownCount`, '');
                          }}
                        />
                      }
                    />
                    <CustomCell
                      title="scaling 기준 metric (CPU, 단위%)"
                      required
                      content={
                        <Field
                          component={InputTextFormik}
                          name={`metricAverage`}
                          value={`metricAverage`}
                          handleClear={() => {
                            formik.setFieldValue(`metricAverage`, '');
                          }}
                        />
                      }
                    />
                  </div>
                  {/* 취소 / 저장 버튼 */}
                  <div className="min-h-[6rem] w-full  jcac">
                    <div className="flex gap-5">
                      <Button onClick={setShow} kind="cancel" label="취소" />
                      <Button
                        onClick={() => {
                          formik.handleSubmit();
                        }}
                        kind="primary"
                        label="추가"
                      />
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Draggable>
    );
  }

  return null;
};

export default AutoScaleMng_apply_modal;
