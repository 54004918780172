import { t } from 'i18next';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { IAdminCounselCategoryListRes } from '../../../interfaces/api/counsel/I-api-admin-counsel-category';
import CounselingCategoryContextMenu from '../../context-menu/CounselingCategoryContextMenu';
import ConfirmModal from '../../react-modal/ConfirmModal';
import { reqDeleteCategory } from '../../../helpers/h-counselingMng';
import { setSelectedCategory, clearSelectedCategory } from '../../../redux/slice/counselingManagementSlice';
import { RootState } from '../../../redux/store';
import { openCounselingCategoryModal } from '../../../helpers/h-react-modals';

interface Props {
  categories: IAdminCounselCategoryListRes[];
  onDragEnd: (result: DropResult) => void;
}

const CounselingCategories: React.FC<Props> = ({ categories, onDragEnd }) => {
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({ top: 0, left: 0 });
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const selectedCategoryId = useSelector((state: RootState) => state.counselingManagement.selectedCategoryId);
  const dispatch = useDispatch();

  const contextMenuRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleContextMenu = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, id: string, name: string) => {
    event.preventDefault();
    setShowContextMenu(true);
    dispatch(setSelectedCategory({ id, name }));
    setContextMenuPosition({ top: event.pageY, left: event.pageX });
  };

  const closeContextMenu = () => {
    setShowContextMenu(false);
  };

  const handleEdit = () => {
    openCounselingCategoryModal();
    closeContextMenu();
  };

  const handleDelete = () => {
    setShowConfirmModal(true);
    closeContextMenu();
  };

  const handleConfirmDelete = () => {
    if (!selectedCategoryId) return;
    setShowConfirmModal(false);
    reqDeleteCategory(selectedCategoryId);
  };

  const handleCancelDelete = () => {
    setShowConfirmModal(false);
  };

  const handleClearSelection = () => {
    dispatch(clearSelectedCategory());
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        contextMenuRef.current &&
        !contextMenuRef.current.contains(event.target as Node) &&
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setShowContextMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="p-2 category-list-container" ref={containerRef}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="categories">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef} className="h-full">
              <ul className="min-h-full">
                {categories.map((category, index) => (
                  <Draggable key={category.id} draggableId={category.id} index={index}>
                    {(provided, snapshot) => (
                      <li
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        onClick={(e) => {
                          e.stopPropagation();
                          dispatch(setSelectedCategory({ id: category.id, name: category.name }));
                        }}
                        onContextMenu={(e) => handleContextMenu(e, category.id, category.name)}
                        className={`py-2 px-3 text-sm text-gray-800 rounded cursor-pointer ${
                          selectedCategoryId === category.id ? 'bg-[#e9f0f8]' : ''
                        } ${snapshot.isDragging ? 'bg-blue-100' : ''}`}
                      >
                        {category.name}
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
              <div className="droppable-empty-area flex-1" />
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {showContextMenu && (
        <div ref={contextMenuRef}>
          <CounselingCategoryContextMenu
            top={contextMenuPosition.top}
            left={contextMenuPosition.left}
            show={showContextMenu}
            selectedNodeId={selectedCategoryId}
            onClose={closeContextMenu}
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
        </div>
      )}
      <ConfirmModal
        label={
          <>
            {t('CounselingManagement.categoryDelDesc1') as string} <br />
            {t('CounselingManagement.categoryDelDesc2') as string}
          </>
        }
        isOpen={showConfirmModal}
        onConfirm={handleConfirmDelete}
        onClose={handleCancelDelete}
      />
    </div>
  );
};

export default CounselingCategories;
