import { IadminRuleUser } from './../interfaces/api/rule/I-api-admin-rule-user';
import { adminRuleUserListApi } from './../api/admin/rule/api-admin-rule-user';
import { store } from '../redux/store';
import { hrsUserListApi } from '../api/hrs/api-hrs-users';
import {
  addRuleUser,
  removeRuleUser,
} from '../redux/slice/ruleFunctionManagementSlice';
import {
  IhrsUserListApiResponseFull,
  IHrsUsersListApiBody,
  IUserInfo,
} from '../interfaces/api/I-api-hrs-users';
import { toast } from 'react-toastify';
import axios from 'axios';
const dispatch = store.dispatch;

// rule/user
// list
export const getRuleUserListHandler = async (rulecode: string) => {
  try {
    const res = await adminRuleUserListApi({ rulecode });
    if (!res) return;
    const ruleUserList = res?.data.data.result;
    if (ruleUserList.length > 0) {
      return ruleUserList;
    } else {
      return [];
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log('error: ', error);
    } else {
      console.log('unexpected error: ', error);
    }
  }
};

export const getUsersByBids = async (bid: { bid: string }[]) => {
  try {
    const {
      user: { usersid },
    } = store.getState();

    if (!usersid) {
      toast.error('check getUsersByBids from h-ruleDesignation ');
      return;
    }

    const body: IHrsUsersListApiBody = {
      cmd: '1101',
      sid: usersid,
      who: bid,
      fields: 'FULL',
      language: 'ko',
    };
    const res = await hrsUserListApi(body);
    if (!res) return;

    const userList = res.data.data.result;

    if (userList.length > 0) {
      return userList;
    } else {
      return [];
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log('error: ', error);
    } else {
      console.log('unexpected error: ', error);
    }
  }
};
export const changeToBidObjArray = (ruleUserList: IadminRuleUser[]) => {
  const bidObjArray = ruleUserList.map((ruleUser) => ({
    bid: ruleUser.bid,
  }));
  return bidObjArray;
};

// add
export const handleAddUserToRule = (
  selectedUsers: IUserInfo[],
  rulecode: string,
) => {
  const body = selectedUsers.map((users) => ({
    bid: users.bid,
    rulecode,
    rulegubun: '0',
    ruleorder: 0,
  }));
  dispatch(addRuleUser(body));
};

// remove
export const handleRemoveUserFromRule = (
  selectedUsers: IhrsUserListApiResponseFull[],
  rulecode: string,
) => {
  const body = selectedUsers.map((user) => ({
    bid: user.bid,
    rulecode,
  }));
  dispatch(removeRuleUser(body));
};

// modify

// info
