import { ColDef } from 'ag-grid-community';
import { dateValueGetter } from '../../../helpers/h-agGrid';
import {
  IserviceProviderFedTenant,
  IspTenantListReturnValue_desc_F,
} from '../../../interfaces/api/service-provider/I-api-admin-serviceProvider-fed';
import { AgCellTextCenter } from '../../common/AgCustomCell';

//export const COMMON_TENANT_LIST_TABLE_COL: ColDef<IspTenantListReturnValue_desc_F>[] =
export const COMMON_TENANT_LIST_TABLE_COL  = (t: (key: string) => string) => {
  return [ 
    {
      field: 'tenantid',
      headerName: t('AutoScaleManagement.h-leftTenantId'),
      // headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<IserviceProviderFedTenant>,
    },
    {
      field: 'name',
      headerName: t('AutoScaleManagement.h-leftTenantName'),
      // headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<IserviceProviderFedTenant>,
    },
    {
      field: 'updatetime',
      headerName: t('AutoScaleManagement.h-leftUpdateTime'),
      valueGetter: dateValueGetter,
      // headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<IserviceProviderFedTenant>,
    },
  ];
};