import axios from 'axios';
import {
  IAdminUserAddApiBody,
  IAdminUserAddApiBodyV2,
  IAdminUserListApiBody,
  IadminUserListApiMultiNameResponse,
  IadminUserListApiResponse,
  IadminUserModifyApiBodyForOrgDragNDrop,
} from '../../interfaces/api/I-api-admin-users';
import { IpluralAxiosResponse } from '../../interfaces/api/I-axios';
import { axiosInstance } from '../axiosInstance';
import { SERVER_SUFFIX } from '../../const/server';

const adminUserInfo = {
  url: {
    list: '/api/v1/admin-mg-tp/user/list',
    add: '/api/v1/admin-mg-tp/user/add',
    remove: '/api/v1/admin-mg-tp/user/remove',
    modify: '/api/v1/admin-mg-tp/user/modify',
  },
  body: {
    list: {
      language: 'ko',
      pagesize: 50,
      page: 1,
    },
  },
};

// 조직/사용자 관리 userlist
export const userListApi = ({ page, pagesize }: IAdminUserListApiBody) => {
  const { url, body } = adminUserInfo;
  return axiosInstance.post<
    IpluralAxiosResponse<IadminUserListApiMultiNameResponse>
  >(url.list, { ...body.list, page, pagesize, withadmin: true });
};

// 관리자 권한 관리 userlist
export const adminUserListApi = ({ page, pagesize }: IAdminUserListApiBody) => {
  const { url, body } = adminUserInfo;
  return axiosInstance.post<IpluralAxiosResponse<IadminUserListApiResponse>>(
    url.list,
    { ...body.list, page, withadmin: false },
  );
};

export const adminUserListApiForSp = ({
  page,
  tenantid,
}: {
  page: number;
  tenantid: string;
}) => {
  const { url, body } = adminUserInfo;
  return axiosInstance.post<IpluralAxiosResponse<IadminUserListApiResponse>>(
    `https://${tenantid}${SERVER_SUFFIX}${url.list}`,
    {
      ...body.list,
      page,
    },
  );
};

// add
interface AdminUserAddApiRes {
  code: number;
  data: {
    result: {
      success: boolean;
      count: number;
    };
  };
}
export const adminUserAddApi = (body: IAdminUserAddApiBody) => {
  const { url } = adminUserInfo;
  return axiosInstance.post<AdminUserAddApiRes>(url.add, body);
};

// remove userlist: [{ bid: string }]
export const adminUserRemoveApi = (body: { userlist: { bid: string }[] }) => {
  const { url } = adminUserInfo;
  return axiosInstance.post(url.remove, body);
};

// modify
export const adminUserModifyApi = (body: IAdminUserAddApiBody) => {
  const { url } = adminUserInfo;
  return axiosInstance.post(url.modify, body);
};

export const adminUserModifyApiForOrgDragNDrop = (
  body: IadminUserModifyApiBodyForOrgDragNDrop,
) => {
  console.log(JSON.stringify(body));
  const { url } = adminUserInfo;
  return axiosInstance.post(url.modify, body);
};

// 겸직 모델추가  modify
export const adminUserModifyApiV2 = (body: IAdminUserAddApiBodyV2) => {
  const { url } = adminUserInfo;
  return axiosInstance.post(url.modify, body);
};
