import Arrow from '../../../assets/svg/arrows/Arrow';
import Button from '../../common/buttons/Button';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  addCntBtnToBtnGroupHandler,
  addCtnHandler,
  modifyCtnHandler,
  removeCntBtnHandler,
} from '../../../helpers/h-cntBtnMng';
import { initSelectedCntBtn } from '../../../redux/slice/countButtonManagementSlice';
import { toast } from 'react-toastify';

interface Props {
  onClick: () => void;
}

const DownTopBar = ({ onClick }: Props) => {
  const dispatch = useAppDispatch();
  const { selectedCntBtn, selectedCntBtnGroup } = useAppSelector(
    (state) => state.countButtonManagement,
  );

  return (
    <div className="py-[0.563rem] flex justify-between text-fontColor-default">
      <div className="flex gap-1">
        <Button
          kind="cancel"
          label="추가"
          onClick={() => {
            addCtnHandler(selectedCntBtn);
            // dispatch(initSelectedCntBtn());
          }}
        />
        <Button
          kind="cancel"
          label="수정"
          onClick={() => {
            modifyCtnHandler(selectedCntBtn);
            // dispatch(initSelectedCntBtn());
          }}
        />
        <Button
          kind="cancel"
          label="삭제"
          onClick={() => {
            if (selectedCntBtn.btncode.length < 1) {
              toast.error('버튼을 선택해주세요');
              return;
            }
            removeCntBtnHandler(selectedCntBtn);
            dispatch(initSelectedCntBtn());
          }}
        />
        <Button
          kind="cancel"
          label="버튼 그룹에 버튼 추가"
          onClick={() => {
            addCntBtnToBtnGroupHandler(selectedCntBtn, selectedCntBtnGroup);
            // dispatch(initSelectedCntBtn());
          }}
        />
        {/* <Button kind="cancel" label="버튼 리스트 엑셀 다운로드" /> */}
      </div>
      <Arrow onClick={onClick} direction="down" />
    </div>
  );
};

export default DownTopBar;
