import React from 'react';
import Arrow from '../../../assets/svg/arrows/Arrow';
import Button from '../../common/buttons/Button';
import { openRuleFuncOptionModal } from '../../../helpers/h-modals';

interface Props {
  onClick: () => void;
}

const DownTopBar = ({ onClick }: Props) => {
  return (
    <div className="py-[0.563rem] flex justify-between text-fontColor-default">
      <Button
        label="옵션 수정"
        kind="cancel"
        onClick={openRuleFuncOptionModal}
      />
      <Arrow onClick={onClick} direction="down" />
    </div>
  );
};

export default DownTopBar;
