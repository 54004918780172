import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  spAutoScaleApplyApi,
  spAutoScaleDeleteApi,
} from '../../../api/admin/service-provider/api-autoScale';
import {
  IautoScaleApplysApiBody,
  IautoScaleDeleteApiBody,
} from '../../../interfaces/api/service-provider/I-api-autoscale';
import axios from 'axios';

interface InitialState {
  autoScaleApllyStatus: 'idle' | 'pending' | 'success' | 'fail';
  autoScaleDeleteStatus: 'idle' | 'pending' | 'success' | 'fail';
}

const initialState: InitialState = {
  autoScaleApllyStatus: 'idle',
  autoScaleDeleteStatus: 'idle',
};

// patch/upload
export const autoScaleApply = createAsyncThunk(
  'autoScale/apply',
  async (body: IautoScaleApplysApiBody, { rejectWithValue }) => {
    try {
      const response = await spAutoScaleApplyApi(body);

      if (response?.status === 200) {
        toast.success('성공');
      }

      return response?.data.data.result;
    } catch (error) {
      toast.error('실패');
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
        return rejectWithValue(error.message);
      } else {
        console.log('unexpected error: ', error);
        return rejectWithValue('unexpected error');
      }
    }
  },
);

// patch/delete
export const autoScaleDelete = createAsyncThunk(
  'autoScale/delete',
  async (body: IautoScaleDeleteApiBody, { rejectWithValue }) => {
    try {
      const response = await spAutoScaleDeleteApi(body);

      if (response?.status === 200) {
        toast.success('성공');
      }

      return response?.data.data.result;
    } catch (error) {
      toast.error('실패');
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
        return rejectWithValue(error.message);
      } else {
        console.log('unexpected error: ', error);
        return rejectWithValue('unexpected error');
      }
    }
  },
);

export const autoScaleManagementSlice = createSlice({
  name: 'autoScaleManagement',
  initialState,
  reducers: {
    initAutoScaleManagementSlice: () => initialState,
  },
  extraReducers(builder) {
    builder
      // apply
      .addCase(autoScaleApply.pending, (state) => {
        state.autoScaleApllyStatus = 'pending';
      })
      .addCase(autoScaleApply.fulfilled, (state) => {
        state.autoScaleApllyStatus = 'success';
      })
      .addCase(autoScaleApply.rejected, (state) => {
        state.autoScaleApllyStatus = 'fail';
      })
      // delete
      .addCase(autoScaleDelete.pending, (state) => {
        state.autoScaleDeleteStatus = 'pending';
      })
      .addCase(autoScaleDelete.fulfilled, (state) => {
        state.autoScaleDeleteStatus = 'success';
      })
      .addCase(autoScaleDelete.rejected, (state) => {
        state.autoScaleDeleteStatus = 'fail';
      });
  },
});

export const { initAutoScaleManagementSlice } =
  autoScaleManagementSlice.actions;

export default autoScaleManagementSlice.reducer;
