import React, { useState } from 'react';
import { SelectListValue } from '../../../interfaces/TypeSelectListbox';
// 컴포넌트
import Button from '../../common/buttons/Button';
import DoubleLeftArrow from '../../../assets/svg/arrows/DoubleLeftArrow';
import DoubleRightArrow from '../../../assets/svg/arrows/DoubleRightArrow';
import LeftArrow from '../../../assets/svg/arrows/LeftArrow';
import RightArrow from '../../../assets/svg/arrows/RightArrow';
import SelectListbox from '../../common/selectList/SelectListbox';

const selectValues: SelectListValue[] = [
  { id: '1', name: '25' },
  { id: '2', name: '60' },
  { id: '3', name: '180' },
];

const NavBar = () => {
  const [selected, setSelected] = useState(selectValues[0]);

  return (
    <div className="flex justify-end gap-5 pb-defaultY ">
      <div className="flex gap-3 justify-centet items-center">
        <div className="flex gap-1">
          <DoubleLeftArrow />
          <LeftArrow />
          <RightArrow />
          <DoubleRightArrow />
        </div>
        <div className="truncate">4/18</div>
      </div>
      <div className="flex gap-3 items-center">
        <span className="flex justify-centet items-center truncate">
          열 개수
        </span>
        <SelectListbox
          options={selectValues}
          value={selected}
          optionsHeight={12}
          // width={'5rem'}
          onChange={(value) => {
            setSelected(value);
          }}
        />
        <Button kind="cancel" label="엑셀 다운로드" />
      </div>
    </div>
  );
};

export default NavBar;
