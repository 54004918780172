import { t } from 'i18next';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
// 라이브러리
import { SplitPane } from 'react-multi-split-pane';
import { useResizeDetector } from 'react-resize-detector';

// 컴포넌트

import {
  handleViewPortSize,
  PaneProps,
  splitPaneViewportCase,
} from '../../../helpers/h-splitPane';
import { IspTenantListReturnValue_desc_F } from '../../../interfaces/api/service-provider/I-api-admin-serviceProvider-fed';
import MainPageTitleHeader from '../../common/MainPageTitleHeader';
import TenantList_Table from '../../tables/common/TenantList_Table';
import AutoScaleManagement_list_table from '../../tables/sp/autoScaleManagement/AutoScaleManagement_list_table';

interface IleftPane extends PaneProps {
  setSelectedTenant: Dispatch<
    SetStateAction<IspTenantListReturnValue_desc_F | null>
  >;
}
interface IRightPane extends PaneProps {
  selectedTenant: IspTenantListReturnValue_desc_F | null;
}
// 테넌트 관리
const AutoScaleManagement = () => {
  const [splitPaneViewportKey, setSplitPaneViewportKey] = useState(
    splitPaneViewportCase.default,
  );
  const [selectedTenant, setSelectedTenant] =
    useState<IspTenantListReturnValue_desc_F | null>(null);

  return (
    <div className="h-full w-full">
      <SplitPane
        className="custom"
        split="vertical"
        defaultSizes={[3, 5]}
        minSize={[50, 50]}
      >
        <Left
          setSplitPaneViewportKey={setSplitPaneViewportKey}
          splitPaneUpdatedState={splitPaneViewportKey}
          setSelectedTenant={setSelectedTenant}
        />
        <Right
          setSplitPaneViewportKey={setSplitPaneViewportKey}
          splitPaneUpdatedState={splitPaneViewportKey}
          selectedTenant={selectedTenant}
        />
      </SplitPane>
    </div>
  );
};

const Left = ({
  setSplitPaneViewportKey,
  splitPaneUpdatedState,
  setSelectedTenant,
}: IleftPane) => {
  const { width, ref } = useResizeDetector();

  useEffect(() => {
    ref.current.parentElement.style.flex = splitPaneUpdatedState.left;
  }, [splitPaneUpdatedState]);

  const splitPaneHandler = () => {
    handleViewPortSize([setSplitPaneViewportKey, 'clickedLeft']);
  };

  return (
    <div
      ref={ref}
      className="w-full flex flex-col px-6 border border-solid bg-white border-[#dfe2e8] rounded"
    >
      <MainPageTitleHeader
        onClick={splitPaneHandler}
        text={t('AutoScaleManagement.tenantList')}
        direction="left"
      />
      <TenantList_Table setSelectedTenant={setSelectedTenant} />
    </div>
  );
};

const Right = ({
  setSplitPaneViewportKey,
  splitPaneUpdatedState,
  selectedTenant,
}: IRightPane) => {
  const { width, ref } = useResizeDetector();

  const splitPaneHandler = () =>
    handleViewPortSize([setSplitPaneViewportKey, 'clickedRight']);

  return (
    <div
      ref={ref}
      className="w-full flex flex-col h-full px-6 border border-solid border-[#dfe2e8] rounded bg-white"
    >
      <MainPageTitleHeader
        onClick={splitPaneHandler}
        text={t('AutoScaleManagement.autoScaleList')}
        direction="right"
      />
      <AutoScaleManagement_list_table selectedTenant={selectedTenant} />
    </div>
  );
};

export default AutoScaleManagement;
