import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { CellClickedEvent, ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  IfedTenantListItem,
  IserviceProviderFedTenant,
} from '../../../interfaces/api/service-provider/I-api-admin-serviceProvider-fed';
import { useAppSelector } from '../../../redux/hooks';
import { FEDERATION_connectApply_TABLE_COLUMN } from './columns';

interface Props {
  setSelectedTenant: (tenant: IfedTenantListItem) => void;
}
const Federation_connectApply_Table = ({ setSelectedTenant }: Props) => {
  const { t } = useTranslation();
  const { tenantList } = useAppSelector((state) => state.federatinManagement);

  // AG_GRID
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
  // const [columnDefs, setColumnDefs] = useState<ColDef[]>(
  //   FEDERATION_connectApply_TABLE_COLUMN,
  // );
  const [rowData, setRowData] = useState<IserviceProviderFedTenant[]>([]);
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      resizable: true,
      minWidth: 100,
    };
  }, []);

  const cellClickedListener = useCallback(
    (event: CellClickedEvent<IfedTenantListItem>) => {
      // console.log('cellClicked', event);

      const tenant = event.data;

      if (!tenant) return;

      setSelectedTenant(tenant);
    },
    [],
  );

  // set ROWDATA
  useEffect(() => {
    if (tenantList.length < 1) {
      setRowData([]);
      return;
    } else {
      setRowData(tenantList);
    }
  }, [tenantList]);

  useEffect(() => {
    setColumnDefs(FEDERATION_connectApply_TABLE_COLUMN(t));
  }, [t]);

  return (
    <div className="w-full grow pb-2 flex flex-col overflow-hidden">
      <div className="ag-theme-alpine ag-theme-mycustomtheme w-full grow">
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onCellClicked={cellClickedListener}
          rowSelection={'single'}
          overlayNoRowsTemplate={'\xa0'}
        ></AgGridReact>
      </div>
    </div>
  );
};

export default Federation_connectApply_Table;
