import { ColDef, ValueGetterParams } from 'ag-grid-community';
import {
  patchFileDevicess,
  patchFileProduct,
} from '../../../../const/sp/upgradeManagement';
import { dateValueGetter } from '../../../../helpers/h-agGrid';
import { IclientPatchListApiResponse } from '../../../../interfaces/api/client/I-api-admin-client';
import { IspUksGetsApiResponse_deployment } from '../../../../interfaces/api/service-provider/I-api-admin-sp-uks';
import { AgCellTextCenter } from '../../../common/AgCustomCell';

//export const UPGRADE_MNG_Version_LIST_TABLE_COL: ColDef<IspUksGetsApiResponse_deployment>[] =
export const UPGRADE_MNG_Version_LIST_TABLE_COL  = (t: (key: string) => string) => {
  return [  
    {
      field: 'name',
      headerName: t('UpgradeManagement.h-upperServiceName'),
      cellRenderer: AgCellTextCenter<IclientPatchListApiResponse>,
    },
    {
      field: 'upgradable',
      headerName: t('UpgradeManagement.h-upperUpgradable'),
      valueGetter: (params: { data: { upgradable: any; }; }) => {
        if (!params.data) return;

        if (params.data.upgradable) {
          return 'O';
        } else {
          return 'X';
        }
      },
      cellClassRules: {
        'ag-green': (params: { value: string; }) => params.value === 'O',
        'ag-red': (params: { value: string; }) => params.value === 'X',
      },
      cellRenderer: AgCellTextCenter<IclientPatchListApiResponse>,
    },
    {
      field: 'version.current',
      headerName: t('UpgradeManagement.h-upperCurrentVer'),
      cellRenderer: AgCellTextCenter<IclientPatchListApiResponse>,
    },
    {
      field: 'version.latest',
      headerName: t('UpgradeManagement.h-upperLatestVer'),
      cellRenderer: AgCellTextCenter<IclientPatchListApiResponse>,
    },
  ];
};

//export const UPGRADE_MNG_PATCH_LIST_TABLE_COL: ColDef<IclientPatchListApiResponse>[] =
export const UPGRADE_MNG_PATCH_LIST_TABLE_COL  = (t: (key: string) => string) => {
  return [ 
    {
      field: 'url',
      valueGetter: (params: ValueGetterParams<IclientPatchListApiResponse>) => {
        if (!params.data) return;
        const ver = params.data.url.split('/')[4];
        return ver;
      },
      headerName: t('UpgradeManagement.h-downVersion'),
      cellRenderer: AgCellTextCenter<IclientPatchListApiResponse>,
    },
    {
      field: 'url',
      valueGetter: (params: ValueGetterParams<IclientPatchListApiResponse>) => {
        if (!params.data) return;
        const product = params.data.url.split('/')[5].split('')[0];
        return patchFileProduct[product];
      },
      headerName: t('UpgradeManagement.h-downProductCode'),
      cellRenderer: AgCellTextCenter<IclientPatchListApiResponse>,
    },
    {
      field: 'url',
      valueGetter: (params: ValueGetterParams<IclientPatchListApiResponse>) => {
        if (!params.data) return;
        const [p, d, s] = params.data.url.split('/')[5].split('');
        const deviceType = [d.toUpperCase(), s.toUpperCase()].join('_');
        return patchFileDevicess[deviceType];
      },
      headerName: t('UpgradeManagement.h-downDeviceType'),
      cellRenderer: AgCellTextCenter<IclientPatchListApiResponse>,
    },
    // {
    //   field: 'groupid',
    //   headerName: '그룹 아이디',
    //   cellRenderer: AgCellTextCenter<IclientPatchListApiResponse>,
    // },
    // {
    //   field: 'patchid',
    //   headerName: '패치 아이디',
    //   cellRenderer: AgCellTextCenter<IclientPatchListApiResponse>,
    // },
    {
      field: 'filename',
      headerName: t('UpgradeManagement.h-downFileName'),
      cellRenderer: AgCellTextCenter<IclientPatchListApiResponse>,
    },
    {
      field: 'filesize',
      headerName:  t('UpgradeManagement.h-downFileSize'),
      valueGetter: (v: { data: { filesize: string; }; }) => {
        if (!v.data) return;
        const mbFormat = parseInt(v.data.filesize) / 1024 / 1024;
        const mbFormatToFixed = mbFormat.toFixed(2);
        return `${mbFormatToFixed}MB`;
      },
      cellRenderer: AgCellTextCenter<IclientPatchListApiResponse>,
    },
    {
      field: 'url',
      headerName: t('UpgradeManagement.h-downFilePath'),
      cellRenderer: AgCellTextCenter<IclientPatchListApiResponse>,
    },
    {
      field: 'updatetime',
      headerName: t('UpgradeManagement.h-downRegistDate'),
      valueGetter: dateValueGetter,
      cellRenderer: AgCellTextCenter<IclientPatchListApiResponse>,
    },
  ];
};
