import { useTranslation } from 'react-i18next';
import {
  ColDef,
  GridApi,
  GridReadyEvent,
  RowDoubleClickedEvent,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useEffect, useMemo, useState } from 'react';
import { adminClientPatchListApi } from '../../../../api/admin/client/api-admin-client';
import Button from '../../../common/buttons/Button';
import { IclientPatchListApiResponse } from '../../../../interfaces/api/client/I-api-admin-client';
import { IspTenantListReturnValue_desc_T } from '../../../../interfaces/api/service-provider/I-api-admin-serviceProvider-fed';
import { useAppSelector } from '../../../../redux/hooks';
import UpgradeMng_patchUpload_modal from '../../../modals/sp/UpgradeMng_patchUpload_modal';
import InformaionModal from '../../../modals/tenantMng/InformaionModal';
import { UPGRADE_MNG_PATCH_LIST_TABLE_COL } from './columns';
import axios from 'axios';

interface Props {
  selectedTenant: IspTenantListReturnValue_desc_T | null;
}
const UpgradeManagement_PatchList_Table = ({ selectedTenant }: Props) => {
  const { clientPatchUploadStatus } = useAppSelector(
    (state) => state.upgradeManagement,
  );
  const [modalData, setModalData] = useState<[string, string][] | null>(null);
  const [show, setShow] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const { t } = useTranslation();

  // AG_GRID
  const [gridApi, setGridApi] =
    useState<GridApi<IclientPatchListApiResponse[]>>();
  const [rowData, setRowData] = useState<IclientPatchListApiResponse[]>([]);
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
  // const [columnDefs, setColumnDefs] = useState<ColDef[]>(
  //   UPGRADE_MNG_PATCH_LIST_TABLE_COL,
  // );
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      resizable: true,
      minWidth: 100,
      // sortable: true,
      // filter: true,
      // editable: true,
    };
  }, []);

  const togleModal = () => {
    setShow((state) => !state);
  };
  const toggleUploadModal = () => {
    setShowUploadModal((state) => !state);
  };

  const onGridReady = (
    params: GridReadyEvent<IclientPatchListApiResponse[]>,
  ) => {
    setGridApi(params.api);
  };

  const onRowDoubleClicked = (
    event: RowDoubleClickedEvent<IclientPatchListApiResponse>,
  ) => {
    if (event.data) {
      const dataForModal = Object.entries(event.data);
      setModalData(dataForModal);
      togleModal();
    }
  };

  const fetchRowData = async (tenantid: string) => {
    try {
      const clientPatchList = await adminClientPatchListApi({
        tenantid,
        page: 1,
        pagesize: 100,
      });
      if (!clientPatchList) return;

      const data = clientPatchList.data.data.result;
      console.log(data);
      setRowData(data);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
        return error;
      } else {
        console.log('unexpected error: ', error);
        return 'An unexpected error occurred';
      }
    }
  };

  useEffect(() => {
    if (!gridApi || !selectedTenant) return;
  }, [selectedTenant, gridApi]);

  useEffect(() => {
    if (!selectedTenant) return;

    fetchRowData(selectedTenant.tenantid);
  }, [selectedTenant]);

  useEffect(() => {
    if (!selectedTenant) return;
    if (clientPatchUploadStatus !== 'success') return;

    fetchRowData(selectedTenant.tenantid);
  }, [clientPatchUploadStatus]);

  useEffect(() => {
    setColumnDefs(UPGRADE_MNG_PATCH_LIST_TABLE_COL(t));
  }, [t]);

  return (
    <div className="w-full grow flex flex-col pb-defaultY overflow-hidden">
      {/* 탑 바 */}
      {selectedTenant && (
        <div className="pb-[0.563rem] gap-1 flex justify-end text-fontColor-default">
          <div>
            <Button kind="cancel" label={t('UpgradeManagement.upload')} onClick={toggleUploadModal} />
          </div>
        </div>
      )}
      {/* 테이블 */}
      <div className="ag-theme-alpine ag-theme-mycustomtheme grow w-full">
        <AgGridReact
          columnDefs={columnDefs}
          rowData={rowData}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          rowSelection={'single'}
          onRowDoubleClicked={onRowDoubleClicked}
          overlayNoRowsTemplate={'\xa0'}
        ></AgGridReact>
        {modalData && (
          <InformaionModal
            show={show}
            setShow={togleModal}
            data={modalData}
            title={t('UpgradeManagement.fileDetails')}
          />
        )}
        {selectedTenant && (
          <UpgradeMng_patchUpload_modal
            show={showUploadModal}
            setShow={toggleUploadModal}
            tenantid={selectedTenant.tenantid}
            title={t('UpgradeManagement.envFileUpload')}
          />
        )}
      </div>
    </div>
  );
};

export default UpgradeManagement_PatchList_Table;
