import React from 'react';
import Lists from './Lists';
import { dummyData } from './data';
import ListTitle from '../../common/ListTitle';

const GroupList = () => {
  return (
    <div className="flex flex-col h-[calc(100%_-_5.25rem)]">
      {/* 리스트 제목 */}
      <ListTitle titles={['그룹 코드', '그룹 이름', '그룹 캡션', '도메인']} />
      {/* 리스트 내용 */}
      <div className="overflow-auto h-full">
        {dummyData.map((d, i) => (
          <Lists key={i} data={d} index={i} />
        ))}
      </div>
    </div>
  );
};

export default GroupList;
