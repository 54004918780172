import { getMyTenantId, getOrgCode } from './../../helpers/h-userInfo';
import {
  IroleAddApiBody,
  IroleAddApiResponse,
  IroleInfoApiResponse,
  IroleListApiBody,
  IroleListApiListResponse,
  IroleModifyApiBody,
  IroleRemoveApiBody,
} from '../../interfaces/api/I-api-admin-role';
import { IpluralAxiosResponse, IsingularAxiosResponse } from '../../interfaces/api/I-axios';
import { axiosInstance } from '../axiosInstance';

const adminRoleInfo = {
  url: {
    list: '/api/v1/admin-mg-tp/role/list',
    info: '/api/v1/admin-mg-tp/role/info',
    add: '/api/v1/admin-mg-tp/role/add',
    remove: '/api/v1/admin-mg-tp/role/remove',
    modify: '/api/v1/admin-mg-tp/role/modify',
  },
};

// list
export const adminRoleListApi = (props: IroleListApiBody) => {
  const { url } = adminRoleInfo;
  const tenantid = getMyTenantId();
  const orgcode = getOrgCode();
  return axiosInstance.post<IpluralAxiosResponse<IroleListApiListResponse>>(url.list, {
    ...props,
    tenantid: tenantid ? tenantid : '',
    orgcode: orgcode ? orgcode : '',
  });
};

// info
export const adminRoleInfoApi = (props: { bid: string; role: string }) => {
  const { url } = adminRoleInfo;

  return axiosInstance.post<IsingularAxiosResponse<IroleInfoApiResponse>>(url.info, props);
};

// add
export const adminRoleAddApi = (props: IroleAddApiBody) => {
  const { url } = adminRoleInfo;

  return axiosInstance.post<IsingularAxiosResponse<IroleAddApiResponse>>(url.add, props);
};

// modify
export const adminRoleModifyApi = (props: IroleModifyApiBody) => {
  const { url } = adminRoleInfo;

  return axiosInstance.post<IsingularAxiosResponse<IroleAddApiResponse>>(url.modify, props);
};

// remove
export const adminRoleRemoveApi = (props: IroleRemoveApiBody) => {
  const { url } = adminRoleInfo;

  return axiosInstance.post<IsingularAxiosResponse<IroleAddApiResponse>>(url.remove, props);
};
