import { t } from 'i18next';
import { ColDef, IDatasource, GridApi, GridReadyEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { adminRoleListApi } from '../../../api/admin/api-amin-role';
import { IroleListApiListResponse } from '../../../interfaces/api/I-api-admin-role';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import RightTopBar from '../../mainPage/admin_id_management/RightTopBar';
import { USER_LIST_TALBLE_COLUMN } from './columns';
import { useSelector } from 'react-redux';

const AdminIdMngAdminUserList_Table = () => {
  const dispatch = useAppDispatch();
  const { adminRoleStatus, adminRoleAddStatus } = useAppSelector((state) => state.adminIdManagement);
  const [selectedUsers, setSelectedUsers] = useState<IroleListApiListResponse[]>([]);
  // AG_GRID
  const [gridApi, setGridApi] = useState<GridApi<IroleListApiListResponse[]>>();
  const gridRef = useRef<any>();
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      resizable: true,
      minWidth: 100,
    };
  }, []);
  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    console.log(selectedRows);
    setSelectedUsers(selectedRows);
  }, []);

  // 테이블 인피니트 스크롤용
  let page = 1;
  const dataSource: IDatasource = {
    rowCount: undefined,
    getRows: (params) => {
      adminRoleListApi({
        language: 'ko',
        page,
        pagesize: 50,
        roles: ['ADMIN'],
      })
        .then((resp) => ({
          userList: resp?.data.data.result,
          totpage: resp?.data.data.totpage,
          totcount: resp?.data.data.totcount,
        }))
        .then((data) => {
          if (data.totpage === undefined || data.userList === undefined || data.totcount === undefined) {
            params.failCallback();
            return;
          }
          const lastPage = page < data.totpage ? 50 * page + 1 : parseInt(data.totcount);
          params.successCallback(data.userList, lastPage);
          page++;
        })

        .catch((error) => {
          if (axios.isAxiosError(error)) {
            console.log('error: ', error);
          } else {
            console.log('unexpected error: ', error);
          }
        });
    },
  };

  const onGridReady = (params: GridReadyEvent<IroleListApiListResponse[]>) => {
    setGridApi(params.api);
    params.api.setDatasource(dataSource);
  };

  useEffect(() => {
    setColumnDefs(USER_LIST_TALBLE_COLUMN(t));
  }, [t]);

  useEffect(() => {
    if (adminRoleStatus === 'success' && gridApi) {
      gridApi.deselectAll();
      gridApi.setDatasource(dataSource);
    }
  }, [adminRoleStatus, gridApi]);

  return (
    <div className="w-full grow flex flex-col overflow-hidden">
      <RightTopBar gridApi={gridApi} selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers} />
      <div className="ag-theme-alpine ag-theme-mycustomtheme grow w-full">
        <AgGridReact
          ref={gridRef}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowModelType={'infinite'}
          cacheBlockSize={50}
          onGridReady={onGridReady}
          rowMultiSelectWithClick
          rowSelection={'multiple'}
          onSelectionChanged={onSelectionChanged}
          //overlayNoRowsTemplate={'\xa0'}
        ></AgGridReact>
      </div>
    </div>
  );
};

export default AdminIdMngAdminUserList_Table;
