import { toast } from 'react-toastify';
import Arrow from '../../../assets/svg/arrows/Arrow';
import Button from '../../common/buttons/Button';
import {
  addFuncBtnHandler,
  addFuncBtnToBtnGroupHandler,
  modifyFuncBtnHandler,
  removeFuncBtnHandler,
} from '../../../helpers/h-funcBtnMng';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { initSelectedFuncBtn } from '../../../redux/slice/funcButtonManagementSlice';

interface Props {
  onClick: () => void;
}

const DownTopBar = ({ onClick }: Props) => {
  const dispatch = useAppDispatch();
  const { selectedFuncBtn, selectedFuncBtnGroup } = useAppSelector(
    (state) => state.funcButtonManagement,
  );

  return (
    <div className="py-[0.563rem] flex justify-between text-fontColor-default">
      <div className="flex gap-1">
        <Button
          kind="cancel"
          label="추가"
          onClick={() => addFuncBtnHandler(selectedFuncBtn)}
        />
        <Button
          kind="cancel"
          label="수정"
          onClick={() => modifyFuncBtnHandler(selectedFuncBtn)}
        />
        <Button
          kind="cancel"
          label="삭제"
          onClick={() => {
            if (selectedFuncBtn.btncode.length < 1) {
              toast.error('버튼을 선택해주세요');
              return;
            }
            removeFuncBtnHandler(selectedFuncBtn);
            dispatch(initSelectedFuncBtn());
          }}
        />
        <Button
          kind="cancel"
          label="버튼 그룹에 버튼 추가"
          onClick={() => {
            addFuncBtnToBtnGroupHandler(selectedFuncBtn, selectedFuncBtnGroup);
            // dispatch(initSelectedFuncBtn());
          }}
        />
        {/* <Button kind="cancel" label="버튼 리스트 엑셀 다운로드" /> */}
      </div>
      <Arrow onClick={onClick} direction="down" />
    </div>
  );
};

export default DownTopBar;
