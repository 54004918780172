import { useEffect, useState } from 'react';

// 라이브러리
import { SplitPane } from 'react-multi-split-pane';
import { useResizeDetector } from 'react-resize-detector';
import {
  handleViewPortSize,
  NestedPane,
  PaneProps,
  splitPaneViewportCase,
} from '../../../helpers/h-splitPane';

// 컴포넌트
import DownFormContainer from './DownFormContainer';
import DownTopBar from './DownTopBar';
import UpperRightTopBar from './UpperRightTopBar';

// 인터페이스

// 리덕스
import CntBtnList_Table from '../../tables/cntBtnMng/CntBtnList_Table';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { initModals } from '../../../redux/slice/toggleSlice';
import CntBtnGroupHubList_Table from '../../tables/cntBtnMng/CntBtnGroupHubList_Table';
import {
  fetchAndSetCntBtnGroupList,
  fetchAndSetCntBtnList,
} from '../../../helpers/h-cntBtnMng';
import { initCntBtnMngSlice } from '../../../redux/slice/countButtonManagementSlice';
import observeStatus from '../../../hooks/observeStatus';
import MainPageTitleHeader from '../../common/MainPageTitleHeader';

const CountButtonManagement = () => {
  const dispatch = useAppDispatch();
  const {
    cntBtnStatus,
    cntBtnGroupStatus,
    //    cntBtn
    cntBtnAddStatus,
    cntBtnModifyStatus,
    cntBtnRemoveStatus,
    //    cntBtnGroup
    cntBtnGroupAddStatus,
    cntBtnGroupModifyStatus,
    cntBtnGroupRemoveStatus,
    //    cntBrnGroupHub
    CntBtnGroupsBtnAddStatus,
    CntBtnGroupsBtnRemoveStatus,
  } = useAppSelector((state) => state.countButtonManagement);

  const [splitPaneViewportKey, setSplitPaneViewportKey] = useState(
    splitPaneViewportCase.default,
  );

  // 성공/실패 알람
  //    funcBtn
  observeStatus(cntBtnAddStatus, '버튼 추가 성공', '버튼 추가 실패');
  observeStatus(cntBtnModifyStatus, '버튼 수정 성공', '버튼 수정 실패');
  observeStatus(cntBtnRemoveStatus, '버튼 삭제 성공', '버튼 삭제 실패');
  //    funcBtnGroup
  observeStatus(
    cntBtnGroupAddStatus,
    '버튼 그룹 추가 성공',
    '버튼 그룹 추가 실패',
  );
  observeStatus(
    cntBtnGroupModifyStatus,
    '버튼 그룹 수정 성공',
    '버튼 그룹 수정 실패',
  );
  observeStatus(
    cntBtnGroupRemoveStatus,
    '버튼 그룹 삭제 성공',
    '버튼 그룹 삭제 실패',
  );
  //    funcBtnGroupHub
  observeStatus(
    CntBtnGroupsBtnAddStatus,
    '버튼 그룹에 추가 성공',
    '버튼 그룹에 추가 실패',
  );
  observeStatus(
    CntBtnGroupsBtnRemoveStatus,
    '버튼 그룹에서 삭제 성공',
    '버튼 그룹에서 삭제 실패',
  );

  // 카운트 버튼 / 카운트 버튼 그룹 초기화
  useEffect(() => {
    fetchAndSetCntBtnList();
    fetchAndSetCntBtnGroupList();
  }, []);

  // 카운트 버튼 추가/수정/삭제 성공시 카운트 버튼 리스트 갱신
  useEffect(() => {
    if (cntBtnStatus !== 'success') return;
    fetchAndSetCntBtnList();
  }, [cntBtnStatus]);

  // 카운트 버튼 그룹 추가/수정/삭제 성공시 카운트 버튼 그룹 리스트 갱신
  useEffect(() => {
    if (cntBtnGroupStatus !== 'success') return;
    fetchAndSetCntBtnGroupList();
  }, [cntBtnGroupStatus]);

  // 모달 초기화
  useEffect(() => {
    return () => {
      dispatch(initModals());
      dispatch(initCntBtnMngSlice());
    };
  }, []);

  return (
    <div className="h-full w-full">
      <SplitPane
        className="custom"
        split="horizontal"
        minSize={[100, 200]}
        defaultSizes={[348, 628]}
      >
        <Upper
          setSplitPaneViewportKey={setSplitPaneViewportKey}
          splitPaneUpdatedState={splitPaneViewportKey}
        />
        <Down
          setSplitPaneViewportKey={setSplitPaneViewportKey}
          splitPaneUpdatedState={splitPaneViewportKey}
        />
      </SplitPane>
    </div>
  );
};

const Upper = ({
  setSplitPaneViewportKey,
  splitPaneUpdatedState,
}: PaneProps) => {
  const splitPaneHandler = () =>
    handleViewPortSize([setSplitPaneViewportKey, 'clickedUpper']);

  return (
    <SplitPane
      className="custom"
      split="vertical"
      minSize={[100, 200]}
      // defaultSizes={[1,1]}
    >
      <UpperLeft
        onClick={splitPaneHandler}
        splitPaneUpdatedState={splitPaneUpdatedState}
      />
      <UpperRight
        onClick={splitPaneHandler}
        splitPaneUpdatedState={splitPaneUpdatedState}
      />
    </SplitPane>
  );
};

const UpperLeft = ({ onClick, splitPaneUpdatedState }: NestedPane) => {
  const { ref } = useResizeDetector();
  const [isFirst, setisFirst] = useState(true);

  useEffect(() => {
    if (isFirst) return setisFirst(false);
    ref.current.parentElement.parentElement.style.flex =
      splitPaneUpdatedState.upper;
  }, [splitPaneUpdatedState]);

  return (
    <div
      ref={ref}
      className="w-full h-full flex flex-col px-[1.5rem] pb-3 border border-solid border-[#dfe2e8] bg-white rounded"
    >
      <MainPageTitleHeader text="count 버튼 목록" onClick={onClick} />
      <CntBtnList_Table />
    </div>
  );
};

const UpperRight = ({ onClick, splitPaneUpdatedState }: NestedPane) => {
  const { ref } = useResizeDetector();
  const [isFirst, setisFirst] = useState(true);

  useEffect(() => {
    if (isFirst) return setisFirst(false);
    ref.current.parentElement.parentElement.style.flex =
      splitPaneUpdatedState.upper;
  }, [splitPaneUpdatedState]);

  return (
    <div
      ref={ref}
      className="w-full h-full flex flex-col px-[1.5rem] pb-3 border border-solid border-[#dfe2e8] bg-white rounded"
    >
      <div className="w-full flex flex-col grow overflow-hidden">
        <MainPageTitleHeader text="버튼 그룹 목록" onClick={onClick} />
        <UpperRightTopBar />
        <CntBtnGroupHubList_Table />
      </div>
      {/* <UpperRightListContainer /> */}
    </div>
  );
};

const Down = ({
  setSplitPaneViewportKey,
  splitPaneUpdatedState,
}: PaneProps) => {
  const { ref } = useResizeDetector();
  const [isFirst, setisFirst] = useState(true);

  useEffect(() => {
    if (isFirst) return setisFirst(false);
    ref.current.parentElement.style.flex = splitPaneUpdatedState.down;
  }, [splitPaneUpdatedState]);

  return (
    <div
      ref={ref}
      className="w-full h-full px-[1.5rem] border border-solid border-[#dfe2e8] rounded bg-white"
    >
      <DownTopBar
        onClick={() =>
          handleViewPortSize([setSplitPaneViewportKey, 'clickedDown'])
        }
      />
      <DownFormContainer />
    </div>
  );
};

export default CountButtonManagement;
