import { ColGroupDef, ValueGetterParams } from 'ag-grid-community';
import { IautosScale } from '../../../../interfaces/api/service-provider/I-api-autoscale';
import { AgCellTextCenter } from '../../../common/AgCustomCell';

//export const AutoScale_LIST_TABLE_COL: ColGroupDef<IautosScale>[] = [
export const AutoScale_LIST_TABLE_COL  = (t: (key: string) => string) => {
  return [ 
    {
      headerName: 'meta',
      children: [
        {
          field: 'meta.name',
          headerName: t('AutoScaleManagement.h-rightServiceName'),
          cellRenderer: AgCellTextCenter<IautosScale>,
        },
        {
          field: 'meta.namespace',
          headerName: t('AutoScaleManagement.h-rightTenant'),
          cellRenderer: AgCellTextCenter<IautosScale>,
        },
      ],
    },
    {
      headerName: 'replicas',
      children: [
        {
          field: 'replicas.min',
          headerName: t('AutoScaleManagement.h-rightLeastServiceCnt'),
          cellRenderer: AgCellTextCenter<IautosScale>,
        },
        {
          field: 'replicas.min',
          headerName: t('AutoScaleManagement.h-rightMostServiceCnt'),
          cellRenderer: AgCellTextCenter<IautosScale>,
        },
      ],
    },
    {
      headerName: 'scale',
      children: [
        {
          headerName: 'up',
          children: [
            {
              field: 'behavior.up.period',
              valueGetter: (params: ValueGetterParams<IautosScale>) => {
                if (!params.data) return;
                return `${params.data.behavior.up.period}`;
              },
              headerName: t('AutoScaleManagement.h-rightDutaion'),
              cellRenderer: AgCellTextCenter<IautosScale>,
            },
            {
              field: 'behavior.up.count',
              headerName: t('AutoScaleManagement.h-rightOnServiceCnt'),
              cellRenderer: AgCellTextCenter<IautosScale>,
            },
          ],
        },
        {
          headerName: 'down',
          children: [
            {
              field: 'behavior.down.period',
              valueGetter: (params: ValueGetterParams<IautosScale>) => {
                if (!params.data) return;
                return `${params.data.behavior.down.period}`;
              },
              headerName: t('AutoScaleManagement.h-rightDutaion'),
              cellRenderer: AgCellTextCenter<IautosScale>,
            },
            {
              field: 'behavior.down.count',
              headerName:  t('AutoScaleManagement.h-rightOnServiceCnt'),
              cellRenderer: AgCellTextCenter<IautosScale>,
            },
          ],
        },
      ],
    },
    {
      headerName: 'metric',
      children: [
        {
          field: 'metric.average',
          valueGetter: (params: ValueGetterParams<IautosScale>) => {
            if (!params.data) return;
            return `${params.data.metric.average} %`;
          },
          headerName: t('AutoScaleManagement.h-rightCpuUse'),
          cellRenderer: AgCellTextCenter<IautosScale>,
        },
      ],
    },
    // {
    //   field: 'filesize',
    //   headerName: '파일 크기',
    //   valueGetter: (v) => {
    //     if (!v.data) return;
    //     const mbFormat = parseInt(v.data.filesize) / 1024 / 1024;
    //     const mbFormatToFixed = mbFormat.toFixed(2);
    //     return `${mbFormatToFixed}MB`;
    //   },
    //   cellRenderer: AgCellTextCenter<IautosScale>,
    // },
    // {
    //   field: 'url',
    //   headerName: '파일 경로',
    //   cellRenderer: AgCellTextCenter<IautosScale>,
    // },
    // {
    //   field: 'updatetime',
    //   headerName: '생성일',
    //   valueGetter: dateValueGetter,
    //   cellRenderer: AgCellTextCenter<IautosScale>,
    // },
  ];
};
