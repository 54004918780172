import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  adminButtonCntAddApi,
  adminButtonCntModifyApi,
  adminButtonCntRemoveApi,
} from '../../api/admin/button/api-admin-btn-cnt';
import {
  adminButtonGroupAddApi,
  adminButtonGroupModifyApi,
  adminButtonGroupRemoveApi,
} from '../../api/admin/button/api-admin-btn-group';
import {
  adminButtonGroupHubAddApi,
  adminButtonGroupHubRemoveApi,
} from '../../api/admin/button/api-admin-btn-group-hub';
import {
  IAdminButtonCntAddBtn,
  IAdminButtonCntListApiResult,
} from '../../interfaces/api/button/I-api-admin-btn-cnt';
import {
  IAdminButtonGroup,
  IAdminButtonGroupAddApiBody,
  IAdminButtonGroupModifyApiBody,
  IAdminButtonGroupRemoveApiBody,
} from '../../interfaces/api/button/I-api-admin-btn-group';

interface CountButtonManagementState {
  cntBtnList: IAdminButtonCntListApiResult[] | null;
  selectedCntBtn: IAdminButtonCntListApiResult;
  cntBtnGroupList: IAdminButtonGroup[];
  selectedCntBtnGroup: IAdminButtonGroup;
  selectedCntBtnGroupsBtn: IAdminButtonCntListApiResult | null;
  selectedCntBtnGroupModal: IAdminButtonGroup | null;
  //status
  //    cntBtn
  cntBtnStatus: 'idle' | 'pending' | 'success' | 'fail';
  cntBtnAddStatus: 'idle' | 'pending' | 'success' | 'fail';
  cntBtnModifyStatus: 'idle' | 'pending' | 'success' | 'fail';
  cntBtnRemoveStatus: 'idle' | 'pending' | 'success' | 'fail';
  //    cntBtnGroup
  cntBtnGroupStatus: 'idle' | 'pending' | 'success' | 'fail';
  cntBtnGroupAddStatus: 'idle' | 'pending' | 'success' | 'fail';
  cntBtnGroupModifyStatus: 'idle' | 'pending' | 'success' | 'fail';
  cntBtnGroupRemoveStatus: 'idle' | 'pending' | 'success' | 'fail';
  //    cntBrnGroupHub
  CntBtnGroupsBtnStatus: 'idle' | 'pending' | 'success' | 'fail';
  CntBtnGroupsBtnAddStatus: 'idle' | 'pending' | 'success' | 'fail';
  CntBtnGroupsBtnRemoveStatus: 'idle' | 'pending' | 'success' | 'fail';
}

/*
  - 카운트 버튼 리스트
  - 선택한 카운트 버튼
  - 카운트 버튼 상태 : {
    status: 'idle' | 'loading' | 'success' | 'fail'
  }
  - 카운트 버튼 그룹 리스트
  - 선택한 버튼 그룹
  - 카운트 버튼 그룹 상태
*/
const initialState: CountButtonManagementState = {
  cntBtnList: null,
  selectedCntBtn: {
    tenantid: '',
    orgcode: '',
    btncode: '',
    btnname: '',
    btnmethod: '',
    btnurlexe: '',
    btnurlexeopt: '',
    btnviewurl: '',
    btnviewopt: '',
    btncomment: '',
    btnimgsrc: '',
  },
  cntBtnGroupList: [],
  selectedCntBtnGroup: {
    tenantid: '',
    orgcode: '',
    groupcode: '',
    groupkind: 'C',
    groupname: '',
    groupver: 0,
    grouporder: 0,
  },
  selectedCntBtnGroupsBtn: null,
  selectedCntBtnGroupModal: null,
  //status
  //    cntBtn
  cntBtnStatus: 'idle',
  cntBtnAddStatus: 'idle',
  cntBtnModifyStatus: 'idle',
  cntBtnRemoveStatus: 'idle',
  //    cntBtnGroup
  cntBtnGroupStatus: 'idle',
  cntBtnGroupAddStatus: 'idle',
  cntBtnGroupModifyStatus: 'idle',
  cntBtnGroupRemoveStatus: 'idle',
  //    cntBrnGroupHub
  CntBtnGroupsBtnStatus: 'idle',
  CntBtnGroupsBtnAddStatus: 'idle',
  CntBtnGroupsBtnRemoveStatus: 'idle',
};

// 카운트 버튼 추가
export const addCntBtn = createAsyncThunk(
  'cntBtn/add',
  async (cntBtn: IAdminButtonCntAddBtn, { rejectWithValue }) => {
    try {
      const response = await adminButtonCntAddApi([cntBtn]);
      // console.log('response from thunk', response);
      return response?.data;
    } catch (error) {
      // return rejectWithValue(String(error));
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
        return rejectWithValue(error.message);
      } else {
        console.log('unexpected error: ', error);
        return rejectWithValue('An unexpected error occurred');
      }
    }
  },
);
// 카운트 버튼 수정
export const modifyCntBtn = createAsyncThunk(
  'cntBtn/modify',
  async (cntBtn: IAdminButtonCntAddBtn, { rejectWithValue }) => {
    try {
      const response = await adminButtonCntModifyApi(cntBtn);
      return response?.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
        return rejectWithValue(error.message);
      } else {
        console.log('unexpected error: ', error);
        return rejectWithValue('unexpected error');
      }
    }
  },
);
// 카운트 버튼 삭제
export const removeCntBtn = createAsyncThunk(
  'cntBtn/remove',
  async (btncodeList: { btncode: string }[], { rejectWithValue }) => {
    try {
      const response = await adminButtonCntRemoveApi(btncodeList);
      return response?.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
        return rejectWithValue(error.message);
      } else {
        console.log('unexpected error: ', error);
        return rejectWithValue('unexpected error');
      }
    }
  },
);
// 카운트 버튼 그룹 추가
export const addCntBtnGroup = createAsyncThunk(
  'cntBtnGroup/add',
  async (cntBtnGroup: IAdminButtonGroupAddApiBody, { rejectWithValue }) => {
    try {
      const response = await adminButtonGroupAddApi(cntBtnGroup);
      return response?.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
        return rejectWithValue(error.message);
      } else {
        console.log('unexpected error: ', error);
        return rejectWithValue('unexpected error');
      }
    }
  },
);
// 카운트 버튼 그룹 수정
export const modifyCntBtnGroup = createAsyncThunk(
  'cntBtnGroup/modify',
  async (cntBtnGroup: IAdminButtonGroupModifyApiBody, { rejectWithValue }) => {
    try {
      const response = await adminButtonGroupModifyApi(cntBtnGroup);
      return response?.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
        return rejectWithValue(error.message);
      } else {
        console.log('unexpected error: ', error);
        return rejectWithValue('unexpected error');
      }
    }
  },
);
// 카운트 버튼 그룹 삭제
export const removeCntBtnGroup = createAsyncThunk(
  'cntBtnGroup/remove',
  async (groupcode: IAdminButtonGroupRemoveApiBody, { rejectWithValue }) => {
    try {
      const response = await adminButtonGroupRemoveApi(groupcode);
      return response?.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
        return rejectWithValue(error.message);
      } else {
        console.log('unexpected error: ', error);
        return rejectWithValue('unexpected error');
      }
    }
  },
);
// 카운트 버튼 그룹 허브
// 추가
export const addCntBtnToBtnGroup = createAsyncThunk(
  'cntBtnGroupHub/add',
  async (
    {
      selectedCntBtn,
      selectedCntBtnGroup,
    }: {
      selectedCntBtn: IAdminButtonCntListApiResult;
      selectedCntBtnGroup: IAdminButtonGroup;
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await adminButtonGroupHubAddApi({
        groupcode: selectedCntBtnGroup.groupcode,
        btnkind: 'C',
        btncode: selectedCntBtn.btncode,
        btnorder: 0,
      });
      return response?.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
        return rejectWithValue(error.message);
      } else {
        console.log('unexpected error: ', error);
        return rejectWithValue('unexpected error');
      }
    }
  },
);
// 삭제
export const removeCntBtnFromBtnGroup = createAsyncThunk(
  'cntBtnGroupHub/remove',
  async (
    {
      selectedCntBtnGroupsBtn,
      selectedCntBtnGroup,
    }: {
      selectedCntBtnGroupsBtn: IAdminButtonCntListApiResult;
      selectedCntBtnGroup: IAdminButtonGroup;
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await adminButtonGroupHubRemoveApi({
        groupcode: selectedCntBtnGroup.groupcode,
        btncode: selectedCntBtnGroupsBtn.btncode,
      });
      return response?.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
        return rejectWithValue(error.message);
      } else {
        console.log('unexpected error: ', error);
        return rejectWithValue('unexpected error');
      }
    }
  },
);

export const countButtonManagementSlice = createSlice({
  name: 'countButtonManagement',
  initialState,
  reducers: {
    // INIT
    initCntBtnMngSlice: () => initialState,
    // CNT BTN
    setCntBtnList: (state, action) => {
      state.cntBtnList = action.payload;
    },
    setSelectedCntBtn: (state, action) => {
      state.selectedCntBtn = action.payload;
    },
    changeSelectedCntBtn: (state, action) => {
      state.selectedCntBtn = {
        ...state.selectedCntBtn,
        [action.payload.name]: action.payload.value,
      };
    },
    initSelectedCntBtn: (state) => {
      state.selectedCntBtn = initialState.selectedCntBtn;
    },
    // CNT BTN GROUP
    setCntBtnGroupList: (
      state,
      { payload: cntBtnGroupList }: { payload: IAdminButtonGroup[] },
    ) => {
      state.cntBtnGroupList = cntBtnGroupList;
    },
    setSelectedCntBtnGroup: (
      state,
      { payload: cntBtnGroup }: { payload: IAdminButtonGroup },
    ) => {
      state.selectedCntBtnGroup = cntBtnGroup;
    },
    // CNT BTN GROUPs BTN
    setSelectedCntBtnGroupsBtn: (
      state,
      { payload: cntBtn }: { payload: IAdminButtonCntListApiResult | null },
    ) => {
      state.selectedCntBtnGroupsBtn = cntBtn;
    },
    // MODAL
    setSelectedCntBtnGroupModal: (
      state,
      { payload: cntBtnGroup }: { payload: IAdminButtonGroup },
    ) => {
      state.selectedCntBtnGroupModal = cntBtnGroup;
    },
    initSelectedCntBtnGroupModal: (state) => {
      state.selectedCntBtnGroupModal = initialState.selectedCntBtnGroupModal;
    },
  },
  extraReducers(builder) {
    builder
      // 카운트 버튼
      .addCase(addCntBtn.pending, (state) => {
        state.cntBtnStatus = 'pending';
        state.cntBtnAddStatus = 'pending';
      })
      .addCase(addCntBtn.fulfilled, (state) => {
        state.cntBtnStatus = 'success';
        state.cntBtnAddStatus = 'success';
      })
      .addCase(addCntBtn.rejected, (state) => {
        state.cntBtnStatus = 'fail';
        state.cntBtnAddStatus = 'fail';
      })
      .addCase(modifyCntBtn.pending, (state) => {
        state.cntBtnStatus = 'pending';
        state.cntBtnModifyStatus = 'pending';
      })
      .addCase(modifyCntBtn.fulfilled, (state) => {
        state.cntBtnStatus = 'success';
        state.cntBtnModifyStatus = 'success';
      })
      .addCase(modifyCntBtn.rejected, (state) => {
        state.cntBtnStatus = 'fail';
        state.cntBtnModifyStatus = 'fail';
      })
      .addCase(removeCntBtn.pending, (state) => {
        state.cntBtnStatus = 'pending';
        state.cntBtnRemoveStatus = 'pending';
      })
      .addCase(removeCntBtn.fulfilled, (state) => {
        state.cntBtnStatus = 'success';
        state.cntBtnRemoveStatus = 'success';
      })
      .addCase(removeCntBtn.rejected, (state) => {
        state.cntBtnStatus = 'fail';
        state.cntBtnRemoveStatus = 'fail';
      })
      // 카운트 버튼 그룹
      .addCase(addCntBtnGroup.pending, (state) => {
        state.cntBtnGroupStatus = 'pending';
        state.cntBtnGroupAddStatus = 'pending';
      })
      .addCase(addCntBtnGroup.fulfilled, (state) => {
        state.cntBtnGroupStatus = 'success';
        state.cntBtnGroupAddStatus = 'success';
      })
      .addCase(addCntBtnGroup.rejected, (state) => {
        state.cntBtnGroupStatus = 'fail';
        state.cntBtnGroupAddStatus = 'fail';
      })
      .addCase(modifyCntBtnGroup.pending, (state) => {
        state.cntBtnGroupStatus = 'pending';
        state.cntBtnGroupModifyStatus = 'pending';
      })
      .addCase(modifyCntBtnGroup.fulfilled, (state) => {
        state.cntBtnGroupStatus = 'success';
        state.cntBtnGroupModifyStatus = 'success';
      })
      .addCase(modifyCntBtnGroup.rejected, (state) => {
        state.cntBtnGroupStatus = 'fail';
        state.cntBtnGroupModifyStatus = 'fail';
      })
      .addCase(removeCntBtnGroup.pending, (state) => {
        state.cntBtnGroupStatus = 'pending';
        state.cntBtnGroupRemoveStatus = 'pending';
      })
      .addCase(removeCntBtnGroup.fulfilled, (state) => {
        state.cntBtnGroupStatus = 'success';
        state.cntBtnGroupRemoveStatus = 'success';
      })
      .addCase(removeCntBtnGroup.rejected, (state) => {
        state.cntBtnGroupStatus = 'fail';
        state.cntBtnGroupRemoveStatus = 'fail';
      })
      // 카운트 버튼 그룹 허브
      // ADD
      .addCase(addCntBtnToBtnGroup.pending, (state) => {
        state.CntBtnGroupsBtnStatus = 'pending';
        state.CntBtnGroupsBtnAddStatus = 'pending';
      })
      .addCase(addCntBtnToBtnGroup.fulfilled, (state) => {
        state.CntBtnGroupsBtnStatus = 'success';
        state.CntBtnGroupsBtnAddStatus = 'success';
      })
      .addCase(addCntBtnToBtnGroup.rejected, (state) => {
        state.CntBtnGroupsBtnStatus = 'fail';
        state.CntBtnGroupsBtnAddStatus = 'fail';
      })
      //REMOVE
      .addCase(removeCntBtnFromBtnGroup.pending, (state) => {
        state.CntBtnGroupsBtnStatus = 'pending';
        state.CntBtnGroupsBtnRemoveStatus = 'pending';
      })
      .addCase(removeCntBtnFromBtnGroup.fulfilled, (state) => {
        state.CntBtnGroupsBtnStatus = 'success';
        state.CntBtnGroupsBtnRemoveStatus = 'success';
      })
      .addCase(removeCntBtnFromBtnGroup.rejected, (state) => {
        state.CntBtnGroupsBtnStatus = 'fail';
        state.CntBtnGroupsBtnRemoveStatus = 'fail';
      });
  },
});

export const {
  initCntBtnMngSlice,
  setCntBtnList,
  setSelectedCntBtn,
  changeSelectedCntBtn,
  initSelectedCntBtn,
  setCntBtnGroupList,
  setSelectedCntBtnGroup,
  setSelectedCntBtnGroupsBtn,
  setSelectedCntBtnGroupModal,
  initSelectedCntBtnGroupModal,
} = countButtonManagementSlice.actions;

export default countButtonManagementSlice.reducer;
