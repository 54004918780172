import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { useDispatch } from 'react-redux';
import { Resizable, ResizeCallbackData } from 'react-resizable';
import { toast } from 'react-toastify';
import Button from '../../common/buttons/Button';
import {
  fedAcceptHandler,
  fedRequestHandler,
  getAndSetFedTenantList,
} from '../../../helpers/h-federationMng';
import { closeFederation_connectApply_Modal } from '../../../helpers/h-modals';
import { getMyTenantId } from '../../../helpers/h-userInfo';
import {
  IfedTenantListItem,
  IserviceProviderFedRequestApiBody,
} from '../../../interfaces/api/service-provider/I-api-admin-serviceProvider-fed';
import { requestFed } from '../../../redux/slice/federatinManagementSlice';
import Federation_connectApply_Table from '../../tables/federationMng/Federation_connectApply_Table';

type CONNECT_BODY = {
  srctenantid: string;
  desttenantid: string;
  kind: 'CONNECT';
};

const FederationMng_connectApply_Modal = () => {
  const [selectedTenant, setSelectedTenant] =
    useState<IfedTenantListItem | null>(null);

  const [size, setSize] = useState({
    width: 600,
    height: 600,
  });

  const onResize = (
    e: React.SyntheticEvent<Element, Event>,
    data: ResizeCallbackData,
  ) => {
    setSize({
      width: data.size.width,
      height: data.size.height,
    });
  };

  // 신청 버튼
  const onClickRequestConnection = () => {
    if (!selectedTenant) return toast.error(String(t('FederationManagement.toast1')));

    const srctenantid = getMyTenantId();

    if (!srctenantid) return console.log('error... getMyTenantId === null');

    const body: CONNECT_BODY = {
      srctenantid,
      desttenantid: selectedTenant.tenantid,
      kind: 'CONNECT',
    };

    // console.log('body from onClickRequestConnection : ', body);

    fedRequestHandler(body);
  };

  useEffect(() => {
    // console.log('selectedTenant', selectedTenant);
  }, [selectedTenant]);

  // fed/tenant/list
  useEffect(() => {
    getAndSetFedTenantList();
  }, []);

  // 초기화
  useEffect(() => {
    return () => {
      setSelectedTenant(null);
    };
  }, []);

  return (
    <Draggable handle="#handle">
      <Resizable
        handle={
          <div
            className="resizable-custom-handler absolute"
            style={{
              left: size.width - 19,
              top: size.height - 19,
            }}
          ></div>
        }
        height={size.height}
        width={size.width}
        onResize={onResize}
      >
        <div className="modal-wrapper">
          {/* WRAPPER */}
          <div
            className="box drag-box  flex flex-col px-[1.25rem]
            relative border border-modal-subTitle bg-white z-50 shadow-listbox-options
            rounded
          "
            style={{ width: `${size.width}px`, height: `${size.height}px` }}
          >
            {/* HEADER */}
            <div
              id="handle"
              className="min-h-[3.5rem] cursor-move w-full flex flex-col relative"
            >
              <div className="grow font-bold w-full flex items-center truncate">
                {(t('FederationManagement.federationApplication') as string)}
              </div>
            </div>
            {/* BODY */}
            <div className="grow w-full flex flex-col gap-3 overflow-y-auto">
              <Federation_connectApply_Table
                setSelectedTenant={setSelectedTenant}
              />
            </div>
            {/* FOOTER */}
            <div className="min-h-[6rem] w-full jcac gap-6">
              <Button
                kind="cancel"
                label={t('FederationManagement.cancel')}
                onClick={closeFederation_connectApply_Modal}
              />
              <Button
                kind="primary"
                label={t('FederationManagement.application')}
                onClick={onClickRequestConnection}
              />
            </div>
          </div>
        </div>
      </Resizable>
    </Draggable>
  );
};

export default FederationMng_connectApply_Modal;
