import React from 'react';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import { fedAcceptHandler } from '../../../helpers/h-federationMng';
import { closeFederation_accept_Modal } from '../../../helpers/h-modals';
import { IserviceProviderFedAcceptApiBody } from '../../../interfaces/api/service-provider/I-api-admin-serviceProvider-fed';
import { useAppSelector } from '../../../redux/hooks';
import FederationMng_ask_common from './FederationMng_ask_common';

type CONNECT_BODY = {
  srctenantid: string;
  desttenantid: string;
  kind: 'CONNECT';
};

type RELEASE_BODY = {
  srctenantid: string;
  desttenantid: string;
  kind: 'RELEASE';
};

const FederationMng_accept_Modal = () => {
  const { selectedOtherFederation } = useAppSelector(
    (state) => state.federatinManagement,
  );

  const handleAcceptRequest = () => {
    if (!selectedOtherFederation)
      return toast.error('Please select item first');

    // 연결 신청 수락...
    if (selectedOtherFederation.status === 2) {
      const body: CONNECT_BODY = {
        srctenantid: selectedOtherFederation.srctenantid,
        desttenantid: selectedOtherFederation.desttenantid,
        kind: 'CONNECT',
      };
      fedAcceptHandler(body);
      return;
    } else if (selectedOtherFederation.status === 6) {
      // 연결 해제 신청 수락...
      const body: RELEASE_BODY = {
        srctenantid: selectedOtherFederation.srctenantid,
        desttenantid: selectedOtherFederation.desttenantid,
        kind: 'RELEASE',
      };
      fedAcceptHandler(body);
      return;
    }
  };

  return (
    <FederationMng_ask_common
      label={t('FederationManagement.acceptFederationRelation')}
      onClickClose={closeFederation_accept_Modal}
      onClickHandler={() => {
        handleAcceptRequest();
        closeFederation_accept_Modal();
      }}
    />
  );
};

export default FederationMng_accept_Modal;
