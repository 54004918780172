import axios from 'axios';
import {
  IspAuthChangeApiBody,
  IspAuthLoginApiBody,
  IspAuthLoginApiReturnValue,
  IspAuthMailSendApiBody,
  IspAuthMailVerifyApiBody,
  IspAuthRegisterApiBody,
  IspAuthSmsSendApiBody,
  IspAuthSmsVerifyApiBody,
} from '../../interfaces/api/auth/I-api-sp-auth';
import { axiosErrorHandler, spAxiosInstance } from '../axiosInstance';

const apAuthInfo = {
  url: {
    login: `/api/v1/admin-mg-sp/auth/login`,
    change: `/api/v1/admin-mg-sp/auth/change`,
    register: `/api/v1/admin-mg-sp/auth/register`,
    tfa_mail_send: `/api/v1/admin-mg-sp/auth/tfa/mail/send`,
    tfa_mail_verify: `/api/v1/admin-mg-sp/auth/tfa/mail/verify`,
    tfa_sms_send: `/api/v1/admin-mg-sp/auth/tfa/sms/send`,
    tfa_sms_verify: `/api/v1/admin-mg-sp/auth/tfa/sms/verify`,
  },
};

// login
export const spAuthLoginApi = (props: IspAuthLoginApiBody) => {
  const { url } = apAuthInfo;

  return spAxiosInstance.post<IspAuthLoginApiReturnValue>(url.login, props);
};
// change
export const spAuthChangeApi = (props: IspAuthChangeApiBody) => {
  const { url } = apAuthInfo;

  return spAxiosInstance.post(url.change, props);
};
// register
export const spAuthRegisterApi = (props: IspAuthRegisterApiBody) => {
  const { url } = apAuthInfo;

  return spAxiosInstance.post(url.register, props);
};
// tfa
// mail
// send
export const spAuthMailSendApi = (props: IspAuthMailSendApiBody) => {
  const { url } = apAuthInfo;
  return spAxiosInstance.put(url.tfa_mail_send, props);
};
// verify
export const spAuthMailVerifyApi = (props: IspAuthMailVerifyApiBody) => {
  const { url } = apAuthInfo;
  const { adminid, authcode } = props;

  return spAxiosInstance.get(
    `${url.tfa_mail_verify}/?adminid=${adminid}&authcode=${authcode}`,
  );
};
// sms
// send
export const spAuthSmsSendApi = (props: IspAuthSmsSendApiBody) => {
  const { url } = apAuthInfo;

  return spAxiosInstance.put(url.tfa_sms_send, props);
};
// verify
export const spAuthSmsVerifyApi = (props: IspAuthSmsVerifyApiBody) => {
  const { url } = apAuthInfo;
  const { adminid, authcode } = props;

  return spAxiosInstance.get(
    `${url.tfa_sms_verify}/?adminid=${adminid}&authcode=${authcode}`,
  );
};
