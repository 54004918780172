import { t } from 'i18next';
import { useEffect, useState } from 'react';
// 라이브러리
import { useResizeDetector } from 'react-resize-detector';

// split-pane 토글
import { handleViewPortSize, PaneProps, splitPaneViewportCase } from '../../../helpers/h-splitPane';
import AdminIdMngAdminUserList_Table from '../../tables/adminIdMng/AdminIdMngAdminUserList_Table';
import MainPageTitleHeader from '../../common/MainPageTitleHeader';

const AdminIdManagement = () => {
  const [splitPaneViewportKey, setSplitPaneViewportKey] = useState(splitPaneViewportCase.default);
  return <Center setSplitPaneViewportKey={setSplitPaneViewportKey} splitPaneUpdatedState={splitPaneViewportKey} />;
};

const Center = ({ setSplitPaneViewportKey, splitPaneUpdatedState }: PaneProps) => {
  const { ref } = useResizeDetector();

  useEffect(() => {
    ref.current.parentElement.style.flex = splitPaneUpdatedState.right;
  }, [splitPaneUpdatedState]);

  return (
    <div ref={ref} className="w-full h-full flex flex-col px-6 border border-solid border-[#dfe2e8] rounded bg-white">
      <MainPageTitleHeader
        textOnly={true}
        onClick={() => handleViewPortSize([setSplitPaneViewportKey, 'clickedRight'])}
        text={t('AdminIdManagement.adminList')}
      />
      <AdminIdMngAdminUserList_Table />
    </div>
  );
};

export default AdminIdManagement;
