import { toast } from 'react-toastify';
import { useEffect } from 'react';

export default function observeStatus(
  status: string,
  successT: string,
  failT: string,
) {
  useEffect(() => {
    if (status === 'idle' || status === 'pending') return;

    if (status === 'success') {
      toast.success(successT);
    }

    if (status === 'fail') {
      toast.error(failT);
    }
  }, [status]);
}
