//import '../../../styles/react-resizable.css';
import '../../../styles/joyride.css';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
// 라이브러리
import ReactJoyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { SplitPane } from 'react-multi-split-pane';
import { useResizeDetector } from 'react-resize-detector';

// 컴포넌트
import { handleViewPortSize, PanePropsV1, PanePropsV2, splitPaneViewportCase } from '../../../helpers/h-splitPane';
import MainPageTitleHeader from '../../common/MainPageTitleHeader';
import OrgUserMng_Table from '../../tables/orgUserMng/OrgUserMng_Table';
import TreeViewV6 from '../../treeView/TreeViewV6';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { setJoyride1stFinished, setJoyrideFinished } from '../../../redux/slice/joyRideFinishedSlice';

// 조직/사용자 관리
const OrganizationUserManagement = () => {
  const [leftReady, setLeftReady] = useState(false);
  const [splitPaneViewportKey, setSplitPaneViewportKey] = useState(splitPaneViewportCase.default);

  return (
    <div className="h-full w-full">
      <SplitPane className="custom" split="vertical" defaultSizes={[2, 5]} minSize={[50, 50]}>
        <Left
          setSplitPaneViewportKey={setSplitPaneViewportKey}
          splitPaneUpdatedState={splitPaneViewportKey}
          setLeftReady={setLeftReady}
        />
        <Right
          setSplitPaneViewportKey={setSplitPaneViewportKey}
          splitPaneUpdatedState={splitPaneViewportKey}
          leftReady={leftReady}
        />
      </SplitPane>
    </div>
  );
};

const Left = ({ setLeftReady, setSplitPaneViewportKey, splitPaneUpdatedState }: PanePropsV1) => {
  const { width, ref } = useResizeDetector();
  const [direction, setDirection] = useState('left');

  useEffect(() => {
    ref.current.parentElement.style.flex = splitPaneUpdatedState.left;
  }, [splitPaneUpdatedState]);

  useEffect(() => {
    setLeftReady(true);
  }, []);

  const handleDirectionToggle = () => {
    const newDirection = direction === 'left' ? 'clickedLeft' : 'clickedRight';
    handleViewPortSize([setSplitPaneViewportKey, newDirection]);
    setDirection(newDirection === 'clickedLeft' ? 'right' : 'left');
  };

  return (
    <div
      ref={ref}
      id="left"
      className="w-full flex flex-col px-6 border border-solid bg-white border-[#dfe2e8] rounded"
    >
      <MainPageTitleHeader
        onClick={handleDirectionToggle}
        text={t('OrganizationUserManagement.orgInfo')}
        direction={direction}
        textOnly={true}
      />
      <div className="grow pb-2 w-full">
        <TreeViewV6 width={width} />
      </div>
    </div>
  );
};

const Right = ({ leftReady, setSplitPaneViewportKey, splitPaneUpdatedState }: PanePropsV2) => {
  const { width, ref } = useResizeDetector();
  const [direction, setDirection] = useState('right'); // direction 상태 추가
  const [rightTitle, setRightTitle] = useState(t('OrganizationUserManagement.userInfo') as string);

  const runJoyride = useSelector((state: RootState) => state.welcomeModal.runJoyride);
  const isJoyride1stFin = useSelector((state: RootState) => state.joyRide.isJoyride1stFinished);

  useEffect(() => {
    ref.current.parentElement.style.flex = splitPaneUpdatedState.right;
  }, [splitPaneUpdatedState]);

  const handleDirectionToggle = () => {
    // direction 토글 로직
    const newDirection = direction === 'left' ? 'clickedLeft' : 'clickedRight';
    handleViewPortSize([setSplitPaneViewportKey, newDirection]);
    setDirection(newDirection === 'clickedLeft' ? 'right' : 'left');
  };

  const steps = [
    {
      target: '#left',
      content: t('Joyride.OrgUserManagementDesc1'),
      placement: 'right' as const,
      disableBeacon: true,
    },
    {
      target: '#right',
      content: t('Joyride.OrgUserManagementDesc2'),
      placement: 'right' as const,
      disableBeacon: true,
    },
    {
      target: '.ucTenantTree-title',
      content: t('Joyride.OrgUserManagementDesc3'),
      placement: 'bottom' as const,
      disableBeacon: true,
    },
    {
      target: '#b1',
      content: t('Joyride.OrgUserManagementDesc4'),
      placement: 'bottom' as const,
      disableBeacon: false,
    },
  ];
  const dispatch = useDispatch(); // React Redux의 useDispatch 훅을 사용
  const handleJoyrideCallback = (data: { action: string; index: number; type: string; status: string }) => {
    const { action, index, type, status } = data;
    if (status === STATUS.FINISHED) {
      dispatch(setJoyrideFinished(true));
      dispatch(setJoyride1stFinished(false));
    }
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     const element = document.querySelector(
  //       '.ucTenantTree-title',
  //     ) as HTMLElement;
  //     if (element) {
  //       element.click();
  //     }
  //   }, 230);
  // }, []);

  return (
    <div
      ref={ref}
      id="right"
      className="w-full flex flex-col h-full px-6 border border-solid border-[#dfe2e8] rounded bg-white"
    >
      <MainPageTitleHeader onClick={handleDirectionToggle} text={rightTitle} direction={direction} textOnly={true} />
      <OrgUserMng_Table width={width} />
      {/* <ReactJoyride
        steps={steps}
        run={runJoyride && isJoyride1stFin}
        continuous={true} // 연속적으로 스텝을 진행
        showProgress={true} // 진행 상태 표시
        showSkipButton={false} // 스킵 버튼 표시
        styles={{
          options: {
            zIndex: 10000,
            primaryColor: '#00bfff', // 파란색 계열로 버튼 색상 지정
            backgroundColor: '#f8f9fa', // 배경색 변경
            textColor: '#000', // 텍스트 색상 변경
            arrowColor: '#fff',
            beaconSize: 45,
            overlayColor: 'rgba(0, 0, 0, 0.5)',
            spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
            width: undefined,
          },
          tooltipContent: {
            fontSize: '0.875rem',
          },
          buttonNext: {
            backgroundColor: '#1f62b9',
            color: '#fff',
          },
          buttonBack: {
            color: '#000',
          },
        }}
        callback={handleJoyrideCallback}
      /> */}
    </div>
  );
};

export default OrganizationUserManagement;
