import { ErrorMessage } from 'formik';
import { forwardRef, FormEvent, ChangeEvent, MouseEvent } from 'react';
import FormikError from '../FormikError';
import CloseButton from '../buttons/CloseButton';

interface Props {
  name?: string;
  type?: 'password' | 'text';
  placeholder?: string;
  disabled?: boolean;
  isReadOnly?: boolean;
  value?: string;
  onClick?: (event: FormEvent<HTMLInputElement>) => void;
  handleChangeInput?: (event: ChangeEvent<HTMLInputElement>) => void;
  handleClear?: (e: MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  grow?: boolean;
  maxLength?: number;
  // formik에서 넘어오는 props
  field: any;
  handleBlur: any;
  form: { touched: any; errors: any; setFieldValue: any; setFieldError: any };
  meta: any;
}
const InputTextFormik = forwardRef<HTMLInputElement, Props>(function textInput(
  {
    name,
    type,
    placeholder,
    disabled = false,
    isReadOnly = false,
    value,
    handleBlur,
    onClick,
    handleChangeInput,
    handleClear,
    className,
    grow,
    maxLength,
    field,
    form: { touched, errors, setFieldValue, setFieldError },
    meta,
    ...props
  },
  ref,
) {
  return (
    <div className={`relative ${grow ? 'grow' : ''}`}>
      <div className={`relative ${grow ? 'grow' : ''}`}>
        <input
          onBlur={handleBlur}
          type={type ? type : 'text'}
          ref={ref}
          placeholder={placeholder}
          disabled={disabled}
          className={`${className} w-99per border border-solid border-C-c8cace h-9 rounded 
          placeholder:text-C-9d9ea2 focus:border-C-1f62b9 text-C-555555 
          disabled:bg-C-f1f3f6 disabled:text-C-b4b5b8 px-3 text-sm w-full`}
          readOnly={isReadOnly}
          onClick={onClick}
          maxLength={maxLength ? maxLength : 524288}
          {...field}
          {...props}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            field.onChange(e);
            if (handleChangeInput) {
              handleChangeInput(e);
            }
          }}
        />
        {value && !disabled && (
          <CloseButton
            width={13}
            height={13}
            className="absolute right-2 top-1/3"
            classNameSVG="stroke-C-c8cace hover:stroke-C-9d9ea2"
            name={name}
            onClick={handleClear}
            type="button"
          />
        )}
      </div>
      <ErrorMessage {...field} component={FormikError} />
    </div>
  );
});

export default InputTextFormik;
