import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  adminButtonFuncAddApi,
  adminButtonFuncModifyApi,
  adminButtonFuncRemoveApi,
} from '../../api/admin/button/api-admin-btn-func';
import {
  adminButtonGroupAddApi,
  adminButtonGroupModifyApi,
  adminButtonGroupRemoveApi,
} from '../../api/admin/button/api-admin-btn-group';
import {
  adminButtonGroupHubAddApi,
  adminButtonGroupHubRemoveApi,
} from '../../api/admin/button/api-admin-btn-group-hub';
import {
  IAdminButtonFunc,
  IAdminButtonFuncAddApiBody,
  IAdminButtonFuncModifyApiBody,
} from '../../interfaces/api/button/I-api-admin-btn-func';
import {
  IAdminButtonGroup,
  IAdminButtonGroupAddApiBody,
  IAdminButtonGroupModifyApiBody,
  IAdminButtonGroupRemoveApiBody,
} from '../../interfaces/api/button/I-api-admin-btn-group';

interface InitialState {
  funcBtnList: IAdminButtonFunc[];
  selectedFuncBtn: IAdminButtonFunc;
  funcBtnGroupList: IAdminButtonGroup[];
  selectedFuncBtnGroup: IAdminButtonGroup;
  selectedFuncBtnGroupsBtn: IAdminButtonFunc | null;
  selectedFuncBtnGroupModal: IAdminButtonGroup | null;
  // status
  //    funcbtn
  funcBtnStatus: 'idle' | 'pending' | 'success' | 'fail';
  funcBtnAddStatus: 'idle' | 'pending' | 'success' | 'fail';
  funcBtnModifyStatus: 'idle' | 'pending' | 'success' | 'fail';
  funcBtnRemoveStatus: 'idle' | 'pending' | 'success' | 'fail';
  //    group
  funcBtnGroupStatus: 'idle' | 'pending' | 'success' | 'fail';
  funcBtnGroupAddStatus: 'idle' | 'pending' | 'success' | 'fail';
  funcBtnGroupModifyStatus: 'idle' | 'pending' | 'success' | 'fail';
  funcBtnGroupRemoveStatus: 'idle' | 'pending' | 'success' | 'fail';
  //    groupHub
  funcBtnGroupsBtnStatus: 'idle' | 'pending' | 'success' | 'fail';
  funcBtnGroupsBtnAddStatus: 'idle' | 'pending' | 'success' | 'fail';
  funcBtnGroupsBtnRemoveStatus: 'idle' | 'pending' | 'success' | 'fail';
}

const initialState: InitialState = {
  // FUNC BUTTON
  funcBtnList: [],
  selectedFuncBtn: {
    tenantid: '',
    orgcode: '',
    btncode: '',
    btnname: '',
    btntype: 'URL',
    btndelimeter: ',',
    btnurlexe: '',
    btnurlexeopt: '',
    btnpopupname: '',
    btnpopupurl: '',
    btnpopupopt: '',
    btnvisible: '0',
    btnusepopup: '0',
    btnselectuser: '0',
    btninmessenger: '0',
    btnusesvrfile: '0',
    btnsvrfile: 'test',
    btnsvrrunexe: '',
    btnsvrfilesize: 0,
    btnsize: 0,
    btnsvrver: '',
    btncomment: '',
    btnimgsrc: 'test',
  },
  // FUNC BUTTON GROUP
  funcBtnGroupList: [],
  selectedFuncBtnGroup: {
    tenantid: '',
    orgcode: '',
    groupcode: '',
    groupkind: 'F',
    groupname: '',
    groupver: 0,
    grouporder: 0,
  },
  // FUNC BUTTON GROUP HUB
  selectedFuncBtnGroupsBtn: null,
  // MODAL
  selectedFuncBtnGroupModal: null,
  // status
  funcBtnStatus: 'idle',
  funcBtnAddStatus: 'idle',
  funcBtnModifyStatus: 'idle',
  funcBtnRemoveStatus: 'idle',
  funcBtnGroupStatus: 'idle',
  funcBtnGroupAddStatus: 'idle',
  funcBtnGroupModifyStatus: 'idle',
  funcBtnGroupRemoveStatus: 'idle',
  funcBtnGroupsBtnStatus: 'idle',
  funcBtnGroupsBtnAddStatus: 'idle',
  funcBtnGroupsBtnRemoveStatus: 'idle',
};

// FUNC BUTTON
// ADD
export const addFuncBtn = createAsyncThunk(
  'funcBtn/add',
  async (funcBtn: IAdminButtonFuncAddApiBody, { rejectWithValue }) => {
    try {
      const response = await adminButtonFuncAddApi(funcBtn);
      return response?.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
        return rejectWithValue(error.message);
      } else {
        console.log('unexpected error: ', error);
        return rejectWithValue('unexpected error');
      }
    }
  },
);
// REMOVE
export const removeFuncBtn = createAsyncThunk(
  'funcBtn/remove',
  async (btncode: string, { rejectWithValue }) => {
    try {
      const response = await adminButtonFuncRemoveApi({ btncode });
      return response?.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
        return rejectWithValue(error.message);
      } else {
        console.log('unexpected error: ', error);
        return rejectWithValue('unexpected error');
      }
    }
  },
);
// MODIFY
export const modifyFuncBtn = createAsyncThunk(
  'funcBtn/modify',
  async (funcBtn: IAdminButtonFuncModifyApiBody, { rejectWithValue }) => {
    try {
      const response = await adminButtonFuncModifyApi(funcBtn);
      return response?.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
        return rejectWithValue(error.message);
      } else {
        console.log('unexpected error: ', error);
        return rejectWithValue('unexpected error');
      }
    }
  },
);

// FUNC BUTTON GROUP
// ADD
export const addFuncBtnGroup = createAsyncThunk(
  'funcBtnGroup/add',
  async (cntBtnGroup: IAdminButtonGroupAddApiBody, { rejectWithValue }) => {
    try {
      const response = await adminButtonGroupAddApi(cntBtnGroup);
      return response?.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
        return rejectWithValue(error.message);
      } else {
        console.log('unexpected error: ', error);
        return rejectWithValue('unexpected error');
      }
    }
  },
);
// REMOVE
export const removeFuncBtnGroup = createAsyncThunk(
  'funcBtnGroup/remove',
  async (groupcode: IAdminButtonGroupRemoveApiBody, { rejectWithValue }) => {
    try {
      const response = await adminButtonGroupRemoveApi(groupcode);
      return response?.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
        return rejectWithValue(error.message);
      } else {
        console.log('unexpected error: ', error);
        return rejectWithValue('unexpected error');
      }
    }
  },
);
// MODIFY
export const modifyFuncBtnGroup = createAsyncThunk(
  'funcBtnGroup/modify',
  async (funcBtnGroup: IAdminButtonGroupModifyApiBody, { rejectWithValue }) => {
    try {
      const response = await adminButtonGroupModifyApi(funcBtnGroup);
      return response?.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
        return rejectWithValue(error.message);
      } else {
        console.log('unexpected error: ', error);
        return rejectWithValue('unexpected error');
      }
    }
  },
);

// FUNC BUTTON GROUP HUB
// ADD
export const addFuncBtnToBtnGroup = createAsyncThunk(
  'funcBtnGroupHub/add',
  async (
    {
      selectedFuncBtn,
      selectedFuncBtnGroup,
    }: {
      selectedFuncBtn: IAdminButtonFunc;
      selectedFuncBtnGroup: IAdminButtonGroup;
    },
    { rejectWithValue },
  ) => {
    // try {
    //   const response = await adminButtonGroupHubAddApi({
    //     groupcode: selectedFuncBtnGroup.groupcode,
    //     btnkind: 'F',
    //     btncode: selectedFuncBtn.btncode,
    //     btnorder: 0,
    //   });
    //   return response?.data;
    // } catch (error) {
    //   return rejectWithValue(String(error));
    // }
    try {
      const response = await adminButtonGroupHubAddApi({
        groupcode: selectedFuncBtnGroup.groupcode,
        btnkind: 'F',
        btncode: selectedFuncBtn.btncode,
        btnorder: 0,
      });
      return response?.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
        return rejectWithValue(error.message);
      } else {
        console.log('unexpected error: ', error);
        return rejectWithValue('unexpected error');
      }
    }
  },
);

// REMOVE
export const removeFuncBtnFromBtnGroup = createAsyncThunk(
  'funcBtnGroupHub/remove',
  async (
    {
      selectedFuncBtnGroupsBtn,
      selectedFuncBtnGroup,
    }: {
      selectedFuncBtnGroupsBtn: IAdminButtonFunc;
      selectedFuncBtnGroup: IAdminButtonGroup;
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await adminButtonGroupHubRemoveApi({
        groupcode: selectedFuncBtnGroup.groupcode,
        btncode: selectedFuncBtnGroupsBtn.btncode,
      });
      return response?.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
        return rejectWithValue(error.message);
      } else {
        console.log('unexpected error: ', error);
        return rejectWithValue('unexpected error');
      }
    }
  },
);

export const funcButtonManagementSlice = createSlice({
  name: 'functionButtonManagement',
  initialState,
  reducers: {
    // INIT
    initFuncBtnMngSlice: () => initialState,
    // FUNC BTN
    setFuncBtnList: (state, action) => {
      state.funcBtnList = action.payload;
    },
    setSelectedFuncBtn: (state, action) => {
      state.selectedFuncBtn = action.payload;
    },
    changeSelectedFuncBtn: (state, action) => {
      state.selectedFuncBtn = {
        ...state.selectedFuncBtn,
        [action.payload.name]: action.payload.value,
      };
    },
    initSelectedFuncBtn: (state) => {
      state.selectedFuncBtn = initialState.selectedFuncBtn;
    },
    // FUNC BTN GROUP
    setFuncBtnGroupList: (
      state,
      { payload: funcBtnGroupList }: { payload: IAdminButtonGroup[] },
    ) => {
      state.funcBtnGroupList = funcBtnGroupList;
    },
    setSelectedFuncBtnGroup: (
      state,
      { payload: funcBtnGroup }: { payload: IAdminButtonGroup },
    ) => {
      state.selectedFuncBtnGroup = funcBtnGroup;
    },
    // FUNC BTN GROUP HUB
    setSelectedFuncBtnGroupsBtn: (
      state,
      { payload: funcBtn }: { payload: IAdminButtonFunc | null },
    ) => {
      state.selectedFuncBtnGroupsBtn = funcBtn;
    },
    // MODAL
    setSelectedFuncBtnGroupModal: (
      state,
      { payload: funcBtnGroup }: { payload: IAdminButtonGroup },
    ) => {
      state.selectedFuncBtnGroupModal = funcBtnGroup;
    },
    initSelectedFuncBtnGroupModal: (state) => {
      state.selectedFuncBtnGroupModal = initialState.selectedFuncBtnGroupModal;
    },
  },
  extraReducers(builder) {
    builder
      // FUNC BTN
      .addCase(addFuncBtn.pending, (state) => {
        state.funcBtnStatus = 'pending';
        state.funcBtnAddStatus = 'pending';
      })
      .addCase(addFuncBtn.fulfilled, (state) => {
        state.funcBtnStatus = 'success';
        state.funcBtnAddStatus = 'success';
      })
      .addCase(addFuncBtn.rejected, (state) => {
        state.funcBtnStatus = 'fail';
        state.funcBtnAddStatus = 'fail';
      })
      .addCase(modifyFuncBtn.pending, (state) => {
        state.funcBtnStatus = 'pending';
        state.funcBtnModifyStatus = 'pending';
      })
      .addCase(modifyFuncBtn.fulfilled, (state) => {
        state.funcBtnStatus = 'success';
        state.funcBtnModifyStatus = 'success';
      })
      .addCase(modifyFuncBtn.rejected, (state) => {
        state.funcBtnStatus = 'fail';
        state.funcBtnModifyStatus = 'fail';
      })
      .addCase(removeFuncBtn.pending, (state) => {
        state.funcBtnStatus = 'pending';
        state.funcBtnRemoveStatus = 'pending';
      })
      .addCase(removeFuncBtn.fulfilled, (state) => {
        state.funcBtnStatus = 'success';
        state.funcBtnRemoveStatus = 'success';
      })
      .addCase(removeFuncBtn.rejected, (state) => {
        state.funcBtnStatus = 'fail';
        state.funcBtnRemoveStatus = 'fail';
      })
      // FUNC BTN GROUP
      .addCase(addFuncBtnGroup.pending, (state) => {
        state.funcBtnGroupStatus = 'pending';
        state.funcBtnGroupAddStatus = 'pending';
      })
      .addCase(addFuncBtnGroup.fulfilled, (state) => {
        state.funcBtnGroupStatus = 'success';
        state.funcBtnGroupAddStatus = 'success';
      })
      .addCase(addFuncBtnGroup.rejected, (state) => {
        state.funcBtnGroupStatus = 'fail';
        state.funcBtnGroupAddStatus = 'fail';
      })
      .addCase(modifyFuncBtnGroup.pending, (state) => {
        state.funcBtnGroupStatus = 'pending';
        state.funcBtnGroupModifyStatus = 'pending';
      })
      .addCase(modifyFuncBtnGroup.fulfilled, (state) => {
        state.funcBtnGroupStatus = 'success';
        state.funcBtnGroupModifyStatus = 'success';
      })
      .addCase(modifyFuncBtnGroup.rejected, (state) => {
        state.funcBtnGroupStatus = 'fail';
        state.funcBtnGroupModifyStatus = 'fail';
      })
      .addCase(removeFuncBtnGroup.pending, (state) => {
        state.funcBtnGroupStatus = 'pending';
        state.funcBtnGroupRemoveStatus = 'pending';
      })
      .addCase(removeFuncBtnGroup.fulfilled, (state) => {
        state.funcBtnGroupStatus = 'success';
        state.funcBtnGroupRemoveStatus = 'success';
      })
      .addCase(removeFuncBtnGroup.rejected, (state) => {
        state.funcBtnGroupStatus = 'fail';
        state.funcBtnGroupRemoveStatus = 'fail';
      })
      // FUNC BTN GROUP HUB
      // ADD
      .addCase(addFuncBtnToBtnGroup.pending, (state) => {
        state.funcBtnGroupsBtnStatus = 'pending';
        state.funcBtnGroupsBtnAddStatus = 'pending';
      })
      .addCase(addFuncBtnToBtnGroup.fulfilled, (state) => {
        state.funcBtnGroupsBtnStatus = 'success';
        state.funcBtnGroupsBtnAddStatus = 'success';
      })
      .addCase(addFuncBtnToBtnGroup.rejected, (state) => {
        state.funcBtnGroupsBtnStatus = 'fail';
        state.funcBtnGroupsBtnAddStatus = 'fail';
      })
      //REMOVE
      .addCase(removeFuncBtnFromBtnGroup.pending, (state) => {
        state.funcBtnGroupsBtnStatus = 'pending';
        state.funcBtnGroupsBtnRemoveStatus = 'pending';
      })
      .addCase(removeFuncBtnFromBtnGroup.fulfilled, (state) => {
        state.funcBtnGroupsBtnStatus = 'success';
        state.funcBtnGroupsBtnRemoveStatus = 'success';
      })
      .addCase(removeFuncBtnFromBtnGroup.rejected, (state) => {
        state.funcBtnGroupsBtnStatus = 'fail';
        state.funcBtnGroupsBtnRemoveStatus = 'fail';
      });
  },
});

export const {
  initFuncBtnMngSlice,
  setFuncBtnList,
  setSelectedFuncBtn,
  changeSelectedFuncBtn,
  initSelectedFuncBtn,
  setFuncBtnGroupList,
  setSelectedFuncBtnGroup,
  setSelectedFuncBtnGroupsBtn,
  setSelectedFuncBtnGroupModal,
  initSelectedFuncBtnGroupModal,
} = funcButtonManagementSlice.actions;

export default funcButtonManagementSlice.reducer;
