import { ICellRendererParams, ValueGetterParams } from 'ag-grid-community';
import { IfedListItem } from '../interfaces/api/service-provider/I-api-admin-serviceProvider-fed';
import { IspUksGetsApiResponse } from '../interfaces/api/service-provider/I-api-admin-sp-uks';
import { t } from 'i18next';

function padTo2Digits(num: number) {
  return num.toString().padStart(2, '0');
}

function formatDate(date: Date) {
  return (
    [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-') +
    ' ' +
    [
      padTo2Digits(date.getHours()),
      padTo2Digits(date.getMinutes()),
      padTo2Digits(date.getSeconds()),
    ].join(':')
  );
}

const translateToUtcDateForm = (date: string) => {
  const year = date.slice(0, 4);
  const month = date.slice(4, 6);
  const day = date.slice(6, 8);
  const hour = date.slice(8, 10);
  const min = date.slice(10, 12);
  const sec = date.slice(12, 14);

  const utcDateForm = `${year}-${month}-${day}T${hour}:${min}:${sec}Z`;
  return utcDateForm;
};

export function dateValueGetter(params: ValueGetterParams<any>) {
  if (!params.data) return '';
  const localeDate = new Date(translateToUtcDateForm(params.data.updatetime));
  const finalDate = formatDate(localeDate);
  return finalDate;
}

export function uksGetsDateValueGetter(
  params: ValueGetterParams<IspUksGetsApiResponse>,
) {
  if (!params.data) return '';

  const localeDate = new Date(params.data.creationTimestamp);
  const finalDate = formatDate(localeDate);
  return finalDate;
}

export const CustomCell_fed = (props: ICellRendererParams<IfedListItem>) => {
  if (!props.data) return;

  let bgColor: string;
  let label: string;

  switch (props.data.status) {
    case 0:
      bgColor = '#c8cace';
      label = t('ServiceMornitoring.h-disconnectionComplete');
      break;
    case 1:
      bgColor = '#00bbff';
      label = t('ServiceMornitoring.h-connectionRequesting');
      break;
    case 2:
      bgColor = '#008cff';
      label = t('ServiceMornitoring.h-connectionRequestReceived');
      break;
    case 3:
      bgColor = '#105d9c';
      label = t('ServiceMornitoring.h-connectionAccepted');
      break;
    case 4:
      bgColor = '#fa164f';
      label = t('ServiceMornitoring.h-connectionRejected');
      break;
    case 5:
      bgColor = '#4719a5';
      label = t('ServiceMornitoring.h-disconnectionRequesting');
      break;
    case 6:
      bgColor = '#6c70ff';
      label = t('ServiceMornitoring.h-disconnectionRequestReceived');
      break;
    case 7:
      bgColor = '#0c814a';
      label = t('ServiceMornitoring.h-disconnectionAccepted');
      break;
    case 8:
      bgColor = '#0cd17f';
      label = t('ServiceMornitoring.h-connected');
      break;
    case 9:
      bgColor = '#fc285d';
      label = t('ServiceMornitoring.h-disconnectionRejected');
      break;
    default:
      bgColor = '#c8cace';
      label = 'default';
      break;
  }

  return (
    <div
      className="bg-[#9bd00d] rounded  text-white font-bold truncate text-center"
      style={{
        backgroundColor: bgColor,
      }}
    >
      {label}
    </div>
  );
};
