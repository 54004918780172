// style
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../../../styles/ag-grid.css';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { CellClickedEvent, ColDef } from 'ag-grid-community';
import { RULE_FUNC_HUB_LIST_TALBLE_COLUMN } from './columns';
import { AgGridReact } from 'ag-grid-react';
import { IAdminButtonGroupHub } from '../../../interfaces/api/button/I-api-admin-btn-group-hub';
import { fetchRuleFuncHubList } from '../../../helpers/h-ruleFuncMng';
import {
  IadminRulefuncHub,
  IadminRuleFuncHubModifyApiBody,
  IruleFuncHubOption,
} from '../../../interfaces/api/rule/I-api-admin-rule-func-hub';
import {
  initRuleFuncHubList,
  initSelectedRuleFuncHub,
  setRuleFuncHubOption,
  setSelectedRuleFuncHub,
} from '../../../redux/slice/ruleFunctionManagementSlice';
import {
  IadminRuleFunc,
  IadminRuleFuncHunListInfo,
} from '../../../interfaces/api/rule/I-api-admin-rule-func';
import { adminRuleFuncInfoApi } from '../../../api/admin/rule/api-admin-rule-func';
import axios from 'axios';

const RuleFuncMngFuncHubList_Table = () => {
  const dispatch = useAppDispatch();
  // STATE
  const { ruleCodeList, selectedRuleCode, ruleFuncHubStatus, ruleCodeStatus } =
    useAppSelector((state) => state.ruleFunctionManagement);
  const [selectedBtnGroupsBtnCodeList, setSelectedBtnGroupsBtnCodeList] =
    useState<IAdminButtonGroupHub[]>([]);
  const [ruleFuncHubList, setRuleFuncHubList] = useState<IadminRulefuncHub[]>(
    [],
  );
  const [ruleFuncInfoList, setRuleFuncInfoList] = useState<IadminRuleFunc[]>(
    [],
  );
  const [selectedRuleFuncKey, setSelectedRuleFuncKey] = useState<string | null>(
    null,
  );

  // AG_GRID
  const [columnDefs, setColumnDefs] = useState<ColDef[]>(
    RULE_FUNC_HUB_LIST_TALBLE_COLUMN,
  );
  const [rowData, setRowData] = useState<IadminRuleFuncHunListInfo[]>([]);
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      resizable: true,
      minWidth: 100,
    };
  }, []);

  // 버튼그룹의 버튼 선택시..
  const cellClickedListener = useCallback(
    (event: CellClickedEvent<IadminRuleFuncHunListInfo>) => {
      if (!event.data) return;

      setSelectedRuleFuncKey(event.data.rulefunckey);

      const rulefuncHub: IadminRuleFuncHubModifyApiBody = {
        rulefunckey: event.data.rulefunckey,
        rulecode: event.data.rulecode,
        funccode: event.data.funccode,
        funcvalue1: event.data.funcvalue1,
        funcvalue2: event.data.funcvalue2,
        funcorder: event.data.funcorder,
      };
      const ruleFuncHubOption: IruleFuncHubOption = event.data;

      dispatch(setSelectedRuleFuncHub(rulefuncHub));
      dispatch(setRuleFuncHubOption(ruleFuncHubOption));

      // console.log('rulefuncHub 의 정보', rulefuncHub);
    },
    [ruleFuncHubList],
  );

  // func/hub/list 받아서 func/info 조회후에 func/info/list 반환
  const fetchRuleFuncInfo = async (ruleFuncHubList: IadminRulefuncHub[]) => {
    const ruleFuncInfoList: IadminRuleFunc[] = [];
    try {
      for (const funcHub of ruleFuncHubList) {
        const res = await adminRuleFuncInfoApi({ funccode: funcHub.funccode });
        if (!res) return;

        const ruleFuncInfo = res.data.data.result;
        ruleFuncInfoList.push(ruleFuncInfo);
      }
      setRuleFuncInfoList(ruleFuncInfoList);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
      } else {
        console.log('unexpected error: ', error);
      }
    }
  };

  /**
   * rule/func/hub/list 가져오기
   */
  const fetchData = async () => {
    const ruleFuncHubList = await fetchRuleFuncHubList(
      selectedRuleCode.rulecode,
    );
    if (!ruleFuncHubList) return;

    setRuleFuncHubList(ruleFuncHubList);
  };

  // rule/func/hub/modify 액션 발생시 선택한 항목의 정보갱신을 위한 함수
  useEffect(() => {
    if (!rowData) return;
    if (!selectedRuleFuncKey) return;

    const [ruleFuncHubOptionInfo] = rowData.filter(
      (v) => v.rulefunckey === selectedRuleFuncKey,
    );

    if (!ruleFuncHubOptionInfo) return;

    dispatch(setRuleFuncHubOption(ruleFuncHubOptionInfo));
  }, [rowData, selectedRuleFuncKey]);

  // SET AG_rowData
  useEffect(() => {
    if (ruleFuncInfoList.length < 1) {
      setRowData([]);
      return;
    }

    const ROWDATA: IadminRuleFuncHunListInfo[] = [];

    let index = 0;
    while (index < ruleFuncInfoList.length) {
      const dataObj: IadminRuleFuncHunListInfo = {
        funcname: ruleFuncInfoList[index].funcname,
        funckind: ruleFuncInfoList[index].funckind,
        funcfield1: ruleFuncInfoList[index].funcfield1,
        funcfield2: ruleFuncInfoList[index].funcfield2,
        defaultvalue: ruleFuncInfoList[index].defaultvalue,
        funcvalue1: ruleFuncHubList[index].funcvalue1,
        funcvalue2: ruleFuncHubList[index].funcvalue2,
        rulefunckey: ruleFuncHubList[index].rulefunckey,
        rulecode: ruleFuncHubList[index].rulecode,
        funccode: ruleFuncHubList[index].funccode,
        funcorder: ruleFuncHubList[index].funcorder,
      };

      ROWDATA.push(dataObj);
      index++;
    }

    setRowData(ROWDATA);
  }, [ruleFuncInfoList]);

  // rule/func/info/list 구하기
  useEffect(() => {
    fetchRuleFuncInfo(ruleFuncHubList);
  }, [ruleFuncHubList]);

  // rule/func/hub/list 구하기
  useEffect(() => {
    if (selectedRuleCode.rulecode.length < 1) {
      dispatch(initRuleFuncHubList());
      return;
    }

    fetchData();
  }, [selectedRuleCode]);

  // 옵저버
  useEffect(() => {
    if (ruleFuncHubStatus !== 'success') return;

    fetchData();
  }, [ruleFuncHubStatus]);

  useEffect(() => {
    if (ruleCodeList.length < 1) {
      setRowData([]);
      dispatch(initRuleFuncHubList());
      dispatch(initSelectedRuleFuncHub());

      return;
    }
  }, [ruleCodeList]);

  return (
    <div className="w-full grow flex pb-2 flex-col overflow-hidden">
      <div className="ag-theme-alpine ag-theme-mycustomtheme w-full grow">
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowSelection={'single'}
          onCellClicked={cellClickedListener}
          overlayNoRowsTemplate={'\xa0'}
        ></AgGridReact>
      </div>
    </div>
  );
};

export default RuleFuncMngFuncHubList_Table;
